import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  json: any;
  numeric: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "book" */
export type Book = {
  __typename?: 'book';
  allowinstantmeetings: Scalars['Boolean'];
  application?: Maybe<Scalars['json']>;
  approved?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  book_topics: Array<Book_Topic>;
  /** An aggregate relationship */
  book_topics_aggregate: Book_Topic_Aggregate;
  /** An array relationship */
  bookevents: Array<Bookevent>;
  /** An aggregate relationship */
  bookevents_aggregate: Bookevent_Aggregate;
  /** An array relationship */
  booklanguages: Array<Booklanguage>;
  /** An aggregate relationship */
  booklanguages_aggregate: Booklanguage_Aggregate;
  created: Scalars['timestamp'];
  currentbookstate_id: Scalars['uuid'];
  /** An object relationship */
  currentstate: Bookstate;
  id: Scalars['uuid'];
  maxreaders?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "book_person_pronouns" */
  pronouns?: Maybe<Scalars['String']>;
  /** An array relationship */
  readings: Array<Reading>;
  /** An aggregate relationship */
  readings_aggregate: Reading_Aggregate;
  /** A computed field, executes function "get_book_topic_name" */
  topicName?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "book" */
export type BookApplicationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "book" */
export type BookBook_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


/** columns and relationships of "book" */
export type BookBook_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


/** columns and relationships of "book" */
export type BookBookeventsArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


/** columns and relationships of "book" */
export type BookBookevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


/** columns and relationships of "book" */
export type BookBooklanguagesArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


/** columns and relationships of "book" */
export type BookBooklanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


/** columns and relationships of "book" */
export type BookReadingsArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


/** columns and relationships of "book" */
export type BookReadings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};

/** aggregated selection of "book" */
export type Book_Aggregate = {
  __typename?: 'book_aggregate';
  aggregate?: Maybe<Book_Aggregate_Fields>;
  nodes: Array<Book>;
};

export type Book_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Book_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Book_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Book_Aggregate_Bool_Exp_Count>;
};

export type Book_Aggregate_Bool_Exp_Bool_And = {
  arguments: Book_Select_Column_Book_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Book_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Book_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Book_Select_Column_Book_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Book_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Book_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Book_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Book_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "book" */
export type Book_Aggregate_Fields = {
  __typename?: 'book_aggregate_fields';
  avg?: Maybe<Book_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Book_Max_Fields>;
  min?: Maybe<Book_Min_Fields>;
  stddev?: Maybe<Book_Stddev_Fields>;
  stddev_pop?: Maybe<Book_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Book_Stddev_Samp_Fields>;
  sum?: Maybe<Book_Sum_Fields>;
  var_pop?: Maybe<Book_Var_Pop_Fields>;
  var_samp?: Maybe<Book_Var_Samp_Fields>;
  variance?: Maybe<Book_Variance_Fields>;
};


/** aggregate fields of "book" */
export type Book_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Book_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "book" */
export type Book_Aggregate_Order_By = {
  avg?: InputMaybe<Book_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Book_Max_Order_By>;
  min?: InputMaybe<Book_Min_Order_By>;
  stddev?: InputMaybe<Book_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Book_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Book_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Book_Sum_Order_By>;
  var_pop?: InputMaybe<Book_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Book_Var_Samp_Order_By>;
  variance?: InputMaybe<Book_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "book" */
export type Book_Arr_Rel_Insert_Input = {
  data: Array<Book_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Book_On_Conflict>;
};

/** aggregate avg on columns */
export type Book_Avg_Fields = {
  __typename?: 'book_avg_fields';
  maxreaders?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "book" */
export type Book_Avg_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "book". All fields are combined with a logical 'AND'. */
export type Book_Bool_Exp = {
  _and?: InputMaybe<Array<Book_Bool_Exp>>;
  _not?: InputMaybe<Book_Bool_Exp>;
  _or?: InputMaybe<Array<Book_Bool_Exp>>;
  allowinstantmeetings?: InputMaybe<Boolean_Comparison_Exp>;
  application?: InputMaybe<Json_Comparison_Exp>;
  approved?: InputMaybe<Timestamp_Comparison_Exp>;
  book_topics?: InputMaybe<Book_Topic_Bool_Exp>;
  book_topics_aggregate?: InputMaybe<Book_Topic_Aggregate_Bool_Exp>;
  bookevents?: InputMaybe<Bookevent_Bool_Exp>;
  bookevents_aggregate?: InputMaybe<Bookevent_Aggregate_Bool_Exp>;
  booklanguages?: InputMaybe<Booklanguage_Bool_Exp>;
  booklanguages_aggregate?: InputMaybe<Booklanguage_Aggregate_Bool_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  currentbookstate_id?: InputMaybe<Uuid_Comparison_Exp>;
  currentstate?: InputMaybe<Bookstate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maxreaders?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pronouns?: InputMaybe<String_Comparison_Exp>;
  readings?: InputMaybe<Reading_Bool_Exp>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Bool_Exp>;
  topicName?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "book" */
export enum Book_Constraint {
  /** unique or primary key constraint on columns "id" */
  BookPk = 'book_pk'
}

export type Book_Get_Number_Of_Readings_Args = {
  book_user_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** input type for incrementing numeric columns in table "book" */
export type Book_Inc_Input = {
  maxreaders?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "book" */
export type Book_Insert_Input = {
  allowinstantmeetings?: InputMaybe<Scalars['Boolean']>;
  application?: InputMaybe<Scalars['json']>;
  approved?: InputMaybe<Scalars['timestamp']>;
  book_topics?: InputMaybe<Book_Topic_Arr_Rel_Insert_Input>;
  bookevents?: InputMaybe<Bookevent_Arr_Rel_Insert_Input>;
  booklanguages?: InputMaybe<Booklanguage_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamp']>;
  currentbookstate_id?: InputMaybe<Scalars['uuid']>;
  currentstate?: InputMaybe<Bookstate_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  readings?: InputMaybe<Reading_Arr_Rel_Insert_Input>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Book_Max_Fields = {
  __typename?: 'book_max_fields';
  approved?: Maybe<Scalars['timestamp']>;
  created?: Maybe<Scalars['timestamp']>;
  currentbookstate_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  maxreaders?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "book_person_pronouns" */
  pronouns?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_book_topic_name" */
  topicName?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "book" */
export type Book_Max_Order_By = {
  approved?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currentbookstate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxreaders?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Book_Min_Fields = {
  __typename?: 'book_min_fields';
  approved?: Maybe<Scalars['timestamp']>;
  created?: Maybe<Scalars['timestamp']>;
  currentbookstate_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  maxreaders?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "book_person_pronouns" */
  pronouns?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_book_topic_name" */
  topicName?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "book" */
export type Book_Min_Order_By = {
  approved?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currentbookstate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxreaders?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "book" */
export type Book_Mutation_Response = {
  __typename?: 'book_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Book>;
};

/** input type for inserting object relation for remote table "book" */
export type Book_Obj_Rel_Insert_Input = {
  data: Book_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Book_On_Conflict>;
};

/** on_conflict condition type for table "book" */
export type Book_On_Conflict = {
  constraint: Book_Constraint;
  update_columns?: Array<Book_Update_Column>;
  where?: InputMaybe<Book_Bool_Exp>;
};

/** Ordering options when selecting data from "book". */
export type Book_Order_By = {
  allowinstantmeetings?: InputMaybe<Order_By>;
  application?: InputMaybe<Order_By>;
  approved?: InputMaybe<Order_By>;
  book_topics_aggregate?: InputMaybe<Book_Topic_Aggregate_Order_By>;
  bookevents_aggregate?: InputMaybe<Bookevent_Aggregate_Order_By>;
  booklanguages_aggregate?: InputMaybe<Booklanguage_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  currentbookstate_id?: InputMaybe<Order_By>;
  currentstate?: InputMaybe<Bookstate_Order_By>;
  id?: InputMaybe<Order_By>;
  maxreaders?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Order_By>;
  topicName?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: book */
export type Book_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "book" */
export enum Book_Select_Column {
  /** column name */
  Allowinstantmeetings = 'allowinstantmeetings',
  /** column name */
  Application = 'application',
  /** column name */
  Approved = 'approved',
  /** column name */
  Created = 'created',
  /** column name */
  CurrentbookstateId = 'currentbookstate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Maxreaders = 'maxreaders',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** select "book_aggregate_bool_exp_bool_and_arguments_columns" columns of table "book" */
export enum Book_Select_Column_Book_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Allowinstantmeetings = 'allowinstantmeetings'
}

/** select "book_aggregate_bool_exp_bool_or_arguments_columns" columns of table "book" */
export enum Book_Select_Column_Book_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Allowinstantmeetings = 'allowinstantmeetings'
}

/** input type for updating data in table "book" */
export type Book_Set_Input = {
  allowinstantmeetings?: InputMaybe<Scalars['Boolean']>;
  application?: InputMaybe<Scalars['json']>;
  approved?: InputMaybe<Scalars['timestamp']>;
  created?: InputMaybe<Scalars['timestamp']>;
  currentbookstate_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Book_Stddev_Fields = {
  __typename?: 'book_stddev_fields';
  maxreaders?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "book" */
export type Book_Stddev_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Book_Stddev_Pop_Fields = {
  __typename?: 'book_stddev_pop_fields';
  maxreaders?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "book" */
export type Book_Stddev_Pop_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Book_Stddev_Samp_Fields = {
  __typename?: 'book_stddev_samp_fields';
  maxreaders?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "book" */
export type Book_Stddev_Samp_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "book" */
export type Book_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Book_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Book_Stream_Cursor_Value_Input = {
  allowinstantmeetings?: InputMaybe<Scalars['Boolean']>;
  application?: InputMaybe<Scalars['json']>;
  approved?: InputMaybe<Scalars['timestamp']>;
  created?: InputMaybe<Scalars['timestamp']>;
  currentbookstate_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Book_Sum_Fields = {
  __typename?: 'book_sum_fields';
  maxreaders?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "book" */
export type Book_Sum_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** columns and relationships of "book_topic" */
export type Book_Topic = {
  __typename?: 'book_topic';
  /** An object relationship */
  book: Book;
  book_id: Scalars['uuid'];
  created: Scalars['timestamp'];
  /** An object relationship */
  topic: Topic;
  topic_id: Scalars['uuid'];
  topicname?: Maybe<Scalars['String']>;
};

/** aggregated selection of "book_topic" */
export type Book_Topic_Aggregate = {
  __typename?: 'book_topic_aggregate';
  aggregate?: Maybe<Book_Topic_Aggregate_Fields>;
  nodes: Array<Book_Topic>;
};

export type Book_Topic_Aggregate_Bool_Exp = {
  count?: InputMaybe<Book_Topic_Aggregate_Bool_Exp_Count>;
};

export type Book_Topic_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Book_Topic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Book_Topic_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "book_topic" */
export type Book_Topic_Aggregate_Fields = {
  __typename?: 'book_topic_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Book_Topic_Max_Fields>;
  min?: Maybe<Book_Topic_Min_Fields>;
};


/** aggregate fields of "book_topic" */
export type Book_Topic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Book_Topic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "book_topic" */
export type Book_Topic_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Book_Topic_Max_Order_By>;
  min?: InputMaybe<Book_Topic_Min_Order_By>;
};

/** input type for inserting array relation for remote table "book_topic" */
export type Book_Topic_Arr_Rel_Insert_Input = {
  data: Array<Book_Topic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Book_Topic_On_Conflict>;
};

/** Boolean expression to filter rows from the table "book_topic". All fields are combined with a logical 'AND'. */
export type Book_Topic_Bool_Exp = {
  _and?: InputMaybe<Array<Book_Topic_Bool_Exp>>;
  _not?: InputMaybe<Book_Topic_Bool_Exp>;
  _or?: InputMaybe<Array<Book_Topic_Bool_Exp>>;
  book?: InputMaybe<Book_Bool_Exp>;
  book_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topic_id?: InputMaybe<Uuid_Comparison_Exp>;
  topicname?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "book_topic" */
export enum Book_Topic_Constraint {
  /** unique or primary key constraint on columns "topic_id", "book_id" */
  BookTopicPk = 'book_topic_pk'
}

/** input type for inserting data into table "book_topic" */
export type Book_Topic_Insert_Input = {
  book?: InputMaybe<Book_Obj_Rel_Insert_Input>;
  book_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: InputMaybe<Scalars['uuid']>;
  topicname?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Book_Topic_Max_Fields = {
  __typename?: 'book_topic_max_fields';
  book_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  topic_id?: Maybe<Scalars['uuid']>;
  topicname?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "book_topic" */
export type Book_Topic_Max_Order_By = {
  book_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  topicname?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Book_Topic_Min_Fields = {
  __typename?: 'book_topic_min_fields';
  book_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  topic_id?: Maybe<Scalars['uuid']>;
  topicname?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "book_topic" */
export type Book_Topic_Min_Order_By = {
  book_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  topicname?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "book_topic" */
export type Book_Topic_Mutation_Response = {
  __typename?: 'book_topic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Book_Topic>;
};

/** on_conflict condition type for table "book_topic" */
export type Book_Topic_On_Conflict = {
  constraint: Book_Topic_Constraint;
  update_columns?: Array<Book_Topic_Update_Column>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};

/** Ordering options when selecting data from "book_topic". */
export type Book_Topic_Order_By = {
  book?: InputMaybe<Book_Order_By>;
  book_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topic_id?: InputMaybe<Order_By>;
  topicname?: InputMaybe<Order_By>;
};

/** primary key columns input for table: book_topic */
export type Book_Topic_Pk_Columns_Input = {
  book_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};

/** select columns of table "book_topic" */
export enum Book_Topic_Select_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  Created = 'created',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Topicname = 'topicname'
}

/** input type for updating data in table "book_topic" */
export type Book_Topic_Set_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
  topicname?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "book_topic" */
export type Book_Topic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Book_Topic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Book_Topic_Stream_Cursor_Value_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
  topicname?: InputMaybe<Scalars['String']>;
};

/** update columns of table "book_topic" */
export enum Book_Topic_Update_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  Created = 'created',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Topicname = 'topicname'
}

export type Book_Topic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Book_Topic_Set_Input>;
  /** filter the rows which have to be updated */
  where: Book_Topic_Bool_Exp;
};

/** update columns of table "book" */
export enum Book_Update_Column {
  /** column name */
  Allowinstantmeetings = 'allowinstantmeetings',
  /** column name */
  Application = 'application',
  /** column name */
  Approved = 'approved',
  /** column name */
  Created = 'created',
  /** column name */
  CurrentbookstateId = 'currentbookstate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Maxreaders = 'maxreaders',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

export type Book_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Book_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Book_Set_Input>;
  /** filter the rows which have to be updated */
  where: Book_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Book_Var_Pop_Fields = {
  __typename?: 'book_var_pop_fields';
  maxreaders?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "book" */
export type Book_Var_Pop_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Book_Var_Samp_Fields = {
  __typename?: 'book_var_samp_fields';
  maxreaders?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "book" */
export type Book_Var_Samp_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Book_Variance_Fields = {
  __typename?: 'book_variance_fields';
  maxreaders?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "book" */
export type Book_Variance_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
};

/** columns and relationships of "bookevent" */
export type Bookevent = {
  __typename?: 'bookevent';
  /** An object relationship */
  book: Book;
  book_id: Scalars['uuid'];
  /** An object relationship */
  bookstate: Bookstate;
  bookstate_id: Scalars['uuid'];
  comment?: Maybe<Scalars['String']>;
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "bookevent" */
export type Bookevent_Aggregate = {
  __typename?: 'bookevent_aggregate';
  aggregate?: Maybe<Bookevent_Aggregate_Fields>;
  nodes: Array<Bookevent>;
};

export type Bookevent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bookevent_Aggregate_Bool_Exp_Count>;
};

export type Bookevent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bookevent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bookevent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bookevent" */
export type Bookevent_Aggregate_Fields = {
  __typename?: 'bookevent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bookevent_Max_Fields>;
  min?: Maybe<Bookevent_Min_Fields>;
};


/** aggregate fields of "bookevent" */
export type Bookevent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bookevent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bookevent" */
export type Bookevent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bookevent_Max_Order_By>;
  min?: InputMaybe<Bookevent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bookevent" */
export type Bookevent_Arr_Rel_Insert_Input = {
  data: Array<Bookevent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bookevent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bookevent". All fields are combined with a logical 'AND'. */
export type Bookevent_Bool_Exp = {
  _and?: InputMaybe<Array<Bookevent_Bool_Exp>>;
  _not?: InputMaybe<Bookevent_Bool_Exp>;
  _or?: InputMaybe<Array<Bookevent_Bool_Exp>>;
  book?: InputMaybe<Book_Bool_Exp>;
  book_id?: InputMaybe<Uuid_Comparison_Exp>;
  bookstate?: InputMaybe<Bookstate_Bool_Exp>;
  bookstate_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookevent" */
export enum Bookevent_Constraint {
  /** unique or primary key constraint on columns "id" */
  BookeventPk = 'bookevent_pk'
}

/** input type for inserting data into table "bookevent" */
export type Bookevent_Insert_Input = {
  book?: InputMaybe<Book_Obj_Rel_Insert_Input>;
  book_id?: InputMaybe<Scalars['uuid']>;
  bookstate?: InputMaybe<Bookstate_Obj_Rel_Insert_Input>;
  bookstate_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bookevent_Max_Fields = {
  __typename?: 'bookevent_max_fields';
  book_id?: Maybe<Scalars['uuid']>;
  bookstate_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "bookevent" */
export type Bookevent_Max_Order_By = {
  book_id?: InputMaybe<Order_By>;
  bookstate_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bookevent_Min_Fields = {
  __typename?: 'bookevent_min_fields';
  book_id?: Maybe<Scalars['uuid']>;
  bookstate_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "bookevent" */
export type Bookevent_Min_Order_By = {
  book_id?: InputMaybe<Order_By>;
  bookstate_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bookevent" */
export type Bookevent_Mutation_Response = {
  __typename?: 'bookevent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bookevent>;
};

/** on_conflict condition type for table "bookevent" */
export type Bookevent_On_Conflict = {
  constraint: Bookevent_Constraint;
  update_columns?: Array<Bookevent_Update_Column>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};

/** Ordering options when selecting data from "bookevent". */
export type Bookevent_Order_By = {
  book?: InputMaybe<Book_Order_By>;
  book_id?: InputMaybe<Order_By>;
  bookstate?: InputMaybe<Bookstate_Order_By>;
  bookstate_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bookevent */
export type Bookevent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bookevent" */
export enum Bookevent_Select_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  BookstateId = 'bookstate_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "bookevent" */
export type Bookevent_Set_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  bookstate_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bookevent" */
export type Bookevent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bookevent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bookevent_Stream_Cursor_Value_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  bookstate_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "bookevent" */
export enum Bookevent_Update_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  BookstateId = 'bookstate_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Bookevent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bookevent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bookevent_Bool_Exp;
};

/** columns and relationships of "booklanguage" */
export type Booklanguage = {
  __typename?: 'booklanguage';
  /** An object relationship */
  book: Book;
  book_id: Scalars['uuid'];
  created: Scalars['timestamp'];
  /** An object relationship */
  language: Language;
  language_id: Scalars['uuid'];
};

/** aggregated selection of "booklanguage" */
export type Booklanguage_Aggregate = {
  __typename?: 'booklanguage_aggregate';
  aggregate?: Maybe<Booklanguage_Aggregate_Fields>;
  nodes: Array<Booklanguage>;
};

export type Booklanguage_Aggregate_Bool_Exp = {
  count?: InputMaybe<Booklanguage_Aggregate_Bool_Exp_Count>;
};

export type Booklanguage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Booklanguage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Booklanguage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "booklanguage" */
export type Booklanguage_Aggregate_Fields = {
  __typename?: 'booklanguage_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Booklanguage_Max_Fields>;
  min?: Maybe<Booklanguage_Min_Fields>;
};


/** aggregate fields of "booklanguage" */
export type Booklanguage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Booklanguage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "booklanguage" */
export type Booklanguage_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Booklanguage_Max_Order_By>;
  min?: InputMaybe<Booklanguage_Min_Order_By>;
};

/** input type for inserting array relation for remote table "booklanguage" */
export type Booklanguage_Arr_Rel_Insert_Input = {
  data: Array<Booklanguage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Booklanguage_On_Conflict>;
};

/** Boolean expression to filter rows from the table "booklanguage". All fields are combined with a logical 'AND'. */
export type Booklanguage_Bool_Exp = {
  _and?: InputMaybe<Array<Booklanguage_Bool_Exp>>;
  _not?: InputMaybe<Booklanguage_Bool_Exp>;
  _or?: InputMaybe<Array<Booklanguage_Bool_Exp>>;
  book?: InputMaybe<Book_Bool_Exp>;
  book_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  language?: InputMaybe<Language_Bool_Exp>;
  language_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "booklanguage" */
export enum Booklanguage_Constraint {
  /** unique or primary key constraint on columns "language_id", "book_id" */
  BooklanguagePk = 'booklanguage_pk'
}

/** input type for inserting data into table "booklanguage" */
export type Booklanguage_Insert_Input = {
  book?: InputMaybe<Book_Obj_Rel_Insert_Input>;
  book_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  language?: InputMaybe<Language_Obj_Rel_Insert_Input>;
  language_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Booklanguage_Max_Fields = {
  __typename?: 'booklanguage_max_fields';
  book_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  language_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "booklanguage" */
export type Booklanguage_Max_Order_By = {
  book_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Booklanguage_Min_Fields = {
  __typename?: 'booklanguage_min_fields';
  book_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  language_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "booklanguage" */
export type Booklanguage_Min_Order_By = {
  book_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "booklanguage" */
export type Booklanguage_Mutation_Response = {
  __typename?: 'booklanguage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Booklanguage>;
};

/** on_conflict condition type for table "booklanguage" */
export type Booklanguage_On_Conflict = {
  constraint: Booklanguage_Constraint;
  update_columns?: Array<Booklanguage_Update_Column>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};

/** Ordering options when selecting data from "booklanguage". */
export type Booklanguage_Order_By = {
  book?: InputMaybe<Book_Order_By>;
  book_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  language?: InputMaybe<Language_Order_By>;
  language_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booklanguage */
export type Booklanguage_Pk_Columns_Input = {
  book_id: Scalars['uuid'];
  language_id: Scalars['uuid'];
};

/** select columns of table "booklanguage" */
export enum Booklanguage_Select_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  Created = 'created',
  /** column name */
  LanguageId = 'language_id'
}

/** input type for updating data in table "booklanguage" */
export type Booklanguage_Set_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  language_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "booklanguage" */
export type Booklanguage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Booklanguage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Booklanguage_Stream_Cursor_Value_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  language_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "booklanguage" */
export enum Booklanguage_Update_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  Created = 'created',
  /** column name */
  LanguageId = 'language_id'
}

export type Booklanguage_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Booklanguage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Booklanguage_Bool_Exp;
};

/** columns and relationships of "bookstate" */
export type Bookstate = {
  __typename?: 'bookstate';
  /** An array relationship */
  bookevents: Array<Bookevent>;
  /** An aggregate relationship */
  bookevents_aggregate: Bookevent_Aggregate;
  /** An array relationship */
  books: Array<Book>;
  /** An aggregate relationship */
  books_aggregate: Book_Aggregate;
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "bookstate" */
export type BookstateBookeventsArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


/** columns and relationships of "bookstate" */
export type BookstateBookevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


/** columns and relationships of "bookstate" */
export type BookstateBooksArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


/** columns and relationships of "bookstate" */
export type BookstateBooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};

/** aggregated selection of "bookstate" */
export type Bookstate_Aggregate = {
  __typename?: 'bookstate_aggregate';
  aggregate?: Maybe<Bookstate_Aggregate_Fields>;
  nodes: Array<Bookstate>;
};

/** aggregate fields of "bookstate" */
export type Bookstate_Aggregate_Fields = {
  __typename?: 'bookstate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bookstate_Max_Fields>;
  min?: Maybe<Bookstate_Min_Fields>;
};


/** aggregate fields of "bookstate" */
export type Bookstate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bookstate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bookstate". All fields are combined with a logical 'AND'. */
export type Bookstate_Bool_Exp = {
  _and?: InputMaybe<Array<Bookstate_Bool_Exp>>;
  _not?: InputMaybe<Bookstate_Bool_Exp>;
  _or?: InputMaybe<Array<Bookstate_Bool_Exp>>;
  bookevents?: InputMaybe<Bookevent_Bool_Exp>;
  bookevents_aggregate?: InputMaybe<Bookevent_Aggregate_Bool_Exp>;
  books?: InputMaybe<Book_Bool_Exp>;
  books_aggregate?: InputMaybe<Book_Aggregate_Bool_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookstate" */
export enum Bookstate_Constraint {
  /** unique or primary key constraint on columns "name" */
  BookstateAkName = 'bookstate_ak_name',
  /** unique or primary key constraint on columns "id" */
  BookstatePk = 'bookstate_pk'
}

/** input type for inserting data into table "bookstate" */
export type Bookstate_Insert_Input = {
  bookevents?: InputMaybe<Bookevent_Arr_Rel_Insert_Input>;
  books?: InputMaybe<Book_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bookstate_Max_Fields = {
  __typename?: 'bookstate_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bookstate_Min_Fields = {
  __typename?: 'bookstate_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bookstate" */
export type Bookstate_Mutation_Response = {
  __typename?: 'bookstate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bookstate>;
};

/** input type for inserting object relation for remote table "bookstate" */
export type Bookstate_Obj_Rel_Insert_Input = {
  data: Bookstate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bookstate_On_Conflict>;
};

/** on_conflict condition type for table "bookstate" */
export type Bookstate_On_Conflict = {
  constraint: Bookstate_Constraint;
  update_columns?: Array<Bookstate_Update_Column>;
  where?: InputMaybe<Bookstate_Bool_Exp>;
};

/** Ordering options when selecting data from "bookstate". */
export type Bookstate_Order_By = {
  bookevents_aggregate?: InputMaybe<Bookevent_Aggregate_Order_By>;
  books_aggregate?: InputMaybe<Book_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bookstate */
export type Bookstate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bookstate" */
export enum Bookstate_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "bookstate" */
export type Bookstate_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bookstate" */
export type Bookstate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bookstate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bookstate_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bookstate" */
export enum Bookstate_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Bookstate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bookstate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bookstate_Bool_Exp;
};

/** columns and relationships of "cancel_review" */
export type Cancel_Review = {
  __typename?: 'cancel_review';
  /** An object relationship */
  reading: Reading;
  reading_id: Scalars['uuid'];
  text?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "cancel_review" */
export type Cancel_Review_Aggregate = {
  __typename?: 'cancel_review_aggregate';
  aggregate?: Maybe<Cancel_Review_Aggregate_Fields>;
  nodes: Array<Cancel_Review>;
};

export type Cancel_Review_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cancel_Review_Aggregate_Bool_Exp_Count>;
};

export type Cancel_Review_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cancel_Review_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cancel_review" */
export type Cancel_Review_Aggregate_Fields = {
  __typename?: 'cancel_review_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cancel_Review_Max_Fields>;
  min?: Maybe<Cancel_Review_Min_Fields>;
};


/** aggregate fields of "cancel_review" */
export type Cancel_Review_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cancel_review" */
export type Cancel_Review_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cancel_Review_Max_Order_By>;
  min?: InputMaybe<Cancel_Review_Min_Order_By>;
};

/** input type for inserting array relation for remote table "cancel_review" */
export type Cancel_Review_Arr_Rel_Insert_Input = {
  data: Array<Cancel_Review_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Cancel_Review_On_Conflict>;
};

/** Boolean expression to filter rows from the table "cancel_review". All fields are combined with a logical 'AND'. */
export type Cancel_Review_Bool_Exp = {
  _and?: InputMaybe<Array<Cancel_Review_Bool_Exp>>;
  _not?: InputMaybe<Cancel_Review_Bool_Exp>;
  _or?: InputMaybe<Array<Cancel_Review_Bool_Exp>>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "cancel_review" */
export enum Cancel_Review_Constraint {
  /** unique or primary key constraint on columns "user_id", "reading_id" */
  CancelReviewPkey = 'cancel_review_pkey'
}

/** input type for inserting data into table "cancel_review" */
export type Cancel_Review_Insert_Input = {
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Cancel_Review_Max_Fields = {
  __typename?: 'cancel_review_max_fields';
  reading_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "cancel_review" */
export type Cancel_Review_Max_Order_By = {
  reading_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cancel_Review_Min_Fields = {
  __typename?: 'cancel_review_min_fields';
  reading_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "cancel_review" */
export type Cancel_Review_Min_Order_By = {
  reading_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cancel_review" */
export type Cancel_Review_Mutation_Response = {
  __typename?: 'cancel_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cancel_Review>;
};

/** on_conflict condition type for table "cancel_review" */
export type Cancel_Review_On_Conflict = {
  constraint: Cancel_Review_Constraint;
  update_columns?: Array<Cancel_Review_Update_Column>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "cancel_review". */
export type Cancel_Review_Order_By = {
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cancel_review */
export type Cancel_Review_Pk_Columns_Input = {
  reading_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "cancel_review" */
export enum Cancel_Review_Select_Column {
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "cancel_review" */
export type Cancel_Review_Set_Input = {
  reading_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "cancel_review" */
export type Cancel_Review_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cancel_Review_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cancel_Review_Stream_Cursor_Value_Input = {
  reading_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "cancel_review" */
export enum Cancel_Review_Update_Column {
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

export type Cancel_Review_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cancel_Review_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cancel_Review_Bool_Exp;
};

/** columns and relationships of "chat_message" */
export type Chat_Message = {
  __typename?: 'chat_message';
  /** An object relationship */
  chatMessageType: Chat_Message_Type;
  /** An object relationship */
  chatRoom?: Maybe<Chat_Room>;
  chat_message_type_id: Scalars['uuid'];
  created: Scalars['timestamptz'];
  /** An object relationship */
  emailChatMessageUnreadReminder?: Maybe<Email_Chat_Message_Unread_Reminder>;
  /** A computed field, executes function "get_chat_message_person_name" */
  fromPersonName?: Maybe<Scalars['String']>;
  /** An object relationship */
  fromUser?: Maybe<User>;
  from_user_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  reading?: Maybe<Reading>;
  reading_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_chat_message_to_user_person_name" */
  toPersonName?: Maybe<Scalars['String']>;
  unread: Scalars['Boolean'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "chat_message" */
export type Chat_Message_Aggregate = {
  __typename?: 'chat_message_aggregate';
  aggregate?: Maybe<Chat_Message_Aggregate_Fields>;
  nodes: Array<Chat_Message>;
};

export type Chat_Message_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Message_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Message_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Message_Aggregate_Bool_Exp_Count>;
};

export type Chat_Message_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Message_Select_Column_Chat_Message_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Message_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Message_Select_Column_Chat_Message_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Message_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Message_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Message_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_message" */
export type Chat_Message_Aggregate_Fields = {
  __typename?: 'chat_message_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Message_Max_Fields>;
  min?: Maybe<Chat_Message_Min_Fields>;
};


/** aggregate fields of "chat_message" */
export type Chat_Message_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Message_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message" */
export type Chat_Message_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Message_Max_Order_By>;
  min?: InputMaybe<Chat_Message_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_message" */
export type Chat_Message_Arr_Rel_Insert_Input = {
  data: Array<Chat_Message_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Message_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_message". All fields are combined with a logical 'AND'. */
export type Chat_Message_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Message_Bool_Exp>>;
  _not?: InputMaybe<Chat_Message_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Message_Bool_Exp>>;
  chatMessageType?: InputMaybe<Chat_Message_Type_Bool_Exp>;
  chatRoom?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_message_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  emailChatMessageUnreadReminder?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
  fromPersonName?: InputMaybe<String_Comparison_Exp>;
  fromUser?: InputMaybe<User_Bool_Exp>;
  from_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  toPersonName?: InputMaybe<String_Comparison_Exp>;
  unread?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message" */
export enum Chat_Message_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessagePkey = 'chat_message_pkey'
}

/** input type for inserting data into table "chat_message" */
export type Chat_Message_Insert_Input = {
  chatMessageType?: InputMaybe<Chat_Message_Type_Obj_Rel_Insert_Input>;
  chatRoom?: InputMaybe<Chat_Room_Obj_Rel_Insert_Input>;
  chat_message_type_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  emailChatMessageUnreadReminder?: InputMaybe<Email_Chat_Message_Unread_Reminder_Obj_Rel_Insert_Input>;
  fromUser?: InputMaybe<User_Obj_Rel_Insert_Input>;
  from_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chat_Message_Max_Fields = {
  __typename?: 'chat_message_max_fields';
  chat_message_type_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_chat_message_person_name" */
  fromPersonName?: Maybe<Scalars['String']>;
  from_user_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_chat_message_to_user_person_name" */
  toPersonName?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "chat_message" */
export type Chat_Message_Max_Order_By = {
  chat_message_type_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Message_Min_Fields = {
  __typename?: 'chat_message_min_fields';
  chat_message_type_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_chat_message_person_name" */
  fromPersonName?: Maybe<Scalars['String']>;
  from_user_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_chat_message_to_user_person_name" */
  toPersonName?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "chat_message" */
export type Chat_Message_Min_Order_By = {
  chat_message_type_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_message" */
export type Chat_Message_Mutation_Response = {
  __typename?: 'chat_message_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Message>;
};

/** on_conflict condition type for table "chat_message" */
export type Chat_Message_On_Conflict = {
  constraint: Chat_Message_Constraint;
  update_columns?: Array<Chat_Message_Update_Column>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message". */
export type Chat_Message_Order_By = {
  chatMessageType?: InputMaybe<Chat_Message_Type_Order_By>;
  chatRoom?: InputMaybe<Chat_Room_Order_By>;
  chat_message_type_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  emailChatMessageUnreadReminder?: InputMaybe<Email_Chat_Message_Unread_Reminder_Order_By>;
  fromPersonName?: InputMaybe<Order_By>;
  fromUser?: InputMaybe<User_Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  toPersonName?: InputMaybe<Order_By>;
  unread?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_message */
export type Chat_Message_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_message" */
export enum Chat_Message_Select_Column {
  /** column name */
  ChatMessageTypeId = 'chat_message_type_id',
  /** column name */
  Created = 'created',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  Unread = 'unread',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** select "chat_message_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_message" */
export enum Chat_Message_Select_Column_Chat_Message_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Unread = 'unread'
}

/** select "chat_message_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_message" */
export enum Chat_Message_Select_Column_Chat_Message_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Unread = 'unread'
}

/** input type for updating data in table "chat_message" */
export type Chat_Message_Set_Input = {
  chat_message_type_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message" */
export type Chat_Message_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Message_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Message_Stream_Cursor_Value_Input = {
  chat_message_type_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  from_user_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  unread?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "chat_message_type" */
export type Chat_Message_Type = {
  __typename?: 'chat_message_type';
  /** An array relationship */
  chat_messages: Array<Chat_Message>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Message_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "chat_message_type" */
export type Chat_Message_TypeChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


/** columns and relationships of "chat_message_type" */
export type Chat_Message_TypeChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};

/** aggregated selection of "chat_message_type" */
export type Chat_Message_Type_Aggregate = {
  __typename?: 'chat_message_type_aggregate';
  aggregate?: Maybe<Chat_Message_Type_Aggregate_Fields>;
  nodes: Array<Chat_Message_Type>;
};

/** aggregate fields of "chat_message_type" */
export type Chat_Message_Type_Aggregate_Fields = {
  __typename?: 'chat_message_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Message_Type_Max_Fields>;
  min?: Maybe<Chat_Message_Type_Min_Fields>;
};


/** aggregate fields of "chat_message_type" */
export type Chat_Message_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Message_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_message_type". All fields are combined with a logical 'AND'. */
export type Chat_Message_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Message_Type_Bool_Exp>>;
  _not?: InputMaybe<Chat_Message_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Message_Type_Bool_Exp>>;
  chat_messages?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message_type" */
export enum Chat_Message_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessageTypePkey = 'chat_message_type_pkey'
}

/** input type for inserting data into table "chat_message_type" */
export type Chat_Message_Type_Insert_Input = {
  chat_messages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chat_Message_Type_Max_Fields = {
  __typename?: 'chat_message_type_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chat_Message_Type_Min_Fields = {
  __typename?: 'chat_message_type_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_message_type" */
export type Chat_Message_Type_Mutation_Response = {
  __typename?: 'chat_message_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Message_Type>;
};

/** input type for inserting object relation for remote table "chat_message_type" */
export type Chat_Message_Type_Obj_Rel_Insert_Input = {
  data: Chat_Message_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Message_Type_On_Conflict>;
};

/** on_conflict condition type for table "chat_message_type" */
export type Chat_Message_Type_On_Conflict = {
  constraint: Chat_Message_Type_Constraint;
  update_columns?: Array<Chat_Message_Type_Update_Column>;
  where?: InputMaybe<Chat_Message_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message_type". */
export type Chat_Message_Type_Order_By = {
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_message_type */
export type Chat_Message_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_message_type" */
export enum Chat_Message_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "chat_message_type" */
export type Chat_Message_Type_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_type" */
export type Chat_Message_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Message_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Message_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_type" */
export enum Chat_Message_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Chat_Message_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Message_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Message_Type_Bool_Exp;
};

/** update columns of table "chat_message" */
export enum Chat_Message_Update_Column {
  /** column name */
  ChatMessageTypeId = 'chat_message_type_id',
  /** column name */
  Created = 'created',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  Unread = 'unread',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Chat_Message_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Message_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Message_Bool_Exp;
};

/** columns and relationships of "chat_reading_message" */
export type Chat_Reading_Message = {
  __typename?: 'chat_reading_message';
  created: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  /** A computed field, executes function "reading_room_message_participationevent_name" */
  participationEventName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_person_name" */
  personName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_person_pronouns" */
  personPronouns?: Maybe<Scalars['String']>;
  /** An object relationship */
  reading: Reading;
  reading_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "chat_reading_message" */
export type Chat_Reading_Message_Aggregate = {
  __typename?: 'chat_reading_message_aggregate';
  aggregate?: Maybe<Chat_Reading_Message_Aggregate_Fields>;
  nodes: Array<Chat_Reading_Message>;
};

/** aggregate fields of "chat_reading_message" */
export type Chat_Reading_Message_Aggregate_Fields = {
  __typename?: 'chat_reading_message_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Reading_Message_Max_Fields>;
  min?: Maybe<Chat_Reading_Message_Min_Fields>;
};


/** aggregate fields of "chat_reading_message" */
export type Chat_Reading_Message_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Reading_Message_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_reading_message". All fields are combined with a logical 'AND'. */
export type Chat_Reading_Message_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Reading_Message_Bool_Exp>>;
  _not?: InputMaybe<Chat_Reading_Message_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Reading_Message_Bool_Exp>>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  participationEventName?: InputMaybe<String_Comparison_Exp>;
  personName?: InputMaybe<String_Comparison_Exp>;
  personPronouns?: InputMaybe<String_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_reading_message" */
export enum Chat_Reading_Message_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatReadingMessagePkey = 'chat_reading_message_pkey'
}

/** input type for inserting data into table "chat_reading_message" */
export type Chat_Reading_Message_Insert_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Chat_Reading_Message_Max_Fields = {
  __typename?: 'chat_reading_message_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_participationevent_name" */
  participationEventName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_person_name" */
  personName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_person_pronouns" */
  personPronouns?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Chat_Reading_Message_Min_Fields = {
  __typename?: 'chat_reading_message_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_participationevent_name" */
  participationEventName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_person_name" */
  personName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "reading_room_message_person_pronouns" */
  personPronouns?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "chat_reading_message" */
export type Chat_Reading_Message_Mutation_Response = {
  __typename?: 'chat_reading_message_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Reading_Message>;
};

/** on_conflict condition type for table "chat_reading_message" */
export type Chat_Reading_Message_On_Conflict = {
  constraint: Chat_Reading_Message_Constraint;
  update_columns?: Array<Chat_Reading_Message_Update_Column>;
  where?: InputMaybe<Chat_Reading_Message_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_reading_message". */
export type Chat_Reading_Message_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  participationEventName?: InputMaybe<Order_By>;
  personName?: InputMaybe<Order_By>;
  personPronouns?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_reading_message */
export type Chat_Reading_Message_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_reading_message" */
export enum Chat_Reading_Message_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "chat_reading_message" */
export type Chat_Reading_Message_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_reading_message" */
export type Chat_Reading_Message_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Reading_Message_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Reading_Message_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_reading_message" */
export enum Chat_Reading_Message_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

export type Chat_Reading_Message_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Reading_Message_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Reading_Message_Bool_Exp;
};

/** columns and relationships of "chat_room" */
export type Chat_Room = {
  __typename?: 'chat_room';
  active: Scalars['Boolean'];
  created: Scalars['timestamptz'];
  /** An object relationship */
  ownerUser?: Maybe<User>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  updated: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "chat_room" */
export type Chat_Room_Aggregate = {
  __typename?: 'chat_room_aggregate';
  aggregate?: Maybe<Chat_Room_Aggregate_Fields>;
  nodes: Array<Chat_Room>;
};

export type Chat_Room_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Room_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Room_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Room_Aggregate_Bool_Exp_Count>;
};

export type Chat_Room_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Room_Select_Column_Chat_Room_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Room_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Room_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Room_Select_Column_Chat_Room_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Room_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Room_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Room_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_room" */
export type Chat_Room_Aggregate_Fields = {
  __typename?: 'chat_room_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Room_Max_Fields>;
  min?: Maybe<Chat_Room_Min_Fields>;
};


/** aggregate fields of "chat_room" */
export type Chat_Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_room" */
export type Chat_Room_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Room_Max_Order_By>;
  min?: InputMaybe<Chat_Room_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_room" */
export type Chat_Room_Arr_Rel_Insert_Input = {
  data: Array<Chat_Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_room". All fields are combined with a logical 'AND'. */
export type Chat_Room_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Room_Bool_Exp>>;
  _not?: InputMaybe<Chat_Room_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Room_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  ownerUser?: InputMaybe<User_Bool_Exp>;
  owner_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_room" */
export enum Chat_Room_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  ChatRoomPkey = 'chat_room_pkey'
}

/** input type for inserting data into table "chat_room" */
export type Chat_Room_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  ownerUser?: InputMaybe<User_Obj_Rel_Insert_Input>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Chat_Room_Max_Fields = {
  __typename?: 'chat_room_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chat_room" */
export type Chat_Room_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Room_Min_Fields = {
  __typename?: 'chat_room_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  updated?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chat_room" */
export type Chat_Room_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_room" */
export type Chat_Room_Mutation_Response = {
  __typename?: 'chat_room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Room>;
};

/** input type for inserting object relation for remote table "chat_room" */
export type Chat_Room_Obj_Rel_Insert_Input = {
  data: Chat_Room_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};

/** on_conflict condition type for table "chat_room" */
export type Chat_Room_On_Conflict = {
  constraint: Chat_Room_Constraint;
  update_columns?: Array<Chat_Room_Update_Column>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_room". */
export type Chat_Room_Order_By = {
  active?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  ownerUser?: InputMaybe<User_Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_room */
export type Chat_Room_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "chat_room" */
export enum Chat_Room_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Created = 'created',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

/** select "chat_room_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_room" */
export enum Chat_Room_Select_Column_Chat_Room_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "chat_room_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_room" */
export enum Chat_Room_Select_Column_Chat_Room_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "chat_room" */
export type Chat_Room_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_room" */
export type Chat_Room_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Room_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Room_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_room" */
export enum Chat_Room_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Created = 'created',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UserId = 'user_id'
}

export type Chat_Room_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Room_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Room_Bool_Exp;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  isdefault: Scalars['Boolean'];
  isocode: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  orgs: Array<Org>;
  /** An aggregate relationship */
  orgs_aggregate: Org_Aggregate;
  /** An array relationship */
  people: Array<Person>;
  /** An aggregate relationship */
  people_aggregate: Person_Aggregate;
};


/** columns and relationships of "country" */
export type CountryOrgsArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryOrgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryPeopleArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryPeople_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Bool_Exp>>;
  _not?: InputMaybe<Country_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isdefault?: InputMaybe<Boolean_Comparison_Exp>;
  isocode?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orgs?: InputMaybe<Org_Bool_Exp>;
  orgs_aggregate?: InputMaybe<Org_Aggregate_Bool_Exp>;
  people?: InputMaybe<Person_Bool_Exp>;
  people_aggregate?: InputMaybe<Person_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "isocode" */
  CountryAkIsocode = 'country_ak_isocode',
  /** unique or primary key constraint on columns "name" */
  CountryAkName = 'country_ak_name',
  /** unique or primary key constraint on columns "id" */
  CountryPk = 'country_pk'
}

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  isocode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orgs?: InputMaybe<Org_Arr_Rel_Insert_Input>;
  people?: InputMaybe<Person_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isocode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isocode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: InputMaybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isdefault?: InputMaybe<Order_By>;
  isocode?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  orgs_aggregate?: InputMaybe<Org_Aggregate_Order_By>;
  people_aggregate?: InputMaybe<Person_Aggregate_Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Isdefault = 'isdefault',
  /** column name */
  Isocode = 'isocode',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  isocode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  isocode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Isdefault = 'isdefault',
  /** column name */
  Isocode = 'isocode',
  /** column name */
  Name = 'name'
}

export type Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "debriefing" */
export type Debriefing = {
  __typename?: 'debriefing';
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  link: Scalars['String'];
};

/** aggregated selection of "debriefing" */
export type Debriefing_Aggregate = {
  __typename?: 'debriefing_aggregate';
  aggregate?: Maybe<Debriefing_Aggregate_Fields>;
  nodes: Array<Debriefing>;
};

/** aggregate fields of "debriefing" */
export type Debriefing_Aggregate_Fields = {
  __typename?: 'debriefing_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Debriefing_Max_Fields>;
  min?: Maybe<Debriefing_Min_Fields>;
};


/** aggregate fields of "debriefing" */
export type Debriefing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Debriefing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "debriefing". All fields are combined with a logical 'AND'. */
export type Debriefing_Bool_Exp = {
  _and?: InputMaybe<Array<Debriefing_Bool_Exp>>;
  _not?: InputMaybe<Debriefing_Bool_Exp>;
  _or?: InputMaybe<Array<Debriefing_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "debriefing" */
export enum Debriefing_Constraint {
  /** unique or primary key constraint on columns "id" */
  DebriefingPkey = 'debriefing_pkey'
}

/** input type for inserting data into table "debriefing" */
export type Debriefing_Insert_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Debriefing_Max_Fields = {
  __typename?: 'debriefing_max_fields';
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Debriefing_Min_Fields = {
  __typename?: 'debriefing_min_fields';
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "debriefing" */
export type Debriefing_Mutation_Response = {
  __typename?: 'debriefing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Debriefing>;
};

/** on_conflict condition type for table "debriefing" */
export type Debriefing_On_Conflict = {
  constraint: Debriefing_Constraint;
  update_columns?: Array<Debriefing_Update_Column>;
  where?: InputMaybe<Debriefing_Bool_Exp>;
};

/** Ordering options when selecting data from "debriefing". */
export type Debriefing_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** primary key columns input for table: debriefing */
export type Debriefing_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "debriefing" */
export enum Debriefing_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link'
}

/** input type for updating data in table "debriefing" */
export type Debriefing_Set_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "debriefing" */
export type Debriefing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Debriefing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Debriefing_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** update columns of table "debriefing" */
export enum Debriefing_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link'
}

export type Debriefing_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Debriefing_Set_Input>;
  /** filter the rows which have to be updated */
  where: Debriefing_Bool_Exp;
};

/** columns and relationships of "duration" */
export type Duration = {
  __typename?: 'duration';
  id: Scalars['uuid'];
  minutes: Scalars['Int'];
  /** An array relationship */
  readings: Array<Reading>;
  /** An aggregate relationship */
  readings_aggregate: Reading_Aggregate;
};


/** columns and relationships of "duration" */
export type DurationReadingsArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


/** columns and relationships of "duration" */
export type DurationReadings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};

/** aggregated selection of "duration" */
export type Duration_Aggregate = {
  __typename?: 'duration_aggregate';
  aggregate?: Maybe<Duration_Aggregate_Fields>;
  nodes: Array<Duration>;
};

/** aggregate fields of "duration" */
export type Duration_Aggregate_Fields = {
  __typename?: 'duration_aggregate_fields';
  avg?: Maybe<Duration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Duration_Max_Fields>;
  min?: Maybe<Duration_Min_Fields>;
  stddev?: Maybe<Duration_Stddev_Fields>;
  stddev_pop?: Maybe<Duration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Duration_Stddev_Samp_Fields>;
  sum?: Maybe<Duration_Sum_Fields>;
  var_pop?: Maybe<Duration_Var_Pop_Fields>;
  var_samp?: Maybe<Duration_Var_Samp_Fields>;
  variance?: Maybe<Duration_Variance_Fields>;
};


/** aggregate fields of "duration" */
export type Duration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Duration_Avg_Fields = {
  __typename?: 'duration_avg_fields';
  minutes?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "duration". All fields are combined with a logical 'AND'. */
export type Duration_Bool_Exp = {
  _and?: InputMaybe<Array<Duration_Bool_Exp>>;
  _not?: InputMaybe<Duration_Bool_Exp>;
  _or?: InputMaybe<Array<Duration_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  minutes?: InputMaybe<Int_Comparison_Exp>;
  readings?: InputMaybe<Reading_Bool_Exp>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "duration" */
export enum Duration_Constraint {
  /** unique or primary key constraint on columns "id" */
  DurationPkey = 'duration_pkey'
}

/** input type for incrementing numeric columns in table "duration" */
export type Duration_Inc_Input = {
  minutes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "duration" */
export type Duration_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  minutes?: InputMaybe<Scalars['Int']>;
  readings?: InputMaybe<Reading_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Duration_Max_Fields = {
  __typename?: 'duration_max_fields';
  id?: Maybe<Scalars['uuid']>;
  minutes?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Duration_Min_Fields = {
  __typename?: 'duration_min_fields';
  id?: Maybe<Scalars['uuid']>;
  minutes?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "duration" */
export type Duration_Mutation_Response = {
  __typename?: 'duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Duration>;
};

/** input type for inserting object relation for remote table "duration" */
export type Duration_Obj_Rel_Insert_Input = {
  data: Duration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Duration_On_Conflict>;
};

/** on_conflict condition type for table "duration" */
export type Duration_On_Conflict = {
  constraint: Duration_Constraint;
  update_columns?: Array<Duration_Update_Column>;
  where?: InputMaybe<Duration_Bool_Exp>;
};

/** Ordering options when selecting data from "duration". */
export type Duration_Order_By = {
  id?: InputMaybe<Order_By>;
  minutes?: InputMaybe<Order_By>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Order_By>;
};

/** primary key columns input for table: duration */
export type Duration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "duration" */
export enum Duration_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Minutes = 'minutes'
}

/** input type for updating data in table "duration" */
export type Duration_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  minutes?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Duration_Stddev_Fields = {
  __typename?: 'duration_stddev_fields';
  minutes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Duration_Stddev_Pop_Fields = {
  __typename?: 'duration_stddev_pop_fields';
  minutes?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Duration_Stddev_Samp_Fields = {
  __typename?: 'duration_stddev_samp_fields';
  minutes?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "duration" */
export type Duration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Duration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Duration_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  minutes?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Duration_Sum_Fields = {
  __typename?: 'duration_sum_fields';
  minutes?: Maybe<Scalars['Int']>;
};

/** update columns of table "duration" */
export enum Duration_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Minutes = 'minutes'
}

export type Duration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Duration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Duration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Duration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Duration_Var_Pop_Fields = {
  __typename?: 'duration_var_pop_fields';
  minutes?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Duration_Var_Samp_Fields = {
  __typename?: 'duration_var_samp_fields';
  minutes?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Duration_Variance_Fields = {
  __typename?: 'duration_variance_fields';
  minutes?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "email_activate_reminder" */
export type Email_Activate_Reminder = {
  __typename?: 'email_activate_reminder';
  person_id: Scalars['uuid'];
  sent_first_reminder_at?: Maybe<Scalars['timestamptz']>;
  sent_second_reminder_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "email_activate_reminder" */
export type Email_Activate_Reminder_Aggregate = {
  __typename?: 'email_activate_reminder_aggregate';
  aggregate?: Maybe<Email_Activate_Reminder_Aggregate_Fields>;
  nodes: Array<Email_Activate_Reminder>;
};

/** aggregate fields of "email_activate_reminder" */
export type Email_Activate_Reminder_Aggregate_Fields = {
  __typename?: 'email_activate_reminder_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Email_Activate_Reminder_Max_Fields>;
  min?: Maybe<Email_Activate_Reminder_Min_Fields>;
};


/** aggregate fields of "email_activate_reminder" */
export type Email_Activate_Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Activate_Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "email_activate_reminder". All fields are combined with a logical 'AND'. */
export type Email_Activate_Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Activate_Reminder_Bool_Exp>>;
  _not?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Activate_Reminder_Bool_Exp>>;
  person_id?: InputMaybe<Uuid_Comparison_Exp>;
  sent_first_reminder_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  sent_second_reminder_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_activate_reminder" */
export enum Email_Activate_Reminder_Constraint {
  /** unique or primary key constraint on columns "person_id" */
  EmailActivateReminderPkey = 'email_activate_reminder_pkey'
}

/** input type for inserting data into table "email_activate_reminder" */
export type Email_Activate_Reminder_Insert_Input = {
  person_id?: InputMaybe<Scalars['uuid']>;
  sent_first_reminder_at?: InputMaybe<Scalars['timestamptz']>;
  sent_second_reminder_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Activate_Reminder_Max_Fields = {
  __typename?: 'email_activate_reminder_max_fields';
  person_id?: Maybe<Scalars['uuid']>;
  sent_first_reminder_at?: Maybe<Scalars['timestamptz']>;
  sent_second_reminder_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Activate_Reminder_Min_Fields = {
  __typename?: 'email_activate_reminder_min_fields';
  person_id?: Maybe<Scalars['uuid']>;
  sent_first_reminder_at?: Maybe<Scalars['timestamptz']>;
  sent_second_reminder_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_activate_reminder" */
export type Email_Activate_Reminder_Mutation_Response = {
  __typename?: 'email_activate_reminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Activate_Reminder>;
};

/** input type for inserting object relation for remote table "email_activate_reminder" */
export type Email_Activate_Reminder_Obj_Rel_Insert_Input = {
  data: Email_Activate_Reminder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Activate_Reminder_On_Conflict>;
};

/** on_conflict condition type for table "email_activate_reminder" */
export type Email_Activate_Reminder_On_Conflict = {
  constraint: Email_Activate_Reminder_Constraint;
  update_columns?: Array<Email_Activate_Reminder_Update_Column>;
  where?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
};

/** Ordering options when selecting data from "email_activate_reminder". */
export type Email_Activate_Reminder_Order_By = {
  person_id?: InputMaybe<Order_By>;
  sent_first_reminder_at?: InputMaybe<Order_By>;
  sent_second_reminder_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_activate_reminder */
export type Email_Activate_Reminder_Pk_Columns_Input = {
  person_id: Scalars['uuid'];
};

/** select columns of table "email_activate_reminder" */
export enum Email_Activate_Reminder_Select_Column {
  /** column name */
  PersonId = 'person_id',
  /** column name */
  SentFirstReminderAt = 'sent_first_reminder_at',
  /** column name */
  SentSecondReminderAt = 'sent_second_reminder_at'
}

/** input type for updating data in table "email_activate_reminder" */
export type Email_Activate_Reminder_Set_Input = {
  person_id?: InputMaybe<Scalars['uuid']>;
  sent_first_reminder_at?: InputMaybe<Scalars['timestamptz']>;
  sent_second_reminder_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "email_activate_reminder" */
export type Email_Activate_Reminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Activate_Reminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Activate_Reminder_Stream_Cursor_Value_Input = {
  person_id?: InputMaybe<Scalars['uuid']>;
  sent_first_reminder_at?: InputMaybe<Scalars['timestamptz']>;
  sent_second_reminder_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "email_activate_reminder" */
export enum Email_Activate_Reminder_Update_Column {
  /** column name */
  PersonId = 'person_id',
  /** column name */
  SentFirstReminderAt = 'sent_first_reminder_at',
  /** column name */
  SentSecondReminderAt = 'sent_second_reminder_at'
}

export type Email_Activate_Reminder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Activate_Reminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Activate_Reminder_Bool_Exp;
};

/** columns and relationships of "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder = {
  __typename?: 'email_chat_message_unread_reminder';
  chat_message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Aggregate = {
  __typename?: 'email_chat_message_unread_reminder_aggregate';
  aggregate?: Maybe<Email_Chat_Message_Unread_Reminder_Aggregate_Fields>;
  nodes: Array<Email_Chat_Message_Unread_Reminder>;
};

export type Email_Chat_Message_Unread_Reminder_Aggregate_Bool_Exp = {
  count?: InputMaybe<Email_Chat_Message_Unread_Reminder_Aggregate_Bool_Exp_Count>;
};

export type Email_Chat_Message_Unread_Reminder_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Aggregate_Fields = {
  __typename?: 'email_chat_message_unread_reminder_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Email_Chat_Message_Unread_Reminder_Max_Fields>;
  min?: Maybe<Email_Chat_Message_Unread_Reminder_Min_Fields>;
};


/** aggregate fields of "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Email_Chat_Message_Unread_Reminder_Max_Order_By>;
  min?: InputMaybe<Email_Chat_Message_Unread_Reminder_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Arr_Rel_Insert_Input = {
  data: Array<Email_Chat_Message_Unread_Reminder_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Chat_Message_Unread_Reminder_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_chat_message_unread_reminder". All fields are combined with a logical 'AND'. */
export type Email_Chat_Message_Unread_Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Bool_Exp>>;
  _not?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Bool_Exp>>;
  chat_message_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_chat_message_unread_reminder" */
export enum Email_Chat_Message_Unread_Reminder_Constraint {
  /** unique or primary key constraint on columns "chat_message_id" */
  EmailChatMessageUnreadReminderChatMessageIdKey = 'email_chat_message_unread_reminder_chat_message_id_key',
  /** unique or primary key constraint on columns "user_id", "chat_message_id" */
  EmailChatMessageUnreadReminderPkey = 'email_chat_message_unread_reminder_pkey'
}

/** input type for inserting data into table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Insert_Input = {
  chat_message_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Email_Chat_Message_Unread_Reminder_Max_Fields = {
  __typename?: 'email_chat_message_unread_reminder_max_fields';
  chat_message_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Max_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Chat_Message_Unread_Reminder_Min_Fields = {
  __typename?: 'email_chat_message_unread_reminder_min_fields';
  chat_message_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Min_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Mutation_Response = {
  __typename?: 'email_chat_message_unread_reminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Chat_Message_Unread_Reminder>;
};

/** input type for inserting object relation for remote table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Obj_Rel_Insert_Input = {
  data: Email_Chat_Message_Unread_Reminder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Chat_Message_Unread_Reminder_On_Conflict>;
};

/** on_conflict condition type for table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_On_Conflict = {
  constraint: Email_Chat_Message_Unread_Reminder_Constraint;
  update_columns?: Array<Email_Chat_Message_Unread_Reminder_Update_Column>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};

/** Ordering options when selecting data from "email_chat_message_unread_reminder". */
export type Email_Chat_Message_Unread_Reminder_Order_By = {
  chat_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_chat_message_unread_reminder */
export type Email_Chat_Message_Unread_Reminder_Pk_Columns_Input = {
  chat_message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "email_chat_message_unread_reminder" */
export enum Email_Chat_Message_Unread_Reminder_Select_Column {
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Set_Input = {
  chat_message_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "email_chat_message_unread_reminder" */
export type Email_Chat_Message_Unread_Reminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Chat_Message_Unread_Reminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Chat_Message_Unread_Reminder_Stream_Cursor_Value_Input = {
  chat_message_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "email_chat_message_unread_reminder" */
export enum Email_Chat_Message_Unread_Reminder_Update_Column {
  /** column name */
  ChatMessageId = 'chat_message_id',
  /** column name */
  UserId = 'user_id'
}

export type Email_Chat_Message_Unread_Reminder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Chat_Message_Unread_Reminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Chat_Message_Unread_Reminder_Bool_Exp;
};

/** columns and relationships of "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder = {
  __typename?: 'email_library_card_expiration_reminder';
  readingplan_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Aggregate = {
  __typename?: 'email_library_card_expiration_reminder_aggregate';
  aggregate?: Maybe<Email_Library_Card_Expiration_Reminder_Aggregate_Fields>;
  nodes: Array<Email_Library_Card_Expiration_Reminder>;
};

/** aggregate fields of "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Aggregate_Fields = {
  __typename?: 'email_library_card_expiration_reminder_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Email_Library_Card_Expiration_Reminder_Max_Fields>;
  min?: Maybe<Email_Library_Card_Expiration_Reminder_Min_Fields>;
};


/** aggregate fields of "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "email_library_card_expiration_reminder". All fields are combined with a logical 'AND'. */
export type Email_Library_Card_Expiration_Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Bool_Exp>>;
  _not?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Bool_Exp>>;
  readingplan_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_library_card_expiration_reminder" */
export enum Email_Library_Card_Expiration_Reminder_Constraint {
  /** unique or primary key constraint on columns "readingplan_id" */
  EmailLibraryCardExpirationReminderPkey = 'email_library_card_expiration_reminder_pkey'
}

/** input type for inserting data into table "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Insert_Input = {
  readingplan_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Library_Card_Expiration_Reminder_Max_Fields = {
  __typename?: 'email_library_card_expiration_reminder_max_fields';
  readingplan_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Library_Card_Expiration_Reminder_Min_Fields = {
  __typename?: 'email_library_card_expiration_reminder_min_fields';
  readingplan_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Mutation_Response = {
  __typename?: 'email_library_card_expiration_reminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Library_Card_Expiration_Reminder>;
};

/** input type for inserting object relation for remote table "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Obj_Rel_Insert_Input = {
  data: Email_Library_Card_Expiration_Reminder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Library_Card_Expiration_Reminder_On_Conflict>;
};

/** on_conflict condition type for table "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_On_Conflict = {
  constraint: Email_Library_Card_Expiration_Reminder_Constraint;
  update_columns?: Array<Email_Library_Card_Expiration_Reminder_Update_Column>;
  where?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
};

/** Ordering options when selecting data from "email_library_card_expiration_reminder". */
export type Email_Library_Card_Expiration_Reminder_Order_By = {
  readingplan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_library_card_expiration_reminder */
export type Email_Library_Card_Expiration_Reminder_Pk_Columns_Input = {
  readingplan_id: Scalars['uuid'];
};

/** select columns of table "email_library_card_expiration_reminder" */
export enum Email_Library_Card_Expiration_Reminder_Select_Column {
  /** column name */
  ReadingplanId = 'readingplan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Set_Input = {
  readingplan_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "email_library_card_expiration_reminder" */
export type Email_Library_Card_Expiration_Reminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Library_Card_Expiration_Reminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Library_Card_Expiration_Reminder_Stream_Cursor_Value_Input = {
  readingplan_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "email_library_card_expiration_reminder" */
export enum Email_Library_Card_Expiration_Reminder_Update_Column {
  /** column name */
  ReadingplanId = 'readingplan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Email_Library_Card_Expiration_Reminder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Library_Card_Expiration_Reminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Library_Card_Expiration_Reminder_Bool_Exp;
};

/** columns and relationships of "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder = {
  __typename?: 'email_library_card_expired_reminder';
  readingplan_id: Scalars['uuid'];
  sent_at: Scalars['timestamptz'];
};

/** aggregated selection of "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Aggregate = {
  __typename?: 'email_library_card_expired_reminder_aggregate';
  aggregate?: Maybe<Email_Library_Card_Expired_Reminder_Aggregate_Fields>;
  nodes: Array<Email_Library_Card_Expired_Reminder>;
};

/** aggregate fields of "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Aggregate_Fields = {
  __typename?: 'email_library_card_expired_reminder_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Email_Library_Card_Expired_Reminder_Max_Fields>;
  min?: Maybe<Email_Library_Card_Expired_Reminder_Min_Fields>;
};


/** aggregate fields of "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "email_library_card_expired_reminder". All fields are combined with a logical 'AND'. */
export type Email_Library_Card_Expired_Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Bool_Exp>>;
  _not?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Bool_Exp>>;
  readingplan_id?: InputMaybe<Uuid_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_library_card_expired_reminder" */
export enum Email_Library_Card_Expired_Reminder_Constraint {
  /** unique or primary key constraint on columns "readingplan_id" */
  EmailLibraryCardExpiredReminderPkey = 'email_library_card_expired_reminder_pkey'
}

/** input type for inserting data into table "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Insert_Input = {
  readingplan_id?: InputMaybe<Scalars['uuid']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Library_Card_Expired_Reminder_Max_Fields = {
  __typename?: 'email_library_card_expired_reminder_max_fields';
  readingplan_id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Library_Card_Expired_Reminder_Min_Fields = {
  __typename?: 'email_library_card_expired_reminder_min_fields';
  readingplan_id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Mutation_Response = {
  __typename?: 'email_library_card_expired_reminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Library_Card_Expired_Reminder>;
};

/** input type for inserting object relation for remote table "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Obj_Rel_Insert_Input = {
  data: Email_Library_Card_Expired_Reminder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Library_Card_Expired_Reminder_On_Conflict>;
};

/** on_conflict condition type for table "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_On_Conflict = {
  constraint: Email_Library_Card_Expired_Reminder_Constraint;
  update_columns?: Array<Email_Library_Card_Expired_Reminder_Update_Column>;
  where?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
};

/** Ordering options when selecting data from "email_library_card_expired_reminder". */
export type Email_Library_Card_Expired_Reminder_Order_By = {
  readingplan_id?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_library_card_expired_reminder */
export type Email_Library_Card_Expired_Reminder_Pk_Columns_Input = {
  readingplan_id: Scalars['uuid'];
};

/** select columns of table "email_library_card_expired_reminder" */
export enum Email_Library_Card_Expired_Reminder_Select_Column {
  /** column name */
  ReadingplanId = 'readingplan_id',
  /** column name */
  SentAt = 'sent_at'
}

/** input type for updating data in table "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Set_Input = {
  readingplan_id?: InputMaybe<Scalars['uuid']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "email_library_card_expired_reminder" */
export type Email_Library_Card_Expired_Reminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Library_Card_Expired_Reminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Library_Card_Expired_Reminder_Stream_Cursor_Value_Input = {
  readingplan_id?: InputMaybe<Scalars['uuid']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "email_library_card_expired_reminder" */
export enum Email_Library_Card_Expired_Reminder_Update_Column {
  /** column name */
  ReadingplanId = 'readingplan_id',
  /** column name */
  SentAt = 'sent_at'
}

export type Email_Library_Card_Expired_Reminder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Library_Card_Expired_Reminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Library_Card_Expired_Reminder_Bool_Exp;
};

/** columns and relationships of "eula" */
export type Eula = {
  __typename?: 'eula';
  created: Scalars['timestamp'];
  /** An array relationship */
  eula_acceptances: Array<Eula_Acceptance>;
  /** An aggregate relationship */
  eula_acceptances_aggregate: Eula_Acceptance_Aggregate;
  id: Scalars['uuid'];
  translationkey: Scalars['String'];
  version: Scalars['Int'];
};


/** columns and relationships of "eula" */
export type EulaEula_AcceptancesArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


/** columns and relationships of "eula" */
export type EulaEula_Acceptances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};

/** columns and relationships of "eula_acceptance" */
export type Eula_Acceptance = {
  __typename?: 'eula_acceptance';
  accepted: Scalars['timestamp'];
  /** An object relationship */
  eula: Eula;
  eula_id: Scalars['uuid'];
  /** An object relationship */
  person: Person;
  person_id: Scalars['uuid'];
};

/** aggregated selection of "eula_acceptance" */
export type Eula_Acceptance_Aggregate = {
  __typename?: 'eula_acceptance_aggregate';
  aggregate?: Maybe<Eula_Acceptance_Aggregate_Fields>;
  nodes: Array<Eula_Acceptance>;
};

export type Eula_Acceptance_Aggregate_Bool_Exp = {
  count?: InputMaybe<Eula_Acceptance_Aggregate_Bool_Exp_Count>;
};

export type Eula_Acceptance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Eula_Acceptance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "eula_acceptance" */
export type Eula_Acceptance_Aggregate_Fields = {
  __typename?: 'eula_acceptance_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Eula_Acceptance_Max_Fields>;
  min?: Maybe<Eula_Acceptance_Min_Fields>;
};


/** aggregate fields of "eula_acceptance" */
export type Eula_Acceptance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "eula_acceptance" */
export type Eula_Acceptance_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Eula_Acceptance_Max_Order_By>;
  min?: InputMaybe<Eula_Acceptance_Min_Order_By>;
};

/** input type for inserting array relation for remote table "eula_acceptance" */
export type Eula_Acceptance_Arr_Rel_Insert_Input = {
  data: Array<Eula_Acceptance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Eula_Acceptance_On_Conflict>;
};

/** Boolean expression to filter rows from the table "eula_acceptance". All fields are combined with a logical 'AND'. */
export type Eula_Acceptance_Bool_Exp = {
  _and?: InputMaybe<Array<Eula_Acceptance_Bool_Exp>>;
  _not?: InputMaybe<Eula_Acceptance_Bool_Exp>;
  _or?: InputMaybe<Array<Eula_Acceptance_Bool_Exp>>;
  accepted?: InputMaybe<Timestamp_Comparison_Exp>;
  eula?: InputMaybe<Eula_Bool_Exp>;
  eula_id?: InputMaybe<Uuid_Comparison_Exp>;
  person?: InputMaybe<Person_Bool_Exp>;
  person_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "eula_acceptance" */
export enum Eula_Acceptance_Constraint {
  /** unique or primary key constraint on columns "person_id", "eula_id" */
  EulaAcceptancePk = 'eula_acceptance_pk'
}

/** input type for inserting data into table "eula_acceptance" */
export type Eula_Acceptance_Insert_Input = {
  accepted?: InputMaybe<Scalars['timestamp']>;
  eula?: InputMaybe<Eula_Obj_Rel_Insert_Input>;
  eula_id?: InputMaybe<Scalars['uuid']>;
  person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  person_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Eula_Acceptance_Max_Fields = {
  __typename?: 'eula_acceptance_max_fields';
  accepted?: Maybe<Scalars['timestamp']>;
  eula_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "eula_acceptance" */
export type Eula_Acceptance_Max_Order_By = {
  accepted?: InputMaybe<Order_By>;
  eula_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Eula_Acceptance_Min_Fields = {
  __typename?: 'eula_acceptance_min_fields';
  accepted?: Maybe<Scalars['timestamp']>;
  eula_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "eula_acceptance" */
export type Eula_Acceptance_Min_Order_By = {
  accepted?: InputMaybe<Order_By>;
  eula_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "eula_acceptance" */
export type Eula_Acceptance_Mutation_Response = {
  __typename?: 'eula_acceptance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Eula_Acceptance>;
};

/** on_conflict condition type for table "eula_acceptance" */
export type Eula_Acceptance_On_Conflict = {
  constraint: Eula_Acceptance_Constraint;
  update_columns?: Array<Eula_Acceptance_Update_Column>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};

/** Ordering options when selecting data from "eula_acceptance". */
export type Eula_Acceptance_Order_By = {
  accepted?: InputMaybe<Order_By>;
  eula?: InputMaybe<Eula_Order_By>;
  eula_id?: InputMaybe<Order_By>;
  person?: InputMaybe<Person_Order_By>;
  person_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eula_acceptance */
export type Eula_Acceptance_Pk_Columns_Input = {
  eula_id: Scalars['uuid'];
  person_id: Scalars['uuid'];
};

/** select columns of table "eula_acceptance" */
export enum Eula_Acceptance_Select_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  EulaId = 'eula_id',
  /** column name */
  PersonId = 'person_id'
}

/** input type for updating data in table "eula_acceptance" */
export type Eula_Acceptance_Set_Input = {
  accepted?: InputMaybe<Scalars['timestamp']>;
  eula_id?: InputMaybe<Scalars['uuid']>;
  person_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "eula_acceptance" */
export type Eula_Acceptance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eula_Acceptance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eula_Acceptance_Stream_Cursor_Value_Input = {
  accepted?: InputMaybe<Scalars['timestamp']>;
  eula_id?: InputMaybe<Scalars['uuid']>;
  person_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "eula_acceptance" */
export enum Eula_Acceptance_Update_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  EulaId = 'eula_id',
  /** column name */
  PersonId = 'person_id'
}

export type Eula_Acceptance_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Eula_Acceptance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Eula_Acceptance_Bool_Exp;
};

/** aggregated selection of "eula" */
export type Eula_Aggregate = {
  __typename?: 'eula_aggregate';
  aggregate?: Maybe<Eula_Aggregate_Fields>;
  nodes: Array<Eula>;
};

/** aggregate fields of "eula" */
export type Eula_Aggregate_Fields = {
  __typename?: 'eula_aggregate_fields';
  avg?: Maybe<Eula_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Eula_Max_Fields>;
  min?: Maybe<Eula_Min_Fields>;
  stddev?: Maybe<Eula_Stddev_Fields>;
  stddev_pop?: Maybe<Eula_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Eula_Stddev_Samp_Fields>;
  sum?: Maybe<Eula_Sum_Fields>;
  var_pop?: Maybe<Eula_Var_Pop_Fields>;
  var_samp?: Maybe<Eula_Var_Samp_Fields>;
  variance?: Maybe<Eula_Variance_Fields>;
};


/** aggregate fields of "eula" */
export type Eula_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Eula_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Eula_Avg_Fields = {
  __typename?: 'eula_avg_fields';
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "eula". All fields are combined with a logical 'AND'. */
export type Eula_Bool_Exp = {
  _and?: InputMaybe<Array<Eula_Bool_Exp>>;
  _not?: InputMaybe<Eula_Bool_Exp>;
  _or?: InputMaybe<Array<Eula_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  eula_acceptances?: InputMaybe<Eula_Acceptance_Bool_Exp>;
  eula_acceptances_aggregate?: InputMaybe<Eula_Acceptance_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  translationkey?: InputMaybe<String_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "eula" */
export enum Eula_Constraint {
  /** unique or primary key constraint on columns "id" */
  EulaPk = 'eula_pk'
}

/** input type for incrementing numeric columns in table "eula" */
export type Eula_Inc_Input = {
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "eula" */
export type Eula_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  eula_acceptances?: InputMaybe<Eula_Acceptance_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  translationkey?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Eula_Max_Fields = {
  __typename?: 'eula_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  translationkey?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Eula_Min_Fields = {
  __typename?: 'eula_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  translationkey?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "eula" */
export type Eula_Mutation_Response = {
  __typename?: 'eula_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Eula>;
};

/** input type for inserting object relation for remote table "eula" */
export type Eula_Obj_Rel_Insert_Input = {
  data: Eula_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Eula_On_Conflict>;
};

/** on_conflict condition type for table "eula" */
export type Eula_On_Conflict = {
  constraint: Eula_Constraint;
  update_columns?: Array<Eula_Update_Column>;
  where?: InputMaybe<Eula_Bool_Exp>;
};

/** Ordering options when selecting data from "eula". */
export type Eula_Order_By = {
  created?: InputMaybe<Order_By>;
  eula_acceptances_aggregate?: InputMaybe<Eula_Acceptance_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  translationkey?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eula */
export type Eula_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "eula" */
export enum Eula_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Translationkey = 'translationkey',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "eula" */
export type Eula_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  translationkey?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Eula_Stddev_Fields = {
  __typename?: 'eula_stddev_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Eula_Stddev_Pop_Fields = {
  __typename?: 'eula_stddev_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Eula_Stddev_Samp_Fields = {
  __typename?: 'eula_stddev_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "eula" */
export type Eula_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Eula_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eula_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  translationkey?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Eula_Sum_Fields = {
  __typename?: 'eula_sum_fields';
  version?: Maybe<Scalars['Int']>;
};

/** update columns of table "eula" */
export enum Eula_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Translationkey = 'translationkey',
  /** column name */
  Version = 'version'
}

export type Eula_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Eula_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Eula_Set_Input>;
  /** filter the rows which have to be updated */
  where: Eula_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Eula_Var_Pop_Fields = {
  __typename?: 'eula_var_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Eula_Var_Samp_Fields = {
  __typename?: 'eula_var_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Eula_Variance_Fields = {
  __typename?: 'eula_variance_fields';
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

/** columns and relationships of "language" */
export type Language = {
  __typename?: 'language';
  /** An array relationship */
  booklanguages: Array<Booklanguage>;
  /** An aggregate relationship */
  booklanguages_aggregate: Booklanguage_Aggregate;
  created: Scalars['timestamp'];
  /** An array relationship */
  defaultlanguage: Array<Person>;
  /** An aggregate relationship */
  defaultlanguage_aggregate: Person_Aggregate;
  id: Scalars['uuid'];
  isdefault: Scalars['Boolean'];
  isocode: Scalars['String'];
  /** An array relationship */
  literary_quotes: Array<Literary_Quote>;
  /** An aggregate relationship */
  literary_quotes_aggregate: Literary_Quote_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  readings: Array<Reading>;
  /** An aggregate relationship */
  readings_aggregate: Reading_Aggregate;
};


/** columns and relationships of "language" */
export type LanguageBooklanguagesArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageBooklanguages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageDefaultlanguageArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageDefaultlanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageLiterary_QuotesArgs = {
  distinct_on?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Literary_Quote_Order_By>>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageLiterary_Quotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Literary_Quote_Order_By>>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageReadingsArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


/** columns and relationships of "language" */
export type LanguageReadings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};

/** aggregated selection of "language" */
export type Language_Aggregate = {
  __typename?: 'language_aggregate';
  aggregate?: Maybe<Language_Aggregate_Fields>;
  nodes: Array<Language>;
};

/** aggregate fields of "language" */
export type Language_Aggregate_Fields = {
  __typename?: 'language_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Language_Max_Fields>;
  min?: Maybe<Language_Min_Fields>;
};


/** aggregate fields of "language" */
export type Language_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Language_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "language". All fields are combined with a logical 'AND'. */
export type Language_Bool_Exp = {
  _and?: InputMaybe<Array<Language_Bool_Exp>>;
  _not?: InputMaybe<Language_Bool_Exp>;
  _or?: InputMaybe<Array<Language_Bool_Exp>>;
  booklanguages?: InputMaybe<Booklanguage_Bool_Exp>;
  booklanguages_aggregate?: InputMaybe<Booklanguage_Aggregate_Bool_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  defaultlanguage?: InputMaybe<Person_Bool_Exp>;
  defaultlanguage_aggregate?: InputMaybe<Person_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isdefault?: InputMaybe<Boolean_Comparison_Exp>;
  isocode?: InputMaybe<String_Comparison_Exp>;
  literary_quotes?: InputMaybe<Literary_Quote_Bool_Exp>;
  literary_quotes_aggregate?: InputMaybe<Literary_Quote_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  readings?: InputMaybe<Reading_Bool_Exp>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "language" */
export enum Language_Constraint {
  /** unique or primary key constraint on columns "isocode" */
  LanguageAkIsocode = 'language_ak_isocode',
  /** unique or primary key constraint on columns "name" */
  LanguageAkName = 'language_ak_name',
  /** unique or primary key constraint on columns "id" */
  LanguagePk = 'language_pk'
}

/** input type for inserting data into table "language" */
export type Language_Insert_Input = {
  booklanguages?: InputMaybe<Booklanguage_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamp']>;
  defaultlanguage?: InputMaybe<Person_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  isocode?: InputMaybe<Scalars['String']>;
  literary_quotes?: InputMaybe<Literary_Quote_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  readings?: InputMaybe<Reading_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Language_Max_Fields = {
  __typename?: 'language_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isocode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Language_Min_Fields = {
  __typename?: 'language_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  isocode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "language" */
export type Language_Mutation_Response = {
  __typename?: 'language_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Language>;
};

/** input type for inserting object relation for remote table "language" */
export type Language_Obj_Rel_Insert_Input = {
  data: Language_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Language_On_Conflict>;
};

/** on_conflict condition type for table "language" */
export type Language_On_Conflict = {
  constraint: Language_Constraint;
  update_columns?: Array<Language_Update_Column>;
  where?: InputMaybe<Language_Bool_Exp>;
};

/** Ordering options when selecting data from "language". */
export type Language_Order_By = {
  booklanguages_aggregate?: InputMaybe<Booklanguage_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  defaultlanguage_aggregate?: InputMaybe<Person_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isdefault?: InputMaybe<Order_By>;
  isocode?: InputMaybe<Order_By>;
  literary_quotes_aggregate?: InputMaybe<Literary_Quote_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Order_By>;
};

/** primary key columns input for table: language */
export type Language_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "language" */
export enum Language_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Isdefault = 'isdefault',
  /** column name */
  Isocode = 'isocode',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "language" */
export type Language_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  isocode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "language" */
export type Language_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Language_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Language_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  isocode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "language" */
export enum Language_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Isdefault = 'isdefault',
  /** column name */
  Isocode = 'isocode',
  /** column name */
  Name = 'name'
}

export type Language_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Language_Set_Input>;
  /** filter the rows which have to be updated */
  where: Language_Bool_Exp;
};

/** columns and relationships of "literary_quote" */
export type Literary_Quote = {
  __typename?: 'literary_quote';
  author: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  language: Language;
  language_id: Scalars['uuid'];
  /** An array relationship */
  pillars: Array<Pillar>;
  /** An aggregate relationship */
  pillars_aggregate: Pillar_Aggregate;
  text: Scalars['String'];
};


/** columns and relationships of "literary_quote" */
export type Literary_QuotePillarsArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Order_By>>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};


/** columns and relationships of "literary_quote" */
export type Literary_QuotePillars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Order_By>>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};

/** aggregated selection of "literary_quote" */
export type Literary_Quote_Aggregate = {
  __typename?: 'literary_quote_aggregate';
  aggregate?: Maybe<Literary_Quote_Aggregate_Fields>;
  nodes: Array<Literary_Quote>;
};

export type Literary_Quote_Aggregate_Bool_Exp = {
  count?: InputMaybe<Literary_Quote_Aggregate_Bool_Exp_Count>;
};

export type Literary_Quote_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Literary_Quote_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "literary_quote" */
export type Literary_Quote_Aggregate_Fields = {
  __typename?: 'literary_quote_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Literary_Quote_Max_Fields>;
  min?: Maybe<Literary_Quote_Min_Fields>;
};


/** aggregate fields of "literary_quote" */
export type Literary_Quote_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "literary_quote" */
export type Literary_Quote_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Literary_Quote_Max_Order_By>;
  min?: InputMaybe<Literary_Quote_Min_Order_By>;
};

/** input type for inserting array relation for remote table "literary_quote" */
export type Literary_Quote_Arr_Rel_Insert_Input = {
  data: Array<Literary_Quote_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Literary_Quote_On_Conflict>;
};

/** Boolean expression to filter rows from the table "literary_quote". All fields are combined with a logical 'AND'. */
export type Literary_Quote_Bool_Exp = {
  _and?: InputMaybe<Array<Literary_Quote_Bool_Exp>>;
  _not?: InputMaybe<Literary_Quote_Bool_Exp>;
  _or?: InputMaybe<Array<Literary_Quote_Bool_Exp>>;
  author?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<Language_Bool_Exp>;
  language_id?: InputMaybe<Uuid_Comparison_Exp>;
  pillars?: InputMaybe<Pillar_Bool_Exp>;
  pillars_aggregate?: InputMaybe<Pillar_Aggregate_Bool_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "literary_quote" */
export enum Literary_Quote_Constraint {
  /** unique or primary key constraint on columns "id" */
  LiteraryQuotePkey = 'literary_quote_pkey'
}

/** input type for inserting data into table "literary_quote" */
export type Literary_Quote_Insert_Input = {
  author?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Language_Obj_Rel_Insert_Input>;
  language_id?: InputMaybe<Scalars['uuid']>;
  pillars?: InputMaybe<Pillar_Arr_Rel_Insert_Input>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Literary_Quote_Max_Fields = {
  __typename?: 'literary_quote_max_fields';
  author?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "literary_quote" */
export type Literary_Quote_Max_Order_By = {
  author?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Literary_Quote_Min_Fields = {
  __typename?: 'literary_quote_min_fields';
  author?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "literary_quote" */
export type Literary_Quote_Min_Order_By = {
  author?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "literary_quote" */
export type Literary_Quote_Mutation_Response = {
  __typename?: 'literary_quote_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Literary_Quote>;
};

/** input type for inserting object relation for remote table "literary_quote" */
export type Literary_Quote_Obj_Rel_Insert_Input = {
  data: Literary_Quote_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Literary_Quote_On_Conflict>;
};

/** on_conflict condition type for table "literary_quote" */
export type Literary_Quote_On_Conflict = {
  constraint: Literary_Quote_Constraint;
  update_columns?: Array<Literary_Quote_Update_Column>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};

/** Ordering options when selecting data from "literary_quote". */
export type Literary_Quote_Order_By = {
  author?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Language_Order_By>;
  language_id?: InputMaybe<Order_By>;
  pillars_aggregate?: InputMaybe<Pillar_Aggregate_Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: literary_quote */
export type Literary_Quote_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "literary_quote" */
export enum Literary_Quote_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "literary_quote" */
export type Literary_Quote_Set_Input = {
  author?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  language_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "literary_quote" */
export type Literary_Quote_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Literary_Quote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Literary_Quote_Stream_Cursor_Value_Input = {
  author?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  language_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
};

/** update columns of table "literary_quote" */
export enum Literary_Quote_Update_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Text = 'text'
}

export type Literary_Quote_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Literary_Quote_Set_Input>;
  /** filter the rows which have to be updated */
  where: Literary_Quote_Bool_Exp;
};

/** Live call states for users in meetings */
export type Live_Call_State = {
  __typename?: 'live_call_state';
  is_hand_raised: Scalars['Boolean'];
  last_updated: Scalars['timestamptz'];
  /** A computed field, executes function "live_call_state_get_latest_participationevent_name_v2" */
  participationEventStatusNameV2?: Maybe<Scalars['String']>;
  /** Securely retrieves the person name associated with user_id. This way we avoid the need to configure complex person/user permissions. */
  personName?: Maybe<Scalars['String']>;
  /** An object relationship */
  reading: Reading;
  reading_id: Scalars['uuid'];
  /** Securely retrieves the user role associated with user_id. This way we avoid the need to configure complex person/user permissions. */
  role?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "live_call_state" */
export type Live_Call_State_Aggregate = {
  __typename?: 'live_call_state_aggregate';
  aggregate?: Maybe<Live_Call_State_Aggregate_Fields>;
  nodes: Array<Live_Call_State>;
};

export type Live_Call_State_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Live_Call_State_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Live_Call_State_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Live_Call_State_Aggregate_Bool_Exp_Count>;
};

export type Live_Call_State_Aggregate_Bool_Exp_Bool_And = {
  arguments: Live_Call_State_Select_Column_Live_Call_State_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Live_Call_State_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Live_Call_State_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Live_Call_State_Select_Column_Live_Call_State_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Live_Call_State_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Live_Call_State_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Live_Call_State_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_call_state" */
export type Live_Call_State_Aggregate_Fields = {
  __typename?: 'live_call_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Live_Call_State_Max_Fields>;
  min?: Maybe<Live_Call_State_Min_Fields>;
};


/** aggregate fields of "live_call_state" */
export type Live_Call_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "live_call_state" */
export type Live_Call_State_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Live_Call_State_Max_Order_By>;
  min?: InputMaybe<Live_Call_State_Min_Order_By>;
};

/** input type for inserting array relation for remote table "live_call_state" */
export type Live_Call_State_Arr_Rel_Insert_Input = {
  data: Array<Live_Call_State_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Live_Call_State_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_call_state". All fields are combined with a logical 'AND'. */
export type Live_Call_State_Bool_Exp = {
  _and?: InputMaybe<Array<Live_Call_State_Bool_Exp>>;
  _not?: InputMaybe<Live_Call_State_Bool_Exp>;
  _or?: InputMaybe<Array<Live_Call_State_Bool_Exp>>;
  is_hand_raised?: InputMaybe<Boolean_Comparison_Exp>;
  last_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  participationEventStatusNameV2?: InputMaybe<String_Comparison_Exp>;
  personName?: InputMaybe<String_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "live_call_state" */
export enum Live_Call_State_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  LiveCallStatePkey = 'live_call_state_pkey',
  /** unique or primary key constraint on columns "user_id" */
  LiveCallStateUserIdKey = 'live_call_state_user_id_key'
}

/** input type for inserting data into table "live_call_state" */
export type Live_Call_State_Insert_Input = {
  is_hand_raised?: InputMaybe<Scalars['Boolean']>;
  last_updated?: InputMaybe<Scalars['timestamptz']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Live_Call_State_Max_Fields = {
  __typename?: 'live_call_state_max_fields';
  last_updated?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "live_call_state_get_latest_participationevent_name_v2" */
  participationEventStatusNameV2?: Maybe<Scalars['String']>;
  /** Securely retrieves the person name associated with user_id. This way we avoid the need to configure complex person/user permissions. */
  personName?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  /** Securely retrieves the user role associated with user_id. This way we avoid the need to configure complex person/user permissions. */
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "live_call_state" */
export type Live_Call_State_Max_Order_By = {
  last_updated?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Call_State_Min_Fields = {
  __typename?: 'live_call_state_min_fields';
  last_updated?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "live_call_state_get_latest_participationevent_name_v2" */
  participationEventStatusNameV2?: Maybe<Scalars['String']>;
  /** Securely retrieves the person name associated with user_id. This way we avoid the need to configure complex person/user permissions. */
  personName?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  /** Securely retrieves the user role associated with user_id. This way we avoid the need to configure complex person/user permissions. */
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "live_call_state" */
export type Live_Call_State_Min_Order_By = {
  last_updated?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_call_state" */
export type Live_Call_State_Mutation_Response = {
  __typename?: 'live_call_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Live_Call_State>;
};

/** input type for inserting object relation for remote table "live_call_state" */
export type Live_Call_State_Obj_Rel_Insert_Input = {
  data: Live_Call_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Live_Call_State_On_Conflict>;
};

/** on_conflict condition type for table "live_call_state" */
export type Live_Call_State_On_Conflict = {
  constraint: Live_Call_State_Constraint;
  update_columns?: Array<Live_Call_State_Update_Column>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};

/** Ordering options when selecting data from "live_call_state". */
export type Live_Call_State_Order_By = {
  is_hand_raised?: InputMaybe<Order_By>;
  last_updated?: InputMaybe<Order_By>;
  participationEventStatusNameV2?: InputMaybe<Order_By>;
  personName?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: live_call_state */
export type Live_Call_State_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "live_call_state" */
export enum Live_Call_State_Select_Column {
  /** column name */
  IsHandRaised = 'is_hand_raised',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** select "live_call_state_aggregate_bool_exp_bool_and_arguments_columns" columns of table "live_call_state" */
export enum Live_Call_State_Select_Column_Live_Call_State_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsHandRaised = 'is_hand_raised'
}

/** select "live_call_state_aggregate_bool_exp_bool_or_arguments_columns" columns of table "live_call_state" */
export enum Live_Call_State_Select_Column_Live_Call_State_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsHandRaised = 'is_hand_raised'
}

/** input type for updating data in table "live_call_state" */
export type Live_Call_State_Set_Input = {
  is_hand_raised?: InputMaybe<Scalars['Boolean']>;
  last_updated?: InputMaybe<Scalars['timestamptz']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "live_call_state" */
export type Live_Call_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Live_Call_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Call_State_Stream_Cursor_Value_Input = {
  is_hand_raised?: InputMaybe<Scalars['Boolean']>;
  last_updated?: InputMaybe<Scalars['timestamptz']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "live_call_state" */
export enum Live_Call_State_Update_Column {
  /** column name */
  IsHandRaised = 'is_hand_raised',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

export type Live_Call_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Live_Call_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Live_Call_State_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "book" */
  delete_book?: Maybe<Book_Mutation_Response>;
  /** delete single row from the table: "book" */
  delete_book_by_pk?: Maybe<Book>;
  /** delete data from the table: "book_topic" */
  delete_book_topic?: Maybe<Book_Topic_Mutation_Response>;
  /** delete single row from the table: "book_topic" */
  delete_book_topic_by_pk?: Maybe<Book_Topic>;
  /** delete data from the table: "bookevent" */
  delete_bookevent?: Maybe<Bookevent_Mutation_Response>;
  /** delete single row from the table: "bookevent" */
  delete_bookevent_by_pk?: Maybe<Bookevent>;
  /** delete data from the table: "booklanguage" */
  delete_booklanguage?: Maybe<Booklanguage_Mutation_Response>;
  /** delete single row from the table: "booklanguage" */
  delete_booklanguage_by_pk?: Maybe<Booklanguage>;
  /** delete data from the table: "bookstate" */
  delete_bookstate?: Maybe<Bookstate_Mutation_Response>;
  /** delete single row from the table: "bookstate" */
  delete_bookstate_by_pk?: Maybe<Bookstate>;
  /** delete data from the table: "cancel_review" */
  delete_cancel_review?: Maybe<Cancel_Review_Mutation_Response>;
  /** delete single row from the table: "cancel_review" */
  delete_cancel_review_by_pk?: Maybe<Cancel_Review>;
  /** delete data from the table: "chat_message" */
  delete_chat_message?: Maybe<Chat_Message_Mutation_Response>;
  /** delete single row from the table: "chat_message" */
  delete_chat_message_by_pk?: Maybe<Chat_Message>;
  /** delete data from the table: "chat_message_type" */
  delete_chat_message_type?: Maybe<Chat_Message_Type_Mutation_Response>;
  /** delete single row from the table: "chat_message_type" */
  delete_chat_message_type_by_pk?: Maybe<Chat_Message_Type>;
  /** delete data from the table: "chat_reading_message" */
  delete_chat_reading_message?: Maybe<Chat_Reading_Message_Mutation_Response>;
  /** delete single row from the table: "chat_reading_message" */
  delete_chat_reading_message_by_pk?: Maybe<Chat_Reading_Message>;
  /** delete data from the table: "chat_room" */
  delete_chat_room?: Maybe<Chat_Room_Mutation_Response>;
  /** delete single row from the table: "chat_room" */
  delete_chat_room_by_pk?: Maybe<Chat_Room>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "debriefing" */
  delete_debriefing?: Maybe<Debriefing_Mutation_Response>;
  /** delete single row from the table: "debriefing" */
  delete_debriefing_by_pk?: Maybe<Debriefing>;
  /** delete data from the table: "duration" */
  delete_duration?: Maybe<Duration_Mutation_Response>;
  /** delete single row from the table: "duration" */
  delete_duration_by_pk?: Maybe<Duration>;
  /** delete data from the table: "email_activate_reminder" */
  delete_email_activate_reminder?: Maybe<Email_Activate_Reminder_Mutation_Response>;
  /** delete single row from the table: "email_activate_reminder" */
  delete_email_activate_reminder_by_pk?: Maybe<Email_Activate_Reminder>;
  /** delete data from the table: "email_chat_message_unread_reminder" */
  delete_email_chat_message_unread_reminder?: Maybe<Email_Chat_Message_Unread_Reminder_Mutation_Response>;
  /** delete single row from the table: "email_chat_message_unread_reminder" */
  delete_email_chat_message_unread_reminder_by_pk?: Maybe<Email_Chat_Message_Unread_Reminder>;
  /** delete data from the table: "email_library_card_expiration_reminder" */
  delete_email_library_card_expiration_reminder?: Maybe<Email_Library_Card_Expiration_Reminder_Mutation_Response>;
  /** delete single row from the table: "email_library_card_expiration_reminder" */
  delete_email_library_card_expiration_reminder_by_pk?: Maybe<Email_Library_Card_Expiration_Reminder>;
  /** delete data from the table: "email_library_card_expired_reminder" */
  delete_email_library_card_expired_reminder?: Maybe<Email_Library_Card_Expired_Reminder_Mutation_Response>;
  /** delete single row from the table: "email_library_card_expired_reminder" */
  delete_email_library_card_expired_reminder_by_pk?: Maybe<Email_Library_Card_Expired_Reminder>;
  /** delete data from the table: "eula" */
  delete_eula?: Maybe<Eula_Mutation_Response>;
  /** delete data from the table: "eula_acceptance" */
  delete_eula_acceptance?: Maybe<Eula_Acceptance_Mutation_Response>;
  /** delete single row from the table: "eula_acceptance" */
  delete_eula_acceptance_by_pk?: Maybe<Eula_Acceptance>;
  /** delete single row from the table: "eula" */
  delete_eula_by_pk?: Maybe<Eula>;
  /** delete data from the table: "language" */
  delete_language?: Maybe<Language_Mutation_Response>;
  /** delete single row from the table: "language" */
  delete_language_by_pk?: Maybe<Language>;
  /** delete data from the table: "literary_quote" */
  delete_literary_quote?: Maybe<Literary_Quote_Mutation_Response>;
  /** delete single row from the table: "literary_quote" */
  delete_literary_quote_by_pk?: Maybe<Literary_Quote>;
  /** delete data from the table: "live_call_state" */
  delete_live_call_state?: Maybe<Live_Call_State_Mutation_Response>;
  /** delete single row from the table: "live_call_state" */
  delete_live_call_state_by_pk?: Maybe<Live_Call_State>;
  /** delete data from the table: "org" */
  delete_org?: Maybe<Org_Mutation_Response>;
  /** delete single row from the table: "org" */
  delete_org_by_pk?: Maybe<Org>;
  /** delete data from the table: "participationevent" */
  delete_participationevent?: Maybe<Participationevent_Mutation_Response>;
  /** delete single row from the table: "participationevent" */
  delete_participationevent_by_pk?: Maybe<Participationevent>;
  /** delete data from the table: "participationstate" */
  delete_participationstate?: Maybe<Participationstate_Mutation_Response>;
  /** delete single row from the table: "participationstate" */
  delete_participationstate_by_pk?: Maybe<Participationstate>;
  /** delete data from the table: "person" */
  delete_person?: Maybe<Person_Mutation_Response>;
  /** delete single row from the table: "person" */
  delete_person_by_pk?: Maybe<Person>;
  /** delete data from the table: "pillar" */
  delete_pillar?: Maybe<Pillar_Mutation_Response>;
  /** delete single row from the table: "pillar" */
  delete_pillar_by_pk?: Maybe<Pillar>;
  /** delete data from the table: "pillar_topic" */
  delete_pillar_topic?: Maybe<Pillar_Topic_Mutation_Response>;
  /** delete single row from the table: "pillar_topic" */
  delete_pillar_topic_by_pk?: Maybe<Pillar_Topic>;
  /** delete data from the table: "pillarconstraint" */
  delete_pillarconstraint?: Maybe<Pillarconstraint_Mutation_Response>;
  /** delete single row from the table: "pillarconstraint" */
  delete_pillarconstraint_by_pk?: Maybe<Pillarconstraint>;
  /** delete data from the table: "reading" */
  delete_reading?: Maybe<Reading_Mutation_Response>;
  /** delete single row from the table: "reading" */
  delete_reading_by_pk?: Maybe<Reading>;
  /** delete data from the table: "reading_setting" */
  delete_reading_setting?: Maybe<Reading_Setting_Mutation_Response>;
  /** delete single row from the table: "reading_setting" */
  delete_reading_setting_by_pk?: Maybe<Reading_Setting>;
  /** delete data from the table: "readingevent" */
  delete_readingevent?: Maybe<Readingevent_Mutation_Response>;
  /** delete single row from the table: "readingevent" */
  delete_readingevent_by_pk?: Maybe<Readingevent>;
  /** delete data from the table: "readingplan" */
  delete_readingplan?: Maybe<Readingplan_Mutation_Response>;
  /** delete single row from the table: "readingplan" */
  delete_readingplan_by_pk?: Maybe<Readingplan>;
  /** delete data from the table: "readingplan_duration" */
  delete_readingplan_duration?: Maybe<Readingplan_Duration_Mutation_Response>;
  /** delete data from the table: "readingplan_duration_translation" */
  delete_readingplan_duration_translation?: Maybe<Readingplan_Duration_Translation_Mutation_Response>;
  /** delete single row from the table: "readingplan_duration_translation" */
  delete_readingplan_duration_translation_by_pk?: Maybe<Readingplan_Duration_Translation>;
  /** delete data from the table: "readings_per_week" */
  delete_readings_per_week?: Maybe<Readings_Per_Week_Mutation_Response>;
  /** delete data from the table: "readingstate" */
  delete_readingstate?: Maybe<Readingstate_Mutation_Response>;
  /** delete single row from the table: "readingstate" */
  delete_readingstate_by_pk?: Maybe<Readingstate>;
  /** delete data from the table: "review" */
  delete_review?: Maybe<Review_Mutation_Response>;
  /** delete data from the table: "review_book" */
  delete_review_book?: Maybe<Review_Book_Mutation_Response>;
  /** delete single row from the table: "review_book" */
  delete_review_book_by_pk?: Maybe<Review_Book>;
  /** delete data from the table: "review_book_option" */
  delete_review_book_option?: Maybe<Review_Book_Option_Mutation_Response>;
  /** delete single row from the table: "review_book_option" */
  delete_review_book_option_by_pk?: Maybe<Review_Book_Option>;
  /** delete single row from the table: "review" */
  delete_review_by_pk?: Maybe<Review>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "scheduled_email_reminder" */
  delete_scheduled_email_reminder?: Maybe<Scheduled_Email_Reminder_Mutation_Response>;
  /** delete single row from the table: "scheduled_email_reminder" */
  delete_scheduled_email_reminder_by_pk?: Maybe<Scheduled_Email_Reminder>;
  /** delete data from the table: "timezone" */
  delete_timezone?: Maybe<Timezone_Mutation_Response>;
  /** delete single row from the table: "timezone" */
  delete_timezone_by_pk?: Maybe<Timezone>;
  /** delete data from the table: "topic" */
  delete_topic?: Maybe<Topic_Mutation_Response>;
  /** delete single row from the table: "topic" */
  delete_topic_by_pk?: Maybe<Topic>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "view_readings" */
  delete_view_readings?: Maybe<View_Readings_Mutation_Response>;
  /** delete data from the table: "virtual_book_get_number_of_readings" */
  delete_virtual_book_get_number_of_readings?: Maybe<Virtual_Book_Get_Number_Of_Readings_Mutation_Response>;
  /** insert data into the table: "book" */
  insert_book?: Maybe<Book_Mutation_Response>;
  /** insert a single row into the table: "book" */
  insert_book_one?: Maybe<Book>;
  /** insert data into the table: "book_topic" */
  insert_book_topic?: Maybe<Book_Topic_Mutation_Response>;
  /** insert a single row into the table: "book_topic" */
  insert_book_topic_one?: Maybe<Book_Topic>;
  /** insert data into the table: "bookevent" */
  insert_bookevent?: Maybe<Bookevent_Mutation_Response>;
  /** insert a single row into the table: "bookevent" */
  insert_bookevent_one?: Maybe<Bookevent>;
  /** insert data into the table: "booklanguage" */
  insert_booklanguage?: Maybe<Booklanguage_Mutation_Response>;
  /** insert a single row into the table: "booklanguage" */
  insert_booklanguage_one?: Maybe<Booklanguage>;
  /** insert data into the table: "bookstate" */
  insert_bookstate?: Maybe<Bookstate_Mutation_Response>;
  /** insert a single row into the table: "bookstate" */
  insert_bookstate_one?: Maybe<Bookstate>;
  /** insert data into the table: "cancel_review" */
  insert_cancel_review?: Maybe<Cancel_Review_Mutation_Response>;
  /** insert a single row into the table: "cancel_review" */
  insert_cancel_review_one?: Maybe<Cancel_Review>;
  /** insert data into the table: "chat_message" */
  insert_chat_message?: Maybe<Chat_Message_Mutation_Response>;
  /** insert a single row into the table: "chat_message" */
  insert_chat_message_one?: Maybe<Chat_Message>;
  /** insert data into the table: "chat_message_type" */
  insert_chat_message_type?: Maybe<Chat_Message_Type_Mutation_Response>;
  /** insert a single row into the table: "chat_message_type" */
  insert_chat_message_type_one?: Maybe<Chat_Message_Type>;
  /** insert data into the table: "chat_reading_message" */
  insert_chat_reading_message?: Maybe<Chat_Reading_Message_Mutation_Response>;
  /** insert a single row into the table: "chat_reading_message" */
  insert_chat_reading_message_one?: Maybe<Chat_Reading_Message>;
  /** insert data into the table: "chat_room" */
  insert_chat_room?: Maybe<Chat_Room_Mutation_Response>;
  /** insert a single row into the table: "chat_room" */
  insert_chat_room_one?: Maybe<Chat_Room>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "debriefing" */
  insert_debriefing?: Maybe<Debriefing_Mutation_Response>;
  /** insert a single row into the table: "debriefing" */
  insert_debriefing_one?: Maybe<Debriefing>;
  /** insert data into the table: "duration" */
  insert_duration?: Maybe<Duration_Mutation_Response>;
  /** insert a single row into the table: "duration" */
  insert_duration_one?: Maybe<Duration>;
  /** insert data into the table: "email_activate_reminder" */
  insert_email_activate_reminder?: Maybe<Email_Activate_Reminder_Mutation_Response>;
  /** insert a single row into the table: "email_activate_reminder" */
  insert_email_activate_reminder_one?: Maybe<Email_Activate_Reminder>;
  /** insert data into the table: "email_chat_message_unread_reminder" */
  insert_email_chat_message_unread_reminder?: Maybe<Email_Chat_Message_Unread_Reminder_Mutation_Response>;
  /** insert a single row into the table: "email_chat_message_unread_reminder" */
  insert_email_chat_message_unread_reminder_one?: Maybe<Email_Chat_Message_Unread_Reminder>;
  /** insert data into the table: "email_library_card_expiration_reminder" */
  insert_email_library_card_expiration_reminder?: Maybe<Email_Library_Card_Expiration_Reminder_Mutation_Response>;
  /** insert a single row into the table: "email_library_card_expiration_reminder" */
  insert_email_library_card_expiration_reminder_one?: Maybe<Email_Library_Card_Expiration_Reminder>;
  /** insert data into the table: "email_library_card_expired_reminder" */
  insert_email_library_card_expired_reminder?: Maybe<Email_Library_Card_Expired_Reminder_Mutation_Response>;
  /** insert a single row into the table: "email_library_card_expired_reminder" */
  insert_email_library_card_expired_reminder_one?: Maybe<Email_Library_Card_Expired_Reminder>;
  /** insert data into the table: "eula" */
  insert_eula?: Maybe<Eula_Mutation_Response>;
  /** insert data into the table: "eula_acceptance" */
  insert_eula_acceptance?: Maybe<Eula_Acceptance_Mutation_Response>;
  /** insert a single row into the table: "eula_acceptance" */
  insert_eula_acceptance_one?: Maybe<Eula_Acceptance>;
  /** insert a single row into the table: "eula" */
  insert_eula_one?: Maybe<Eula>;
  /** insert data into the table: "language" */
  insert_language?: Maybe<Language_Mutation_Response>;
  /** insert a single row into the table: "language" */
  insert_language_one?: Maybe<Language>;
  /** insert data into the table: "literary_quote" */
  insert_literary_quote?: Maybe<Literary_Quote_Mutation_Response>;
  /** insert a single row into the table: "literary_quote" */
  insert_literary_quote_one?: Maybe<Literary_Quote>;
  /** insert data into the table: "live_call_state" */
  insert_live_call_state?: Maybe<Live_Call_State_Mutation_Response>;
  /** insert a single row into the table: "live_call_state" */
  insert_live_call_state_one?: Maybe<Live_Call_State>;
  /** insert data into the table: "org" */
  insert_org?: Maybe<Org_Mutation_Response>;
  /** insert a single row into the table: "org" */
  insert_org_one?: Maybe<Org>;
  /** insert data into the table: "participationevent" */
  insert_participationevent?: Maybe<Participationevent_Mutation_Response>;
  /** insert a single row into the table: "participationevent" */
  insert_participationevent_one?: Maybe<Participationevent>;
  /** insert data into the table: "participationstate" */
  insert_participationstate?: Maybe<Participationstate_Mutation_Response>;
  /** insert a single row into the table: "participationstate" */
  insert_participationstate_one?: Maybe<Participationstate>;
  /** insert data into the table: "person" */
  insert_person?: Maybe<Person_Mutation_Response>;
  /** insert a single row into the table: "person" */
  insert_person_one?: Maybe<Person>;
  /** insert data into the table: "pillar" */
  insert_pillar?: Maybe<Pillar_Mutation_Response>;
  /** insert a single row into the table: "pillar" */
  insert_pillar_one?: Maybe<Pillar>;
  /** insert data into the table: "pillar_topic" */
  insert_pillar_topic?: Maybe<Pillar_Topic_Mutation_Response>;
  /** insert a single row into the table: "pillar_topic" */
  insert_pillar_topic_one?: Maybe<Pillar_Topic>;
  /** insert data into the table: "pillarconstraint" */
  insert_pillarconstraint?: Maybe<Pillarconstraint_Mutation_Response>;
  /** insert a single row into the table: "pillarconstraint" */
  insert_pillarconstraint_one?: Maybe<Pillarconstraint>;
  /** insert data into the table: "reading" */
  insert_reading?: Maybe<Reading_Mutation_Response>;
  /** insert a single row into the table: "reading" */
  insert_reading_one?: Maybe<Reading>;
  /** insert data into the table: "reading_setting" */
  insert_reading_setting?: Maybe<Reading_Setting_Mutation_Response>;
  /** insert a single row into the table: "reading_setting" */
  insert_reading_setting_one?: Maybe<Reading_Setting>;
  /** insert data into the table: "readingevent" */
  insert_readingevent?: Maybe<Readingevent_Mutation_Response>;
  /** insert a single row into the table: "readingevent" */
  insert_readingevent_one?: Maybe<Readingevent>;
  /** insert data into the table: "readingplan" */
  insert_readingplan?: Maybe<Readingplan_Mutation_Response>;
  /** insert data into the table: "readingplan_duration" */
  insert_readingplan_duration?: Maybe<Readingplan_Duration_Mutation_Response>;
  /** insert a single row into the table: "readingplan_duration" */
  insert_readingplan_duration_one?: Maybe<Readingplan_Duration>;
  /** insert data into the table: "readingplan_duration_translation" */
  insert_readingplan_duration_translation?: Maybe<Readingplan_Duration_Translation_Mutation_Response>;
  /** insert a single row into the table: "readingplan_duration_translation" */
  insert_readingplan_duration_translation_one?: Maybe<Readingplan_Duration_Translation>;
  /** insert a single row into the table: "readingplan" */
  insert_readingplan_one?: Maybe<Readingplan>;
  /** insert data into the table: "readings_per_week" */
  insert_readings_per_week?: Maybe<Readings_Per_Week_Mutation_Response>;
  /** insert a single row into the table: "readings_per_week" */
  insert_readings_per_week_one?: Maybe<Readings_Per_Week>;
  /** insert data into the table: "readingstate" */
  insert_readingstate?: Maybe<Readingstate_Mutation_Response>;
  /** insert a single row into the table: "readingstate" */
  insert_readingstate_one?: Maybe<Readingstate>;
  /** insert data into the table: "review" */
  insert_review?: Maybe<Review_Mutation_Response>;
  /** insert data into the table: "review_book" */
  insert_review_book?: Maybe<Review_Book_Mutation_Response>;
  /** insert a single row into the table: "review_book" */
  insert_review_book_one?: Maybe<Review_Book>;
  /** insert data into the table: "review_book_option" */
  insert_review_book_option?: Maybe<Review_Book_Option_Mutation_Response>;
  /** insert a single row into the table: "review_book_option" */
  insert_review_book_option_one?: Maybe<Review_Book_Option>;
  /** insert a single row into the table: "review" */
  insert_review_one?: Maybe<Review>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "scheduled_email_reminder" */
  insert_scheduled_email_reminder?: Maybe<Scheduled_Email_Reminder_Mutation_Response>;
  /** insert a single row into the table: "scheduled_email_reminder" */
  insert_scheduled_email_reminder_one?: Maybe<Scheduled_Email_Reminder>;
  /** insert data into the table: "timezone" */
  insert_timezone?: Maybe<Timezone_Mutation_Response>;
  /** insert a single row into the table: "timezone" */
  insert_timezone_one?: Maybe<Timezone>;
  /** insert data into the table: "topic" */
  insert_topic?: Maybe<Topic_Mutation_Response>;
  /** insert a single row into the table: "topic" */
  insert_topic_one?: Maybe<Topic>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "view_readings" */
  insert_view_readings?: Maybe<View_Readings_Mutation_Response>;
  /** insert a single row into the table: "view_readings" */
  insert_view_readings_one?: Maybe<View_Readings>;
  /** insert data into the table: "virtual_book_get_number_of_readings" */
  insert_virtual_book_get_number_of_readings?: Maybe<Virtual_Book_Get_Number_Of_Readings_Mutation_Response>;
  /** insert a single row into the table: "virtual_book_get_number_of_readings" */
  insert_virtual_book_get_number_of_readings_one?: Maybe<Virtual_Book_Get_Number_Of_Readings>;
  /** update data of the table: "book" */
  update_book?: Maybe<Book_Mutation_Response>;
  /** update single row of the table: "book" */
  update_book_by_pk?: Maybe<Book>;
  /** update multiples rows of table: "book" */
  update_book_many?: Maybe<Array<Maybe<Book_Mutation_Response>>>;
  /** update data of the table: "book_topic" */
  update_book_topic?: Maybe<Book_Topic_Mutation_Response>;
  /** update single row of the table: "book_topic" */
  update_book_topic_by_pk?: Maybe<Book_Topic>;
  /** update multiples rows of table: "book_topic" */
  update_book_topic_many?: Maybe<Array<Maybe<Book_Topic_Mutation_Response>>>;
  /** update data of the table: "bookevent" */
  update_bookevent?: Maybe<Bookevent_Mutation_Response>;
  /** update single row of the table: "bookevent" */
  update_bookevent_by_pk?: Maybe<Bookevent>;
  /** update multiples rows of table: "bookevent" */
  update_bookevent_many?: Maybe<Array<Maybe<Bookevent_Mutation_Response>>>;
  /** update data of the table: "booklanguage" */
  update_booklanguage?: Maybe<Booklanguage_Mutation_Response>;
  /** update single row of the table: "booklanguage" */
  update_booklanguage_by_pk?: Maybe<Booklanguage>;
  /** update multiples rows of table: "booklanguage" */
  update_booklanguage_many?: Maybe<Array<Maybe<Booklanguage_Mutation_Response>>>;
  /** update data of the table: "bookstate" */
  update_bookstate?: Maybe<Bookstate_Mutation_Response>;
  /** update single row of the table: "bookstate" */
  update_bookstate_by_pk?: Maybe<Bookstate>;
  /** update multiples rows of table: "bookstate" */
  update_bookstate_many?: Maybe<Array<Maybe<Bookstate_Mutation_Response>>>;
  /** update data of the table: "cancel_review" */
  update_cancel_review?: Maybe<Cancel_Review_Mutation_Response>;
  /** update single row of the table: "cancel_review" */
  update_cancel_review_by_pk?: Maybe<Cancel_Review>;
  /** update multiples rows of table: "cancel_review" */
  update_cancel_review_many?: Maybe<Array<Maybe<Cancel_Review_Mutation_Response>>>;
  /** update data of the table: "chat_message" */
  update_chat_message?: Maybe<Chat_Message_Mutation_Response>;
  /** update single row of the table: "chat_message" */
  update_chat_message_by_pk?: Maybe<Chat_Message>;
  /** update multiples rows of table: "chat_message" */
  update_chat_message_many?: Maybe<Array<Maybe<Chat_Message_Mutation_Response>>>;
  /** update data of the table: "chat_message_type" */
  update_chat_message_type?: Maybe<Chat_Message_Type_Mutation_Response>;
  /** update single row of the table: "chat_message_type" */
  update_chat_message_type_by_pk?: Maybe<Chat_Message_Type>;
  /** update multiples rows of table: "chat_message_type" */
  update_chat_message_type_many?: Maybe<Array<Maybe<Chat_Message_Type_Mutation_Response>>>;
  /** update data of the table: "chat_reading_message" */
  update_chat_reading_message?: Maybe<Chat_Reading_Message_Mutation_Response>;
  /** update single row of the table: "chat_reading_message" */
  update_chat_reading_message_by_pk?: Maybe<Chat_Reading_Message>;
  /** update multiples rows of table: "chat_reading_message" */
  update_chat_reading_message_many?: Maybe<Array<Maybe<Chat_Reading_Message_Mutation_Response>>>;
  /** update data of the table: "chat_room" */
  update_chat_room?: Maybe<Chat_Room_Mutation_Response>;
  /** update single row of the table: "chat_room" */
  update_chat_room_by_pk?: Maybe<Chat_Room>;
  /** update multiples rows of table: "chat_room" */
  update_chat_room_many?: Maybe<Array<Maybe<Chat_Room_Mutation_Response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update data of the table: "debriefing" */
  update_debriefing?: Maybe<Debriefing_Mutation_Response>;
  /** update single row of the table: "debriefing" */
  update_debriefing_by_pk?: Maybe<Debriefing>;
  /** update multiples rows of table: "debriefing" */
  update_debriefing_many?: Maybe<Array<Maybe<Debriefing_Mutation_Response>>>;
  /** update data of the table: "duration" */
  update_duration?: Maybe<Duration_Mutation_Response>;
  /** update single row of the table: "duration" */
  update_duration_by_pk?: Maybe<Duration>;
  /** update multiples rows of table: "duration" */
  update_duration_many?: Maybe<Array<Maybe<Duration_Mutation_Response>>>;
  /** update data of the table: "email_activate_reminder" */
  update_email_activate_reminder?: Maybe<Email_Activate_Reminder_Mutation_Response>;
  /** update single row of the table: "email_activate_reminder" */
  update_email_activate_reminder_by_pk?: Maybe<Email_Activate_Reminder>;
  /** update multiples rows of table: "email_activate_reminder" */
  update_email_activate_reminder_many?: Maybe<Array<Maybe<Email_Activate_Reminder_Mutation_Response>>>;
  /** update data of the table: "email_chat_message_unread_reminder" */
  update_email_chat_message_unread_reminder?: Maybe<Email_Chat_Message_Unread_Reminder_Mutation_Response>;
  /** update single row of the table: "email_chat_message_unread_reminder" */
  update_email_chat_message_unread_reminder_by_pk?: Maybe<Email_Chat_Message_Unread_Reminder>;
  /** update multiples rows of table: "email_chat_message_unread_reminder" */
  update_email_chat_message_unread_reminder_many?: Maybe<Array<Maybe<Email_Chat_Message_Unread_Reminder_Mutation_Response>>>;
  /** update data of the table: "email_library_card_expiration_reminder" */
  update_email_library_card_expiration_reminder?: Maybe<Email_Library_Card_Expiration_Reminder_Mutation_Response>;
  /** update single row of the table: "email_library_card_expiration_reminder" */
  update_email_library_card_expiration_reminder_by_pk?: Maybe<Email_Library_Card_Expiration_Reminder>;
  /** update multiples rows of table: "email_library_card_expiration_reminder" */
  update_email_library_card_expiration_reminder_many?: Maybe<Array<Maybe<Email_Library_Card_Expiration_Reminder_Mutation_Response>>>;
  /** update data of the table: "email_library_card_expired_reminder" */
  update_email_library_card_expired_reminder?: Maybe<Email_Library_Card_Expired_Reminder_Mutation_Response>;
  /** update single row of the table: "email_library_card_expired_reminder" */
  update_email_library_card_expired_reminder_by_pk?: Maybe<Email_Library_Card_Expired_Reminder>;
  /** update multiples rows of table: "email_library_card_expired_reminder" */
  update_email_library_card_expired_reminder_many?: Maybe<Array<Maybe<Email_Library_Card_Expired_Reminder_Mutation_Response>>>;
  /** update data of the table: "eula" */
  update_eula?: Maybe<Eula_Mutation_Response>;
  /** update data of the table: "eula_acceptance" */
  update_eula_acceptance?: Maybe<Eula_Acceptance_Mutation_Response>;
  /** update single row of the table: "eula_acceptance" */
  update_eula_acceptance_by_pk?: Maybe<Eula_Acceptance>;
  /** update multiples rows of table: "eula_acceptance" */
  update_eula_acceptance_many?: Maybe<Array<Maybe<Eula_Acceptance_Mutation_Response>>>;
  /** update single row of the table: "eula" */
  update_eula_by_pk?: Maybe<Eula>;
  /** update multiples rows of table: "eula" */
  update_eula_many?: Maybe<Array<Maybe<Eula_Mutation_Response>>>;
  /** update data of the table: "language" */
  update_language?: Maybe<Language_Mutation_Response>;
  /** update single row of the table: "language" */
  update_language_by_pk?: Maybe<Language>;
  /** update multiples rows of table: "language" */
  update_language_many?: Maybe<Array<Maybe<Language_Mutation_Response>>>;
  /** update data of the table: "literary_quote" */
  update_literary_quote?: Maybe<Literary_Quote_Mutation_Response>;
  /** update single row of the table: "literary_quote" */
  update_literary_quote_by_pk?: Maybe<Literary_Quote>;
  /** update multiples rows of table: "literary_quote" */
  update_literary_quote_many?: Maybe<Array<Maybe<Literary_Quote_Mutation_Response>>>;
  /** update data of the table: "live_call_state" */
  update_live_call_state?: Maybe<Live_Call_State_Mutation_Response>;
  /** update single row of the table: "live_call_state" */
  update_live_call_state_by_pk?: Maybe<Live_Call_State>;
  /** update multiples rows of table: "live_call_state" */
  update_live_call_state_many?: Maybe<Array<Maybe<Live_Call_State_Mutation_Response>>>;
  /** update data of the table: "org" */
  update_org?: Maybe<Org_Mutation_Response>;
  /** update single row of the table: "org" */
  update_org_by_pk?: Maybe<Org>;
  /** update multiples rows of table: "org" */
  update_org_many?: Maybe<Array<Maybe<Org_Mutation_Response>>>;
  /** update data of the table: "participationevent" */
  update_participationevent?: Maybe<Participationevent_Mutation_Response>;
  /** update single row of the table: "participationevent" */
  update_participationevent_by_pk?: Maybe<Participationevent>;
  /** update multiples rows of table: "participationevent" */
  update_participationevent_many?: Maybe<Array<Maybe<Participationevent_Mutation_Response>>>;
  /** update data of the table: "participationstate" */
  update_participationstate?: Maybe<Participationstate_Mutation_Response>;
  /** update single row of the table: "participationstate" */
  update_participationstate_by_pk?: Maybe<Participationstate>;
  /** update multiples rows of table: "participationstate" */
  update_participationstate_many?: Maybe<Array<Maybe<Participationstate_Mutation_Response>>>;
  /** update data of the table: "person" */
  update_person?: Maybe<Person_Mutation_Response>;
  /** update single row of the table: "person" */
  update_person_by_pk?: Maybe<Person>;
  /** update multiples rows of table: "person" */
  update_person_many?: Maybe<Array<Maybe<Person_Mutation_Response>>>;
  /** update data of the table: "pillar" */
  update_pillar?: Maybe<Pillar_Mutation_Response>;
  /** update single row of the table: "pillar" */
  update_pillar_by_pk?: Maybe<Pillar>;
  /** update multiples rows of table: "pillar" */
  update_pillar_many?: Maybe<Array<Maybe<Pillar_Mutation_Response>>>;
  /** update data of the table: "pillar_topic" */
  update_pillar_topic?: Maybe<Pillar_Topic_Mutation_Response>;
  /** update single row of the table: "pillar_topic" */
  update_pillar_topic_by_pk?: Maybe<Pillar_Topic>;
  /** update multiples rows of table: "pillar_topic" */
  update_pillar_topic_many?: Maybe<Array<Maybe<Pillar_Topic_Mutation_Response>>>;
  /** update data of the table: "pillarconstraint" */
  update_pillarconstraint?: Maybe<Pillarconstraint_Mutation_Response>;
  /** update single row of the table: "pillarconstraint" */
  update_pillarconstraint_by_pk?: Maybe<Pillarconstraint>;
  /** update multiples rows of table: "pillarconstraint" */
  update_pillarconstraint_many?: Maybe<Array<Maybe<Pillarconstraint_Mutation_Response>>>;
  /** update data of the table: "reading" */
  update_reading?: Maybe<Reading_Mutation_Response>;
  /** update single row of the table: "reading" */
  update_reading_by_pk?: Maybe<Reading>;
  /** update multiples rows of table: "reading" */
  update_reading_many?: Maybe<Array<Maybe<Reading_Mutation_Response>>>;
  /** update data of the table: "reading_setting" */
  update_reading_setting?: Maybe<Reading_Setting_Mutation_Response>;
  /** update single row of the table: "reading_setting" */
  update_reading_setting_by_pk?: Maybe<Reading_Setting>;
  /** update multiples rows of table: "reading_setting" */
  update_reading_setting_many?: Maybe<Array<Maybe<Reading_Setting_Mutation_Response>>>;
  /** update data of the table: "readingevent" */
  update_readingevent?: Maybe<Readingevent_Mutation_Response>;
  /** update single row of the table: "readingevent" */
  update_readingevent_by_pk?: Maybe<Readingevent>;
  /** update multiples rows of table: "readingevent" */
  update_readingevent_many?: Maybe<Array<Maybe<Readingevent_Mutation_Response>>>;
  /** update data of the table: "readingplan" */
  update_readingplan?: Maybe<Readingplan_Mutation_Response>;
  /** update single row of the table: "readingplan" */
  update_readingplan_by_pk?: Maybe<Readingplan>;
  /** update data of the table: "readingplan_duration" */
  update_readingplan_duration?: Maybe<Readingplan_Duration_Mutation_Response>;
  /** update multiples rows of table: "readingplan_duration" */
  update_readingplan_duration_many?: Maybe<Array<Maybe<Readingplan_Duration_Mutation_Response>>>;
  /** update data of the table: "readingplan_duration_translation" */
  update_readingplan_duration_translation?: Maybe<Readingplan_Duration_Translation_Mutation_Response>;
  /** update single row of the table: "readingplan_duration_translation" */
  update_readingplan_duration_translation_by_pk?: Maybe<Readingplan_Duration_Translation>;
  /** update multiples rows of table: "readingplan_duration_translation" */
  update_readingplan_duration_translation_many?: Maybe<Array<Maybe<Readingplan_Duration_Translation_Mutation_Response>>>;
  /** update multiples rows of table: "readingplan" */
  update_readingplan_many?: Maybe<Array<Maybe<Readingplan_Mutation_Response>>>;
  /** update data of the table: "readings_per_week" */
  update_readings_per_week?: Maybe<Readings_Per_Week_Mutation_Response>;
  /** update multiples rows of table: "readings_per_week" */
  update_readings_per_week_many?: Maybe<Array<Maybe<Readings_Per_Week_Mutation_Response>>>;
  /** update data of the table: "readingstate" */
  update_readingstate?: Maybe<Readingstate_Mutation_Response>;
  /** update single row of the table: "readingstate" */
  update_readingstate_by_pk?: Maybe<Readingstate>;
  /** update multiples rows of table: "readingstate" */
  update_readingstate_many?: Maybe<Array<Maybe<Readingstate_Mutation_Response>>>;
  /** update data of the table: "review" */
  update_review?: Maybe<Review_Mutation_Response>;
  /** update data of the table: "review_book" */
  update_review_book?: Maybe<Review_Book_Mutation_Response>;
  /** update single row of the table: "review_book" */
  update_review_book_by_pk?: Maybe<Review_Book>;
  /** update multiples rows of table: "review_book" */
  update_review_book_many?: Maybe<Array<Maybe<Review_Book_Mutation_Response>>>;
  /** update data of the table: "review_book_option" */
  update_review_book_option?: Maybe<Review_Book_Option_Mutation_Response>;
  /** update single row of the table: "review_book_option" */
  update_review_book_option_by_pk?: Maybe<Review_Book_Option>;
  /** update multiples rows of table: "review_book_option" */
  update_review_book_option_many?: Maybe<Array<Maybe<Review_Book_Option_Mutation_Response>>>;
  /** update single row of the table: "review" */
  update_review_by_pk?: Maybe<Review>;
  /** update multiples rows of table: "review" */
  update_review_many?: Maybe<Array<Maybe<Review_Mutation_Response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "scheduled_email_reminder" */
  update_scheduled_email_reminder?: Maybe<Scheduled_Email_Reminder_Mutation_Response>;
  /** update single row of the table: "scheduled_email_reminder" */
  update_scheduled_email_reminder_by_pk?: Maybe<Scheduled_Email_Reminder>;
  /** update multiples rows of table: "scheduled_email_reminder" */
  update_scheduled_email_reminder_many?: Maybe<Array<Maybe<Scheduled_Email_Reminder_Mutation_Response>>>;
  /** update data of the table: "timezone" */
  update_timezone?: Maybe<Timezone_Mutation_Response>;
  /** update single row of the table: "timezone" */
  update_timezone_by_pk?: Maybe<Timezone>;
  /** update multiples rows of table: "timezone" */
  update_timezone_many?: Maybe<Array<Maybe<Timezone_Mutation_Response>>>;
  /** update data of the table: "topic" */
  update_topic?: Maybe<Topic_Mutation_Response>;
  /** update single row of the table: "topic" */
  update_topic_by_pk?: Maybe<Topic>;
  /** update multiples rows of table: "topic" */
  update_topic_many?: Maybe<Array<Maybe<Topic_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "view_readings" */
  update_view_readings?: Maybe<View_Readings_Mutation_Response>;
  /** update multiples rows of table: "view_readings" */
  update_view_readings_many?: Maybe<Array<Maybe<View_Readings_Mutation_Response>>>;
  /** update data of the table: "virtual_book_get_number_of_readings" */
  update_virtual_book_get_number_of_readings?: Maybe<Virtual_Book_Get_Number_Of_Readings_Mutation_Response>;
  /** update multiples rows of table: "virtual_book_get_number_of_readings" */
  update_virtual_book_get_number_of_readings_many?: Maybe<Array<Maybe<Virtual_Book_Get_Number_Of_Readings_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_BookArgs = {
  where: Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Book_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Book_TopicArgs = {
  where: Book_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Book_Topic_By_PkArgs = {
  book_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BookeventArgs = {
  where: Bookevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bookevent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BooklanguageArgs = {
  where: Booklanguage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Booklanguage_By_PkArgs = {
  book_id: Scalars['uuid'];
  language_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BookstateArgs = {
  where: Bookstate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bookstate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Cancel_ReviewArgs = {
  where: Cancel_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cancel_Review_By_PkArgs = {
  reading_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Chat_MessageArgs = {
  where: Chat_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Chat_Message_TypeArgs = {
  where: Chat_Message_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_Message_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Chat_Reading_MessageArgs = {
  where: Chat_Reading_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_Reading_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Chat_RoomArgs = {
  where: Chat_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_Room_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DebriefingArgs = {
  where: Debriefing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Debriefing_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DurationArgs = {
  where: Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Duration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Activate_ReminderArgs = {
  where: Email_Activate_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Activate_Reminder_By_PkArgs = {
  person_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Chat_Message_Unread_ReminderArgs = {
  where: Email_Chat_Message_Unread_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Chat_Message_Unread_Reminder_By_PkArgs = {
  chat_message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Library_Card_Expiration_ReminderArgs = {
  where: Email_Library_Card_Expiration_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Library_Card_Expiration_Reminder_By_PkArgs = {
  readingplan_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Library_Card_Expired_ReminderArgs = {
  where: Email_Library_Card_Expired_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Library_Card_Expired_Reminder_By_PkArgs = {
  readingplan_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EulaArgs = {
  where: Eula_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Eula_AcceptanceArgs = {
  where: Eula_Acceptance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Eula_Acceptance_By_PkArgs = {
  eula_id: Scalars['uuid'];
  person_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Eula_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LanguageArgs = {
  where: Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Language_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Literary_QuoteArgs = {
  where: Literary_Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Literary_Quote_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Live_Call_StateArgs = {
  where: Live_Call_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Live_Call_State_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrgArgs = {
  where: Org_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Org_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ParticipationeventArgs = {
  where: Participationevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Participationevent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ParticipationstateArgs = {
  where: Participationstate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Participationstate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PersonArgs = {
  where: Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Person_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PillarArgs = {
  where: Pillar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pillar_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Pillar_TopicArgs = {
  where: Pillar_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pillar_Topic_By_PkArgs = {
  pillar_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PillarconstraintArgs = {
  where: Pillarconstraint_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pillarconstraint_By_PkArgs = {
  pillarofprejudice_id: Scalars['uuid'];
  readingplan_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReadingArgs = {
  where: Reading_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reading_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reading_SettingArgs = {
  where: Reading_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reading_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReadingeventArgs = {
  where: Readingevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Readingevent_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReadingplanArgs = {
  where: Readingplan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Readingplan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Readingplan_DurationArgs = {
  where: Readingplan_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Readingplan_Duration_TranslationArgs = {
  where: Readingplan_Duration_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Readingplan_Duration_Translation_By_PkArgs = {
  language_id: Scalars['uuid'];
  readingplan_duration_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Readings_Per_WeekArgs = {
  where: Readings_Per_Week_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReadingstateArgs = {
  where: Readingstate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Readingstate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReviewArgs = {
  where: Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Review_BookArgs = {
  where: Review_Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Review_Book_By_PkArgs = {
  reading_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Review_Book_OptionArgs = {
  where: Review_Book_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Review_Book_Option_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_Email_ReminderArgs = {
  where: Scheduled_Email_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_Email_Reminder_By_PkArgs = {
  reading_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TimezoneArgs = {
  where: Timezone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Timezone_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TopicArgs = {
  where: Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Topic_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_View_ReadingsArgs = {
  where: View_Readings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Virtual_Book_Get_Number_Of_ReadingsArgs = {
  where: Virtual_Book_Get_Number_Of_Readings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_BookArgs = {
  objects: Array<Book_Insert_Input>;
  on_conflict?: InputMaybe<Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Book_OneArgs = {
  object: Book_Insert_Input;
  on_conflict?: InputMaybe<Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Book_TopicArgs = {
  objects: Array<Book_Topic_Insert_Input>;
  on_conflict?: InputMaybe<Book_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Book_Topic_OneArgs = {
  object: Book_Topic_Insert_Input;
  on_conflict?: InputMaybe<Book_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BookeventArgs = {
  objects: Array<Bookevent_Insert_Input>;
  on_conflict?: InputMaybe<Bookevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bookevent_OneArgs = {
  object: Bookevent_Insert_Input;
  on_conflict?: InputMaybe<Bookevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BooklanguageArgs = {
  objects: Array<Booklanguage_Insert_Input>;
  on_conflict?: InputMaybe<Booklanguage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Booklanguage_OneArgs = {
  object: Booklanguage_Insert_Input;
  on_conflict?: InputMaybe<Booklanguage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BookstateArgs = {
  objects: Array<Bookstate_Insert_Input>;
  on_conflict?: InputMaybe<Bookstate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bookstate_OneArgs = {
  object: Bookstate_Insert_Input;
  on_conflict?: InputMaybe<Bookstate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cancel_ReviewArgs = {
  objects: Array<Cancel_Review_Insert_Input>;
  on_conflict?: InputMaybe<Cancel_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cancel_Review_OneArgs = {
  object: Cancel_Review_Insert_Input;
  on_conflict?: InputMaybe<Cancel_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_MessageArgs = {
  objects: Array<Chat_Message_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Message_OneArgs = {
  object: Chat_Message_Insert_Input;
  on_conflict?: InputMaybe<Chat_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Message_TypeArgs = {
  objects: Array<Chat_Message_Type_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Message_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Message_Type_OneArgs = {
  object: Chat_Message_Type_Insert_Input;
  on_conflict?: InputMaybe<Chat_Message_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Reading_MessageArgs = {
  objects: Array<Chat_Reading_Message_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Reading_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Reading_Message_OneArgs = {
  object: Chat_Reading_Message_Insert_Input;
  on_conflict?: InputMaybe<Chat_Reading_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_RoomArgs = {
  objects: Array<Chat_Room_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Room_OneArgs = {
  object: Chat_Room_Insert_Input;
  on_conflict?: InputMaybe<Chat_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: InputMaybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DebriefingArgs = {
  objects: Array<Debriefing_Insert_Input>;
  on_conflict?: InputMaybe<Debriefing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Debriefing_OneArgs = {
  object: Debriefing_Insert_Input;
  on_conflict?: InputMaybe<Debriefing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DurationArgs = {
  objects: Array<Duration_Insert_Input>;
  on_conflict?: InputMaybe<Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Duration_OneArgs = {
  object: Duration_Insert_Input;
  on_conflict?: InputMaybe<Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Activate_ReminderArgs = {
  objects: Array<Email_Activate_Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Email_Activate_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Activate_Reminder_OneArgs = {
  object: Email_Activate_Reminder_Insert_Input;
  on_conflict?: InputMaybe<Email_Activate_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Chat_Message_Unread_ReminderArgs = {
  objects: Array<Email_Chat_Message_Unread_Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Email_Chat_Message_Unread_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Chat_Message_Unread_Reminder_OneArgs = {
  object: Email_Chat_Message_Unread_Reminder_Insert_Input;
  on_conflict?: InputMaybe<Email_Chat_Message_Unread_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Library_Card_Expiration_ReminderArgs = {
  objects: Array<Email_Library_Card_Expiration_Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Email_Library_Card_Expiration_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Library_Card_Expiration_Reminder_OneArgs = {
  object: Email_Library_Card_Expiration_Reminder_Insert_Input;
  on_conflict?: InputMaybe<Email_Library_Card_Expiration_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Library_Card_Expired_ReminderArgs = {
  objects: Array<Email_Library_Card_Expired_Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Email_Library_Card_Expired_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Library_Card_Expired_Reminder_OneArgs = {
  object: Email_Library_Card_Expired_Reminder_Insert_Input;
  on_conflict?: InputMaybe<Email_Library_Card_Expired_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EulaArgs = {
  objects: Array<Eula_Insert_Input>;
  on_conflict?: InputMaybe<Eula_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Eula_AcceptanceArgs = {
  objects: Array<Eula_Acceptance_Insert_Input>;
  on_conflict?: InputMaybe<Eula_Acceptance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Eula_Acceptance_OneArgs = {
  object: Eula_Acceptance_Insert_Input;
  on_conflict?: InputMaybe<Eula_Acceptance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Eula_OneArgs = {
  object: Eula_Insert_Input;
  on_conflict?: InputMaybe<Eula_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LanguageArgs = {
  objects: Array<Language_Insert_Input>;
  on_conflict?: InputMaybe<Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Language_OneArgs = {
  object: Language_Insert_Input;
  on_conflict?: InputMaybe<Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Literary_QuoteArgs = {
  objects: Array<Literary_Quote_Insert_Input>;
  on_conflict?: InputMaybe<Literary_Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Literary_Quote_OneArgs = {
  object: Literary_Quote_Insert_Input;
  on_conflict?: InputMaybe<Literary_Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Call_StateArgs = {
  objects: Array<Live_Call_State_Insert_Input>;
  on_conflict?: InputMaybe<Live_Call_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Call_State_OneArgs = {
  object: Live_Call_State_Insert_Input;
  on_conflict?: InputMaybe<Live_Call_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrgArgs = {
  objects: Array<Org_Insert_Input>;
  on_conflict?: InputMaybe<Org_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Org_OneArgs = {
  object: Org_Insert_Input;
  on_conflict?: InputMaybe<Org_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ParticipationeventArgs = {
  objects: Array<Participationevent_Insert_Input>;
  on_conflict?: InputMaybe<Participationevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Participationevent_OneArgs = {
  object: Participationevent_Insert_Input;
  on_conflict?: InputMaybe<Participationevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ParticipationstateArgs = {
  objects: Array<Participationstate_Insert_Input>;
  on_conflict?: InputMaybe<Participationstate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Participationstate_OneArgs = {
  object: Participationstate_Insert_Input;
  on_conflict?: InputMaybe<Participationstate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonArgs = {
  objects: Array<Person_Insert_Input>;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Person_OneArgs = {
  object: Person_Insert_Input;
  on_conflict?: InputMaybe<Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PillarArgs = {
  objects: Array<Pillar_Insert_Input>;
  on_conflict?: InputMaybe<Pillar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pillar_OneArgs = {
  object: Pillar_Insert_Input;
  on_conflict?: InputMaybe<Pillar_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pillar_TopicArgs = {
  objects: Array<Pillar_Topic_Insert_Input>;
  on_conflict?: InputMaybe<Pillar_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pillar_Topic_OneArgs = {
  object: Pillar_Topic_Insert_Input;
  on_conflict?: InputMaybe<Pillar_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PillarconstraintArgs = {
  objects: Array<Pillarconstraint_Insert_Input>;
  on_conflict?: InputMaybe<Pillarconstraint_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pillarconstraint_OneArgs = {
  object: Pillarconstraint_Insert_Input;
  on_conflict?: InputMaybe<Pillarconstraint_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReadingArgs = {
  objects: Array<Reading_Insert_Input>;
  on_conflict?: InputMaybe<Reading_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reading_OneArgs = {
  object: Reading_Insert_Input;
  on_conflict?: InputMaybe<Reading_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reading_SettingArgs = {
  objects: Array<Reading_Setting_Insert_Input>;
  on_conflict?: InputMaybe<Reading_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reading_Setting_OneArgs = {
  object: Reading_Setting_Insert_Input;
  on_conflict?: InputMaybe<Reading_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReadingeventArgs = {
  objects: Array<Readingevent_Insert_Input>;
  on_conflict?: InputMaybe<Readingevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readingevent_OneArgs = {
  object: Readingevent_Insert_Input;
  on_conflict?: InputMaybe<Readingevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReadingplanArgs = {
  objects: Array<Readingplan_Insert_Input>;
  on_conflict?: InputMaybe<Readingplan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readingplan_DurationArgs = {
  objects: Array<Readingplan_Duration_Insert_Input>;
  on_conflict?: InputMaybe<Readingplan_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readingplan_Duration_OneArgs = {
  object: Readingplan_Duration_Insert_Input;
  on_conflict?: InputMaybe<Readingplan_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readingplan_Duration_TranslationArgs = {
  objects: Array<Readingplan_Duration_Translation_Insert_Input>;
  on_conflict?: InputMaybe<Readingplan_Duration_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readingplan_Duration_Translation_OneArgs = {
  object: Readingplan_Duration_Translation_Insert_Input;
  on_conflict?: InputMaybe<Readingplan_Duration_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readingplan_OneArgs = {
  object: Readingplan_Insert_Input;
  on_conflict?: InputMaybe<Readingplan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readings_Per_WeekArgs = {
  objects: Array<Readings_Per_Week_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Readings_Per_Week_OneArgs = {
  object: Readings_Per_Week_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ReadingstateArgs = {
  objects: Array<Readingstate_Insert_Input>;
  on_conflict?: InputMaybe<Readingstate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Readingstate_OneArgs = {
  object: Readingstate_Insert_Input;
  on_conflict?: InputMaybe<Readingstate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReviewArgs = {
  objects: Array<Review_Insert_Input>;
  on_conflict?: InputMaybe<Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_BookArgs = {
  objects: Array<Review_Book_Insert_Input>;
  on_conflict?: InputMaybe<Review_Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_Book_OneArgs = {
  object: Review_Book_Insert_Input;
  on_conflict?: InputMaybe<Review_Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_Book_OptionArgs = {
  objects: Array<Review_Book_Option_Insert_Input>;
  on_conflict?: InputMaybe<Review_Book_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_Book_Option_OneArgs = {
  object: Review_Book_Option_Insert_Input;
  on_conflict?: InputMaybe<Review_Book_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Review_OneArgs = {
  object: Review_Insert_Input;
  on_conflict?: InputMaybe<Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_Email_ReminderArgs = {
  objects: Array<Scheduled_Email_Reminder_Insert_Input>;
  on_conflict?: InputMaybe<Scheduled_Email_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_Email_Reminder_OneArgs = {
  object: Scheduled_Email_Reminder_Insert_Input;
  on_conflict?: InputMaybe<Scheduled_Email_Reminder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TimezoneArgs = {
  objects: Array<Timezone_Insert_Input>;
  on_conflict?: InputMaybe<Timezone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Timezone_OneArgs = {
  object: Timezone_Insert_Input;
  on_conflict?: InputMaybe<Timezone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TopicArgs = {
  objects: Array<Topic_Insert_Input>;
  on_conflict?: InputMaybe<Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topic_OneArgs = {
  object: Topic_Insert_Input;
  on_conflict?: InputMaybe<Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_View_ReadingsArgs = {
  objects: Array<View_Readings_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_View_Readings_OneArgs = {
  object: View_Readings_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Virtual_Book_Get_Number_Of_ReadingsArgs = {
  objects: Array<Virtual_Book_Get_Number_Of_Readings_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Virtual_Book_Get_Number_Of_Readings_OneArgs = {
  object: Virtual_Book_Get_Number_Of_Readings_Insert_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BookArgs = {
  _inc?: InputMaybe<Book_Inc_Input>;
  _set?: InputMaybe<Book_Set_Input>;
  where: Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Book_By_PkArgs = {
  _inc?: InputMaybe<Book_Inc_Input>;
  _set?: InputMaybe<Book_Set_Input>;
  pk_columns: Book_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Book_ManyArgs = {
  updates: Array<Book_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Book_TopicArgs = {
  _set?: InputMaybe<Book_Topic_Set_Input>;
  where: Book_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Book_Topic_By_PkArgs = {
  _set?: InputMaybe<Book_Topic_Set_Input>;
  pk_columns: Book_Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Book_Topic_ManyArgs = {
  updates: Array<Book_Topic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BookeventArgs = {
  _set?: InputMaybe<Bookevent_Set_Input>;
  where: Bookevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bookevent_By_PkArgs = {
  _set?: InputMaybe<Bookevent_Set_Input>;
  pk_columns: Bookevent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bookevent_ManyArgs = {
  updates: Array<Bookevent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BooklanguageArgs = {
  _set?: InputMaybe<Booklanguage_Set_Input>;
  where: Booklanguage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Booklanguage_By_PkArgs = {
  _set?: InputMaybe<Booklanguage_Set_Input>;
  pk_columns: Booklanguage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Booklanguage_ManyArgs = {
  updates: Array<Booklanguage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BookstateArgs = {
  _set?: InputMaybe<Bookstate_Set_Input>;
  where: Bookstate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bookstate_By_PkArgs = {
  _set?: InputMaybe<Bookstate_Set_Input>;
  pk_columns: Bookstate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bookstate_ManyArgs = {
  updates: Array<Bookstate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Cancel_ReviewArgs = {
  _set?: InputMaybe<Cancel_Review_Set_Input>;
  where: Cancel_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cancel_Review_By_PkArgs = {
  _set?: InputMaybe<Cancel_Review_Set_Input>;
  pk_columns: Cancel_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cancel_Review_ManyArgs = {
  updates: Array<Cancel_Review_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_MessageArgs = {
  _set?: InputMaybe<Chat_Message_Set_Input>;
  where: Chat_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Message_By_PkArgs = {
  _set?: InputMaybe<Chat_Message_Set_Input>;
  pk_columns: Chat_Message_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Message_ManyArgs = {
  updates: Array<Chat_Message_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Message_TypeArgs = {
  _set?: InputMaybe<Chat_Message_Type_Set_Input>;
  where: Chat_Message_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Message_Type_By_PkArgs = {
  _set?: InputMaybe<Chat_Message_Type_Set_Input>;
  pk_columns: Chat_Message_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Message_Type_ManyArgs = {
  updates: Array<Chat_Message_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Reading_MessageArgs = {
  _set?: InputMaybe<Chat_Reading_Message_Set_Input>;
  where: Chat_Reading_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Reading_Message_By_PkArgs = {
  _set?: InputMaybe<Chat_Reading_Message_Set_Input>;
  pk_columns: Chat_Reading_Message_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Reading_Message_ManyArgs = {
  updates: Array<Chat_Reading_Message_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_RoomArgs = {
  _set?: InputMaybe<Chat_Room_Set_Input>;
  where: Chat_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Room_By_PkArgs = {
  _set?: InputMaybe<Chat_Room_Set_Input>;
  pk_columns: Chat_Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Room_ManyArgs = {
  updates: Array<Chat_Room_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _set?: InputMaybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _set?: InputMaybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DebriefingArgs = {
  _set?: InputMaybe<Debriefing_Set_Input>;
  where: Debriefing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Debriefing_By_PkArgs = {
  _set?: InputMaybe<Debriefing_Set_Input>;
  pk_columns: Debriefing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Debriefing_ManyArgs = {
  updates: Array<Debriefing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DurationArgs = {
  _inc?: InputMaybe<Duration_Inc_Input>;
  _set?: InputMaybe<Duration_Set_Input>;
  where: Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Duration_By_PkArgs = {
  _inc?: InputMaybe<Duration_Inc_Input>;
  _set?: InputMaybe<Duration_Set_Input>;
  pk_columns: Duration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Duration_ManyArgs = {
  updates: Array<Duration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Activate_ReminderArgs = {
  _set?: InputMaybe<Email_Activate_Reminder_Set_Input>;
  where: Email_Activate_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Activate_Reminder_By_PkArgs = {
  _set?: InputMaybe<Email_Activate_Reminder_Set_Input>;
  pk_columns: Email_Activate_Reminder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Activate_Reminder_ManyArgs = {
  updates: Array<Email_Activate_Reminder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Chat_Message_Unread_ReminderArgs = {
  _set?: InputMaybe<Email_Chat_Message_Unread_Reminder_Set_Input>;
  where: Email_Chat_Message_Unread_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Chat_Message_Unread_Reminder_By_PkArgs = {
  _set?: InputMaybe<Email_Chat_Message_Unread_Reminder_Set_Input>;
  pk_columns: Email_Chat_Message_Unread_Reminder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Chat_Message_Unread_Reminder_ManyArgs = {
  updates: Array<Email_Chat_Message_Unread_Reminder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Library_Card_Expiration_ReminderArgs = {
  _set?: InputMaybe<Email_Library_Card_Expiration_Reminder_Set_Input>;
  where: Email_Library_Card_Expiration_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Library_Card_Expiration_Reminder_By_PkArgs = {
  _set?: InputMaybe<Email_Library_Card_Expiration_Reminder_Set_Input>;
  pk_columns: Email_Library_Card_Expiration_Reminder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Library_Card_Expiration_Reminder_ManyArgs = {
  updates: Array<Email_Library_Card_Expiration_Reminder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Library_Card_Expired_ReminderArgs = {
  _set?: InputMaybe<Email_Library_Card_Expired_Reminder_Set_Input>;
  where: Email_Library_Card_Expired_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Library_Card_Expired_Reminder_By_PkArgs = {
  _set?: InputMaybe<Email_Library_Card_Expired_Reminder_Set_Input>;
  pk_columns: Email_Library_Card_Expired_Reminder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Library_Card_Expired_Reminder_ManyArgs = {
  updates: Array<Email_Library_Card_Expired_Reminder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EulaArgs = {
  _inc?: InputMaybe<Eula_Inc_Input>;
  _set?: InputMaybe<Eula_Set_Input>;
  where: Eula_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Eula_AcceptanceArgs = {
  _set?: InputMaybe<Eula_Acceptance_Set_Input>;
  where: Eula_Acceptance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Eula_Acceptance_By_PkArgs = {
  _set?: InputMaybe<Eula_Acceptance_Set_Input>;
  pk_columns: Eula_Acceptance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Eula_Acceptance_ManyArgs = {
  updates: Array<Eula_Acceptance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Eula_By_PkArgs = {
  _inc?: InputMaybe<Eula_Inc_Input>;
  _set?: InputMaybe<Eula_Set_Input>;
  pk_columns: Eula_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Eula_ManyArgs = {
  updates: Array<Eula_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LanguageArgs = {
  _set?: InputMaybe<Language_Set_Input>;
  where: Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Language_By_PkArgs = {
  _set?: InputMaybe<Language_Set_Input>;
  pk_columns: Language_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Language_ManyArgs = {
  updates: Array<Language_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Literary_QuoteArgs = {
  _set?: InputMaybe<Literary_Quote_Set_Input>;
  where: Literary_Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Literary_Quote_By_PkArgs = {
  _set?: InputMaybe<Literary_Quote_Set_Input>;
  pk_columns: Literary_Quote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Literary_Quote_ManyArgs = {
  updates: Array<Literary_Quote_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Call_StateArgs = {
  _set?: InputMaybe<Live_Call_State_Set_Input>;
  where: Live_Call_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Call_State_By_PkArgs = {
  _set?: InputMaybe<Live_Call_State_Set_Input>;
  pk_columns: Live_Call_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Call_State_ManyArgs = {
  updates: Array<Live_Call_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrgArgs = {
  _set?: InputMaybe<Org_Set_Input>;
  where: Org_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Org_By_PkArgs = {
  _set?: InputMaybe<Org_Set_Input>;
  pk_columns: Org_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Org_ManyArgs = {
  updates: Array<Org_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ParticipationeventArgs = {
  _set?: InputMaybe<Participationevent_Set_Input>;
  where: Participationevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Participationevent_By_PkArgs = {
  _set?: InputMaybe<Participationevent_Set_Input>;
  pk_columns: Participationevent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Participationevent_ManyArgs = {
  updates: Array<Participationevent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ParticipationstateArgs = {
  _set?: InputMaybe<Participationstate_Set_Input>;
  where: Participationstate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Participationstate_By_PkArgs = {
  _set?: InputMaybe<Participationstate_Set_Input>;
  pk_columns: Participationstate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Participationstate_ManyArgs = {
  updates: Array<Participationstate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PersonArgs = {
  _set?: InputMaybe<Person_Set_Input>;
  where: Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Person_By_PkArgs = {
  _set?: InputMaybe<Person_Set_Input>;
  pk_columns: Person_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Person_ManyArgs = {
  updates: Array<Person_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PillarArgs = {
  _set?: InputMaybe<Pillar_Set_Input>;
  where: Pillar_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pillar_By_PkArgs = {
  _set?: InputMaybe<Pillar_Set_Input>;
  pk_columns: Pillar_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pillar_ManyArgs = {
  updates: Array<Pillar_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pillar_TopicArgs = {
  _set?: InputMaybe<Pillar_Topic_Set_Input>;
  where: Pillar_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pillar_Topic_By_PkArgs = {
  _set?: InputMaybe<Pillar_Topic_Set_Input>;
  pk_columns: Pillar_Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pillar_Topic_ManyArgs = {
  updates: Array<Pillar_Topic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PillarconstraintArgs = {
  _set?: InputMaybe<Pillarconstraint_Set_Input>;
  where: Pillarconstraint_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pillarconstraint_By_PkArgs = {
  _set?: InputMaybe<Pillarconstraint_Set_Input>;
  pk_columns: Pillarconstraint_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pillarconstraint_ManyArgs = {
  updates: Array<Pillarconstraint_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReadingArgs = {
  _inc?: InputMaybe<Reading_Inc_Input>;
  _set?: InputMaybe<Reading_Set_Input>;
  where: Reading_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reading_By_PkArgs = {
  _inc?: InputMaybe<Reading_Inc_Input>;
  _set?: InputMaybe<Reading_Set_Input>;
  pk_columns: Reading_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reading_ManyArgs = {
  updates: Array<Reading_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reading_SettingArgs = {
  _set?: InputMaybe<Reading_Setting_Set_Input>;
  where: Reading_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reading_Setting_By_PkArgs = {
  _set?: InputMaybe<Reading_Setting_Set_Input>;
  pk_columns: Reading_Setting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reading_Setting_ManyArgs = {
  updates: Array<Reading_Setting_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReadingeventArgs = {
  _set?: InputMaybe<Readingevent_Set_Input>;
  where: Readingevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Readingevent_By_PkArgs = {
  _set?: InputMaybe<Readingevent_Set_Input>;
  pk_columns: Readingevent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Readingevent_ManyArgs = {
  updates: Array<Readingevent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReadingplanArgs = {
  _inc?: InputMaybe<Readingplan_Inc_Input>;
  _set?: InputMaybe<Readingplan_Set_Input>;
  where: Readingplan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Readingplan_By_PkArgs = {
  _inc?: InputMaybe<Readingplan_Inc_Input>;
  _set?: InputMaybe<Readingplan_Set_Input>;
  pk_columns: Readingplan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Readingplan_DurationArgs = {
  _inc?: InputMaybe<Readingplan_Duration_Inc_Input>;
  _set?: InputMaybe<Readingplan_Duration_Set_Input>;
  where: Readingplan_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Readingplan_Duration_ManyArgs = {
  updates: Array<Readingplan_Duration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Readingplan_Duration_TranslationArgs = {
  _set?: InputMaybe<Readingplan_Duration_Translation_Set_Input>;
  where: Readingplan_Duration_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Readingplan_Duration_Translation_By_PkArgs = {
  _set?: InputMaybe<Readingplan_Duration_Translation_Set_Input>;
  pk_columns: Readingplan_Duration_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Readingplan_Duration_Translation_ManyArgs = {
  updates: Array<Readingplan_Duration_Translation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Readingplan_ManyArgs = {
  updates: Array<Readingplan_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Readings_Per_WeekArgs = {
  _inc?: InputMaybe<Readings_Per_Week_Inc_Input>;
  _set?: InputMaybe<Readings_Per_Week_Set_Input>;
  where: Readings_Per_Week_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Readings_Per_Week_ManyArgs = {
  updates: Array<Readings_Per_Week_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReadingstateArgs = {
  _set?: InputMaybe<Readingstate_Set_Input>;
  where: Readingstate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Readingstate_By_PkArgs = {
  _set?: InputMaybe<Readingstate_Set_Input>;
  pk_columns: Readingstate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Readingstate_ManyArgs = {
  updates: Array<Readingstate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReviewArgs = {
  _inc?: InputMaybe<Review_Inc_Input>;
  _set?: InputMaybe<Review_Set_Input>;
  where: Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Review_BookArgs = {
  _set?: InputMaybe<Review_Book_Set_Input>;
  where: Review_Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Review_Book_By_PkArgs = {
  _set?: InputMaybe<Review_Book_Set_Input>;
  pk_columns: Review_Book_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Review_Book_ManyArgs = {
  updates: Array<Review_Book_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Review_Book_OptionArgs = {
  _inc?: InputMaybe<Review_Book_Option_Inc_Input>;
  _set?: InputMaybe<Review_Book_Option_Set_Input>;
  where: Review_Book_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Review_Book_Option_By_PkArgs = {
  _inc?: InputMaybe<Review_Book_Option_Inc_Input>;
  _set?: InputMaybe<Review_Book_Option_Set_Input>;
  pk_columns: Review_Book_Option_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Review_Book_Option_ManyArgs = {
  updates: Array<Review_Book_Option_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Review_By_PkArgs = {
  _inc?: InputMaybe<Review_Inc_Input>;
  _set?: InputMaybe<Review_Set_Input>;
  pk_columns: Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Review_ManyArgs = {
  updates: Array<Review_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _set?: InputMaybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _set?: InputMaybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Email_ReminderArgs = {
  _set?: InputMaybe<Scheduled_Email_Reminder_Set_Input>;
  where: Scheduled_Email_Reminder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Email_Reminder_By_PkArgs = {
  _set?: InputMaybe<Scheduled_Email_Reminder_Set_Input>;
  pk_columns: Scheduled_Email_Reminder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Email_Reminder_ManyArgs = {
  updates: Array<Scheduled_Email_Reminder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TimezoneArgs = {
  _inc?: InputMaybe<Timezone_Inc_Input>;
  _set?: InputMaybe<Timezone_Set_Input>;
  where: Timezone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Timezone_By_PkArgs = {
  _inc?: InputMaybe<Timezone_Inc_Input>;
  _set?: InputMaybe<Timezone_Set_Input>;
  pk_columns: Timezone_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Timezone_ManyArgs = {
  updates: Array<Timezone_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TopicArgs = {
  _set?: InputMaybe<Topic_Set_Input>;
  where: Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Topic_By_PkArgs = {
  _set?: InputMaybe<Topic_Set_Input>;
  pk_columns: Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Topic_ManyArgs = {
  updates: Array<Topic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_View_ReadingsArgs = {
  _inc?: InputMaybe<View_Readings_Inc_Input>;
  _set?: InputMaybe<View_Readings_Set_Input>;
  where: View_Readings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_View_Readings_ManyArgs = {
  updates: Array<View_Readings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Virtual_Book_Get_Number_Of_ReadingsArgs = {
  _inc?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Inc_Input>;
  _set?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Set_Input>;
  where: Virtual_Book_Get_Number_Of_Readings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Virtual_Book_Get_Number_Of_Readings_ManyArgs = {
  updates: Array<Virtual_Book_Get_Number_Of_Readings_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "org" */
export type Org = {
  __typename?: 'org';
  /** An object relationship */
  country?: Maybe<Country>;
  country_id?: Maybe<Scalars['uuid']>;
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  org?: Maybe<Org>;
  /** An array relationship */
  orgs: Array<Org>;
  /** An aggregate relationship */
  orgs_aggregate: Org_Aggregate;
  parent?: Maybe<Scalars['uuid']>;
  reader_review_short_url?: Maybe<Scalars['String']>;
  reader_review_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};


/** columns and relationships of "org" */
export type OrgOrgsArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


/** columns and relationships of "org" */
export type OrgOrgs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


/** columns and relationships of "org" */
export type OrgUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "org" */
export type OrgUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "org" */
export type Org_Aggregate = {
  __typename?: 'org_aggregate';
  aggregate?: Maybe<Org_Aggregate_Fields>;
  nodes: Array<Org>;
};

export type Org_Aggregate_Bool_Exp = {
  count?: InputMaybe<Org_Aggregate_Bool_Exp_Count>;
};

export type Org_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Org_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Org_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "org" */
export type Org_Aggregate_Fields = {
  __typename?: 'org_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Org_Max_Fields>;
  min?: Maybe<Org_Min_Fields>;
};


/** aggregate fields of "org" */
export type Org_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Org_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "org" */
export type Org_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Org_Max_Order_By>;
  min?: InputMaybe<Org_Min_Order_By>;
};

/** input type for inserting array relation for remote table "org" */
export type Org_Arr_Rel_Insert_Input = {
  data: Array<Org_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Org_On_Conflict>;
};

/** Boolean expression to filter rows from the table "org". All fields are combined with a logical 'AND'. */
export type Org_Bool_Exp = {
  _and?: InputMaybe<Array<Org_Bool_Exp>>;
  _not?: InputMaybe<Org_Bool_Exp>;
  _or?: InputMaybe<Array<Org_Bool_Exp>>;
  country?: InputMaybe<Country_Bool_Exp>;
  country_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  org?: InputMaybe<Org_Bool_Exp>;
  orgs?: InputMaybe<Org_Bool_Exp>;
  orgs_aggregate?: InputMaybe<Org_Aggregate_Bool_Exp>;
  parent?: InputMaybe<Uuid_Comparison_Exp>;
  reader_review_short_url?: InputMaybe<String_Comparison_Exp>;
  reader_review_url?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "org" */
export enum Org_Constraint {
  /** unique or primary key constraint on columns "name" */
  OrgAkName = 'org_ak_name',
  /** unique or primary key constraint on columns "id" */
  OrgPk = 'org_pk'
}

/** input type for inserting data into table "org" */
export type Org_Insert_Input = {
  country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  org?: InputMaybe<Org_Obj_Rel_Insert_Input>;
  orgs?: InputMaybe<Org_Arr_Rel_Insert_Input>;
  parent?: InputMaybe<Scalars['uuid']>;
  reader_review_short_url?: InputMaybe<Scalars['String']>;
  reader_review_url?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Org_Max_Fields = {
  __typename?: 'org_max_fields';
  country_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  reader_review_short_url?: Maybe<Scalars['String']>;
  reader_review_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "org" */
export type Org_Max_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  reader_review_short_url?: InputMaybe<Order_By>;
  reader_review_url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Org_Min_Fields = {
  __typename?: 'org_min_fields';
  country_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  reader_review_short_url?: Maybe<Scalars['String']>;
  reader_review_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "org" */
export type Org_Min_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent?: InputMaybe<Order_By>;
  reader_review_short_url?: InputMaybe<Order_By>;
  reader_review_url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "org" */
export type Org_Mutation_Response = {
  __typename?: 'org_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Org>;
};

/** input type for inserting object relation for remote table "org" */
export type Org_Obj_Rel_Insert_Input = {
  data: Org_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Org_On_Conflict>;
};

/** on_conflict condition type for table "org" */
export type Org_On_Conflict = {
  constraint: Org_Constraint;
  update_columns?: Array<Org_Update_Column>;
  where?: InputMaybe<Org_Bool_Exp>;
};

/** Ordering options when selecting data from "org". */
export type Org_Order_By = {
  country?: InputMaybe<Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  org?: InputMaybe<Org_Order_By>;
  orgs_aggregate?: InputMaybe<Org_Aggregate_Order_By>;
  parent?: InputMaybe<Order_By>;
  reader_review_short_url?: InputMaybe<Order_By>;
  reader_review_url?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: org */
export type Org_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "org" */
export enum Org_Select_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Parent = 'parent',
  /** column name */
  ReaderReviewShortUrl = 'reader_review_short_url',
  /** column name */
  ReaderReviewUrl = 'reader_review_url'
}

/** input type for updating data in table "org" */
export type Org_Set_Input = {
  country_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['uuid']>;
  reader_review_short_url?: InputMaybe<Scalars['String']>;
  reader_review_url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "org" */
export type Org_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Org_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Org_Stream_Cursor_Value_Input = {
  country_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['uuid']>;
  reader_review_short_url?: InputMaybe<Scalars['String']>;
  reader_review_url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "org" */
export enum Org_Update_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Parent = 'parent',
  /** column name */
  ReaderReviewShortUrl = 'reader_review_short_url',
  /** column name */
  ReaderReviewUrl = 'reader_review_url'
}

export type Org_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Org_Set_Input>;
  /** filter the rows which have to be updated */
  where: Org_Bool_Exp;
};

/** columns and relationships of "participationevent" */
export type Participationevent = {
  __typename?: 'participationevent';
  comment?: Maybe<Scalars['String']>;
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  participationstate: Participationstate;
  participationstate_id: Scalars['uuid'];
  /** An object relationship */
  reading: Reading;
  reading_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "participationevent" */
export type Participationevent_Aggregate = {
  __typename?: 'participationevent_aggregate';
  aggregate?: Maybe<Participationevent_Aggregate_Fields>;
  nodes: Array<Participationevent>;
};

export type Participationevent_Aggregate_Bool_Exp = {
  count?: InputMaybe<Participationevent_Aggregate_Bool_Exp_Count>;
};

export type Participationevent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Participationevent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Participationevent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "participationevent" */
export type Participationevent_Aggregate_Fields = {
  __typename?: 'participationevent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Participationevent_Max_Fields>;
  min?: Maybe<Participationevent_Min_Fields>;
};


/** aggregate fields of "participationevent" */
export type Participationevent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Participationevent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "participationevent" */
export type Participationevent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Participationevent_Max_Order_By>;
  min?: InputMaybe<Participationevent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "participationevent" */
export type Participationevent_Arr_Rel_Insert_Input = {
  data: Array<Participationevent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Participationevent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "participationevent". All fields are combined with a logical 'AND'. */
export type Participationevent_Bool_Exp = {
  _and?: InputMaybe<Array<Participationevent_Bool_Exp>>;
  _not?: InputMaybe<Participationevent_Bool_Exp>;
  _or?: InputMaybe<Array<Participationevent_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  participationstate?: InputMaybe<Participationstate_Bool_Exp>;
  participationstate_id?: InputMaybe<Uuid_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "participationevent" */
export enum Participationevent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParticipationeventPk = 'participationevent_pk'
}

/** input type for inserting data into table "participationevent" */
export type Participationevent_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  participationstate?: InputMaybe<Participationstate_Obj_Rel_Insert_Input>;
  participationstate_id?: InputMaybe<Scalars['uuid']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Participationevent_Max_Fields = {
  __typename?: 'participationevent_max_fields';
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  participationstate_id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "participationevent" */
export type Participationevent_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participationstate_id?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Participationevent_Min_Fields = {
  __typename?: 'participationevent_min_fields';
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  participationstate_id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "participationevent" */
export type Participationevent_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participationstate_id?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "participationevent" */
export type Participationevent_Mutation_Response = {
  __typename?: 'participationevent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Participationevent>;
};

/** on_conflict condition type for table "participationevent" */
export type Participationevent_On_Conflict = {
  constraint: Participationevent_Constraint;
  update_columns?: Array<Participationevent_Update_Column>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};

/** Ordering options when selecting data from "participationevent". */
export type Participationevent_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  participationstate?: InputMaybe<Participationstate_Order_By>;
  participationstate_id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: participationevent */
export type Participationevent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "participationevent" */
export enum Participationevent_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ParticipationstateId = 'participationstate_id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "participationevent" */
export type Participationevent_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  participationstate_id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "participationevent" */
export type Participationevent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Participationevent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Participationevent_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  participationstate_id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "participationevent" */
export enum Participationevent_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  ParticipationstateId = 'participationstate_id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

export type Participationevent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Participationevent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Participationevent_Bool_Exp;
};

/** columns and relationships of "participationstate" */
export type Participationstate = {
  __typename?: 'participationstate';
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  participationevents: Array<Participationevent>;
  /** An aggregate relationship */
  participationevents_aggregate: Participationevent_Aggregate;
};


/** columns and relationships of "participationstate" */
export type ParticipationstateParticipationeventsArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


/** columns and relationships of "participationstate" */
export type ParticipationstateParticipationevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};

/** aggregated selection of "participationstate" */
export type Participationstate_Aggregate = {
  __typename?: 'participationstate_aggregate';
  aggregate?: Maybe<Participationstate_Aggregate_Fields>;
  nodes: Array<Participationstate>;
};

/** aggregate fields of "participationstate" */
export type Participationstate_Aggregate_Fields = {
  __typename?: 'participationstate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Participationstate_Max_Fields>;
  min?: Maybe<Participationstate_Min_Fields>;
};


/** aggregate fields of "participationstate" */
export type Participationstate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Participationstate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "participationstate". All fields are combined with a logical 'AND'. */
export type Participationstate_Bool_Exp = {
  _and?: InputMaybe<Array<Participationstate_Bool_Exp>>;
  _not?: InputMaybe<Participationstate_Bool_Exp>;
  _or?: InputMaybe<Array<Participationstate_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  participationevents?: InputMaybe<Participationevent_Bool_Exp>;
  participationevents_aggregate?: InputMaybe<Participationevent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "participationstate" */
export enum Participationstate_Constraint {
  /** unique or primary key constraint on columns "name" */
  ParticipationstateAkName = 'participationstate_ak_name',
  /** unique or primary key constraint on columns "id" */
  ParticipationstatePk = 'participationstate_pk'
}

/** input type for inserting data into table "participationstate" */
export type Participationstate_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  participationevents?: InputMaybe<Participationevent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Participationstate_Max_Fields = {
  __typename?: 'participationstate_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Participationstate_Min_Fields = {
  __typename?: 'participationstate_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "participationstate" */
export type Participationstate_Mutation_Response = {
  __typename?: 'participationstate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Participationstate>;
};

/** input type for inserting object relation for remote table "participationstate" */
export type Participationstate_Obj_Rel_Insert_Input = {
  data: Participationstate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Participationstate_On_Conflict>;
};

/** on_conflict condition type for table "participationstate" */
export type Participationstate_On_Conflict = {
  constraint: Participationstate_Constraint;
  update_columns?: Array<Participationstate_Update_Column>;
  where?: InputMaybe<Participationstate_Bool_Exp>;
};

/** Ordering options when selecting data from "participationstate". */
export type Participationstate_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  participationevents_aggregate?: InputMaybe<Participationevent_Aggregate_Order_By>;
};

/** primary key columns input for table: participationstate */
export type Participationstate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "participationstate" */
export enum Participationstate_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "participationstate" */
export type Participationstate_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "participationstate" */
export type Participationstate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Participationstate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Participationstate_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "participationstate" */
export enum Participationstate_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Participationstate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Participationstate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Participationstate_Bool_Exp;
};

/** columns and relationships of "person" */
export type Person = {
  __typename?: 'person';
  /** An object relationship */
  country: Country;
  country_id: Scalars['uuid'];
  created: Scalars['timestamp'];
  default_language_id?: Maybe<Scalars['uuid']>;
  email: Scalars['String'];
  /** An object relationship */
  emailActivateReminder?: Maybe<Email_Activate_Reminder>;
  emailverified: Scalars['Boolean'];
  /** An array relationship */
  eula_acceptances: Array<Eula_Acceptance>;
  /** An aggregate relationship */
  eula_acceptances_aggregate: Eula_Acceptance_Aggregate;
  externalId?: Maybe<Scalars['String']>;
  externalid?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  language?: Maybe<Language>;
  lastlogin?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "person_get_has_accepted_latest_eula" */
  person_get_has_accepted_latest_eula?: Maybe<Scalars['uuid']>;
  pictureurl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};


/** columns and relationships of "person" */
export type PersonEula_AcceptancesArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonEula_Acceptances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "person" */
export type PersonUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "person" */
export type Person_Aggregate = {
  __typename?: 'person_aggregate';
  aggregate?: Maybe<Person_Aggregate_Fields>;
  nodes: Array<Person>;
};

export type Person_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Person_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Person_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Person_Aggregate_Bool_Exp_Count>;
};

export type Person_Aggregate_Bool_Exp_Bool_And = {
  arguments: Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Person_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Person_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Person_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "person" */
export type Person_Aggregate_Fields = {
  __typename?: 'person_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Person_Max_Fields>;
  min?: Maybe<Person_Min_Fields>;
};


/** aggregate fields of "person" */
export type Person_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "person" */
export type Person_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Person_Max_Order_By>;
  min?: InputMaybe<Person_Min_Order_By>;
};

/** input type for inserting array relation for remote table "person" */
export type Person_Arr_Rel_Insert_Input = {
  data: Array<Person_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Person_On_Conflict>;
};

/** Boolean expression to filter rows from the table "person". All fields are combined with a logical 'AND'. */
export type Person_Bool_Exp = {
  _and?: InputMaybe<Array<Person_Bool_Exp>>;
  _not?: InputMaybe<Person_Bool_Exp>;
  _or?: InputMaybe<Array<Person_Bool_Exp>>;
  country?: InputMaybe<Country_Bool_Exp>;
  country_id?: InputMaybe<Uuid_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  default_language_id?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  emailActivateReminder?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
  emailverified?: InputMaybe<Boolean_Comparison_Exp>;
  eula_acceptances?: InputMaybe<Eula_Acceptance_Bool_Exp>;
  eula_acceptances_aggregate?: InputMaybe<Eula_Acceptance_Aggregate_Bool_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  externalid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<Language_Bool_Exp>;
  lastlogin?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  person_get_has_accepted_latest_eula?: InputMaybe<Uuid_Comparison_Exp>;
  pictureurl?: InputMaybe<String_Comparison_Exp>;
  pronouns?: InputMaybe<String_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "person" */
export enum Person_Constraint {
  /** unique or primary key constraint on columns "email" */
  PersonAk_1 = 'person_ak_1',
  /** unique or primary key constraint on columns "id" */
  PersonPk = 'person_pk'
}

/** input type for inserting data into table "person" */
export type Person_Insert_Input = {
  country?: InputMaybe<Country_Obj_Rel_Insert_Input>;
  country_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  default_language_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  emailActivateReminder?: InputMaybe<Email_Activate_Reminder_Obj_Rel_Insert_Input>;
  emailverified?: InputMaybe<Scalars['Boolean']>;
  eula_acceptances?: InputMaybe<Eula_Acceptance_Arr_Rel_Insert_Input>;
  externalId?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Language_Obj_Rel_Insert_Input>;
  lastlogin?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  pictureurl?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Person_Max_Fields = {
  __typename?: 'person_max_fields';
  country_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  default_language_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastlogin?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "person_get_has_accepted_latest_eula" */
  person_get_has_accepted_latest_eula?: Maybe<Scalars['uuid']>;
  pictureurl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "person" */
export type Person_Max_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  default_language_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastlogin?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pictureurl?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Person_Min_Fields = {
  __typename?: 'person_min_fields';
  country_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamp']>;
  default_language_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  externalid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastlogin?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "person_get_has_accepted_latest_eula" */
  person_get_has_accepted_latest_eula?: Maybe<Scalars['uuid']>;
  pictureurl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "person" */
export type Person_Min_Order_By = {
  country_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  default_language_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastlogin?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pictureurl?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "person" */
export type Person_Mutation_Response = {
  __typename?: 'person_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Person>;
};

/** input type for inserting object relation for remote table "person" */
export type Person_Obj_Rel_Insert_Input = {
  data: Person_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Person_On_Conflict>;
};

/** on_conflict condition type for table "person" */
export type Person_On_Conflict = {
  constraint: Person_Constraint;
  update_columns?: Array<Person_Update_Column>;
  where?: InputMaybe<Person_Bool_Exp>;
};

/** Ordering options when selecting data from "person". */
export type Person_Order_By = {
  country?: InputMaybe<Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  default_language_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailActivateReminder?: InputMaybe<Email_Activate_Reminder_Order_By>;
  emailverified?: InputMaybe<Order_By>;
  eula_acceptances_aggregate?: InputMaybe<Eula_Acceptance_Aggregate_Order_By>;
  externalId?: InputMaybe<Order_By>;
  externalid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Language_Order_By>;
  lastlogin?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  person_get_has_accepted_latest_eula?: InputMaybe<Order_By>;
  pictureurl?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: person */
export type Person_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "person" */
export enum Person_Select_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  DefaultLanguageId = 'default_language_id',
  /** column name */
  Email = 'email',
  /** column name */
  Emailverified = 'emailverified',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Externalid = 'externalid',
  /** column name */
  Id = 'id',
  /** column name */
  Lastlogin = 'lastlogin',
  /** column name */
  Name = 'name',
  /** column name */
  Pictureurl = 'pictureurl',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  Timezone = 'timezone'
}

/** select "person_aggregate_bool_exp_bool_and_arguments_columns" columns of table "person" */
export enum Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Emailverified = 'emailverified'
}

/** select "person_aggregate_bool_exp_bool_or_arguments_columns" columns of table "person" */
export enum Person_Select_Column_Person_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Emailverified = 'emailverified'
}

/** input type for updating data in table "person" */
export type Person_Set_Input = {
  country_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  default_language_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  emailverified?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastlogin?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  pictureurl?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "person" */
export type Person_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Person_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Person_Stream_Cursor_Value_Input = {
  country_id?: InputMaybe<Scalars['uuid']>;
  created?: InputMaybe<Scalars['timestamp']>;
  default_language_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  emailverified?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastlogin?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  pictureurl?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** update columns of table "person" */
export enum Person_Update_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Created = 'created',
  /** column name */
  DefaultLanguageId = 'default_language_id',
  /** column name */
  Email = 'email',
  /** column name */
  Emailverified = 'emailverified',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Externalid = 'externalid',
  /** column name */
  Id = 'id',
  /** column name */
  Lastlogin = 'lastlogin',
  /** column name */
  Name = 'name',
  /** column name */
  Pictureurl = 'pictureurl',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  Timezone = 'timezone'
}

export type Person_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Person_Set_Input>;
  /** filter the rows which have to be updated */
  where: Person_Bool_Exp;
};

/** columns and relationships of "pillar" */
export type Pillar = {
  __typename?: 'pillar';
  created: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  literaryQuote?: Maybe<Literary_Quote>;
  literary_quote_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  pillar_topics: Array<Pillar_Topic>;
  /** An aggregate relationship */
  pillar_topics_aggregate: Pillar_Topic_Aggregate;
  /** An array relationship */
  pillarconstraints: Array<Pillarconstraint>;
  /** An aggregate relationship */
  pillarconstraints_aggregate: Pillarconstraint_Aggregate;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  topic_description?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pillar" */
export type PillarPillar_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


/** columns and relationships of "pillar" */
export type PillarPillar_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


/** columns and relationships of "pillar" */
export type PillarPillarconstraintsArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};


/** columns and relationships of "pillar" */
export type PillarPillarconstraints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};

/** aggregated selection of "pillar" */
export type Pillar_Aggregate = {
  __typename?: 'pillar_aggregate';
  aggregate?: Maybe<Pillar_Aggregate_Fields>;
  nodes: Array<Pillar>;
};

export type Pillar_Aggregate_Bool_Exp = {
  count?: InputMaybe<Pillar_Aggregate_Bool_Exp_Count>;
};

export type Pillar_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Pillar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pillar_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pillar" */
export type Pillar_Aggregate_Fields = {
  __typename?: 'pillar_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pillar_Max_Fields>;
  min?: Maybe<Pillar_Min_Fields>;
};


/** aggregate fields of "pillar" */
export type Pillar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pillar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pillar" */
export type Pillar_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pillar_Max_Order_By>;
  min?: InputMaybe<Pillar_Min_Order_By>;
};

/** input type for inserting array relation for remote table "pillar" */
export type Pillar_Arr_Rel_Insert_Input = {
  data: Array<Pillar_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pillar_On_Conflict>;
};

/** Boolean expression to filter rows from the table "pillar". All fields are combined with a logical 'AND'. */
export type Pillar_Bool_Exp = {
  _and?: InputMaybe<Array<Pillar_Bool_Exp>>;
  _not?: InputMaybe<Pillar_Bool_Exp>;
  _or?: InputMaybe<Array<Pillar_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  literaryQuote?: InputMaybe<Literary_Quote_Bool_Exp>;
  literary_quote_id?: InputMaybe<Uuid_Comparison_Exp>;
  pillar_topics?: InputMaybe<Pillar_Topic_Bool_Exp>;
  pillar_topics_aggregate?: InputMaybe<Pillar_Topic_Aggregate_Bool_Exp>;
  pillarconstraints?: InputMaybe<Pillarconstraint_Bool_Exp>;
  pillarconstraints_aggregate?: InputMaybe<Pillarconstraint_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  topic_description?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pillar" */
export enum Pillar_Constraint {
  /** unique or primary key constraint on columns "title" */
  PillarAkTitle = 'pillar_ak_title',
  /** unique or primary key constraint on columns "id" */
  PillarPk = 'pillar_pk',
  /** unique or primary key constraint on columns "slug" */
  PillarSlugKey = 'pillar_slug_key'
}

/** input type for inserting data into table "pillar" */
export type Pillar_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  literaryQuote?: InputMaybe<Literary_Quote_Obj_Rel_Insert_Input>;
  literary_quote_id?: InputMaybe<Scalars['uuid']>;
  pillar_topics?: InputMaybe<Pillar_Topic_Arr_Rel_Insert_Input>;
  pillarconstraints?: InputMaybe<Pillarconstraint_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topic_description?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pillar_Max_Fields = {
  __typename?: 'pillar_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  literary_quote_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic_description?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pillar" */
export type Pillar_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  literary_quote_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  topic_description?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pillar_Min_Fields = {
  __typename?: 'pillar_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  literary_quote_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic_description?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pillar" */
export type Pillar_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  literary_quote_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  topic_description?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pillar" */
export type Pillar_Mutation_Response = {
  __typename?: 'pillar_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pillar>;
};

/** input type for inserting object relation for remote table "pillar" */
export type Pillar_Obj_Rel_Insert_Input = {
  data: Pillar_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Pillar_On_Conflict>;
};

/** on_conflict condition type for table "pillar" */
export type Pillar_On_Conflict = {
  constraint: Pillar_Constraint;
  update_columns?: Array<Pillar_Update_Column>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};

/** Ordering options when selecting data from "pillar". */
export type Pillar_Order_By = {
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  literaryQuote?: InputMaybe<Literary_Quote_Order_By>;
  literary_quote_id?: InputMaybe<Order_By>;
  pillar_topics_aggregate?: InputMaybe<Pillar_Topic_Aggregate_Order_By>;
  pillarconstraints_aggregate?: InputMaybe<Pillarconstraint_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  topic_description?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pillar */
export type Pillar_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pillar" */
export enum Pillar_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LiteraryQuoteId = 'literary_quote_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  TopicDescription = 'topic_description'
}

/** input type for updating data in table "pillar" */
export type Pillar_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  literary_quote_id?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topic_description?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "pillar" */
export type Pillar_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pillar_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pillar_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  literary_quote_id?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topic_description?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "pillar_topic" */
export type Pillar_Topic = {
  __typename?: 'pillar_topic';
  created: Scalars['timestamp'];
  isprimary: Scalars['Boolean'];
  /** An object relationship */
  pillar: Pillar;
  pillar_id: Scalars['uuid'];
  /** An object relationship */
  topic: Topic;
  topic_id: Scalars['uuid'];
};

/** aggregated selection of "pillar_topic" */
export type Pillar_Topic_Aggregate = {
  __typename?: 'pillar_topic_aggregate';
  aggregate?: Maybe<Pillar_Topic_Aggregate_Fields>;
  nodes: Array<Pillar_Topic>;
};

export type Pillar_Topic_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Pillar_Topic_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Pillar_Topic_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Pillar_Topic_Aggregate_Bool_Exp_Count>;
};

export type Pillar_Topic_Aggregate_Bool_Exp_Bool_And = {
  arguments: Pillar_Topic_Select_Column_Pillar_Topic_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pillar_Topic_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Pillar_Topic_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Pillar_Topic_Select_Column_Pillar_Topic_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pillar_Topic_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Pillar_Topic_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pillar_Topic_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pillar_topic" */
export type Pillar_Topic_Aggregate_Fields = {
  __typename?: 'pillar_topic_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pillar_Topic_Max_Fields>;
  min?: Maybe<Pillar_Topic_Min_Fields>;
};


/** aggregate fields of "pillar_topic" */
export type Pillar_Topic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pillar_topic" */
export type Pillar_Topic_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pillar_Topic_Max_Order_By>;
  min?: InputMaybe<Pillar_Topic_Min_Order_By>;
};

/** input type for inserting array relation for remote table "pillar_topic" */
export type Pillar_Topic_Arr_Rel_Insert_Input = {
  data: Array<Pillar_Topic_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pillar_Topic_On_Conflict>;
};

/** Boolean expression to filter rows from the table "pillar_topic". All fields are combined with a logical 'AND'. */
export type Pillar_Topic_Bool_Exp = {
  _and?: InputMaybe<Array<Pillar_Topic_Bool_Exp>>;
  _not?: InputMaybe<Pillar_Topic_Bool_Exp>;
  _or?: InputMaybe<Array<Pillar_Topic_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  isprimary?: InputMaybe<Boolean_Comparison_Exp>;
  pillar?: InputMaybe<Pillar_Bool_Exp>;
  pillar_id?: InputMaybe<Uuid_Comparison_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topic_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "pillar_topic" */
export enum Pillar_Topic_Constraint {
  /** unique or primary key constraint on columns "topic_id", "pillar_id" */
  PillarTopicPk = 'pillar_topic_pk'
}

/** input type for inserting data into table "pillar_topic" */
export type Pillar_Topic_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  isprimary?: InputMaybe<Scalars['Boolean']>;
  pillar?: InputMaybe<Pillar_Obj_Rel_Insert_Input>;
  pillar_id?: InputMaybe<Scalars['uuid']>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Pillar_Topic_Max_Fields = {
  __typename?: 'pillar_topic_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  pillar_id?: Maybe<Scalars['uuid']>;
  topic_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pillar_topic" */
export type Pillar_Topic_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  pillar_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pillar_Topic_Min_Fields = {
  __typename?: 'pillar_topic_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  pillar_id?: Maybe<Scalars['uuid']>;
  topic_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "pillar_topic" */
export type Pillar_Topic_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  pillar_id?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pillar_topic" */
export type Pillar_Topic_Mutation_Response = {
  __typename?: 'pillar_topic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pillar_Topic>;
};

/** on_conflict condition type for table "pillar_topic" */
export type Pillar_Topic_On_Conflict = {
  constraint: Pillar_Topic_Constraint;
  update_columns?: Array<Pillar_Topic_Update_Column>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};

/** Ordering options when selecting data from "pillar_topic". */
export type Pillar_Topic_Order_By = {
  created?: InputMaybe<Order_By>;
  isprimary?: InputMaybe<Order_By>;
  pillar?: InputMaybe<Pillar_Order_By>;
  pillar_id?: InputMaybe<Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pillar_topic */
export type Pillar_Topic_Pk_Columns_Input = {
  pillar_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};

/** select columns of table "pillar_topic" */
export enum Pillar_Topic_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Isprimary = 'isprimary',
  /** column name */
  PillarId = 'pillar_id',
  /** column name */
  TopicId = 'topic_id'
}

/** select "pillar_topic_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pillar_topic" */
export enum Pillar_Topic_Select_Column_Pillar_Topic_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Isprimary = 'isprimary'
}

/** select "pillar_topic_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pillar_topic" */
export enum Pillar_Topic_Select_Column_Pillar_Topic_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Isprimary = 'isprimary'
}

/** input type for updating data in table "pillar_topic" */
export type Pillar_Topic_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  isprimary?: InputMaybe<Scalars['Boolean']>;
  pillar_id?: InputMaybe<Scalars['uuid']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "pillar_topic" */
export type Pillar_Topic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pillar_Topic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pillar_Topic_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  isprimary?: InputMaybe<Scalars['Boolean']>;
  pillar_id?: InputMaybe<Scalars['uuid']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "pillar_topic" */
export enum Pillar_Topic_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Isprimary = 'isprimary',
  /** column name */
  PillarId = 'pillar_id',
  /** column name */
  TopicId = 'topic_id'
}

export type Pillar_Topic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pillar_Topic_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pillar_Topic_Bool_Exp;
};

/** update columns of table "pillar" */
export enum Pillar_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LiteraryQuoteId = 'literary_quote_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  TopicDescription = 'topic_description'
}

export type Pillar_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pillar_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pillar_Bool_Exp;
};

/** columns and relationships of "pillarconstraint" */
export type Pillarconstraint = {
  __typename?: 'pillarconstraint';
  created: Scalars['timestamp'];
  /** An object relationship */
  pillar: Pillar;
  pillarofprejudice_id: Scalars['uuid'];
  /** An object relationship */
  readingplan: Readingplan;
  readingplan_id: Scalars['uuid'];
};

/** aggregated selection of "pillarconstraint" */
export type Pillarconstraint_Aggregate = {
  __typename?: 'pillarconstraint_aggregate';
  aggregate?: Maybe<Pillarconstraint_Aggregate_Fields>;
  nodes: Array<Pillarconstraint>;
};

export type Pillarconstraint_Aggregate_Bool_Exp = {
  count?: InputMaybe<Pillarconstraint_Aggregate_Bool_Exp_Count>;
};

export type Pillarconstraint_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Pillarconstraint_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "pillarconstraint" */
export type Pillarconstraint_Aggregate_Fields = {
  __typename?: 'pillarconstraint_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pillarconstraint_Max_Fields>;
  min?: Maybe<Pillarconstraint_Min_Fields>;
};


/** aggregate fields of "pillarconstraint" */
export type Pillarconstraint_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pillarconstraint" */
export type Pillarconstraint_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Pillarconstraint_Max_Order_By>;
  min?: InputMaybe<Pillarconstraint_Min_Order_By>;
};

/** input type for inserting array relation for remote table "pillarconstraint" */
export type Pillarconstraint_Arr_Rel_Insert_Input = {
  data: Array<Pillarconstraint_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Pillarconstraint_On_Conflict>;
};

/** Boolean expression to filter rows from the table "pillarconstraint". All fields are combined with a logical 'AND'. */
export type Pillarconstraint_Bool_Exp = {
  _and?: InputMaybe<Array<Pillarconstraint_Bool_Exp>>;
  _not?: InputMaybe<Pillarconstraint_Bool_Exp>;
  _or?: InputMaybe<Array<Pillarconstraint_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  pillar?: InputMaybe<Pillar_Bool_Exp>;
  pillarofprejudice_id?: InputMaybe<Uuid_Comparison_Exp>;
  readingplan?: InputMaybe<Readingplan_Bool_Exp>;
  readingplan_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "pillarconstraint" */
export enum Pillarconstraint_Constraint {
  /** unique or primary key constraint on columns "readingplan_id", "pillarofprejudice_id" */
  PillarconstraintPk = 'pillarconstraint_pk'
}

/** input type for inserting data into table "pillarconstraint" */
export type Pillarconstraint_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  pillar?: InputMaybe<Pillar_Obj_Rel_Insert_Input>;
  pillarofprejudice_id?: InputMaybe<Scalars['uuid']>;
  readingplan?: InputMaybe<Readingplan_Obj_Rel_Insert_Input>;
  readingplan_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Pillarconstraint_Max_Fields = {
  __typename?: 'pillarconstraint_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  pillarofprejudice_id?: Maybe<Scalars['uuid']>;
  readingplan_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pillarconstraint" */
export type Pillarconstraint_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  pillarofprejudice_id?: InputMaybe<Order_By>;
  readingplan_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Pillarconstraint_Min_Fields = {
  __typename?: 'pillarconstraint_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  pillarofprejudice_id?: Maybe<Scalars['uuid']>;
  readingplan_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "pillarconstraint" */
export type Pillarconstraint_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  pillarofprejudice_id?: InputMaybe<Order_By>;
  readingplan_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "pillarconstraint" */
export type Pillarconstraint_Mutation_Response = {
  __typename?: 'pillarconstraint_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pillarconstraint>;
};

/** on_conflict condition type for table "pillarconstraint" */
export type Pillarconstraint_On_Conflict = {
  constraint: Pillarconstraint_Constraint;
  update_columns?: Array<Pillarconstraint_Update_Column>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};

/** Ordering options when selecting data from "pillarconstraint". */
export type Pillarconstraint_Order_By = {
  created?: InputMaybe<Order_By>;
  pillar?: InputMaybe<Pillar_Order_By>;
  pillarofprejudice_id?: InputMaybe<Order_By>;
  readingplan?: InputMaybe<Readingplan_Order_By>;
  readingplan_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pillarconstraint */
export type Pillarconstraint_Pk_Columns_Input = {
  pillarofprejudice_id: Scalars['uuid'];
  readingplan_id: Scalars['uuid'];
};

/** select columns of table "pillarconstraint" */
export enum Pillarconstraint_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  PillarofprejudiceId = 'pillarofprejudice_id',
  /** column name */
  ReadingplanId = 'readingplan_id'
}

/** input type for updating data in table "pillarconstraint" */
export type Pillarconstraint_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  pillarofprejudice_id?: InputMaybe<Scalars['uuid']>;
  readingplan_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "pillarconstraint" */
export type Pillarconstraint_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pillarconstraint_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pillarconstraint_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  pillarofprejudice_id?: InputMaybe<Scalars['uuid']>;
  readingplan_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "pillarconstraint" */
export enum Pillarconstraint_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  PillarofprejudiceId = 'pillarofprejudice_id',
  /** column name */
  ReadingplanId = 'readingplan_id'
}

export type Pillarconstraint_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pillarconstraint_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pillarconstraint_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "book" */
  book: Array<Book>;
  /** fetch aggregated fields from the table: "book" */
  book_aggregate: Book_Aggregate;
  /** fetch data from the table: "book" using primary key columns */
  book_by_pk?: Maybe<Book>;
  /** execute function "book_get_number_of_readings" which returns "virtual_book_get_number_of_readings" */
  book_get_number_of_readings: Array<Virtual_Book_Get_Number_Of_Readings>;
  /** execute function "book_get_number_of_readings" and query aggregates on result of table type "virtual_book_get_number_of_readings" */
  book_get_number_of_readings_aggregate: Virtual_Book_Get_Number_Of_Readings_Aggregate;
  /** fetch data from the table: "book_topic" */
  book_topic: Array<Book_Topic>;
  /** fetch aggregated fields from the table: "book_topic" */
  book_topic_aggregate: Book_Topic_Aggregate;
  /** fetch data from the table: "book_topic" using primary key columns */
  book_topic_by_pk?: Maybe<Book_Topic>;
  /** fetch data from the table: "bookevent" */
  bookevent: Array<Bookevent>;
  /** fetch aggregated fields from the table: "bookevent" */
  bookevent_aggregate: Bookevent_Aggregate;
  /** fetch data from the table: "bookevent" using primary key columns */
  bookevent_by_pk?: Maybe<Bookevent>;
  /** fetch data from the table: "booklanguage" */
  booklanguage: Array<Booklanguage>;
  /** fetch aggregated fields from the table: "booklanguage" */
  booklanguage_aggregate: Booklanguage_Aggregate;
  /** fetch data from the table: "booklanguage" using primary key columns */
  booklanguage_by_pk?: Maybe<Booklanguage>;
  /** fetch data from the table: "bookstate" */
  bookstate: Array<Bookstate>;
  /** fetch aggregated fields from the table: "bookstate" */
  bookstate_aggregate: Bookstate_Aggregate;
  /** fetch data from the table: "bookstate" using primary key columns */
  bookstate_by_pk?: Maybe<Bookstate>;
  /** fetch data from the table: "cancel_review" */
  cancel_review: Array<Cancel_Review>;
  /** fetch aggregated fields from the table: "cancel_review" */
  cancel_review_aggregate: Cancel_Review_Aggregate;
  /** fetch data from the table: "cancel_review" using primary key columns */
  cancel_review_by_pk?: Maybe<Cancel_Review>;
  /** fetch data from the table: "chat_message" */
  chat_message: Array<Chat_Message>;
  /** fetch aggregated fields from the table: "chat_message" */
  chat_message_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "chat_message" using primary key columns */
  chat_message_by_pk?: Maybe<Chat_Message>;
  /** fetch data from the table: "chat_message_type" */
  chat_message_type: Array<Chat_Message_Type>;
  /** fetch aggregated fields from the table: "chat_message_type" */
  chat_message_type_aggregate: Chat_Message_Type_Aggregate;
  /** fetch data from the table: "chat_message_type" using primary key columns */
  chat_message_type_by_pk?: Maybe<Chat_Message_Type>;
  /** fetch data from the table: "chat_reading_message" */
  chat_reading_message: Array<Chat_Reading_Message>;
  /** fetch aggregated fields from the table: "chat_reading_message" */
  chat_reading_message_aggregate: Chat_Reading_Message_Aggregate;
  /** fetch data from the table: "chat_reading_message" using primary key columns */
  chat_reading_message_by_pk?: Maybe<Chat_Reading_Message>;
  /** fetch data from the table: "chat_room" */
  chat_room: Array<Chat_Room>;
  /** fetch aggregated fields from the table: "chat_room" */
  chat_room_aggregate: Chat_Room_Aggregate;
  /** fetch data from the table: "chat_room" using primary key columns */
  chat_room_by_pk?: Maybe<Chat_Room>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "debriefing" */
  debriefing: Array<Debriefing>;
  /** fetch aggregated fields from the table: "debriefing" */
  debriefing_aggregate: Debriefing_Aggregate;
  /** fetch data from the table: "debriefing" using primary key columns */
  debriefing_by_pk?: Maybe<Debriefing>;
  /** fetch data from the table: "duration" */
  duration: Array<Duration>;
  /** fetch aggregated fields from the table: "duration" */
  duration_aggregate: Duration_Aggregate;
  /** fetch data from the table: "duration" using primary key columns */
  duration_by_pk?: Maybe<Duration>;
  /** fetch data from the table: "email_activate_reminder" */
  email_activate_reminder: Array<Email_Activate_Reminder>;
  /** fetch aggregated fields from the table: "email_activate_reminder" */
  email_activate_reminder_aggregate: Email_Activate_Reminder_Aggregate;
  /** fetch data from the table: "email_activate_reminder" using primary key columns */
  email_activate_reminder_by_pk?: Maybe<Email_Activate_Reminder>;
  /** fetch data from the table: "email_chat_message_unread_reminder" */
  email_chat_message_unread_reminder: Array<Email_Chat_Message_Unread_Reminder>;
  /** fetch aggregated fields from the table: "email_chat_message_unread_reminder" */
  email_chat_message_unread_reminder_aggregate: Email_Chat_Message_Unread_Reminder_Aggregate;
  /** fetch data from the table: "email_chat_message_unread_reminder" using primary key columns */
  email_chat_message_unread_reminder_by_pk?: Maybe<Email_Chat_Message_Unread_Reminder>;
  /** fetch data from the table: "email_library_card_expiration_reminder" */
  email_library_card_expiration_reminder: Array<Email_Library_Card_Expiration_Reminder>;
  /** fetch aggregated fields from the table: "email_library_card_expiration_reminder" */
  email_library_card_expiration_reminder_aggregate: Email_Library_Card_Expiration_Reminder_Aggregate;
  /** fetch data from the table: "email_library_card_expiration_reminder" using primary key columns */
  email_library_card_expiration_reminder_by_pk?: Maybe<Email_Library_Card_Expiration_Reminder>;
  /** fetch data from the table: "email_library_card_expired_reminder" */
  email_library_card_expired_reminder: Array<Email_Library_Card_Expired_Reminder>;
  /** fetch aggregated fields from the table: "email_library_card_expired_reminder" */
  email_library_card_expired_reminder_aggregate: Email_Library_Card_Expired_Reminder_Aggregate;
  /** fetch data from the table: "email_library_card_expired_reminder" using primary key columns */
  email_library_card_expired_reminder_by_pk?: Maybe<Email_Library_Card_Expired_Reminder>;
  /** fetch data from the table: "eula" */
  eula: Array<Eula>;
  /** fetch data from the table: "eula_acceptance" */
  eula_acceptance: Array<Eula_Acceptance>;
  /** fetch aggregated fields from the table: "eula_acceptance" */
  eula_acceptance_aggregate: Eula_Acceptance_Aggregate;
  /** fetch data from the table: "eula_acceptance" using primary key columns */
  eula_acceptance_by_pk?: Maybe<Eula_Acceptance>;
  /** fetch aggregated fields from the table: "eula" */
  eula_aggregate: Eula_Aggregate;
  /** fetch data from the table: "eula" using primary key columns */
  eula_by_pk?: Maybe<Eula>;
  /** execute function "get_readings_per_week" which returns "readings_per_week" */
  get_readings_per_week: Array<Readings_Per_Week>;
  /** execute function "get_readings_per_week" and query aggregates on result of table type "readings_per_week" */
  get_readings_per_week_aggregate: Readings_Per_Week_Aggregate;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: Language_Aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** fetch data from the table: "literary_quote" */
  literary_quote: Array<Literary_Quote>;
  /** fetch aggregated fields from the table: "literary_quote" */
  literary_quote_aggregate: Literary_Quote_Aggregate;
  /** fetch data from the table: "literary_quote" using primary key columns */
  literary_quote_by_pk?: Maybe<Literary_Quote>;
  /** fetch data from the table: "live_call_state" */
  live_call_state: Array<Live_Call_State>;
  /** fetch aggregated fields from the table: "live_call_state" */
  live_call_state_aggregate: Live_Call_State_Aggregate;
  /** fetch data from the table: "live_call_state" using primary key columns */
  live_call_state_by_pk?: Maybe<Live_Call_State>;
  /** fetch data from the table: "org" */
  org: Array<Org>;
  /** fetch aggregated fields from the table: "org" */
  org_aggregate: Org_Aggregate;
  /** fetch data from the table: "org" using primary key columns */
  org_by_pk?: Maybe<Org>;
  /** fetch data from the table: "participationevent" */
  participationevent: Array<Participationevent>;
  /** fetch aggregated fields from the table: "participationevent" */
  participationevent_aggregate: Participationevent_Aggregate;
  /** fetch data from the table: "participationevent" using primary key columns */
  participationevent_by_pk?: Maybe<Participationevent>;
  /** fetch data from the table: "participationstate" */
  participationstate: Array<Participationstate>;
  /** fetch aggregated fields from the table: "participationstate" */
  participationstate_aggregate: Participationstate_Aggregate;
  /** fetch data from the table: "participationstate" using primary key columns */
  participationstate_by_pk?: Maybe<Participationstate>;
  /** fetch data from the table: "person" */
  person: Array<Person>;
  /** fetch aggregated fields from the table: "person" */
  person_aggregate: Person_Aggregate;
  /** fetch data from the table: "person" using primary key columns */
  person_by_pk?: Maybe<Person>;
  /** fetch data from the table: "pillar" */
  pillar: Array<Pillar>;
  /** fetch aggregated fields from the table: "pillar" */
  pillar_aggregate: Pillar_Aggregate;
  /** fetch data from the table: "pillar" using primary key columns */
  pillar_by_pk?: Maybe<Pillar>;
  /** fetch data from the table: "pillar_topic" */
  pillar_topic: Array<Pillar_Topic>;
  /** fetch aggregated fields from the table: "pillar_topic" */
  pillar_topic_aggregate: Pillar_Topic_Aggregate;
  /** fetch data from the table: "pillar_topic" using primary key columns */
  pillar_topic_by_pk?: Maybe<Pillar_Topic>;
  /** fetch data from the table: "pillarconstraint" */
  pillarconstraint: Array<Pillarconstraint>;
  /** fetch aggregated fields from the table: "pillarconstraint" */
  pillarconstraint_aggregate: Pillarconstraint_Aggregate;
  /** fetch data from the table: "pillarconstraint" using primary key columns */
  pillarconstraint_by_pk?: Maybe<Pillarconstraint>;
  /** fetch data from the table: "reading" */
  reading: Array<Reading>;
  /** fetch aggregated fields from the table: "reading" */
  reading_aggregate: Reading_Aggregate;
  /** fetch data from the table: "reading" using primary key columns */
  reading_by_pk?: Maybe<Reading>;
  /** fetch data from the table: "reading_setting" */
  reading_setting: Array<Reading_Setting>;
  /** fetch aggregated fields from the table: "reading_setting" */
  reading_setting_aggregate: Reading_Setting_Aggregate;
  /** fetch data from the table: "reading_setting" using primary key columns */
  reading_setting_by_pk?: Maybe<Reading_Setting>;
  /** fetch data from the table: "readingevent" */
  readingevent: Array<Readingevent>;
  /** fetch aggregated fields from the table: "readingevent" */
  readingevent_aggregate: Readingevent_Aggregate;
  /** fetch data from the table: "readingevent" using primary key columns */
  readingevent_by_pk?: Maybe<Readingevent>;
  /** fetch data from the table: "readingplan" */
  readingplan: Array<Readingplan>;
  /** fetch aggregated fields from the table: "readingplan" */
  readingplan_aggregate: Readingplan_Aggregate;
  /** fetch data from the table: "readingplan" using primary key columns */
  readingplan_by_pk?: Maybe<Readingplan>;
  /** fetch data from the table: "readingplan_duration" */
  readingplan_duration: Array<Readingplan_Duration>;
  /** fetch aggregated fields from the table: "readingplan_duration" */
  readingplan_duration_aggregate: Readingplan_Duration_Aggregate;
  /** fetch data from the table: "readingplan_duration_translation" */
  readingplan_duration_translation: Array<Readingplan_Duration_Translation>;
  /** fetch aggregated fields from the table: "readingplan_duration_translation" */
  readingplan_duration_translation_aggregate: Readingplan_Duration_Translation_Aggregate;
  /** fetch data from the table: "readingplan_duration_translation" using primary key columns */
  readingplan_duration_translation_by_pk?: Maybe<Readingplan_Duration_Translation>;
  /** execute function "readings_by_participationstate" which returns "reading" */
  readings_by_participationstate: Array<Reading>;
  /** execute function "readings_by_participationstate" and query aggregates on result of table type "reading" */
  readings_by_participationstate_aggregate: Reading_Aggregate;
  /** fetch data from the table: "readings_per_week" */
  readings_per_week: Array<Readings_Per_Week>;
  /** fetch aggregated fields from the table: "readings_per_week" */
  readings_per_week_aggregate: Readings_Per_Week_Aggregate;
  /** fetch data from the table: "readingstate" */
  readingstate: Array<Readingstate>;
  /** fetch aggregated fields from the table: "readingstate" */
  readingstate_aggregate: Readingstate_Aggregate;
  /** fetch data from the table: "readingstate" using primary key columns */
  readingstate_by_pk?: Maybe<Readingstate>;
  /** fetch data from the table: "review" */
  review: Array<Review>;
  /** fetch aggregated fields from the table: "review" */
  review_aggregate: Review_Aggregate;
  /** fetch data from the table: "review_book" */
  review_book: Array<Review_Book>;
  /** fetch aggregated fields from the table: "review_book" */
  review_book_aggregate: Review_Book_Aggregate;
  /** fetch data from the table: "review_book" using primary key columns */
  review_book_by_pk?: Maybe<Review_Book>;
  /** fetch data from the table: "review_book_option" */
  review_book_option: Array<Review_Book_Option>;
  /** fetch aggregated fields from the table: "review_book_option" */
  review_book_option_aggregate: Review_Book_Option_Aggregate;
  /** fetch data from the table: "review_book_option" using primary key columns */
  review_book_option_by_pk?: Maybe<Review_Book_Option>;
  /** fetch data from the table: "review" using primary key columns */
  review_by_pk?: Maybe<Review>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "scheduled_email_reminder" */
  scheduled_email_reminder: Array<Scheduled_Email_Reminder>;
  /** fetch aggregated fields from the table: "scheduled_email_reminder" */
  scheduled_email_reminder_aggregate: Scheduled_Email_Reminder_Aggregate;
  /** fetch data from the table: "scheduled_email_reminder" using primary key columns */
  scheduled_email_reminder_by_pk?: Maybe<Scheduled_Email_Reminder>;
  /** fetch data from the table: "timezone" */
  timezone: Array<Timezone>;
  /** fetch aggregated fields from the table: "timezone" */
  timezone_aggregate: Timezone_Aggregate;
  /** fetch data from the table: "timezone" using primary key columns */
  timezone_by_pk?: Maybe<Timezone>;
  /** fetch data from the table: "topic" */
  topic: Array<Topic>;
  /** fetch aggregated fields from the table: "topic" */
  topic_aggregate: Topic_Aggregate;
  /** fetch data from the table: "topic" using primary key columns */
  topic_by_pk?: Maybe<Topic>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "view_chat_message_with_last_message" */
  view_chat_message_with_last_message: Array<View_Chat_Message_With_Last_Message>;
  /** fetch aggregated fields from the table: "view_chat_message_with_last_message" */
  view_chat_message_with_last_message_aggregate: View_Chat_Message_With_Last_Message_Aggregate;
  /** fetch data from the table: "view_participationstates_with_user_info" */
  view_participationstates_with_user_info: Array<View_Participationstates_With_User_Info>;
  /** fetch aggregated fields from the table: "view_participationstates_with_user_info" */
  view_participationstates_with_user_info_aggregate: View_Participationstates_With_User_Info_Aggregate;
  /** fetch data from the table: "view_reading_room_book" */
  view_reading_room_book: Array<View_Reading_Room_Book>;
  /** fetch aggregated fields from the table: "view_reading_room_book" */
  view_reading_room_book_aggregate: View_Reading_Room_Book_Aggregate;
  /** fetch data from the table: "view_reading_room_participants" */
  view_reading_room_participants: Array<View_Reading_Room_Participants>;
  /** fetch aggregated fields from the table: "view_reading_room_participants" */
  view_reading_room_participants_aggregate: View_Reading_Room_Participants_Aggregate;
  /** fetch data from the table: "view_readings" */
  view_readings: Array<View_Readings>;
  /** fetch aggregated fields from the table: "view_readings" */
  view_readings_aggregate: View_Readings_Aggregate;
  /** fetch data from the table: "view_readings_with_book_info" */
  view_readings_with_book_info: Array<View_Readings_With_Book_Info>;
  /** fetch aggregated fields from the table: "view_readings_with_book_info" */
  view_readings_with_book_info_aggregate: View_Readings_With_Book_Info_Aggregate;
  /** fetch data from the table: "view_users_with_next_reading" */
  view_users_with_next_reading: Array<View_Users_With_Next_Reading>;
  /** fetch aggregated fields from the table: "view_users_with_next_reading" */
  view_users_with_next_reading_aggregate: View_Users_With_Next_Reading_Aggregate;
  /** fetch data from the table: "virtual_book_get_number_of_readings" */
  virtual_book_get_number_of_readings: Array<Virtual_Book_Get_Number_Of_Readings>;
  /** fetch aggregated fields from the table: "virtual_book_get_number_of_readings" */
  virtual_book_get_number_of_readings_aggregate: Virtual_Book_Get_Number_Of_Readings_Aggregate;
};


export type Query_RootBookArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


export type Query_RootBook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


export type Query_RootBook_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBook_Get_Number_Of_ReadingsArgs = {
  args: Book_Get_Number_Of_Readings_Args;
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};


export type Query_RootBook_Get_Number_Of_Readings_AggregateArgs = {
  args: Book_Get_Number_Of_Readings_Args;
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};


export type Query_RootBook_TopicArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


export type Query_RootBook_Topic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


export type Query_RootBook_Topic_By_PkArgs = {
  book_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};


export type Query_RootBookeventArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


export type Query_RootBookevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


export type Query_RootBookevent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooklanguageArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


export type Query_RootBooklanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


export type Query_RootBooklanguage_By_PkArgs = {
  book_id: Scalars['uuid'];
  language_id: Scalars['uuid'];
};


export type Query_RootBookstateArgs = {
  distinct_on?: InputMaybe<Array<Bookstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookstate_Order_By>>;
  where?: InputMaybe<Bookstate_Bool_Exp>;
};


export type Query_RootBookstate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookstate_Order_By>>;
  where?: InputMaybe<Bookstate_Bool_Exp>;
};


export type Query_RootBookstate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCancel_ReviewArgs = {
  distinct_on?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cancel_Review_Order_By>>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};


export type Query_RootCancel_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cancel_Review_Order_By>>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};


export type Query_RootCancel_Review_By_PkArgs = {
  reading_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootChat_MessageArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


export type Query_RootChat_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


export type Query_RootChat_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootChat_Message_TypeArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Chat_Message_Type_Bool_Exp>;
};


export type Query_RootChat_Message_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Chat_Message_Type_Bool_Exp>;
};


export type Query_RootChat_Message_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootChat_Reading_MessageArgs = {
  distinct_on?: InputMaybe<Array<Chat_Reading_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Reading_Message_Order_By>>;
  where?: InputMaybe<Chat_Reading_Message_Bool_Exp>;
};


export type Query_RootChat_Reading_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Reading_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Reading_Message_Order_By>>;
  where?: InputMaybe<Chat_Reading_Message_Bool_Exp>;
};


export type Query_RootChat_Reading_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootChat_RoomArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};


export type Query_RootChat_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};


export type Query_RootChat_Room_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Query_RootCountry_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDebriefingArgs = {
  distinct_on?: InputMaybe<Array<Debriefing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Debriefing_Order_By>>;
  where?: InputMaybe<Debriefing_Bool_Exp>;
};


export type Query_RootDebriefing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Debriefing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Debriefing_Order_By>>;
  where?: InputMaybe<Debriefing_Bool_Exp>;
};


export type Query_RootDebriefing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDurationArgs = {
  distinct_on?: InputMaybe<Array<Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Duration_Order_By>>;
  where?: InputMaybe<Duration_Bool_Exp>;
};


export type Query_RootDuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Duration_Order_By>>;
  where?: InputMaybe<Duration_Bool_Exp>;
};


export type Query_RootDuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmail_Activate_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Activate_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Activate_Reminder_Order_By>>;
  where?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Activate_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Activate_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Activate_Reminder_Order_By>>;
  where?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Activate_Reminder_By_PkArgs = {
  person_id: Scalars['uuid'];
};


export type Query_RootEmail_Chat_Message_Unread_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Order_By>>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Chat_Message_Unread_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Order_By>>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Chat_Message_Unread_Reminder_By_PkArgs = {
  chat_message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootEmail_Library_Card_Expiration_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Library_Card_Expiration_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Library_Card_Expiration_Reminder_By_PkArgs = {
  readingplan_id: Scalars['uuid'];
};


export type Query_RootEmail_Library_Card_Expired_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Library_Card_Expired_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
};


export type Query_RootEmail_Library_Card_Expired_Reminder_By_PkArgs = {
  readingplan_id: Scalars['uuid'];
};


export type Query_RootEulaArgs = {
  distinct_on?: InputMaybe<Array<Eula_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Order_By>>;
  where?: InputMaybe<Eula_Bool_Exp>;
};


export type Query_RootEula_AcceptanceArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


export type Query_RootEula_Acceptance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


export type Query_RootEula_Acceptance_By_PkArgs = {
  eula_id: Scalars['uuid'];
  person_id: Scalars['uuid'];
};


export type Query_RootEula_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eula_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Order_By>>;
  where?: InputMaybe<Eula_Bool_Exp>;
};


export type Query_RootEula_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGet_Readings_Per_WeekArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Query_RootGet_Readings_Per_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Query_RootLanguageArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Query_RootLanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Query_RootLanguage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLiterary_QuoteArgs = {
  distinct_on?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Literary_Quote_Order_By>>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};


export type Query_RootLiterary_Quote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Literary_Quote_Order_By>>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};


export type Query_RootLiterary_Quote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLive_Call_StateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_State_Order_By>>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};


export type Query_RootLive_Call_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_State_Order_By>>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};


export type Query_RootLive_Call_State_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootOrgArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


export type Query_RootOrg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


export type Query_RootOrg_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootParticipationeventArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


export type Query_RootParticipationevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


export type Query_RootParticipationevent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootParticipationstateArgs = {
  distinct_on?: InputMaybe<Array<Participationstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationstate_Order_By>>;
  where?: InputMaybe<Participationstate_Bool_Exp>;
};


export type Query_RootParticipationstate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participationstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationstate_Order_By>>;
  where?: InputMaybe<Participationstate_Bool_Exp>;
};


export type Query_RootParticipationstate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPersonArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Query_RootPerson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Query_RootPerson_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPillarArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Order_By>>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};


export type Query_RootPillar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Order_By>>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};


export type Query_RootPillar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPillar_TopicArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


export type Query_RootPillar_Topic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


export type Query_RootPillar_Topic_By_PkArgs = {
  pillar_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};


export type Query_RootPillarconstraintArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};


export type Query_RootPillarconstraint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};


export type Query_RootPillarconstraint_By_PkArgs = {
  pillarofprejudice_id: Scalars['uuid'];
  readingplan_id: Scalars['uuid'];
};


export type Query_RootReadingArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Query_RootReading_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Query_RootReading_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReading_SettingArgs = {
  distinct_on?: InputMaybe<Array<Reading_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Setting_Order_By>>;
  where?: InputMaybe<Reading_Setting_Bool_Exp>;
};


export type Query_RootReading_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Setting_Order_By>>;
  where?: InputMaybe<Reading_Setting_Bool_Exp>;
};


export type Query_RootReading_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReadingeventArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


export type Query_RootReadingevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


export type Query_RootReadingevent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReadingplanArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Order_By>>;
  where?: InputMaybe<Readingplan_Bool_Exp>;
};


export type Query_RootReadingplan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Order_By>>;
  where?: InputMaybe<Readingplan_Bool_Exp>;
};


export type Query_RootReadingplan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReadingplan_DurationArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Bool_Exp>;
};


export type Query_RootReadingplan_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Bool_Exp>;
};


export type Query_RootReadingplan_Duration_TranslationArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Translation_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};


export type Query_RootReadingplan_Duration_Translation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Translation_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};


export type Query_RootReadingplan_Duration_Translation_By_PkArgs = {
  language_id: Scalars['uuid'];
  readingplan_duration_id: Scalars['uuid'];
};


export type Query_RootReadings_By_ParticipationstateArgs = {
  args: Readings_By_Participationstate_Args;
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Query_RootReadings_By_Participationstate_AggregateArgs = {
  args: Readings_By_Participationstate_Args;
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Query_RootReadings_Per_WeekArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Query_RootReadings_Per_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Query_RootReadingstateArgs = {
  distinct_on?: InputMaybe<Array<Readingstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingstate_Order_By>>;
  where?: InputMaybe<Readingstate_Bool_Exp>;
};


export type Query_RootReadingstate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingstate_Order_By>>;
  where?: InputMaybe<Readingstate_Bool_Exp>;
};


export type Query_RootReadingstate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReviewArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};


export type Query_RootReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};


export type Query_RootReview_BookArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Order_By>>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};


export type Query_RootReview_Book_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Order_By>>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};


export type Query_RootReview_Book_By_PkArgs = {
  reading_id: Scalars['uuid'];
};


export type Query_RootReview_Book_OptionArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Option_Order_By>>;
  where?: InputMaybe<Review_Book_Option_Bool_Exp>;
};


export type Query_RootReview_Book_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Option_Order_By>>;
  where?: InputMaybe<Review_Book_Option_Bool_Exp>;
};


export type Query_RootReview_Book_Option_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Query_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Query_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootScheduled_Email_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Email_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Email_Reminder_Order_By>>;
  where?: InputMaybe<Scheduled_Email_Reminder_Bool_Exp>;
};


export type Query_RootScheduled_Email_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Email_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Email_Reminder_Order_By>>;
  where?: InputMaybe<Scheduled_Email_Reminder_Bool_Exp>;
};


export type Query_RootScheduled_Email_Reminder_By_PkArgs = {
  reading_id: Scalars['uuid'];
};


export type Query_RootTimezoneArgs = {
  distinct_on?: InputMaybe<Array<Timezone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Timezone_Order_By>>;
  where?: InputMaybe<Timezone_Bool_Exp>;
};


export type Query_RootTimezone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Timezone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Timezone_Order_By>>;
  where?: InputMaybe<Timezone_Bool_Exp>;
};


export type Query_RootTimezone_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTopicArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Query_RootTopic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Query_RootTopic_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootView_Chat_Message_With_Last_MessageArgs = {
  distinct_on?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Order_By>>;
  where?: InputMaybe<View_Chat_Message_With_Last_Message_Bool_Exp>;
};


export type Query_RootView_Chat_Message_With_Last_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Order_By>>;
  where?: InputMaybe<View_Chat_Message_With_Last_Message_Bool_Exp>;
};


export type Query_RootView_Participationstates_With_User_InfoArgs = {
  distinct_on?: InputMaybe<Array<View_Participationstates_With_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Participationstates_With_User_Info_Order_By>>;
  where?: InputMaybe<View_Participationstates_With_User_Info_Bool_Exp>;
};


export type Query_RootView_Participationstates_With_User_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Participationstates_With_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Participationstates_With_User_Info_Order_By>>;
  where?: InputMaybe<View_Participationstates_With_User_Info_Bool_Exp>;
};


export type Query_RootView_Reading_Room_BookArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Book_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Book_Bool_Exp>;
};


export type Query_RootView_Reading_Room_Book_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Book_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Book_Bool_Exp>;
};


export type Query_RootView_Reading_Room_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Participants_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Participants_Bool_Exp>;
};


export type Query_RootView_Reading_Room_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Participants_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Participants_Bool_Exp>;
};


export type Query_RootView_ReadingsArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_Order_By>>;
  where?: InputMaybe<View_Readings_Bool_Exp>;
};


export type Query_RootView_Readings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_Order_By>>;
  where?: InputMaybe<View_Readings_Bool_Exp>;
};


export type Query_RootView_Readings_With_Book_InfoArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_With_Book_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_With_Book_Info_Order_By>>;
  where?: InputMaybe<View_Readings_With_Book_Info_Bool_Exp>;
};


export type Query_RootView_Readings_With_Book_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_With_Book_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_With_Book_Info_Order_By>>;
  where?: InputMaybe<View_Readings_With_Book_Info_Bool_Exp>;
};


export type Query_RootView_Users_With_Next_ReadingArgs = {
  distinct_on?: InputMaybe<Array<View_Users_With_Next_Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Users_With_Next_Reading_Order_By>>;
  where?: InputMaybe<View_Users_With_Next_Reading_Bool_Exp>;
};


export type Query_RootView_Users_With_Next_Reading_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Users_With_Next_Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Users_With_Next_Reading_Order_By>>;
  where?: InputMaybe<View_Users_With_Next_Reading_Bool_Exp>;
};


export type Query_RootVirtual_Book_Get_Number_Of_ReadingsArgs = {
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};


export type Query_RootVirtual_Book_Get_Number_Of_Readings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};

/** columns and relationships of "reading" */
export type Reading = {
  __typename?: 'reading';
  /** An object relationship */
  book: Book;
  /** An array relationship */
  cancel_reviews: Array<Cancel_Review>;
  /** An aggregate relationship */
  cancel_reviews_aggregate: Cancel_Review_Aggregate;
  /** An array relationship */
  chat_messages: Array<Chat_Message>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Message_Aggregate;
  created: Scalars['timestamp'];
  /** An object relationship */
  duration: Duration;
  duration_id: Scalars['uuid'];
  externalid?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  inviteonly: Scalars['Boolean'];
  /** An object relationship */
  language: Language;
  language_id: Scalars['uuid'];
  /** An array relationship */
  live_call_states: Array<Live_Call_State>;
  /** An aggregate relationship */
  live_call_states_aggregate: Live_Call_State_Aggregate;
  maxreaders?: Maybe<Scalars['Int']>;
  meetingurl?: Maybe<Scalars['String']>;
  minreaders?: Maybe<Scalars['Int']>;
  organisingbook_id: Scalars['uuid'];
  /** A computed field, executes function "reading_get_latest_participationevent_name" */
  participationEventName?: Maybe<Scalars['String']>;
  /** An array relationship */
  participationevents: Array<Participationevent>;
  /** An aggregate relationship */
  participationevents_aggregate: Participationevent_Aggregate;
  /** A computed field, executes function "get_latest_readingstatus_name" */
  readingEventStatusName?: Maybe<Scalars['String']>;
  /** An array relationship */
  readingevents: Array<Readingevent>;
  /** An aggregate relationship */
  readingevents_aggregate: Readingevent_Aggregate;
  readingstart: Scalars['timestamptz'];
  /** An object relationship */
  review_book?: Maybe<Review_Book>;
  /** An array relationship */
  reviews: Array<Review>;
  /** An aggregate relationship */
  reviews_aggregate: Review_Aggregate;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  topic: Topic;
  /** A computed field, executes function "get_reading_topic_name" */
  topicName?: Maybe<Scalars['String']>;
  topic_id: Scalars['uuid'];
};


/** columns and relationships of "reading" */
export type ReadingCancel_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cancel_Review_Order_By>>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingCancel_Reviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cancel_Review_Order_By>>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingLive_Call_StatesArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_State_Order_By>>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingLive_Call_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_State_Order_By>>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingParticipationeventsArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingParticipationevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingReadingeventsArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingReadingevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingReviewsArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};


/** columns and relationships of "reading" */
export type ReadingReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};

/** aggregated selection of "reading" */
export type Reading_Aggregate = {
  __typename?: 'reading_aggregate';
  aggregate?: Maybe<Reading_Aggregate_Fields>;
  nodes: Array<Reading>;
};

export type Reading_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Reading_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Reading_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Reading_Aggregate_Bool_Exp_Count>;
};

export type Reading_Aggregate_Bool_Exp_Bool_And = {
  arguments: Reading_Select_Column_Reading_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reading_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reading_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Reading_Select_Column_Reading_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reading_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reading_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reading_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reading_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reading" */
export type Reading_Aggregate_Fields = {
  __typename?: 'reading_aggregate_fields';
  avg?: Maybe<Reading_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reading_Max_Fields>;
  min?: Maybe<Reading_Min_Fields>;
  stddev?: Maybe<Reading_Stddev_Fields>;
  stddev_pop?: Maybe<Reading_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reading_Stddev_Samp_Fields>;
  sum?: Maybe<Reading_Sum_Fields>;
  var_pop?: Maybe<Reading_Var_Pop_Fields>;
  var_samp?: Maybe<Reading_Var_Samp_Fields>;
  variance?: Maybe<Reading_Variance_Fields>;
};


/** aggregate fields of "reading" */
export type Reading_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reading_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reading" */
export type Reading_Aggregate_Order_By = {
  avg?: InputMaybe<Reading_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reading_Max_Order_By>;
  min?: InputMaybe<Reading_Min_Order_By>;
  stddev?: InputMaybe<Reading_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reading_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reading_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reading_Sum_Order_By>;
  var_pop?: InputMaybe<Reading_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reading_Var_Samp_Order_By>;
  variance?: InputMaybe<Reading_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reading" */
export type Reading_Arr_Rel_Insert_Input = {
  data: Array<Reading_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reading_On_Conflict>;
};

/** aggregate avg on columns */
export type Reading_Avg_Fields = {
  __typename?: 'reading_avg_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by avg() on columns of table "reading" */
export type Reading_Avg_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reading". All fields are combined with a logical 'AND'. */
export type Reading_Bool_Exp = {
  _and?: InputMaybe<Array<Reading_Bool_Exp>>;
  _not?: InputMaybe<Reading_Bool_Exp>;
  _or?: InputMaybe<Array<Reading_Bool_Exp>>;
  book?: InputMaybe<Book_Bool_Exp>;
  cancel_reviews?: InputMaybe<Cancel_Review_Bool_Exp>;
  cancel_reviews_aggregate?: InputMaybe<Cancel_Review_Aggregate_Bool_Exp>;
  chat_messages?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  duration?: InputMaybe<Duration_Bool_Exp>;
  duration_id?: InputMaybe<Uuid_Comparison_Exp>;
  externalid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inviteonly?: InputMaybe<Boolean_Comparison_Exp>;
  language?: InputMaybe<Language_Bool_Exp>;
  language_id?: InputMaybe<Uuid_Comparison_Exp>;
  live_call_states?: InputMaybe<Live_Call_State_Bool_Exp>;
  live_call_states_aggregate?: InputMaybe<Live_Call_State_Aggregate_Bool_Exp>;
  maxreaders?: InputMaybe<Int_Comparison_Exp>;
  meetingurl?: InputMaybe<String_Comparison_Exp>;
  minreaders?: InputMaybe<Int_Comparison_Exp>;
  organisingbook_id?: InputMaybe<Uuid_Comparison_Exp>;
  participationEventName?: InputMaybe<String_Comparison_Exp>;
  participationevents?: InputMaybe<Participationevent_Bool_Exp>;
  participationevents_aggregate?: InputMaybe<Participationevent_Aggregate_Bool_Exp>;
  readingEventStatusName?: InputMaybe<String_Comparison_Exp>;
  readingevents?: InputMaybe<Readingevent_Bool_Exp>;
  readingevents_aggregate?: InputMaybe<Readingevent_Aggregate_Bool_Exp>;
  readingstart?: InputMaybe<Timestamptz_Comparison_Exp>;
  review_book?: InputMaybe<Review_Book_Bool_Exp>;
  reviews?: InputMaybe<Review_Bool_Exp>;
  reviews_aggregate?: InputMaybe<Review_Aggregate_Bool_Exp>;
  seats_available?: InputMaybe<Bigint_Comparison_Exp>;
  topic?: InputMaybe<Topic_Bool_Exp>;
  topicName?: InputMaybe<String_Comparison_Exp>;
  topic_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reading" */
export enum Reading_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReadingPk = 'reading_pk'
}

/** input type for incrementing numeric columns in table "reading" */
export type Reading_Inc_Input = {
  maxreaders?: InputMaybe<Scalars['Int']>;
  minreaders?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reading" */
export type Reading_Insert_Input = {
  book?: InputMaybe<Book_Obj_Rel_Insert_Input>;
  cancel_reviews?: InputMaybe<Cancel_Review_Arr_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamp']>;
  duration?: InputMaybe<Duration_Obj_Rel_Insert_Input>;
  duration_id?: InputMaybe<Scalars['uuid']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteonly?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language_Obj_Rel_Insert_Input>;
  language_id?: InputMaybe<Scalars['uuid']>;
  live_call_states?: InputMaybe<Live_Call_State_Arr_Rel_Insert_Input>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  meetingurl?: InputMaybe<Scalars['String']>;
  minreaders?: InputMaybe<Scalars['Int']>;
  organisingbook_id?: InputMaybe<Scalars['uuid']>;
  participationevents?: InputMaybe<Participationevent_Arr_Rel_Insert_Input>;
  readingevents?: InputMaybe<Readingevent_Arr_Rel_Insert_Input>;
  readingstart?: InputMaybe<Scalars['timestamptz']>;
  review_book?: InputMaybe<Review_Book_Obj_Rel_Insert_Input>;
  reviews?: InputMaybe<Review_Arr_Rel_Insert_Input>;
  topic?: InputMaybe<Topic_Obj_Rel_Insert_Input>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Reading_Max_Fields = {
  __typename?: 'reading_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  duration_id?: Maybe<Scalars['uuid']>;
  externalid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language_id?: Maybe<Scalars['uuid']>;
  maxreaders?: Maybe<Scalars['Int']>;
  meetingurl?: Maybe<Scalars['String']>;
  minreaders?: Maybe<Scalars['Int']>;
  organisingbook_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "reading_get_latest_participationevent_name" */
  participationEventName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_latest_readingstatus_name" */
  readingEventStatusName?: Maybe<Scalars['String']>;
  readingstart?: Maybe<Scalars['timestamptz']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
  /** A computed field, executes function "get_reading_topic_name" */
  topicName?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reading" */
export type Reading_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  duration_id?: InputMaybe<Order_By>;
  externalid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  maxreaders?: InputMaybe<Order_By>;
  meetingurl?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
  organisingbook_id?: InputMaybe<Order_By>;
  readingstart?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reading_Min_Fields = {
  __typename?: 'reading_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  duration_id?: Maybe<Scalars['uuid']>;
  externalid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language_id?: Maybe<Scalars['uuid']>;
  maxreaders?: Maybe<Scalars['Int']>;
  meetingurl?: Maybe<Scalars['String']>;
  minreaders?: Maybe<Scalars['Int']>;
  organisingbook_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "reading_get_latest_participationevent_name" */
  participationEventName?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_latest_readingstatus_name" */
  readingEventStatusName?: Maybe<Scalars['String']>;
  readingstart?: Maybe<Scalars['timestamptz']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
  /** A computed field, executes function "get_reading_topic_name" */
  topicName?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reading" */
export type Reading_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  duration_id?: InputMaybe<Order_By>;
  externalid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  maxreaders?: InputMaybe<Order_By>;
  meetingurl?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
  organisingbook_id?: InputMaybe<Order_By>;
  readingstart?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reading" */
export type Reading_Mutation_Response = {
  __typename?: 'reading_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reading>;
};

/** input type for inserting object relation for remote table "reading" */
export type Reading_Obj_Rel_Insert_Input = {
  data: Reading_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reading_On_Conflict>;
};

/** on_conflict condition type for table "reading" */
export type Reading_On_Conflict = {
  constraint: Reading_Constraint;
  update_columns?: Array<Reading_Update_Column>;
  where?: InputMaybe<Reading_Bool_Exp>;
};

/** Ordering options when selecting data from "reading". */
export type Reading_Order_By = {
  book?: InputMaybe<Book_Order_By>;
  cancel_reviews_aggregate?: InputMaybe<Cancel_Review_Aggregate_Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  duration?: InputMaybe<Duration_Order_By>;
  duration_id?: InputMaybe<Order_By>;
  externalid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteonly?: InputMaybe<Order_By>;
  language?: InputMaybe<Language_Order_By>;
  language_id?: InputMaybe<Order_By>;
  live_call_states_aggregate?: InputMaybe<Live_Call_State_Aggregate_Order_By>;
  maxreaders?: InputMaybe<Order_By>;
  meetingurl?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
  organisingbook_id?: InputMaybe<Order_By>;
  participationEventName?: InputMaybe<Order_By>;
  participationevents_aggregate?: InputMaybe<Participationevent_Aggregate_Order_By>;
  readingEventStatusName?: InputMaybe<Order_By>;
  readingevents_aggregate?: InputMaybe<Readingevent_Aggregate_Order_By>;
  readingstart?: InputMaybe<Order_By>;
  review_book?: InputMaybe<Review_Book_Order_By>;
  reviews_aggregate?: InputMaybe<Review_Aggregate_Order_By>;
  seats_available?: InputMaybe<Order_By>;
  topic?: InputMaybe<Topic_Order_By>;
  topicName?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reading */
export type Reading_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reading" */
export enum Reading_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  DurationId = 'duration_id',
  /** column name */
  Externalid = 'externalid',
  /** column name */
  Id = 'id',
  /** column name */
  Inviteonly = 'inviteonly',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Maxreaders = 'maxreaders',
  /** column name */
  Meetingurl = 'meetingurl',
  /** column name */
  Minreaders = 'minreaders',
  /** column name */
  OrganisingbookId = 'organisingbook_id',
  /** column name */
  Readingstart = 'readingstart',
  /** column name */
  TopicId = 'topic_id'
}

/** select "reading_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reading" */
export enum Reading_Select_Column_Reading_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Inviteonly = 'inviteonly'
}

/** select "reading_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reading" */
export enum Reading_Select_Column_Reading_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Inviteonly = 'inviteonly'
}

/** input type for updating data in table "reading" */
export type Reading_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  duration_id?: InputMaybe<Scalars['uuid']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteonly?: InputMaybe<Scalars['Boolean']>;
  language_id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  meetingurl?: InputMaybe<Scalars['String']>;
  minreaders?: InputMaybe<Scalars['Int']>;
  organisingbook_id?: InputMaybe<Scalars['uuid']>;
  readingstart?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "reading_setting" */
export type Reading_Setting = {
  __typename?: 'reading_setting';
  id: Scalars['uuid'];
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "reading_setting" */
export type Reading_Setting_Aggregate = {
  __typename?: 'reading_setting_aggregate';
  aggregate?: Maybe<Reading_Setting_Aggregate_Fields>;
  nodes: Array<Reading_Setting>;
};

/** aggregate fields of "reading_setting" */
export type Reading_Setting_Aggregate_Fields = {
  __typename?: 'reading_setting_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reading_Setting_Max_Fields>;
  min?: Maybe<Reading_Setting_Min_Fields>;
};


/** aggregate fields of "reading_setting" */
export type Reading_Setting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reading_Setting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reading_setting". All fields are combined with a logical 'AND'. */
export type Reading_Setting_Bool_Exp = {
  _and?: InputMaybe<Array<Reading_Setting_Bool_Exp>>;
  _not?: InputMaybe<Reading_Setting_Bool_Exp>;
  _or?: InputMaybe<Array<Reading_Setting_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reading_setting" */
export enum Reading_Setting_Constraint {
  /** unique or primary key constraint on columns "key" */
  ReadingSettingKeyKey = 'reading_setting_key_key',
  /** unique or primary key constraint on columns "id" */
  ReadingSettingPkey = 'reading_setting_pkey'
}

/** input type for inserting data into table "reading_setting" */
export type Reading_Setting_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reading_Setting_Max_Fields = {
  __typename?: 'reading_setting_max_fields';
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reading_Setting_Min_Fields = {
  __typename?: 'reading_setting_min_fields';
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "reading_setting" */
export type Reading_Setting_Mutation_Response = {
  __typename?: 'reading_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reading_Setting>;
};

/** on_conflict condition type for table "reading_setting" */
export type Reading_Setting_On_Conflict = {
  constraint: Reading_Setting_Constraint;
  update_columns?: Array<Reading_Setting_Update_Column>;
  where?: InputMaybe<Reading_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "reading_setting". */
export type Reading_Setting_Order_By = {
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reading_setting */
export type Reading_Setting_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reading_setting" */
export enum Reading_Setting_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "reading_setting" */
export type Reading_Setting_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reading_setting" */
export type Reading_Setting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reading_Setting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reading_Setting_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "reading_setting" */
export enum Reading_Setting_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type Reading_Setting_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reading_Setting_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reading_Setting_Bool_Exp;
};

/** aggregate stddev on columns */
export type Reading_Stddev_Fields = {
  __typename?: 'reading_stddev_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by stddev() on columns of table "reading" */
export type Reading_Stddev_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reading_Stddev_Pop_Fields = {
  __typename?: 'reading_stddev_pop_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by stddev_pop() on columns of table "reading" */
export type Reading_Stddev_Pop_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reading_Stddev_Samp_Fields = {
  __typename?: 'reading_stddev_samp_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by stddev_samp() on columns of table "reading" */
export type Reading_Stddev_Samp_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reading" */
export type Reading_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reading_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reading_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  duration_id?: InputMaybe<Scalars['uuid']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteonly?: InputMaybe<Scalars['Boolean']>;
  language_id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  meetingurl?: InputMaybe<Scalars['String']>;
  minreaders?: InputMaybe<Scalars['Int']>;
  organisingbook_id?: InputMaybe<Scalars['uuid']>;
  readingstart?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Reading_Sum_Fields = {
  __typename?: 'reading_sum_fields';
  maxreaders?: Maybe<Scalars['Int']>;
  minreaders?: Maybe<Scalars['Int']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "reading" */
export type Reading_Sum_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** update columns of table "reading" */
export enum Reading_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  DurationId = 'duration_id',
  /** column name */
  Externalid = 'externalid',
  /** column name */
  Id = 'id',
  /** column name */
  Inviteonly = 'inviteonly',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Maxreaders = 'maxreaders',
  /** column name */
  Meetingurl = 'meetingurl',
  /** column name */
  Minreaders = 'minreaders',
  /** column name */
  OrganisingbookId = 'organisingbook_id',
  /** column name */
  Readingstart = 'readingstart',
  /** column name */
  TopicId = 'topic_id'
}

export type Reading_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reading_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reading_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reading_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reading_Var_Pop_Fields = {
  __typename?: 'reading_var_pop_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by var_pop() on columns of table "reading" */
export type Reading_Var_Pop_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reading_Var_Samp_Fields = {
  __typename?: 'reading_var_samp_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by var_samp() on columns of table "reading" */
export type Reading_Var_Samp_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reading_Variance_Fields = {
  __typename?: 'reading_variance_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
  /** returns number of reservations available for a reading */
  seats_available?: Maybe<Scalars['bigint']>;
};

/** order by variance() on columns of table "reading" */
export type Reading_Variance_Order_By = {
  maxreaders?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
};

/** columns and relationships of "readingevent" */
export type Readingevent = {
  __typename?: 'readingevent';
  comment?: Maybe<Scalars['String']>;
  created: Scalars['timestamp'];
  email_send: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An object relationship */
  reading: Reading;
  reading_id: Scalars['uuid'];
  /** An object relationship */
  readingstate: Readingstate;
  readingstatus_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "readingevent" */
export type Readingevent_Aggregate = {
  __typename?: 'readingevent_aggregate';
  aggregate?: Maybe<Readingevent_Aggregate_Fields>;
  nodes: Array<Readingevent>;
};

export type Readingevent_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Readingevent_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Readingevent_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Readingevent_Aggregate_Bool_Exp_Count>;
};

export type Readingevent_Aggregate_Bool_Exp_Bool_And = {
  arguments: Readingevent_Select_Column_Readingevent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Readingevent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Readingevent_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Readingevent_Select_Column_Readingevent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Readingevent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Readingevent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Readingevent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Readingevent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "readingevent" */
export type Readingevent_Aggregate_Fields = {
  __typename?: 'readingevent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Readingevent_Max_Fields>;
  min?: Maybe<Readingevent_Min_Fields>;
};


/** aggregate fields of "readingevent" */
export type Readingevent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Readingevent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "readingevent" */
export type Readingevent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Readingevent_Max_Order_By>;
  min?: InputMaybe<Readingevent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "readingevent" */
export type Readingevent_Arr_Rel_Insert_Input = {
  data: Array<Readingevent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Readingevent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "readingevent". All fields are combined with a logical 'AND'. */
export type Readingevent_Bool_Exp = {
  _and?: InputMaybe<Array<Readingevent_Bool_Exp>>;
  _not?: InputMaybe<Readingevent_Bool_Exp>;
  _or?: InputMaybe<Array<Readingevent_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  email_send?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  readingstate?: InputMaybe<Readingstate_Bool_Exp>;
  readingstatus_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "readingevent" */
export enum Readingevent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReadingeventPk = 'readingevent_pk'
}

/** input type for inserting data into table "readingevent" */
export type Readingevent_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  email_send?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  readingstate?: InputMaybe<Readingstate_Obj_Rel_Insert_Input>;
  readingstatus_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Readingevent_Max_Fields = {
  __typename?: 'readingevent_max_fields';
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  readingstatus_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "readingevent" */
export type Readingevent_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  readingstatus_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Readingevent_Min_Fields = {
  __typename?: 'readingevent_min_fields';
  comment?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  readingstatus_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "readingevent" */
export type Readingevent_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  readingstatus_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "readingevent" */
export type Readingevent_Mutation_Response = {
  __typename?: 'readingevent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Readingevent>;
};

/** on_conflict condition type for table "readingevent" */
export type Readingevent_On_Conflict = {
  constraint: Readingevent_Constraint;
  update_columns?: Array<Readingevent_Update_Column>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};

/** Ordering options when selecting data from "readingevent". */
export type Readingevent_Order_By = {
  comment?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  email_send?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  readingstate?: InputMaybe<Readingstate_Order_By>;
  readingstatus_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: readingevent */
export type Readingevent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "readingevent" */
export enum Readingevent_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  EmailSend = 'email_send',
  /** column name */
  Id = 'id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  ReadingstatusId = 'readingstatus_id',
  /** column name */
  UserId = 'user_id'
}

/** select "readingevent_aggregate_bool_exp_bool_and_arguments_columns" columns of table "readingevent" */
export enum Readingevent_Select_Column_Readingevent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EmailSend = 'email_send'
}

/** select "readingevent_aggregate_bool_exp_bool_or_arguments_columns" columns of table "readingevent" */
export enum Readingevent_Select_Column_Readingevent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EmailSend = 'email_send'
}

/** input type for updating data in table "readingevent" */
export type Readingevent_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  email_send?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  readingstatus_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "readingevent" */
export type Readingevent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Readingevent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Readingevent_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['timestamp']>;
  email_send?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  readingstatus_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "readingevent" */
export enum Readingevent_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Created = 'created',
  /** column name */
  EmailSend = 'email_send',
  /** column name */
  Id = 'id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  ReadingstatusId = 'readingstatus_id',
  /** column name */
  UserId = 'user_id'
}

export type Readingevent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Readingevent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Readingevent_Bool_Exp;
};

/** columns and relationships of "readingplan" */
export type Readingplan = {
  __typename?: 'readingplan';
  activated?: Maybe<Scalars['timestamptz']>;
  created: Scalars['timestamp'];
  /** An object relationship */
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  emailLibraryCardExpirationReminder?: Maybe<Email_Library_Card_Expiration_Reminder>;
  /** An object relationship */
  emailLibraryCardExpiredReminder?: Maybe<Email_Library_Card_Expired_Reminder>;
  id: Scalars['uuid'];
  initialreadings: Scalars['Int'];
  issuedtoemail?: Maybe<Scalars['String']>;
  /** An array relationship */
  pillarconstraints: Array<Pillarconstraint>;
  /** An aggregate relationship */
  pillarconstraints_aggregate: Pillarconstraint_Aggregate;
  /** An object relationship */
  readingPlanDuration: Readingplan_Duration;
  readingplan_duration_id: Scalars['uuid'];
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<User>;
  updated_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
  /** Calcuates expire date for reading plan based on readingplan_duration_id and activated columns */
  valid_to?: Maybe<Scalars['date']>;
  validfrom?: Maybe<Scalars['date']>;
  validto?: Maybe<Scalars['date']>;
};


/** columns and relationships of "readingplan" */
export type ReadingplanPillarconstraintsArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};


/** columns and relationships of "readingplan" */
export type ReadingplanPillarconstraints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};

/** aggregated selection of "readingplan" */
export type Readingplan_Aggregate = {
  __typename?: 'readingplan_aggregate';
  aggregate?: Maybe<Readingplan_Aggregate_Fields>;
  nodes: Array<Readingplan>;
};

/** aggregate fields of "readingplan" */
export type Readingplan_Aggregate_Fields = {
  __typename?: 'readingplan_aggregate_fields';
  avg?: Maybe<Readingplan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Readingplan_Max_Fields>;
  min?: Maybe<Readingplan_Min_Fields>;
  stddev?: Maybe<Readingplan_Stddev_Fields>;
  stddev_pop?: Maybe<Readingplan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Readingplan_Stddev_Samp_Fields>;
  sum?: Maybe<Readingplan_Sum_Fields>;
  var_pop?: Maybe<Readingplan_Var_Pop_Fields>;
  var_samp?: Maybe<Readingplan_Var_Samp_Fields>;
  variance?: Maybe<Readingplan_Variance_Fields>;
};


/** aggregate fields of "readingplan" */
export type Readingplan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Readingplan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Readingplan_Avg_Fields = {
  __typename?: 'readingplan_avg_fields';
  initialreadings?: Maybe<Scalars['Float']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "readingplan". All fields are combined with a logical 'AND'. */
export type Readingplan_Bool_Exp = {
  _and?: InputMaybe<Array<Readingplan_Bool_Exp>>;
  _not?: InputMaybe<Readingplan_Bool_Exp>;
  _or?: InputMaybe<Array<Readingplan_Bool_Exp>>;
  activated?: InputMaybe<Timestamptz_Comparison_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  creator?: InputMaybe<User_Bool_Exp>;
  creator_id?: InputMaybe<Uuid_Comparison_Exp>;
  emailLibraryCardExpirationReminder?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
  emailLibraryCardExpiredReminder?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  initialreadings?: InputMaybe<Int_Comparison_Exp>;
  issuedtoemail?: InputMaybe<String_Comparison_Exp>;
  pillarconstraints?: InputMaybe<Pillarconstraint_Bool_Exp>;
  pillarconstraints_aggregate?: InputMaybe<Pillarconstraint_Aggregate_Bool_Exp>;
  readingPlanDuration?: InputMaybe<Readingplan_Duration_Bool_Exp>;
  readingplan_duration_id?: InputMaybe<Uuid_Comparison_Exp>;
  remainingreadings?: InputMaybe<Bigint_Comparison_Exp>;
  updated?: InputMaybe<Timestamp_Comparison_Exp>;
  updatedBy?: InputMaybe<User_Bool_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_to?: InputMaybe<Date_Comparison_Exp>;
  validfrom?: InputMaybe<Date_Comparison_Exp>;
  validto?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "readingplan" */
export enum Readingplan_Constraint {
  /** unique or primary key constraint on columns "id" */
  LibrarycardPk = 'librarycard_pk',
  /** unique or primary key constraint on columns "user_id" */
  ReadingplanUserIdKey = 'readingplan_user_id_key'
}

/** columns and relationships of "readingplan_duration" */
export type Readingplan_Duration = {
  __typename?: 'readingplan_duration';
  id: Scalars['uuid'];
  months?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  translation: Array<Readingplan_Duration_Translation>;
  /** An aggregate relationship */
  translation_aggregate: Readingplan_Duration_Translation_Aggregate;
};


/** columns and relationships of "readingplan_duration" */
export type Readingplan_DurationTranslationArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Translation_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};


/** columns and relationships of "readingplan_duration" */
export type Readingplan_DurationTranslation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Translation_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};

/** aggregated selection of "readingplan_duration" */
export type Readingplan_Duration_Aggregate = {
  __typename?: 'readingplan_duration_aggregate';
  aggregate?: Maybe<Readingplan_Duration_Aggregate_Fields>;
  nodes: Array<Readingplan_Duration>;
};

/** aggregate fields of "readingplan_duration" */
export type Readingplan_Duration_Aggregate_Fields = {
  __typename?: 'readingplan_duration_aggregate_fields';
  avg?: Maybe<Readingplan_Duration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Readingplan_Duration_Max_Fields>;
  min?: Maybe<Readingplan_Duration_Min_Fields>;
  stddev?: Maybe<Readingplan_Duration_Stddev_Fields>;
  stddev_pop?: Maybe<Readingplan_Duration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Readingplan_Duration_Stddev_Samp_Fields>;
  sum?: Maybe<Readingplan_Duration_Sum_Fields>;
  var_pop?: Maybe<Readingplan_Duration_Var_Pop_Fields>;
  var_samp?: Maybe<Readingplan_Duration_Var_Samp_Fields>;
  variance?: Maybe<Readingplan_Duration_Variance_Fields>;
};


/** aggregate fields of "readingplan_duration" */
export type Readingplan_Duration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Readingplan_Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Readingplan_Duration_Avg_Fields = {
  __typename?: 'readingplan_duration_avg_fields';
  months?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "readingplan_duration". All fields are combined with a logical 'AND'. */
export type Readingplan_Duration_Bool_Exp = {
  _and?: InputMaybe<Array<Readingplan_Duration_Bool_Exp>>;
  _not?: InputMaybe<Readingplan_Duration_Bool_Exp>;
  _or?: InputMaybe<Array<Readingplan_Duration_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  months?: InputMaybe<Numeric_Comparison_Exp>;
  translation?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
  translation_aggregate?: InputMaybe<Readingplan_Duration_Translation_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "readingplan_duration" */
export enum Readingplan_Duration_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReadingplanDurationIdKey = 'readingplan_duration_id_key'
}

/** input type for incrementing numeric columns in table "readingplan_duration" */
export type Readingplan_Duration_Inc_Input = {
  months?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "readingplan_duration" */
export type Readingplan_Duration_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  months?: InputMaybe<Scalars['numeric']>;
  translation?: InputMaybe<Readingplan_Duration_Translation_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Readingplan_Duration_Max_Fields = {
  __typename?: 'readingplan_duration_max_fields';
  id?: Maybe<Scalars['uuid']>;
  months?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Readingplan_Duration_Min_Fields = {
  __typename?: 'readingplan_duration_min_fields';
  id?: Maybe<Scalars['uuid']>;
  months?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "readingplan_duration" */
export type Readingplan_Duration_Mutation_Response = {
  __typename?: 'readingplan_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Readingplan_Duration>;
};

/** input type for inserting object relation for remote table "readingplan_duration" */
export type Readingplan_Duration_Obj_Rel_Insert_Input = {
  data: Readingplan_Duration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Readingplan_Duration_On_Conflict>;
};

/** on_conflict condition type for table "readingplan_duration" */
export type Readingplan_Duration_On_Conflict = {
  constraint: Readingplan_Duration_Constraint;
  update_columns?: Array<Readingplan_Duration_Update_Column>;
  where?: InputMaybe<Readingplan_Duration_Bool_Exp>;
};

/** Ordering options when selecting data from "readingplan_duration". */
export type Readingplan_Duration_Order_By = {
  id?: InputMaybe<Order_By>;
  months?: InputMaybe<Order_By>;
  translation_aggregate?: InputMaybe<Readingplan_Duration_Translation_Aggregate_Order_By>;
};

/** select columns of table "readingplan_duration" */
export enum Readingplan_Duration_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Months = 'months'
}

/** input type for updating data in table "readingplan_duration" */
export type Readingplan_Duration_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  months?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Readingplan_Duration_Stddev_Fields = {
  __typename?: 'readingplan_duration_stddev_fields';
  months?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Readingplan_Duration_Stddev_Pop_Fields = {
  __typename?: 'readingplan_duration_stddev_pop_fields';
  months?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Readingplan_Duration_Stddev_Samp_Fields = {
  __typename?: 'readingplan_duration_stddev_samp_fields';
  months?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "readingplan_duration" */
export type Readingplan_Duration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Readingplan_Duration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Readingplan_Duration_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  months?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Readingplan_Duration_Sum_Fields = {
  __typename?: 'readingplan_duration_sum_fields';
  months?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "readingplan_duration_translation" */
export type Readingplan_Duration_Translation = {
  __typename?: 'readingplan_duration_translation';
  language_id: Scalars['uuid'];
  name: Scalars['String'];
  readingplan_duration_id: Scalars['uuid'];
};

/** aggregated selection of "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Aggregate = {
  __typename?: 'readingplan_duration_translation_aggregate';
  aggregate?: Maybe<Readingplan_Duration_Translation_Aggregate_Fields>;
  nodes: Array<Readingplan_Duration_Translation>;
};

export type Readingplan_Duration_Translation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Readingplan_Duration_Translation_Aggregate_Bool_Exp_Count>;
};

export type Readingplan_Duration_Translation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Aggregate_Fields = {
  __typename?: 'readingplan_duration_translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Readingplan_Duration_Translation_Max_Fields>;
  min?: Maybe<Readingplan_Duration_Translation_Min_Fields>;
};


/** aggregate fields of "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Readingplan_Duration_Translation_Max_Order_By>;
  min?: InputMaybe<Readingplan_Duration_Translation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Arr_Rel_Insert_Input = {
  data: Array<Readingplan_Duration_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Readingplan_Duration_Translation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "readingplan_duration_translation". All fields are combined with a logical 'AND'. */
export type Readingplan_Duration_Translation_Bool_Exp = {
  _and?: InputMaybe<Array<Readingplan_Duration_Translation_Bool_Exp>>;
  _not?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
  _or?: InputMaybe<Array<Readingplan_Duration_Translation_Bool_Exp>>;
  language_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  readingplan_duration_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "readingplan_duration_translation" */
export enum Readingplan_Duration_Translation_Constraint {
  /** unique or primary key constraint on columns "language_id", "readingplan_duration_id" */
  ReadingplanDurationTranslationPkey = 'readingplan_duration_translation_pkey'
}

/** input type for inserting data into table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Insert_Input = {
  language_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  readingplan_duration_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Readingplan_Duration_Translation_Max_Fields = {
  __typename?: 'readingplan_duration_translation_max_fields';
  language_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  readingplan_duration_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Max_Order_By = {
  language_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  readingplan_duration_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Readingplan_Duration_Translation_Min_Fields = {
  __typename?: 'readingplan_duration_translation_min_fields';
  language_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  readingplan_duration_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Min_Order_By = {
  language_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  readingplan_duration_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Mutation_Response = {
  __typename?: 'readingplan_duration_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Readingplan_Duration_Translation>;
};

/** on_conflict condition type for table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_On_Conflict = {
  constraint: Readingplan_Duration_Translation_Constraint;
  update_columns?: Array<Readingplan_Duration_Translation_Update_Column>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "readingplan_duration_translation". */
export type Readingplan_Duration_Translation_Order_By = {
  language_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  readingplan_duration_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: readingplan_duration_translation */
export type Readingplan_Duration_Translation_Pk_Columns_Input = {
  language_id: Scalars['uuid'];
  readingplan_duration_id: Scalars['uuid'];
};

/** select columns of table "readingplan_duration_translation" */
export enum Readingplan_Duration_Translation_Select_Column {
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Name = 'name',
  /** column name */
  ReadingplanDurationId = 'readingplan_duration_id'
}

/** input type for updating data in table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Set_Input = {
  language_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  readingplan_duration_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "readingplan_duration_translation" */
export type Readingplan_Duration_Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Readingplan_Duration_Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Readingplan_Duration_Translation_Stream_Cursor_Value_Input = {
  language_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  readingplan_duration_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "readingplan_duration_translation" */
export enum Readingplan_Duration_Translation_Update_Column {
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Name = 'name',
  /** column name */
  ReadingplanDurationId = 'readingplan_duration_id'
}

export type Readingplan_Duration_Translation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Readingplan_Duration_Translation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Readingplan_Duration_Translation_Bool_Exp;
};

/** update columns of table "readingplan_duration" */
export enum Readingplan_Duration_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Months = 'months'
}

export type Readingplan_Duration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Readingplan_Duration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Readingplan_Duration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Readingplan_Duration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Readingplan_Duration_Var_Pop_Fields = {
  __typename?: 'readingplan_duration_var_pop_fields';
  months?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Readingplan_Duration_Var_Samp_Fields = {
  __typename?: 'readingplan_duration_var_samp_fields';
  months?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Readingplan_Duration_Variance_Fields = {
  __typename?: 'readingplan_duration_variance_fields';
  months?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "readingplan" */
export type Readingplan_Inc_Input = {
  initialreadings?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "readingplan" */
export type Readingplan_Insert_Input = {
  activated?: InputMaybe<Scalars['timestamptz']>;
  created?: InputMaybe<Scalars['timestamp']>;
  creator?: InputMaybe<User_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  emailLibraryCardExpirationReminder?: InputMaybe<Email_Library_Card_Expiration_Reminder_Obj_Rel_Insert_Input>;
  emailLibraryCardExpiredReminder?: InputMaybe<Email_Library_Card_Expired_Reminder_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  initialreadings?: InputMaybe<Scalars['Int']>;
  issuedtoemail?: InputMaybe<Scalars['String']>;
  pillarconstraints?: InputMaybe<Pillarconstraint_Arr_Rel_Insert_Input>;
  readingPlanDuration?: InputMaybe<Readingplan_Duration_Obj_Rel_Insert_Input>;
  readingplan_duration_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<User_Obj_Rel_Insert_Input>;
  updated_by?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  validfrom?: InputMaybe<Scalars['date']>;
  validto?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Readingplan_Max_Fields = {
  __typename?: 'readingplan_max_fields';
  activated?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamp']>;
  creator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  initialreadings?: Maybe<Scalars['Int']>;
  issuedtoemail?: Maybe<Scalars['String']>;
  readingplan_duration_id?: Maybe<Scalars['uuid']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  /** Calcuates expire date for reading plan based on readingplan_duration_id and activated columns */
  valid_to?: Maybe<Scalars['date']>;
  validfrom?: Maybe<Scalars['date']>;
  validto?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Readingplan_Min_Fields = {
  __typename?: 'readingplan_min_fields';
  activated?: Maybe<Scalars['timestamptz']>;
  created?: Maybe<Scalars['timestamp']>;
  creator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  initialreadings?: Maybe<Scalars['Int']>;
  issuedtoemail?: Maybe<Scalars['String']>;
  readingplan_duration_id?: Maybe<Scalars['uuid']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
  updated?: Maybe<Scalars['timestamp']>;
  updated_by?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  /** Calcuates expire date for reading plan based on readingplan_duration_id and activated columns */
  valid_to?: Maybe<Scalars['date']>;
  validfrom?: Maybe<Scalars['date']>;
  validto?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "readingplan" */
export type Readingplan_Mutation_Response = {
  __typename?: 'readingplan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Readingplan>;
};

/** input type for inserting object relation for remote table "readingplan" */
export type Readingplan_Obj_Rel_Insert_Input = {
  data: Readingplan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Readingplan_On_Conflict>;
};

/** on_conflict condition type for table "readingplan" */
export type Readingplan_On_Conflict = {
  constraint: Readingplan_Constraint;
  update_columns?: Array<Readingplan_Update_Column>;
  where?: InputMaybe<Readingplan_Bool_Exp>;
};

/** Ordering options when selecting data from "readingplan". */
export type Readingplan_Order_By = {
  activated?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  creator?: InputMaybe<User_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  emailLibraryCardExpirationReminder?: InputMaybe<Email_Library_Card_Expiration_Reminder_Order_By>;
  emailLibraryCardExpiredReminder?: InputMaybe<Email_Library_Card_Expired_Reminder_Order_By>;
  id?: InputMaybe<Order_By>;
  initialreadings?: InputMaybe<Order_By>;
  issuedtoemail?: InputMaybe<Order_By>;
  pillarconstraints_aggregate?: InputMaybe<Pillarconstraint_Aggregate_Order_By>;
  readingPlanDuration?: InputMaybe<Readingplan_Duration_Order_By>;
  readingplan_duration_id?: InputMaybe<Order_By>;
  remainingreadings?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  updatedBy?: InputMaybe<User_Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_to?: InputMaybe<Order_By>;
  validfrom?: InputMaybe<Order_By>;
  validto?: InputMaybe<Order_By>;
};

/** primary key columns input for table: readingplan */
export type Readingplan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "readingplan" */
export enum Readingplan_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  Created = 'created',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Initialreadings = 'initialreadings',
  /** column name */
  Issuedtoemail = 'issuedtoemail',
  /** column name */
  ReadingplanDurationId = 'readingplan_duration_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Validfrom = 'validfrom',
  /** column name */
  Validto = 'validto'
}

/** input type for updating data in table "readingplan" */
export type Readingplan_Set_Input = {
  activated?: InputMaybe<Scalars['timestamptz']>;
  created?: InputMaybe<Scalars['timestamp']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  initialreadings?: InputMaybe<Scalars['Int']>;
  issuedtoemail?: InputMaybe<Scalars['String']>;
  readingplan_duration_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  validfrom?: InputMaybe<Scalars['date']>;
  validto?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type Readingplan_Stddev_Fields = {
  __typename?: 'readingplan_stddev_fields';
  initialreadings?: Maybe<Scalars['Float']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev_pop on columns */
export type Readingplan_Stddev_Pop_Fields = {
  __typename?: 'readingplan_stddev_pop_fields';
  initialreadings?: Maybe<Scalars['Float']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev_samp on columns */
export type Readingplan_Stddev_Samp_Fields = {
  __typename?: 'readingplan_stddev_samp_fields';
  initialreadings?: Maybe<Scalars['Float']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

/** Streaming cursor of the table "readingplan" */
export type Readingplan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Readingplan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Readingplan_Stream_Cursor_Value_Input = {
  activated?: InputMaybe<Scalars['timestamptz']>;
  created?: InputMaybe<Scalars['timestamp']>;
  creator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  initialreadings?: InputMaybe<Scalars['Int']>;
  issuedtoemail?: InputMaybe<Scalars['String']>;
  readingplan_duration_id?: InputMaybe<Scalars['uuid']>;
  updated?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  validfrom?: InputMaybe<Scalars['date']>;
  validto?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Readingplan_Sum_Fields = {
  __typename?: 'readingplan_sum_fields';
  initialreadings?: Maybe<Scalars['Int']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

/** update columns of table "readingplan" */
export enum Readingplan_Update_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  Created = 'created',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Initialreadings = 'initialreadings',
  /** column name */
  Issuedtoemail = 'issuedtoemail',
  /** column name */
  ReadingplanDurationId = 'readingplan_duration_id',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Validfrom = 'validfrom',
  /** column name */
  Validto = 'validto'
}

export type Readingplan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Readingplan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Readingplan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Readingplan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Readingplan_Var_Pop_Fields = {
  __typename?: 'readingplan_var_pop_fields';
  initialreadings?: Maybe<Scalars['Float']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

/** aggregate var_samp on columns */
export type Readingplan_Var_Samp_Fields = {
  __typename?: 'readingplan_var_samp_fields';
  initialreadings?: Maybe<Scalars['Float']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

/** aggregate variance on columns */
export type Readingplan_Variance_Fields = {
  __typename?: 'readingplan_variance_fields';
  initialreadings?: Maybe<Scalars['Float']>;
  /** Returns the remaining number of readings by user */
  remainingreadings?: Maybe<Scalars['bigint']>;
};

export type Readings_By_Participationstate_Args = {
  particiationstate_name?: InputMaybe<Scalars['String']>;
  reader_user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "readings_per_week" */
export type Readings_Per_Week = {
  __typename?: 'readings_per_week';
  readings_count?: Maybe<Scalars['Int']>;
  week_start?: Maybe<Scalars['date']>;
};

export type Readings_Per_Week_Aggregate = {
  __typename?: 'readings_per_week_aggregate';
  aggregate?: Maybe<Readings_Per_Week_Aggregate_Fields>;
  nodes: Array<Readings_Per_Week>;
};

/** aggregate fields of "readings_per_week" */
export type Readings_Per_Week_Aggregate_Fields = {
  __typename?: 'readings_per_week_aggregate_fields';
  avg?: Maybe<Readings_Per_Week_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Readings_Per_Week_Max_Fields>;
  min?: Maybe<Readings_Per_Week_Min_Fields>;
  stddev?: Maybe<Readings_Per_Week_Stddev_Fields>;
  stddev_pop?: Maybe<Readings_Per_Week_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Readings_Per_Week_Stddev_Samp_Fields>;
  sum?: Maybe<Readings_Per_Week_Sum_Fields>;
  var_pop?: Maybe<Readings_Per_Week_Var_Pop_Fields>;
  var_samp?: Maybe<Readings_Per_Week_Var_Samp_Fields>;
  variance?: Maybe<Readings_Per_Week_Variance_Fields>;
};


/** aggregate fields of "readings_per_week" */
export type Readings_Per_Week_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Readings_Per_Week_Avg_Fields = {
  __typename?: 'readings_per_week_avg_fields';
  readings_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "readings_per_week". All fields are combined with a logical 'AND'. */
export type Readings_Per_Week_Bool_Exp = {
  _and?: InputMaybe<Array<Readings_Per_Week_Bool_Exp>>;
  _not?: InputMaybe<Readings_Per_Week_Bool_Exp>;
  _or?: InputMaybe<Array<Readings_Per_Week_Bool_Exp>>;
  readings_count?: InputMaybe<Int_Comparison_Exp>;
  week_start?: InputMaybe<Date_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "readings_per_week" */
export type Readings_Per_Week_Inc_Input = {
  readings_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "readings_per_week" */
export type Readings_Per_Week_Insert_Input = {
  readings_count?: InputMaybe<Scalars['Int']>;
  week_start?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Readings_Per_Week_Max_Fields = {
  __typename?: 'readings_per_week_max_fields';
  readings_count?: Maybe<Scalars['Int']>;
  week_start?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Readings_Per_Week_Min_Fields = {
  __typename?: 'readings_per_week_min_fields';
  readings_count?: Maybe<Scalars['Int']>;
  week_start?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "readings_per_week" */
export type Readings_Per_Week_Mutation_Response = {
  __typename?: 'readings_per_week_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Readings_Per_Week>;
};

/** Ordering options when selecting data from "readings_per_week". */
export type Readings_Per_Week_Order_By = {
  readings_count?: InputMaybe<Order_By>;
  week_start?: InputMaybe<Order_By>;
};

/** select columns of table "readings_per_week" */
export enum Readings_Per_Week_Select_Column {
  /** column name */
  ReadingsCount = 'readings_count',
  /** column name */
  WeekStart = 'week_start'
}

/** input type for updating data in table "readings_per_week" */
export type Readings_Per_Week_Set_Input = {
  readings_count?: InputMaybe<Scalars['Int']>;
  week_start?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type Readings_Per_Week_Stddev_Fields = {
  __typename?: 'readings_per_week_stddev_fields';
  readings_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Readings_Per_Week_Stddev_Pop_Fields = {
  __typename?: 'readings_per_week_stddev_pop_fields';
  readings_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Readings_Per_Week_Stddev_Samp_Fields = {
  __typename?: 'readings_per_week_stddev_samp_fields';
  readings_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "readings_per_week" */
export type Readings_Per_Week_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Readings_Per_Week_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Readings_Per_Week_Stream_Cursor_Value_Input = {
  readings_count?: InputMaybe<Scalars['Int']>;
  week_start?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Readings_Per_Week_Sum_Fields = {
  __typename?: 'readings_per_week_sum_fields';
  readings_count?: Maybe<Scalars['Int']>;
};

export type Readings_Per_Week_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Readings_Per_Week_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Readings_Per_Week_Set_Input>;
  /** filter the rows which have to be updated */
  where: Readings_Per_Week_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Readings_Per_Week_Var_Pop_Fields = {
  __typename?: 'readings_per_week_var_pop_fields';
  readings_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Readings_Per_Week_Var_Samp_Fields = {
  __typename?: 'readings_per_week_var_samp_fields';
  readings_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Readings_Per_Week_Variance_Fields = {
  __typename?: 'readings_per_week_variance_fields';
  readings_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "readingstate" */
export type Readingstate = {
  __typename?: 'readingstate';
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  readingevents: Array<Readingevent>;
  /** An aggregate relationship */
  readingevents_aggregate: Readingevent_Aggregate;
};


/** columns and relationships of "readingstate" */
export type ReadingstateReadingeventsArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


/** columns and relationships of "readingstate" */
export type ReadingstateReadingevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};

/** aggregated selection of "readingstate" */
export type Readingstate_Aggregate = {
  __typename?: 'readingstate_aggregate';
  aggregate?: Maybe<Readingstate_Aggregate_Fields>;
  nodes: Array<Readingstate>;
};

/** aggregate fields of "readingstate" */
export type Readingstate_Aggregate_Fields = {
  __typename?: 'readingstate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Readingstate_Max_Fields>;
  min?: Maybe<Readingstate_Min_Fields>;
};


/** aggregate fields of "readingstate" */
export type Readingstate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Readingstate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "readingstate". All fields are combined with a logical 'AND'. */
export type Readingstate_Bool_Exp = {
  _and?: InputMaybe<Array<Readingstate_Bool_Exp>>;
  _not?: InputMaybe<Readingstate_Bool_Exp>;
  _or?: InputMaybe<Array<Readingstate_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  readingevents?: InputMaybe<Readingevent_Bool_Exp>;
  readingevents_aggregate?: InputMaybe<Readingevent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "readingstate" */
export enum Readingstate_Constraint {
  /** unique or primary key constraint on columns "name" */
  ReadingstateAkName = 'readingstate_ak_name',
  /** unique or primary key constraint on columns "id" */
  ReadingstatePk = 'readingstate_pk'
}

/** input type for inserting data into table "readingstate" */
export type Readingstate_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  readingevents?: InputMaybe<Readingevent_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Readingstate_Max_Fields = {
  __typename?: 'readingstate_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Readingstate_Min_Fields = {
  __typename?: 'readingstate_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "readingstate" */
export type Readingstate_Mutation_Response = {
  __typename?: 'readingstate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Readingstate>;
};

/** input type for inserting object relation for remote table "readingstate" */
export type Readingstate_Obj_Rel_Insert_Input = {
  data: Readingstate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Readingstate_On_Conflict>;
};

/** on_conflict condition type for table "readingstate" */
export type Readingstate_On_Conflict = {
  constraint: Readingstate_Constraint;
  update_columns?: Array<Readingstate_Update_Column>;
  where?: InputMaybe<Readingstate_Bool_Exp>;
};

/** Ordering options when selecting data from "readingstate". */
export type Readingstate_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  readingevents_aggregate?: InputMaybe<Readingevent_Aggregate_Order_By>;
};

/** primary key columns input for table: readingstate */
export type Readingstate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "readingstate" */
export enum Readingstate_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "readingstate" */
export type Readingstate_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "readingstate" */
export type Readingstate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Readingstate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Readingstate_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "readingstate" */
export enum Readingstate_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Readingstate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Readingstate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Readingstate_Bool_Exp;
};

/** columns and relationships of "review" */
export type Review = {
  __typename?: 'review';
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** A computed field, executes function "get_review_person_name" */
  personName?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  /** An object relationship */
  reading: Reading;
  reading_id: Scalars['uuid'];
  text?: Maybe<Scalars['String']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "review" */
export type Review_Aggregate = {
  __typename?: 'review_aggregate';
  aggregate?: Maybe<Review_Aggregate_Fields>;
  nodes: Array<Review>;
};

export type Review_Aggregate_Bool_Exp = {
  count?: InputMaybe<Review_Aggregate_Bool_Exp_Count>;
};

export type Review_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Review_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "review" */
export type Review_Aggregate_Fields = {
  __typename?: 'review_aggregate_fields';
  avg?: Maybe<Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Review_Max_Fields>;
  min?: Maybe<Review_Min_Fields>;
  stddev?: Maybe<Review_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Sum_Fields>;
  var_pop?: Maybe<Review_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Var_Samp_Fields>;
  variance?: Maybe<Review_Variance_Fields>;
};


/** aggregate fields of "review" */
export type Review_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "review" */
export type Review_Aggregate_Order_By = {
  avg?: InputMaybe<Review_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Max_Order_By>;
  min?: InputMaybe<Review_Min_Order_By>;
  stddev?: InputMaybe<Review_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Review_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Review_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Review_Sum_Order_By>;
  var_pop?: InputMaybe<Review_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Review_Var_Samp_Order_By>;
  variance?: InputMaybe<Review_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "review" */
export type Review_Arr_Rel_Insert_Input = {
  data: Array<Review_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_On_Conflict>;
};

/** aggregate avg on columns */
export type Review_Avg_Fields = {
  __typename?: 'review_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "review" */
export type Review_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "review_book" */
export type Review_Book = {
  __typename?: 'review_book';
  created: Scalars['timestamp'];
  /** An object relationship */
  reading: Reading;
  reading_id: Scalars['uuid'];
  /** An object relationship */
  review_book_option: Review_Book_Option;
  review_book_option_id: Scalars['uuid'];
  text?: Maybe<Scalars['String']>;
};

/** aggregated selection of "review_book" */
export type Review_Book_Aggregate = {
  __typename?: 'review_book_aggregate';
  aggregate?: Maybe<Review_Book_Aggregate_Fields>;
  nodes: Array<Review_Book>;
};

export type Review_Book_Aggregate_Bool_Exp = {
  count?: InputMaybe<Review_Book_Aggregate_Bool_Exp_Count>;
};

export type Review_Book_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Review_Book_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Review_Book_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "review_book" */
export type Review_Book_Aggregate_Fields = {
  __typename?: 'review_book_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Review_Book_Max_Fields>;
  min?: Maybe<Review_Book_Min_Fields>;
};


/** aggregate fields of "review_book" */
export type Review_Book_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Book_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "review_book" */
export type Review_Book_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Book_Max_Order_By>;
  min?: InputMaybe<Review_Book_Min_Order_By>;
};

/** input type for inserting array relation for remote table "review_book" */
export type Review_Book_Arr_Rel_Insert_Input = {
  data: Array<Review_Book_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Book_On_Conflict>;
};

/** Boolean expression to filter rows from the table "review_book". All fields are combined with a logical 'AND'. */
export type Review_Book_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Book_Bool_Exp>>;
  _not?: InputMaybe<Review_Book_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Book_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  review_book_option?: InputMaybe<Review_Book_Option_Bool_Exp>;
  review_book_option_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_book" */
export enum Review_Book_Constraint {
  /** unique or primary key constraint on columns "reading_id" */
  ReviewBookPkey = 'review_book_pkey'
}

/** input type for inserting data into table "review_book" */
export type Review_Book_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  review_book_option?: InputMaybe<Review_Book_Option_Obj_Rel_Insert_Input>;
  review_book_option_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Review_Book_Max_Fields = {
  __typename?: 'review_book_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  reading_id?: Maybe<Scalars['uuid']>;
  review_book_option_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "review_book" */
export type Review_Book_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  review_book_option_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Book_Min_Fields = {
  __typename?: 'review_book_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  reading_id?: Maybe<Scalars['uuid']>;
  review_book_option_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "review_book" */
export type Review_Book_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  review_book_option_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "review_book" */
export type Review_Book_Mutation_Response = {
  __typename?: 'review_book_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Book>;
};

/** input type for inserting object relation for remote table "review_book" */
export type Review_Book_Obj_Rel_Insert_Input = {
  data: Review_Book_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Book_On_Conflict>;
};

/** on_conflict condition type for table "review_book" */
export type Review_Book_On_Conflict = {
  constraint: Review_Book_Constraint;
  update_columns?: Array<Review_Book_Update_Column>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};

/** columns and relationships of "review_book_option" */
export type Review_Book_Option = {
  __typename?: 'review_book_option';
  created: Scalars['timestamp'];
  id: Scalars['uuid'];
  order: Scalars['Int'];
  /** An array relationship */
  review_books: Array<Review_Book>;
  /** An aggregate relationship */
  review_books_aggregate: Review_Book_Aggregate;
  translationKey: Scalars['String'];
};


/** columns and relationships of "review_book_option" */
export type Review_Book_OptionReview_BooksArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Order_By>>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};


/** columns and relationships of "review_book_option" */
export type Review_Book_OptionReview_Books_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Order_By>>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};

/** aggregated selection of "review_book_option" */
export type Review_Book_Option_Aggregate = {
  __typename?: 'review_book_option_aggregate';
  aggregate?: Maybe<Review_Book_Option_Aggregate_Fields>;
  nodes: Array<Review_Book_Option>;
};

/** aggregate fields of "review_book_option" */
export type Review_Book_Option_Aggregate_Fields = {
  __typename?: 'review_book_option_aggregate_fields';
  avg?: Maybe<Review_Book_Option_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Review_Book_Option_Max_Fields>;
  min?: Maybe<Review_Book_Option_Min_Fields>;
  stddev?: Maybe<Review_Book_Option_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Book_Option_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Book_Option_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Book_Option_Sum_Fields>;
  var_pop?: Maybe<Review_Book_Option_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Book_Option_Var_Samp_Fields>;
  variance?: Maybe<Review_Book_Option_Variance_Fields>;
};


/** aggregate fields of "review_book_option" */
export type Review_Book_Option_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Book_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Review_Book_Option_Avg_Fields = {
  __typename?: 'review_book_option_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "review_book_option". All fields are combined with a logical 'AND'. */
export type Review_Book_Option_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Book_Option_Bool_Exp>>;
  _not?: InputMaybe<Review_Book_Option_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Book_Option_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  review_books?: InputMaybe<Review_Book_Bool_Exp>;
  review_books_aggregate?: InputMaybe<Review_Book_Aggregate_Bool_Exp>;
  translationKey?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_book_option" */
export enum Review_Book_Option_Constraint {
  /** unique or primary key constraint on columns "order" */
  ReviewBookOptionOrderKey = 'review_book_option_order_key',
  /** unique or primary key constraint on columns "id" */
  ReviewBookOptionPkey = 'review_book_option_pkey'
}

/** input type for incrementing numeric columns in table "review_book_option" */
export type Review_Book_Option_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "review_book_option" */
export type Review_Book_Option_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  review_books?: InputMaybe<Review_Book_Arr_Rel_Insert_Input>;
  translationKey?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Review_Book_Option_Max_Fields = {
  __typename?: 'review_book_option_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  translationKey?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Review_Book_Option_Min_Fields = {
  __typename?: 'review_book_option_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  translationKey?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "review_book_option" */
export type Review_Book_Option_Mutation_Response = {
  __typename?: 'review_book_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Book_Option>;
};

/** input type for inserting object relation for remote table "review_book_option" */
export type Review_Book_Option_Obj_Rel_Insert_Input = {
  data: Review_Book_Option_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Book_Option_On_Conflict>;
};

/** on_conflict condition type for table "review_book_option" */
export type Review_Book_Option_On_Conflict = {
  constraint: Review_Book_Option_Constraint;
  update_columns?: Array<Review_Book_Option_Update_Column>;
  where?: InputMaybe<Review_Book_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "review_book_option". */
export type Review_Book_Option_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  review_books_aggregate?: InputMaybe<Review_Book_Aggregate_Order_By>;
  translationKey?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review_book_option */
export type Review_Book_Option_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "review_book_option" */
export enum Review_Book_Option_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translationKey'
}

/** input type for updating data in table "review_book_option" */
export type Review_Book_Option_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  translationKey?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Review_Book_Option_Stddev_Fields = {
  __typename?: 'review_book_option_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Review_Book_Option_Stddev_Pop_Fields = {
  __typename?: 'review_book_option_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Review_Book_Option_Stddev_Samp_Fields = {
  __typename?: 'review_book_option_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "review_book_option" */
export type Review_Book_Option_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Book_Option_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Book_Option_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  translationKey?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Review_Book_Option_Sum_Fields = {
  __typename?: 'review_book_option_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "review_book_option" */
export enum Review_Book_Option_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translationKey'
}

export type Review_Book_Option_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Review_Book_Option_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Book_Option_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Book_Option_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Review_Book_Option_Var_Pop_Fields = {
  __typename?: 'review_book_option_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Review_Book_Option_Var_Samp_Fields = {
  __typename?: 'review_book_option_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Review_Book_Option_Variance_Fields = {
  __typename?: 'review_book_option_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Ordering options when selecting data from "review_book". */
export type Review_Book_Order_By = {
  created?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  review_book_option?: InputMaybe<Review_Book_Option_Order_By>;
  review_book_option_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review_book */
export type Review_Book_Pk_Columns_Input = {
  reading_id: Scalars['uuid'];
};

/** select columns of table "review_book" */
export enum Review_Book_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  ReviewBookOptionId = 'review_book_option_id',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "review_book" */
export type Review_Book_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  review_book_option_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "review_book" */
export type Review_Book_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Book_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Book_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  review_book_option_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
};

/** update columns of table "review_book" */
export enum Review_Book_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  ReviewBookOptionId = 'review_book_option_id',
  /** column name */
  Text = 'text'
}

export type Review_Book_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Book_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Book_Bool_Exp;
};

/** Boolean expression to filter rows from the table "review". All fields are combined with a logical 'AND'. */
export type Review_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Bool_Exp>>;
  _not?: InputMaybe<Review_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  personName?: InputMaybe<String_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "review" */
export enum Review_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewPk = 'review_pk'
}

/** input type for incrementing numeric columns in table "review" */
export type Review_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "review" */
export type Review_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Review_Max_Fields = {
  __typename?: 'review_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_review_person_name" */
  personName?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  reading_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "review" */
export type Review_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Min_Fields = {
  __typename?: 'review_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_review_person_name" */
  personName?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  reading_id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "review" */
export type Review_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "review" */
export type Review_Mutation_Response = {
  __typename?: 'review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Review>;
};

/** on_conflict condition type for table "review" */
export type Review_On_Conflict = {
  constraint: Review_Constraint;
  update_columns?: Array<Review_Update_Column>;
  where?: InputMaybe<Review_Bool_Exp>;
};

/** Ordering options when selecting data from "review". */
export type Review_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  personName?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review */
export type Review_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "review" */
export enum Review_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "review" */
export type Review_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Review_Stddev_Fields = {
  __typename?: 'review_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "review" */
export type Review_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Review_Stddev_Pop_Fields = {
  __typename?: 'review_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "review" */
export type Review_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Review_Stddev_Samp_Fields = {
  __typename?: 'review_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "review" */
export type Review_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "review" */
export type Review_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Review_Sum_Fields = {
  __typename?: 'review_sum_fields';
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "review" */
export type Review_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "review" */
export enum Review_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

export type Review_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Review_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Review_Var_Pop_Fields = {
  __typename?: 'review_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "review" */
export type Review_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Review_Var_Samp_Fields = {
  __typename?: 'review_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "review" */
export type Review_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Review_Variance_Fields = {
  __typename?: 'review_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "review" */
export type Review_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isdefault: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};


/** columns and relationships of "role" */
export type RoleUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Bool_Exp>>;
  _not?: InputMaybe<Role_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isdefault?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "name" */
  RoleAkName = 'role_ak_name',
  /** unique or primary key constraint on columns "id" */
  RolePk = 'role_pk'
}

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isdefault?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Isdefault = 'isdefault',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isdefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Isdefault = 'isdefault',
  /** column name */
  Name = 'name'
}

export type Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
};

/** columns and relationships of "scheduled_email_reminder" */
export type Scheduled_Email_Reminder = {
  __typename?: 'scheduled_email_reminder';
  created: Scalars['timestamp'];
  event_id: Scalars['uuid'];
  reading_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_Aggregate = {
  __typename?: 'scheduled_email_reminder_aggregate';
  aggregate?: Maybe<Scheduled_Email_Reminder_Aggregate_Fields>;
  nodes: Array<Scheduled_Email_Reminder>;
};

/** aggregate fields of "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_Aggregate_Fields = {
  __typename?: 'scheduled_email_reminder_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Scheduled_Email_Reminder_Max_Fields>;
  min?: Maybe<Scheduled_Email_Reminder_Min_Fields>;
};


/** aggregate fields of "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scheduled_Email_Reminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "scheduled_email_reminder". All fields are combined with a logical 'AND'. */
export type Scheduled_Email_Reminder_Bool_Exp = {
  _and?: InputMaybe<Array<Scheduled_Email_Reminder_Bool_Exp>>;
  _not?: InputMaybe<Scheduled_Email_Reminder_Bool_Exp>;
  _or?: InputMaybe<Array<Scheduled_Email_Reminder_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "scheduled_email_reminder" */
export enum Scheduled_Email_Reminder_Constraint {
  /** unique or primary key constraint on columns "reading_id" */
  ScheduledEmailReminderPkey = 'scheduled_email_reminder_pkey'
}

/** input type for inserting data into table "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Scheduled_Email_Reminder_Max_Fields = {
  __typename?: 'scheduled_email_reminder_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  event_id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Scheduled_Email_Reminder_Min_Fields = {
  __typename?: 'scheduled_email_reminder_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  event_id?: Maybe<Scalars['uuid']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_Mutation_Response = {
  __typename?: 'scheduled_email_reminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Scheduled_Email_Reminder>;
};

/** on_conflict condition type for table "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_On_Conflict = {
  constraint: Scheduled_Email_Reminder_Constraint;
  update_columns?: Array<Scheduled_Email_Reminder_Update_Column>;
  where?: InputMaybe<Scheduled_Email_Reminder_Bool_Exp>;
};

/** Ordering options when selecting data from "scheduled_email_reminder". */
export type Scheduled_Email_Reminder_Order_By = {
  created?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scheduled_email_reminder */
export type Scheduled_Email_Reminder_Pk_Columns_Input = {
  reading_id: Scalars['uuid'];
};

/** select columns of table "scheduled_email_reminder" */
export enum Scheduled_Email_Reminder_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  EventId = 'event_id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "scheduled_email_reminder" */
export type Scheduled_Email_Reminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scheduled_Email_Reminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scheduled_Email_Reminder_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "scheduled_email_reminder" */
export enum Scheduled_Email_Reminder_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  EventId = 'event_id',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

export type Scheduled_Email_Reminder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scheduled_Email_Reminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scheduled_Email_Reminder_Bool_Exp;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "book" */
  book: Array<Book>;
  /** fetch aggregated fields from the table: "book" */
  book_aggregate: Book_Aggregate;
  /** fetch data from the table: "book" using primary key columns */
  book_by_pk?: Maybe<Book>;
  /** execute function "book_get_number_of_readings" which returns "virtual_book_get_number_of_readings" */
  book_get_number_of_readings: Array<Virtual_Book_Get_Number_Of_Readings>;
  /** execute function "book_get_number_of_readings" and query aggregates on result of table type "virtual_book_get_number_of_readings" */
  book_get_number_of_readings_aggregate: Virtual_Book_Get_Number_Of_Readings_Aggregate;
  /** fetch data from the table in a streaming manner: "book" */
  book_stream: Array<Book>;
  /** fetch data from the table: "book_topic" */
  book_topic: Array<Book_Topic>;
  /** fetch aggregated fields from the table: "book_topic" */
  book_topic_aggregate: Book_Topic_Aggregate;
  /** fetch data from the table: "book_topic" using primary key columns */
  book_topic_by_pk?: Maybe<Book_Topic>;
  /** fetch data from the table in a streaming manner: "book_topic" */
  book_topic_stream: Array<Book_Topic>;
  /** fetch data from the table: "bookevent" */
  bookevent: Array<Bookevent>;
  /** fetch aggregated fields from the table: "bookevent" */
  bookevent_aggregate: Bookevent_Aggregate;
  /** fetch data from the table: "bookevent" using primary key columns */
  bookevent_by_pk?: Maybe<Bookevent>;
  /** fetch data from the table in a streaming manner: "bookevent" */
  bookevent_stream: Array<Bookevent>;
  /** fetch data from the table: "booklanguage" */
  booklanguage: Array<Booklanguage>;
  /** fetch aggregated fields from the table: "booklanguage" */
  booklanguage_aggregate: Booklanguage_Aggregate;
  /** fetch data from the table: "booklanguage" using primary key columns */
  booklanguage_by_pk?: Maybe<Booklanguage>;
  /** fetch data from the table in a streaming manner: "booklanguage" */
  booklanguage_stream: Array<Booklanguage>;
  /** fetch data from the table: "bookstate" */
  bookstate: Array<Bookstate>;
  /** fetch aggregated fields from the table: "bookstate" */
  bookstate_aggregate: Bookstate_Aggregate;
  /** fetch data from the table: "bookstate" using primary key columns */
  bookstate_by_pk?: Maybe<Bookstate>;
  /** fetch data from the table in a streaming manner: "bookstate" */
  bookstate_stream: Array<Bookstate>;
  /** fetch data from the table: "cancel_review" */
  cancel_review: Array<Cancel_Review>;
  /** fetch aggregated fields from the table: "cancel_review" */
  cancel_review_aggregate: Cancel_Review_Aggregate;
  /** fetch data from the table: "cancel_review" using primary key columns */
  cancel_review_by_pk?: Maybe<Cancel_Review>;
  /** fetch data from the table in a streaming manner: "cancel_review" */
  cancel_review_stream: Array<Cancel_Review>;
  /** fetch data from the table: "chat_message" */
  chat_message: Array<Chat_Message>;
  /** fetch aggregated fields from the table: "chat_message" */
  chat_message_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "chat_message" using primary key columns */
  chat_message_by_pk?: Maybe<Chat_Message>;
  /** fetch data from the table in a streaming manner: "chat_message" */
  chat_message_stream: Array<Chat_Message>;
  /** fetch data from the table: "chat_message_type" */
  chat_message_type: Array<Chat_Message_Type>;
  /** fetch aggregated fields from the table: "chat_message_type" */
  chat_message_type_aggregate: Chat_Message_Type_Aggregate;
  /** fetch data from the table: "chat_message_type" using primary key columns */
  chat_message_type_by_pk?: Maybe<Chat_Message_Type>;
  /** fetch data from the table in a streaming manner: "chat_message_type" */
  chat_message_type_stream: Array<Chat_Message_Type>;
  /** fetch data from the table: "chat_reading_message" */
  chat_reading_message: Array<Chat_Reading_Message>;
  /** fetch aggregated fields from the table: "chat_reading_message" */
  chat_reading_message_aggregate: Chat_Reading_Message_Aggregate;
  /** fetch data from the table: "chat_reading_message" using primary key columns */
  chat_reading_message_by_pk?: Maybe<Chat_Reading_Message>;
  /** fetch data from the table in a streaming manner: "chat_reading_message" */
  chat_reading_message_stream: Array<Chat_Reading_Message>;
  /** fetch data from the table: "chat_room" */
  chat_room: Array<Chat_Room>;
  /** fetch aggregated fields from the table: "chat_room" */
  chat_room_aggregate: Chat_Room_Aggregate;
  /** fetch data from the table: "chat_room" using primary key columns */
  chat_room_by_pk?: Maybe<Chat_Room>;
  /** fetch data from the table in a streaming manner: "chat_room" */
  chat_room_stream: Array<Chat_Room>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "debriefing" */
  debriefing: Array<Debriefing>;
  /** fetch aggregated fields from the table: "debriefing" */
  debriefing_aggregate: Debriefing_Aggregate;
  /** fetch data from the table: "debriefing" using primary key columns */
  debriefing_by_pk?: Maybe<Debriefing>;
  /** fetch data from the table in a streaming manner: "debriefing" */
  debriefing_stream: Array<Debriefing>;
  /** fetch data from the table: "duration" */
  duration: Array<Duration>;
  /** fetch aggregated fields from the table: "duration" */
  duration_aggregate: Duration_Aggregate;
  /** fetch data from the table: "duration" using primary key columns */
  duration_by_pk?: Maybe<Duration>;
  /** fetch data from the table in a streaming manner: "duration" */
  duration_stream: Array<Duration>;
  /** fetch data from the table: "email_activate_reminder" */
  email_activate_reminder: Array<Email_Activate_Reminder>;
  /** fetch aggregated fields from the table: "email_activate_reminder" */
  email_activate_reminder_aggregate: Email_Activate_Reminder_Aggregate;
  /** fetch data from the table: "email_activate_reminder" using primary key columns */
  email_activate_reminder_by_pk?: Maybe<Email_Activate_Reminder>;
  /** fetch data from the table in a streaming manner: "email_activate_reminder" */
  email_activate_reminder_stream: Array<Email_Activate_Reminder>;
  /** fetch data from the table: "email_chat_message_unread_reminder" */
  email_chat_message_unread_reminder: Array<Email_Chat_Message_Unread_Reminder>;
  /** fetch aggregated fields from the table: "email_chat_message_unread_reminder" */
  email_chat_message_unread_reminder_aggregate: Email_Chat_Message_Unread_Reminder_Aggregate;
  /** fetch data from the table: "email_chat_message_unread_reminder" using primary key columns */
  email_chat_message_unread_reminder_by_pk?: Maybe<Email_Chat_Message_Unread_Reminder>;
  /** fetch data from the table in a streaming manner: "email_chat_message_unread_reminder" */
  email_chat_message_unread_reminder_stream: Array<Email_Chat_Message_Unread_Reminder>;
  /** fetch data from the table: "email_library_card_expiration_reminder" */
  email_library_card_expiration_reminder: Array<Email_Library_Card_Expiration_Reminder>;
  /** fetch aggregated fields from the table: "email_library_card_expiration_reminder" */
  email_library_card_expiration_reminder_aggregate: Email_Library_Card_Expiration_Reminder_Aggregate;
  /** fetch data from the table: "email_library_card_expiration_reminder" using primary key columns */
  email_library_card_expiration_reminder_by_pk?: Maybe<Email_Library_Card_Expiration_Reminder>;
  /** fetch data from the table in a streaming manner: "email_library_card_expiration_reminder" */
  email_library_card_expiration_reminder_stream: Array<Email_Library_Card_Expiration_Reminder>;
  /** fetch data from the table: "email_library_card_expired_reminder" */
  email_library_card_expired_reminder: Array<Email_Library_Card_Expired_Reminder>;
  /** fetch aggregated fields from the table: "email_library_card_expired_reminder" */
  email_library_card_expired_reminder_aggregate: Email_Library_Card_Expired_Reminder_Aggregate;
  /** fetch data from the table: "email_library_card_expired_reminder" using primary key columns */
  email_library_card_expired_reminder_by_pk?: Maybe<Email_Library_Card_Expired_Reminder>;
  /** fetch data from the table in a streaming manner: "email_library_card_expired_reminder" */
  email_library_card_expired_reminder_stream: Array<Email_Library_Card_Expired_Reminder>;
  /** fetch data from the table: "eula" */
  eula: Array<Eula>;
  /** fetch data from the table: "eula_acceptance" */
  eula_acceptance: Array<Eula_Acceptance>;
  /** fetch aggregated fields from the table: "eula_acceptance" */
  eula_acceptance_aggregate: Eula_Acceptance_Aggregate;
  /** fetch data from the table: "eula_acceptance" using primary key columns */
  eula_acceptance_by_pk?: Maybe<Eula_Acceptance>;
  /** fetch data from the table in a streaming manner: "eula_acceptance" */
  eula_acceptance_stream: Array<Eula_Acceptance>;
  /** fetch aggregated fields from the table: "eula" */
  eula_aggregate: Eula_Aggregate;
  /** fetch data from the table: "eula" using primary key columns */
  eula_by_pk?: Maybe<Eula>;
  /** fetch data from the table in a streaming manner: "eula" */
  eula_stream: Array<Eula>;
  /** execute function "get_readings_per_week" which returns "readings_per_week" */
  get_readings_per_week: Array<Readings_Per_Week>;
  /** execute function "get_readings_per_week" and query aggregates on result of table type "readings_per_week" */
  get_readings_per_week_aggregate: Readings_Per_Week_Aggregate;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: Language_Aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** fetch data from the table in a streaming manner: "language" */
  language_stream: Array<Language>;
  /** fetch data from the table: "literary_quote" */
  literary_quote: Array<Literary_Quote>;
  /** fetch aggregated fields from the table: "literary_quote" */
  literary_quote_aggregate: Literary_Quote_Aggregate;
  /** fetch data from the table: "literary_quote" using primary key columns */
  literary_quote_by_pk?: Maybe<Literary_Quote>;
  /** fetch data from the table in a streaming manner: "literary_quote" */
  literary_quote_stream: Array<Literary_Quote>;
  /** fetch data from the table: "live_call_state" */
  live_call_state: Array<Live_Call_State>;
  /** fetch aggregated fields from the table: "live_call_state" */
  live_call_state_aggregate: Live_Call_State_Aggregate;
  /** fetch data from the table: "live_call_state" using primary key columns */
  live_call_state_by_pk?: Maybe<Live_Call_State>;
  /** fetch data from the table in a streaming manner: "live_call_state" */
  live_call_state_stream: Array<Live_Call_State>;
  /** fetch data from the table: "org" */
  org: Array<Org>;
  /** fetch aggregated fields from the table: "org" */
  org_aggregate: Org_Aggregate;
  /** fetch data from the table: "org" using primary key columns */
  org_by_pk?: Maybe<Org>;
  /** fetch data from the table in a streaming manner: "org" */
  org_stream: Array<Org>;
  /** fetch data from the table: "participationevent" */
  participationevent: Array<Participationevent>;
  /** fetch aggregated fields from the table: "participationevent" */
  participationevent_aggregate: Participationevent_Aggregate;
  /** fetch data from the table: "participationevent" using primary key columns */
  participationevent_by_pk?: Maybe<Participationevent>;
  /** fetch data from the table in a streaming manner: "participationevent" */
  participationevent_stream: Array<Participationevent>;
  /** fetch data from the table: "participationstate" */
  participationstate: Array<Participationstate>;
  /** fetch aggregated fields from the table: "participationstate" */
  participationstate_aggregate: Participationstate_Aggregate;
  /** fetch data from the table: "participationstate" using primary key columns */
  participationstate_by_pk?: Maybe<Participationstate>;
  /** fetch data from the table in a streaming manner: "participationstate" */
  participationstate_stream: Array<Participationstate>;
  /** fetch data from the table: "person" */
  person: Array<Person>;
  /** fetch aggregated fields from the table: "person" */
  person_aggregate: Person_Aggregate;
  /** fetch data from the table: "person" using primary key columns */
  person_by_pk?: Maybe<Person>;
  /** fetch data from the table in a streaming manner: "person" */
  person_stream: Array<Person>;
  /** fetch data from the table: "pillar" */
  pillar: Array<Pillar>;
  /** fetch aggregated fields from the table: "pillar" */
  pillar_aggregate: Pillar_Aggregate;
  /** fetch data from the table: "pillar" using primary key columns */
  pillar_by_pk?: Maybe<Pillar>;
  /** fetch data from the table in a streaming manner: "pillar" */
  pillar_stream: Array<Pillar>;
  /** fetch data from the table: "pillar_topic" */
  pillar_topic: Array<Pillar_Topic>;
  /** fetch aggregated fields from the table: "pillar_topic" */
  pillar_topic_aggregate: Pillar_Topic_Aggregate;
  /** fetch data from the table: "pillar_topic" using primary key columns */
  pillar_topic_by_pk?: Maybe<Pillar_Topic>;
  /** fetch data from the table in a streaming manner: "pillar_topic" */
  pillar_topic_stream: Array<Pillar_Topic>;
  /** fetch data from the table: "pillarconstraint" */
  pillarconstraint: Array<Pillarconstraint>;
  /** fetch aggregated fields from the table: "pillarconstraint" */
  pillarconstraint_aggregate: Pillarconstraint_Aggregate;
  /** fetch data from the table: "pillarconstraint" using primary key columns */
  pillarconstraint_by_pk?: Maybe<Pillarconstraint>;
  /** fetch data from the table in a streaming manner: "pillarconstraint" */
  pillarconstraint_stream: Array<Pillarconstraint>;
  /** fetch data from the table: "reading" */
  reading: Array<Reading>;
  /** fetch aggregated fields from the table: "reading" */
  reading_aggregate: Reading_Aggregate;
  /** fetch data from the table: "reading" using primary key columns */
  reading_by_pk?: Maybe<Reading>;
  /** fetch data from the table: "reading_setting" */
  reading_setting: Array<Reading_Setting>;
  /** fetch aggregated fields from the table: "reading_setting" */
  reading_setting_aggregate: Reading_Setting_Aggregate;
  /** fetch data from the table: "reading_setting" using primary key columns */
  reading_setting_by_pk?: Maybe<Reading_Setting>;
  /** fetch data from the table in a streaming manner: "reading_setting" */
  reading_setting_stream: Array<Reading_Setting>;
  /** fetch data from the table in a streaming manner: "reading" */
  reading_stream: Array<Reading>;
  /** fetch data from the table: "readingevent" */
  readingevent: Array<Readingevent>;
  /** fetch aggregated fields from the table: "readingevent" */
  readingevent_aggregate: Readingevent_Aggregate;
  /** fetch data from the table: "readingevent" using primary key columns */
  readingevent_by_pk?: Maybe<Readingevent>;
  /** fetch data from the table in a streaming manner: "readingevent" */
  readingevent_stream: Array<Readingevent>;
  /** fetch data from the table: "readingplan" */
  readingplan: Array<Readingplan>;
  /** fetch aggregated fields from the table: "readingplan" */
  readingplan_aggregate: Readingplan_Aggregate;
  /** fetch data from the table: "readingplan" using primary key columns */
  readingplan_by_pk?: Maybe<Readingplan>;
  /** fetch data from the table: "readingplan_duration" */
  readingplan_duration: Array<Readingplan_Duration>;
  /** fetch aggregated fields from the table: "readingplan_duration" */
  readingplan_duration_aggregate: Readingplan_Duration_Aggregate;
  /** fetch data from the table in a streaming manner: "readingplan_duration" */
  readingplan_duration_stream: Array<Readingplan_Duration>;
  /** fetch data from the table: "readingplan_duration_translation" */
  readingplan_duration_translation: Array<Readingplan_Duration_Translation>;
  /** fetch aggregated fields from the table: "readingplan_duration_translation" */
  readingplan_duration_translation_aggregate: Readingplan_Duration_Translation_Aggregate;
  /** fetch data from the table: "readingplan_duration_translation" using primary key columns */
  readingplan_duration_translation_by_pk?: Maybe<Readingplan_Duration_Translation>;
  /** fetch data from the table in a streaming manner: "readingplan_duration_translation" */
  readingplan_duration_translation_stream: Array<Readingplan_Duration_Translation>;
  /** fetch data from the table in a streaming manner: "readingplan" */
  readingplan_stream: Array<Readingplan>;
  /** execute function "readings_by_participationstate" which returns "reading" */
  readings_by_participationstate: Array<Reading>;
  /** execute function "readings_by_participationstate" and query aggregates on result of table type "reading" */
  readings_by_participationstate_aggregate: Reading_Aggregate;
  /** fetch data from the table: "readings_per_week" */
  readings_per_week: Array<Readings_Per_Week>;
  /** fetch aggregated fields from the table: "readings_per_week" */
  readings_per_week_aggregate: Readings_Per_Week_Aggregate;
  /** fetch data from the table in a streaming manner: "readings_per_week" */
  readings_per_week_stream: Array<Readings_Per_Week>;
  /** fetch data from the table: "readingstate" */
  readingstate: Array<Readingstate>;
  /** fetch aggregated fields from the table: "readingstate" */
  readingstate_aggregate: Readingstate_Aggregate;
  /** fetch data from the table: "readingstate" using primary key columns */
  readingstate_by_pk?: Maybe<Readingstate>;
  /** fetch data from the table in a streaming manner: "readingstate" */
  readingstate_stream: Array<Readingstate>;
  /** fetch data from the table: "review" */
  review: Array<Review>;
  /** fetch aggregated fields from the table: "review" */
  review_aggregate: Review_Aggregate;
  /** fetch data from the table: "review_book" */
  review_book: Array<Review_Book>;
  /** fetch aggregated fields from the table: "review_book" */
  review_book_aggregate: Review_Book_Aggregate;
  /** fetch data from the table: "review_book" using primary key columns */
  review_book_by_pk?: Maybe<Review_Book>;
  /** fetch data from the table: "review_book_option" */
  review_book_option: Array<Review_Book_Option>;
  /** fetch aggregated fields from the table: "review_book_option" */
  review_book_option_aggregate: Review_Book_Option_Aggregate;
  /** fetch data from the table: "review_book_option" using primary key columns */
  review_book_option_by_pk?: Maybe<Review_Book_Option>;
  /** fetch data from the table in a streaming manner: "review_book_option" */
  review_book_option_stream: Array<Review_Book_Option>;
  /** fetch data from the table in a streaming manner: "review_book" */
  review_book_stream: Array<Review_Book>;
  /** fetch data from the table: "review" using primary key columns */
  review_by_pk?: Maybe<Review>;
  /** fetch data from the table in a streaming manner: "review" */
  review_stream: Array<Review>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "scheduled_email_reminder" */
  scheduled_email_reminder: Array<Scheduled_Email_Reminder>;
  /** fetch aggregated fields from the table: "scheduled_email_reminder" */
  scheduled_email_reminder_aggregate: Scheduled_Email_Reminder_Aggregate;
  /** fetch data from the table: "scheduled_email_reminder" using primary key columns */
  scheduled_email_reminder_by_pk?: Maybe<Scheduled_Email_Reminder>;
  /** fetch data from the table in a streaming manner: "scheduled_email_reminder" */
  scheduled_email_reminder_stream: Array<Scheduled_Email_Reminder>;
  /** fetch data from the table: "timezone" */
  timezone: Array<Timezone>;
  /** fetch aggregated fields from the table: "timezone" */
  timezone_aggregate: Timezone_Aggregate;
  /** fetch data from the table: "timezone" using primary key columns */
  timezone_by_pk?: Maybe<Timezone>;
  /** fetch data from the table in a streaming manner: "timezone" */
  timezone_stream: Array<Timezone>;
  /** fetch data from the table: "topic" */
  topic: Array<Topic>;
  /** fetch aggregated fields from the table: "topic" */
  topic_aggregate: Topic_Aggregate;
  /** fetch data from the table: "topic" using primary key columns */
  topic_by_pk?: Maybe<Topic>;
  /** fetch data from the table in a streaming manner: "topic" */
  topic_stream: Array<Topic>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "view_chat_message_with_last_message" */
  view_chat_message_with_last_message: Array<View_Chat_Message_With_Last_Message>;
  /** fetch aggregated fields from the table: "view_chat_message_with_last_message" */
  view_chat_message_with_last_message_aggregate: View_Chat_Message_With_Last_Message_Aggregate;
  /** fetch data from the table in a streaming manner: "view_chat_message_with_last_message" */
  view_chat_message_with_last_message_stream: Array<View_Chat_Message_With_Last_Message>;
  /** fetch data from the table: "view_participationstates_with_user_info" */
  view_participationstates_with_user_info: Array<View_Participationstates_With_User_Info>;
  /** fetch aggregated fields from the table: "view_participationstates_with_user_info" */
  view_participationstates_with_user_info_aggregate: View_Participationstates_With_User_Info_Aggregate;
  /** fetch data from the table in a streaming manner: "view_participationstates_with_user_info" */
  view_participationstates_with_user_info_stream: Array<View_Participationstates_With_User_Info>;
  /** fetch data from the table: "view_reading_room_book" */
  view_reading_room_book: Array<View_Reading_Room_Book>;
  /** fetch aggregated fields from the table: "view_reading_room_book" */
  view_reading_room_book_aggregate: View_Reading_Room_Book_Aggregate;
  /** fetch data from the table in a streaming manner: "view_reading_room_book" */
  view_reading_room_book_stream: Array<View_Reading_Room_Book>;
  /** fetch data from the table: "view_reading_room_participants" */
  view_reading_room_participants: Array<View_Reading_Room_Participants>;
  /** fetch aggregated fields from the table: "view_reading_room_participants" */
  view_reading_room_participants_aggregate: View_Reading_Room_Participants_Aggregate;
  /** fetch data from the table in a streaming manner: "view_reading_room_participants" */
  view_reading_room_participants_stream: Array<View_Reading_Room_Participants>;
  /** fetch data from the table: "view_readings" */
  view_readings: Array<View_Readings>;
  /** fetch aggregated fields from the table: "view_readings" */
  view_readings_aggregate: View_Readings_Aggregate;
  /** fetch data from the table in a streaming manner: "view_readings" */
  view_readings_stream: Array<View_Readings>;
  /** fetch data from the table: "view_readings_with_book_info" */
  view_readings_with_book_info: Array<View_Readings_With_Book_Info>;
  /** fetch aggregated fields from the table: "view_readings_with_book_info" */
  view_readings_with_book_info_aggregate: View_Readings_With_Book_Info_Aggregate;
  /** fetch data from the table in a streaming manner: "view_readings_with_book_info" */
  view_readings_with_book_info_stream: Array<View_Readings_With_Book_Info>;
  /** fetch data from the table: "view_users_with_next_reading" */
  view_users_with_next_reading: Array<View_Users_With_Next_Reading>;
  /** fetch aggregated fields from the table: "view_users_with_next_reading" */
  view_users_with_next_reading_aggregate: View_Users_With_Next_Reading_Aggregate;
  /** fetch data from the table in a streaming manner: "view_users_with_next_reading" */
  view_users_with_next_reading_stream: Array<View_Users_With_Next_Reading>;
  /** fetch data from the table: "virtual_book_get_number_of_readings" */
  virtual_book_get_number_of_readings: Array<Virtual_Book_Get_Number_Of_Readings>;
  /** fetch aggregated fields from the table: "virtual_book_get_number_of_readings" */
  virtual_book_get_number_of_readings_aggregate: Virtual_Book_Get_Number_Of_Readings_Aggregate;
  /** fetch data from the table in a streaming manner: "virtual_book_get_number_of_readings" */
  virtual_book_get_number_of_readings_stream: Array<Virtual_Book_Get_Number_Of_Readings>;
};


export type Subscription_RootBookArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


export type Subscription_RootBook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


export type Subscription_RootBook_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBook_Get_Number_Of_ReadingsArgs = {
  args: Book_Get_Number_Of_Readings_Args;
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};


export type Subscription_RootBook_Get_Number_Of_Readings_AggregateArgs = {
  args: Book_Get_Number_Of_Readings_Args;
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};


export type Subscription_RootBook_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Book_Stream_Cursor_Input>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


export type Subscription_RootBook_TopicArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


export type Subscription_RootBook_Topic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


export type Subscription_RootBook_Topic_By_PkArgs = {
  book_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};


export type Subscription_RootBook_Topic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Book_Topic_Stream_Cursor_Input>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


export type Subscription_RootBookeventArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


export type Subscription_RootBookevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


export type Subscription_RootBookevent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBookevent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bookevent_Stream_Cursor_Input>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


export type Subscription_RootBooklanguageArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


export type Subscription_RootBooklanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Booklanguage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Booklanguage_Order_By>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


export type Subscription_RootBooklanguage_By_PkArgs = {
  book_id: Scalars['uuid'];
  language_id: Scalars['uuid'];
};


export type Subscription_RootBooklanguage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Booklanguage_Stream_Cursor_Input>>;
  where?: InputMaybe<Booklanguage_Bool_Exp>;
};


export type Subscription_RootBookstateArgs = {
  distinct_on?: InputMaybe<Array<Bookstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookstate_Order_By>>;
  where?: InputMaybe<Bookstate_Bool_Exp>;
};


export type Subscription_RootBookstate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookstate_Order_By>>;
  where?: InputMaybe<Bookstate_Bool_Exp>;
};


export type Subscription_RootBookstate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBookstate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bookstate_Stream_Cursor_Input>>;
  where?: InputMaybe<Bookstate_Bool_Exp>;
};


export type Subscription_RootCancel_ReviewArgs = {
  distinct_on?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cancel_Review_Order_By>>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};


export type Subscription_RootCancel_Review_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cancel_Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cancel_Review_Order_By>>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};


export type Subscription_RootCancel_Review_By_PkArgs = {
  reading_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootCancel_Review_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cancel_Review_Stream_Cursor_Input>>;
  where?: InputMaybe<Cancel_Review_Bool_Exp>;
};


export type Subscription_RootChat_MessageArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


export type Subscription_RootChat_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


export type Subscription_RootChat_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootChat_Message_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Message_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


export type Subscription_RootChat_Message_TypeArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Chat_Message_Type_Bool_Exp>;
};


export type Subscription_RootChat_Message_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Type_Order_By>>;
  where?: InputMaybe<Chat_Message_Type_Bool_Exp>;
};


export type Subscription_RootChat_Message_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootChat_Message_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Message_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Message_Type_Bool_Exp>;
};


export type Subscription_RootChat_Reading_MessageArgs = {
  distinct_on?: InputMaybe<Array<Chat_Reading_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Reading_Message_Order_By>>;
  where?: InputMaybe<Chat_Reading_Message_Bool_Exp>;
};


export type Subscription_RootChat_Reading_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Reading_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Reading_Message_Order_By>>;
  where?: InputMaybe<Chat_Reading_Message_Bool_Exp>;
};


export type Subscription_RootChat_Reading_Message_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootChat_Reading_Message_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Reading_Message_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Reading_Message_Bool_Exp>;
};


export type Subscription_RootChat_RoomArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};


export type Subscription_RootChat_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};


export type Subscription_RootChat_Room_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootChat_Room_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Room_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};


export type Subscription_RootCountryArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Order_By>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Bool_Exp>;
};


export type Subscription_RootDebriefingArgs = {
  distinct_on?: InputMaybe<Array<Debriefing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Debriefing_Order_By>>;
  where?: InputMaybe<Debriefing_Bool_Exp>;
};


export type Subscription_RootDebriefing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Debriefing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Debriefing_Order_By>>;
  where?: InputMaybe<Debriefing_Bool_Exp>;
};


export type Subscription_RootDebriefing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDebriefing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Debriefing_Stream_Cursor_Input>>;
  where?: InputMaybe<Debriefing_Bool_Exp>;
};


export type Subscription_RootDurationArgs = {
  distinct_on?: InputMaybe<Array<Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Duration_Order_By>>;
  where?: InputMaybe<Duration_Bool_Exp>;
};


export type Subscription_RootDuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Duration_Order_By>>;
  where?: InputMaybe<Duration_Bool_Exp>;
};


export type Subscription_RootDuration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDuration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Duration_Stream_Cursor_Input>>;
  where?: InputMaybe<Duration_Bool_Exp>;
};


export type Subscription_RootEmail_Activate_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Activate_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Activate_Reminder_Order_By>>;
  where?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Activate_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Activate_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Activate_Reminder_Order_By>>;
  where?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Activate_Reminder_By_PkArgs = {
  person_id: Scalars['uuid'];
};


export type Subscription_RootEmail_Activate_Reminder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Activate_Reminder_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Activate_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Chat_Message_Unread_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Order_By>>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Chat_Message_Unread_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Order_By>>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Chat_Message_Unread_Reminder_By_PkArgs = {
  chat_message_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootEmail_Chat_Message_Unread_Reminder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Chat_Message_Unread_Reminder_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Library_Card_Expiration_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Library_Card_Expiration_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expiration_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Library_Card_Expiration_Reminder_By_PkArgs = {
  readingplan_id: Scalars['uuid'];
};


export type Subscription_RootEmail_Library_Card_Expiration_Reminder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Library_Card_Expiration_Reminder_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Library_Card_Expiration_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Library_Card_Expired_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Library_Card_Expired_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Library_Card_Expired_Reminder_Order_By>>;
  where?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
};


export type Subscription_RootEmail_Library_Card_Expired_Reminder_By_PkArgs = {
  readingplan_id: Scalars['uuid'];
};


export type Subscription_RootEmail_Library_Card_Expired_Reminder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Library_Card_Expired_Reminder_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Library_Card_Expired_Reminder_Bool_Exp>;
};


export type Subscription_RootEulaArgs = {
  distinct_on?: InputMaybe<Array<Eula_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Order_By>>;
  where?: InputMaybe<Eula_Bool_Exp>;
};


export type Subscription_RootEula_AcceptanceArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


export type Subscription_RootEula_Acceptance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eula_Acceptance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Acceptance_Order_By>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


export type Subscription_RootEula_Acceptance_By_PkArgs = {
  eula_id: Scalars['uuid'];
  person_id: Scalars['uuid'];
};


export type Subscription_RootEula_Acceptance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eula_Acceptance_Stream_Cursor_Input>>;
  where?: InputMaybe<Eula_Acceptance_Bool_Exp>;
};


export type Subscription_RootEula_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Eula_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Eula_Order_By>>;
  where?: InputMaybe<Eula_Bool_Exp>;
};


export type Subscription_RootEula_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEula_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Eula_Stream_Cursor_Input>>;
  where?: InputMaybe<Eula_Bool_Exp>;
};


export type Subscription_RootGet_Readings_Per_WeekArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Subscription_RootGet_Readings_Per_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Subscription_RootLanguageArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Subscription_RootLanguage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Language_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Language_Order_By>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Subscription_RootLanguage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLanguage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Language_Stream_Cursor_Input>>;
  where?: InputMaybe<Language_Bool_Exp>;
};


export type Subscription_RootLiterary_QuoteArgs = {
  distinct_on?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Literary_Quote_Order_By>>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};


export type Subscription_RootLiterary_Quote_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Literary_Quote_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Literary_Quote_Order_By>>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};


export type Subscription_RootLiterary_Quote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLiterary_Quote_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Literary_Quote_Stream_Cursor_Input>>;
  where?: InputMaybe<Literary_Quote_Bool_Exp>;
};


export type Subscription_RootLive_Call_StateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_State_Order_By>>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};


export type Subscription_RootLive_Call_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Live_Call_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Live_Call_State_Order_By>>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};


export type Subscription_RootLive_Call_State_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootLive_Call_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Live_Call_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Call_State_Bool_Exp>;
};


export type Subscription_RootOrgArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


export type Subscription_RootOrg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


export type Subscription_RootOrg_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrg_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Org_Stream_Cursor_Input>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


export type Subscription_RootParticipationeventArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


export type Subscription_RootParticipationevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


export type Subscription_RootParticipationevent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootParticipationevent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Participationevent_Stream_Cursor_Input>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


export type Subscription_RootParticipationstateArgs = {
  distinct_on?: InputMaybe<Array<Participationstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationstate_Order_By>>;
  where?: InputMaybe<Participationstate_Bool_Exp>;
};


export type Subscription_RootParticipationstate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participationstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationstate_Order_By>>;
  where?: InputMaybe<Participationstate_Bool_Exp>;
};


export type Subscription_RootParticipationstate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootParticipationstate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Participationstate_Stream_Cursor_Input>>;
  where?: InputMaybe<Participationstate_Bool_Exp>;
};


export type Subscription_RootPersonArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Subscription_RootPerson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Person_Order_By>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Subscription_RootPerson_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPerson_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Person_Stream_Cursor_Input>>;
  where?: InputMaybe<Person_Bool_Exp>;
};


export type Subscription_RootPillarArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Order_By>>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};


export type Subscription_RootPillar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Order_By>>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};


export type Subscription_RootPillar_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPillar_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pillar_Stream_Cursor_Input>>;
  where?: InputMaybe<Pillar_Bool_Exp>;
};


export type Subscription_RootPillar_TopicArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


export type Subscription_RootPillar_Topic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


export type Subscription_RootPillar_Topic_By_PkArgs = {
  pillar_id: Scalars['uuid'];
  topic_id: Scalars['uuid'];
};


export type Subscription_RootPillar_Topic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pillar_Topic_Stream_Cursor_Input>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


export type Subscription_RootPillarconstraintArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};


export type Subscription_RootPillarconstraint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillarconstraint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillarconstraint_Order_By>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};


export type Subscription_RootPillarconstraint_By_PkArgs = {
  pillarofprejudice_id: Scalars['uuid'];
  readingplan_id: Scalars['uuid'];
};


export type Subscription_RootPillarconstraint_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pillarconstraint_Stream_Cursor_Input>>;
  where?: InputMaybe<Pillarconstraint_Bool_Exp>;
};


export type Subscription_RootReadingArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Subscription_RootReading_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Subscription_RootReading_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReading_SettingArgs = {
  distinct_on?: InputMaybe<Array<Reading_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Setting_Order_By>>;
  where?: InputMaybe<Reading_Setting_Bool_Exp>;
};


export type Subscription_RootReading_Setting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Setting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Setting_Order_By>>;
  where?: InputMaybe<Reading_Setting_Bool_Exp>;
};


export type Subscription_RootReading_Setting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReading_Setting_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reading_Setting_Stream_Cursor_Input>>;
  where?: InputMaybe<Reading_Setting_Bool_Exp>;
};


export type Subscription_RootReading_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reading_Stream_Cursor_Input>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Subscription_RootReadingeventArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


export type Subscription_RootReadingevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


export type Subscription_RootReadingevent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReadingevent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Readingevent_Stream_Cursor_Input>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


export type Subscription_RootReadingplanArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Order_By>>;
  where?: InputMaybe<Readingplan_Bool_Exp>;
};


export type Subscription_RootReadingplan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Order_By>>;
  where?: InputMaybe<Readingplan_Bool_Exp>;
};


export type Subscription_RootReadingplan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReadingplan_DurationArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Bool_Exp>;
};


export type Subscription_RootReadingplan_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Bool_Exp>;
};


export type Subscription_RootReadingplan_Duration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Readingplan_Duration_Stream_Cursor_Input>>;
  where?: InputMaybe<Readingplan_Duration_Bool_Exp>;
};


export type Subscription_RootReadingplan_Duration_TranslationArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Translation_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};


export type Subscription_RootReadingplan_Duration_Translation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingplan_Duration_Translation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingplan_Duration_Translation_Order_By>>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};


export type Subscription_RootReadingplan_Duration_Translation_By_PkArgs = {
  language_id: Scalars['uuid'];
  readingplan_duration_id: Scalars['uuid'];
};


export type Subscription_RootReadingplan_Duration_Translation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Readingplan_Duration_Translation_Stream_Cursor_Input>>;
  where?: InputMaybe<Readingplan_Duration_Translation_Bool_Exp>;
};


export type Subscription_RootReadingplan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Readingplan_Stream_Cursor_Input>>;
  where?: InputMaybe<Readingplan_Bool_Exp>;
};


export type Subscription_RootReadings_By_ParticipationstateArgs = {
  args: Readings_By_Participationstate_Args;
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Subscription_RootReadings_By_Participationstate_AggregateArgs = {
  args: Readings_By_Participationstate_Args;
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


export type Subscription_RootReadings_Per_WeekArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Subscription_RootReadings_Per_Week_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readings_Per_Week_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readings_Per_Week_Order_By>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Subscription_RootReadings_Per_Week_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Readings_Per_Week_Stream_Cursor_Input>>;
  where?: InputMaybe<Readings_Per_Week_Bool_Exp>;
};


export type Subscription_RootReadingstateArgs = {
  distinct_on?: InputMaybe<Array<Readingstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingstate_Order_By>>;
  where?: InputMaybe<Readingstate_Bool_Exp>;
};


export type Subscription_RootReadingstate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingstate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingstate_Order_By>>;
  where?: InputMaybe<Readingstate_Bool_Exp>;
};


export type Subscription_RootReadingstate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReadingstate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Readingstate_Stream_Cursor_Input>>;
  where?: InputMaybe<Readingstate_Bool_Exp>;
};


export type Subscription_RootReviewArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};


export type Subscription_RootReview_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};


export type Subscription_RootReview_BookArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Order_By>>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};


export type Subscription_RootReview_Book_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Order_By>>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};


export type Subscription_RootReview_Book_By_PkArgs = {
  reading_id: Scalars['uuid'];
};


export type Subscription_RootReview_Book_OptionArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Option_Order_By>>;
  where?: InputMaybe<Review_Book_Option_Bool_Exp>;
};


export type Subscription_RootReview_Book_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Book_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Book_Option_Order_By>>;
  where?: InputMaybe<Review_Book_Option_Bool_Exp>;
};


export type Subscription_RootReview_Book_Option_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReview_Book_Option_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Review_Book_Option_Stream_Cursor_Input>>;
  where?: InputMaybe<Review_Book_Option_Bool_Exp>;
};


export type Subscription_RootReview_Book_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Review_Book_Stream_Cursor_Input>>;
  where?: InputMaybe<Review_Book_Bool_Exp>;
};


export type Subscription_RootReview_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReview_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Review_Stream_Cursor_Input>>;
  where?: InputMaybe<Review_Bool_Exp>;
};


export type Subscription_RootRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type Subscription_RootScheduled_Email_ReminderArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Email_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Email_Reminder_Order_By>>;
  where?: InputMaybe<Scheduled_Email_Reminder_Bool_Exp>;
};


export type Subscription_RootScheduled_Email_Reminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scheduled_Email_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scheduled_Email_Reminder_Order_By>>;
  where?: InputMaybe<Scheduled_Email_Reminder_Bool_Exp>;
};


export type Subscription_RootScheduled_Email_Reminder_By_PkArgs = {
  reading_id: Scalars['uuid'];
};


export type Subscription_RootScheduled_Email_Reminder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Scheduled_Email_Reminder_Stream_Cursor_Input>>;
  where?: InputMaybe<Scheduled_Email_Reminder_Bool_Exp>;
};


export type Subscription_RootTimezoneArgs = {
  distinct_on?: InputMaybe<Array<Timezone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Timezone_Order_By>>;
  where?: InputMaybe<Timezone_Bool_Exp>;
};


export type Subscription_RootTimezone_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Timezone_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Timezone_Order_By>>;
  where?: InputMaybe<Timezone_Bool_Exp>;
};


export type Subscription_RootTimezone_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTimezone_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Timezone_Stream_Cursor_Input>>;
  where?: InputMaybe<Timezone_Bool_Exp>;
};


export type Subscription_RootTopicArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Subscription_RootTopic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Topic_Order_By>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Subscription_RootTopic_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTopic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Topic_Stream_Cursor_Input>>;
  where?: InputMaybe<Topic_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootView_Chat_Message_With_Last_MessageArgs = {
  distinct_on?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Order_By>>;
  where?: InputMaybe<View_Chat_Message_With_Last_Message_Bool_Exp>;
};


export type Subscription_RootView_Chat_Message_With_Last_Message_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Order_By>>;
  where?: InputMaybe<View_Chat_Message_With_Last_Message_Bool_Exp>;
};


export type Subscription_RootView_Chat_Message_With_Last_Message_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<View_Chat_Message_With_Last_Message_Stream_Cursor_Input>>;
  where?: InputMaybe<View_Chat_Message_With_Last_Message_Bool_Exp>;
};


export type Subscription_RootView_Participationstates_With_User_InfoArgs = {
  distinct_on?: InputMaybe<Array<View_Participationstates_With_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Participationstates_With_User_Info_Order_By>>;
  where?: InputMaybe<View_Participationstates_With_User_Info_Bool_Exp>;
};


export type Subscription_RootView_Participationstates_With_User_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Participationstates_With_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Participationstates_With_User_Info_Order_By>>;
  where?: InputMaybe<View_Participationstates_With_User_Info_Bool_Exp>;
};


export type Subscription_RootView_Participationstates_With_User_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<View_Participationstates_With_User_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<View_Participationstates_With_User_Info_Bool_Exp>;
};


export type Subscription_RootView_Reading_Room_BookArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Book_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Book_Bool_Exp>;
};


export type Subscription_RootView_Reading_Room_Book_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Book_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Book_Bool_Exp>;
};


export type Subscription_RootView_Reading_Room_Book_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<View_Reading_Room_Book_Stream_Cursor_Input>>;
  where?: InputMaybe<View_Reading_Room_Book_Bool_Exp>;
};


export type Subscription_RootView_Reading_Room_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Participants_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Participants_Bool_Exp>;
};


export type Subscription_RootView_Reading_Room_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Reading_Room_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Reading_Room_Participants_Order_By>>;
  where?: InputMaybe<View_Reading_Room_Participants_Bool_Exp>;
};


export type Subscription_RootView_Reading_Room_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<View_Reading_Room_Participants_Stream_Cursor_Input>>;
  where?: InputMaybe<View_Reading_Room_Participants_Bool_Exp>;
};


export type Subscription_RootView_ReadingsArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_Order_By>>;
  where?: InputMaybe<View_Readings_Bool_Exp>;
};


export type Subscription_RootView_Readings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_Order_By>>;
  where?: InputMaybe<View_Readings_Bool_Exp>;
};


export type Subscription_RootView_Readings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<View_Readings_Stream_Cursor_Input>>;
  where?: InputMaybe<View_Readings_Bool_Exp>;
};


export type Subscription_RootView_Readings_With_Book_InfoArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_With_Book_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_With_Book_Info_Order_By>>;
  where?: InputMaybe<View_Readings_With_Book_Info_Bool_Exp>;
};


export type Subscription_RootView_Readings_With_Book_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Readings_With_Book_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Readings_With_Book_Info_Order_By>>;
  where?: InputMaybe<View_Readings_With_Book_Info_Bool_Exp>;
};


export type Subscription_RootView_Readings_With_Book_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<View_Readings_With_Book_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<View_Readings_With_Book_Info_Bool_Exp>;
};


export type Subscription_RootView_Users_With_Next_ReadingArgs = {
  distinct_on?: InputMaybe<Array<View_Users_With_Next_Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Users_With_Next_Reading_Order_By>>;
  where?: InputMaybe<View_Users_With_Next_Reading_Bool_Exp>;
};


export type Subscription_RootView_Users_With_Next_Reading_AggregateArgs = {
  distinct_on?: InputMaybe<Array<View_Users_With_Next_Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<View_Users_With_Next_Reading_Order_By>>;
  where?: InputMaybe<View_Users_With_Next_Reading_Bool_Exp>;
};


export type Subscription_RootView_Users_With_Next_Reading_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<View_Users_With_Next_Reading_Stream_Cursor_Input>>;
  where?: InputMaybe<View_Users_With_Next_Reading_Bool_Exp>;
};


export type Subscription_RootVirtual_Book_Get_Number_Of_ReadingsArgs = {
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};


export type Subscription_RootVirtual_Book_Get_Number_Of_Readings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Order_By>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};


export type Subscription_RootVirtual_Book_Get_Number_Of_Readings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Virtual_Book_Get_Number_Of_Readings_Stream_Cursor_Input>>;
  where?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "timezone" */
export type Timezone = {
  __typename?: 'timezone';
  abbr: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  offset: Scalars['smallint'];
  value: Scalars['String'];
};

/** aggregated selection of "timezone" */
export type Timezone_Aggregate = {
  __typename?: 'timezone_aggregate';
  aggregate?: Maybe<Timezone_Aggregate_Fields>;
  nodes: Array<Timezone>;
};

/** aggregate fields of "timezone" */
export type Timezone_Aggregate_Fields = {
  __typename?: 'timezone_aggregate_fields';
  avg?: Maybe<Timezone_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Timezone_Max_Fields>;
  min?: Maybe<Timezone_Min_Fields>;
  stddev?: Maybe<Timezone_Stddev_Fields>;
  stddev_pop?: Maybe<Timezone_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Timezone_Stddev_Samp_Fields>;
  sum?: Maybe<Timezone_Sum_Fields>;
  var_pop?: Maybe<Timezone_Var_Pop_Fields>;
  var_samp?: Maybe<Timezone_Var_Samp_Fields>;
  variance?: Maybe<Timezone_Variance_Fields>;
};


/** aggregate fields of "timezone" */
export type Timezone_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Timezone_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Timezone_Avg_Fields = {
  __typename?: 'timezone_avg_fields';
  offset?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "timezone". All fields are combined with a logical 'AND'. */
export type Timezone_Bool_Exp = {
  _and?: InputMaybe<Array<Timezone_Bool_Exp>>;
  _not?: InputMaybe<Timezone_Bool_Exp>;
  _or?: InputMaybe<Array<Timezone_Bool_Exp>>;
  abbr?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  offset?: InputMaybe<Smallint_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "timezone" */
export enum Timezone_Constraint {
  /** unique or primary key constraint on columns "id" */
  TimezonesPkey = 'timezones_pkey'
}

/** input type for incrementing numeric columns in table "timezone" */
export type Timezone_Inc_Input = {
  offset?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "timezone" */
export type Timezone_Insert_Input = {
  abbr?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['smallint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Timezone_Max_Fields = {
  __typename?: 'timezone_max_fields';
  abbr?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['smallint']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Timezone_Min_Fields = {
  __typename?: 'timezone_min_fields';
  abbr?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['smallint']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "timezone" */
export type Timezone_Mutation_Response = {
  __typename?: 'timezone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Timezone>;
};

/** on_conflict condition type for table "timezone" */
export type Timezone_On_Conflict = {
  constraint: Timezone_Constraint;
  update_columns?: Array<Timezone_Update_Column>;
  where?: InputMaybe<Timezone_Bool_Exp>;
};

/** Ordering options when selecting data from "timezone". */
export type Timezone_Order_By = {
  abbr?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: timezone */
export type Timezone_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "timezone" */
export enum Timezone_Select_Column {
  /** column name */
  Abbr = 'abbr',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Offset = 'offset',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "timezone" */
export type Timezone_Set_Input = {
  abbr?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['smallint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Timezone_Stddev_Fields = {
  __typename?: 'timezone_stddev_fields';
  offset?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Timezone_Stddev_Pop_Fields = {
  __typename?: 'timezone_stddev_pop_fields';
  offset?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Timezone_Stddev_Samp_Fields = {
  __typename?: 'timezone_stddev_samp_fields';
  offset?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "timezone" */
export type Timezone_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Timezone_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Timezone_Stream_Cursor_Value_Input = {
  abbr?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['smallint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Timezone_Sum_Fields = {
  __typename?: 'timezone_sum_fields';
  offset?: Maybe<Scalars['smallint']>;
};

/** update columns of table "timezone" */
export enum Timezone_Update_Column {
  /** column name */
  Abbr = 'abbr',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Offset = 'offset',
  /** column name */
  Value = 'value'
}

export type Timezone_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Timezone_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Timezone_Set_Input>;
  /** filter the rows which have to be updated */
  where: Timezone_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Timezone_Var_Pop_Fields = {
  __typename?: 'timezone_var_pop_fields';
  offset?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Timezone_Var_Samp_Fields = {
  __typename?: 'timezone_var_samp_fields';
  offset?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Timezone_Variance_Fields = {
  __typename?: 'timezone_variance_fields';
  offset?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "topic" */
export type Topic = {
  __typename?: 'topic';
  /** An array relationship */
  book_topics: Array<Book_Topic>;
  /** An aggregate relationship */
  book_topics_aggregate: Book_Topic_Aggregate;
  created: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  phonetic?: Maybe<Scalars['String']>;
  /** An array relationship */
  pillar_topics: Array<Pillar_Topic>;
  /** An aggregate relationship */
  pillar_topics_aggregate: Pillar_Topic_Aggregate;
  quote?: Maybe<Scalars['String']>;
  /** An array relationship */
  readings: Array<Reading>;
  /** An aggregate relationship */
  readings_aggregate: Reading_Aggregate;
  short_description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


/** columns and relationships of "topic" */
export type TopicBook_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicBook_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Topic_Order_By>>;
  where?: InputMaybe<Book_Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicPillar_TopicsArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicPillar_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pillar_Topic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pillar_Topic_Order_By>>;
  where?: InputMaybe<Pillar_Topic_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicReadingsArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};


/** columns and relationships of "topic" */
export type TopicReadings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reading_Order_By>>;
  where?: InputMaybe<Reading_Bool_Exp>;
};

/** aggregated selection of "topic" */
export type Topic_Aggregate = {
  __typename?: 'topic_aggregate';
  aggregate?: Maybe<Topic_Aggregate_Fields>;
  nodes: Array<Topic>;
};

/** aggregate fields of "topic" */
export type Topic_Aggregate_Fields = {
  __typename?: 'topic_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Topic_Max_Fields>;
  min?: Maybe<Topic_Min_Fields>;
};


/** aggregate fields of "topic" */
export type Topic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Topic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "topic". All fields are combined with a logical 'AND'. */
export type Topic_Bool_Exp = {
  _and?: InputMaybe<Array<Topic_Bool_Exp>>;
  _not?: InputMaybe<Topic_Bool_Exp>;
  _or?: InputMaybe<Array<Topic_Bool_Exp>>;
  book_topics?: InputMaybe<Book_Topic_Bool_Exp>;
  book_topics_aggregate?: InputMaybe<Book_Topic_Aggregate_Bool_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phonetic?: InputMaybe<String_Comparison_Exp>;
  pillar_topics?: InputMaybe<Pillar_Topic_Bool_Exp>;
  pillar_topics_aggregate?: InputMaybe<Pillar_Topic_Aggregate_Bool_Exp>;
  quote?: InputMaybe<String_Comparison_Exp>;
  readings?: InputMaybe<Reading_Bool_Exp>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Bool_Exp>;
  short_description?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "topic" */
export enum Topic_Constraint {
  /** unique or primary key constraint on columns "name" */
  TopicAkName = 'topic_ak_name',
  /** unique or primary key constraint on columns "id" */
  TopicPk = 'topic_pk',
  /** unique or primary key constraint on columns "slug" */
  TopicSlugKey = 'topic_slug_key'
}

/** input type for inserting data into table "topic" */
export type Topic_Insert_Input = {
  book_topics?: InputMaybe<Book_Topic_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phonetic?: InputMaybe<Scalars['String']>;
  pillar_topics?: InputMaybe<Pillar_Topic_Arr_Rel_Insert_Input>;
  quote?: InputMaybe<Scalars['String']>;
  readings?: InputMaybe<Reading_Arr_Rel_Insert_Input>;
  short_description?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Topic_Max_Fields = {
  __typename?: 'topic_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phonetic?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Topic_Min_Fields = {
  __typename?: 'topic_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phonetic?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "topic" */
export type Topic_Mutation_Response = {
  __typename?: 'topic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Topic>;
};

/** input type for inserting object relation for remote table "topic" */
export type Topic_Obj_Rel_Insert_Input = {
  data: Topic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Topic_On_Conflict>;
};

/** on_conflict condition type for table "topic" */
export type Topic_On_Conflict = {
  constraint: Topic_Constraint;
  update_columns?: Array<Topic_Update_Column>;
  where?: InputMaybe<Topic_Bool_Exp>;
};

/** Ordering options when selecting data from "topic". */
export type Topic_Order_By = {
  book_topics_aggregate?: InputMaybe<Book_Topic_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phonetic?: InputMaybe<Order_By>;
  pillar_topics_aggregate?: InputMaybe<Pillar_Topic_Aggregate_Order_By>;
  quote?: InputMaybe<Order_By>;
  readings_aggregate?: InputMaybe<Reading_Aggregate_Order_By>;
  short_description?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: topic */
export type Topic_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "topic" */
export enum Topic_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phonetic = 'phonetic',
  /** column name */
  Quote = 'quote',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "topic" */
export type Topic_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phonetic?: InputMaybe<Scalars['String']>;
  quote?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "topic" */
export type Topic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Topic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Topic_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phonetic?: InputMaybe<Scalars['String']>;
  quote?: InputMaybe<Scalars['String']>;
  short_description?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** update columns of table "topic" */
export enum Topic_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phonetic = 'phonetic',
  /** column name */
  Quote = 'quote',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  Slug = 'slug'
}

export type Topic_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Topic_Set_Input>;
  /** filter the rows which have to be updated */
  where: Topic_Bool_Exp;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  allowedOrgs?: Maybe<Array<Org>>;
  /** An array relationship */
  bookevents: Array<Bookevent>;
  /** An aggregate relationship */
  bookevents_aggregate: Bookevent_Aggregate;
  /** An array relationship */
  books: Array<Book>;
  /** An aggregate relationship */
  books_aggregate: Book_Aggregate;
  /** An array relationship */
  chatMessages: Array<Chat_Message>;
  /** An aggregate relationship */
  chatMessages_aggregate: Chat_Message_Aggregate;
  /** An array relationship */
  chat_messages: Array<Chat_Message>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Message_Aggregate;
  /** An object relationship */
  chat_room?: Maybe<Chat_Room>;
  /** An array relationship */
  chat_rooms: Array<Chat_Room>;
  /** An aggregate relationship */
  chat_rooms_aggregate: Chat_Room_Aggregate;
  created: Scalars['timestamp'];
  /** An array relationship */
  emailChatMessageUnreadReminders: Array<Email_Chat_Message_Unread_Reminder>;
  /** An aggregate relationship */
  emailChatMessageUnreadReminders_aggregate: Email_Chat_Message_Unread_Reminder_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  live_call_state?: Maybe<Live_Call_State>;
  /** An object relationship */
  org: Org;
  org_id: Scalars['uuid'];
  /** An array relationship */
  participationevents: Array<Participationevent>;
  /** An aggregate relationship */
  participationevents_aggregate: Participationevent_Aggregate;
  /** An object relationship */
  person: Person;
  /** A computed field, executes function "get_user_person_name" */
  personName?: Maybe<Scalars['String']>;
  person_id: Scalars['uuid'];
  /** An array relationship */
  readingevents: Array<Readingevent>;
  /** An aggregate relationship */
  readingevents_aggregate: Readingevent_Aggregate;
  /** An object relationship */
  readingplan?: Maybe<Readingplan>;
  /** An array relationship */
  reviews: Array<Review>;
  /** An aggregate relationship */
  reviews_aggregate: Review_Aggregate;
  /** An object relationship */
  role: Role;
  role_id: Scalars['uuid'];
};


/** columns and relationships of "user" */
export type UserAllowedOrgsArgs = {
  distinct_on?: InputMaybe<Array<Org_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Org_Order_By>>;
  where?: InputMaybe<Org_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBookeventsArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBookevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bookevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bookevent_Order_By>>;
  where?: InputMaybe<Bookevent_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBooksArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserBooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Book_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Book_Order_By>>;
  where?: InputMaybe<Book_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserChatMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Message_Order_By>>;
  where?: InputMaybe<Chat_Message_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserChat_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserChat_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Room_Order_By>>;
  where?: InputMaybe<Chat_Room_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEmailChatMessageUnreadRemindersArgs = {
  distinct_on?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Order_By>>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEmailChatMessageUnreadReminders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Chat_Message_Unread_Reminder_Order_By>>;
  where?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserParticipationeventsArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserParticipationevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Participationevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Participationevent_Order_By>>;
  where?: InputMaybe<Participationevent_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReadingeventsArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReadingevents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Readingevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Readingevent_Order_By>>;
  where?: InputMaybe<Readingevent_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReviewsArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Order_By>>;
  where?: InputMaybe<Review_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  allowedOrgs?: InputMaybe<Org_Bool_Exp>;
  bookevents?: InputMaybe<Bookevent_Bool_Exp>;
  bookevents_aggregate?: InputMaybe<Bookevent_Aggregate_Bool_Exp>;
  books?: InputMaybe<Book_Bool_Exp>;
  books_aggregate?: InputMaybe<Book_Aggregate_Bool_Exp>;
  chatMessages?: InputMaybe<Chat_Message_Bool_Exp>;
  chatMessages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  chat_messages?: InputMaybe<Chat_Message_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Bool_Exp>;
  chat_room?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_rooms?: InputMaybe<Chat_Room_Bool_Exp>;
  chat_rooms_aggregate?: InputMaybe<Chat_Room_Aggregate_Bool_Exp>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  emailChatMessageUnreadReminders?: InputMaybe<Email_Chat_Message_Unread_Reminder_Bool_Exp>;
  emailChatMessageUnreadReminders_aggregate?: InputMaybe<Email_Chat_Message_Unread_Reminder_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  live_call_state?: InputMaybe<Live_Call_State_Bool_Exp>;
  org?: InputMaybe<Org_Bool_Exp>;
  org_id?: InputMaybe<Uuid_Comparison_Exp>;
  participationevents?: InputMaybe<Participationevent_Bool_Exp>;
  participationevents_aggregate?: InputMaybe<Participationevent_Aggregate_Bool_Exp>;
  person?: InputMaybe<Person_Bool_Exp>;
  personName?: InputMaybe<String_Comparison_Exp>;
  person_id?: InputMaybe<Uuid_Comparison_Exp>;
  readingevents?: InputMaybe<Readingevent_Bool_Exp>;
  readingevents_aggregate?: InputMaybe<Readingevent_Aggregate_Bool_Exp>;
  readingplan?: InputMaybe<Readingplan_Bool_Exp>;
  reviews?: InputMaybe<Review_Bool_Exp>;
  reviews_aggregate?: InputMaybe<Review_Aggregate_Bool_Exp>;
  role?: InputMaybe<Role_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPk = 'user_pk'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  bookevents?: InputMaybe<Bookevent_Arr_Rel_Insert_Input>;
  books?: InputMaybe<Book_Arr_Rel_Insert_Input>;
  chatMessages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Chat_Message_Arr_Rel_Insert_Input>;
  chat_room?: InputMaybe<Chat_Room_Obj_Rel_Insert_Input>;
  chat_rooms?: InputMaybe<Chat_Room_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamp']>;
  emailChatMessageUnreadReminders?: InputMaybe<Email_Chat_Message_Unread_Reminder_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  live_call_state?: InputMaybe<Live_Call_State_Obj_Rel_Insert_Input>;
  org?: InputMaybe<Org_Obj_Rel_Insert_Input>;
  org_id?: InputMaybe<Scalars['uuid']>;
  participationevents?: InputMaybe<Participationevent_Arr_Rel_Insert_Input>;
  person?: InputMaybe<Person_Obj_Rel_Insert_Input>;
  person_id?: InputMaybe<Scalars['uuid']>;
  readingevents?: InputMaybe<Readingevent_Arr_Rel_Insert_Input>;
  readingplan?: InputMaybe<Readingplan_Obj_Rel_Insert_Input>;
  reviews?: InputMaybe<Review_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_user_person_name" */
  personName?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  org_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_user_person_name" */
  personName?: Maybe<Scalars['String']>;
  person_id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  org_id?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  allowedOrgs_aggregate?: InputMaybe<Org_Aggregate_Order_By>;
  bookevents_aggregate?: InputMaybe<Bookevent_Aggregate_Order_By>;
  books_aggregate?: InputMaybe<Book_Aggregate_Order_By>;
  chatMessages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Message_Aggregate_Order_By>;
  chat_room?: InputMaybe<Chat_Room_Order_By>;
  chat_rooms_aggregate?: InputMaybe<Chat_Room_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  emailChatMessageUnreadReminders_aggregate?: InputMaybe<Email_Chat_Message_Unread_Reminder_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  live_call_state?: InputMaybe<Live_Call_State_Order_By>;
  org?: InputMaybe<Org_Order_By>;
  org_id?: InputMaybe<Order_By>;
  participationevents_aggregate?: InputMaybe<Participationevent_Aggregate_Order_By>;
  person?: InputMaybe<Person_Order_By>;
  personName?: InputMaybe<Order_By>;
  person_id?: InputMaybe<Order_By>;
  readingevents_aggregate?: InputMaybe<Readingevent_Aggregate_Order_By>;
  readingplan?: InputMaybe<Readingplan_Order_By>;
  reviews_aggregate?: InputMaybe<Review_Aggregate_Order_By>;
  role?: InputMaybe<Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  person_id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  org_id?: InputMaybe<Scalars['uuid']>;
  person_id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  OrgId = 'org_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  RoleId = 'role_id'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "view_chat_message_with_last_message" */
export type View_Chat_Message_With_Last_Message = {
  __typename?: 'view_chat_message_with_last_message';
  /** An object relationship */
  chatMessage?: Maybe<Chat_Message>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  lastChatMessage?: Maybe<Chat_Message>;
  last_chat_message_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_chat_message_with_last_message" */
export type View_Chat_Message_With_Last_Message_Aggregate = {
  __typename?: 'view_chat_message_with_last_message_aggregate';
  aggregate?: Maybe<View_Chat_Message_With_Last_Message_Aggregate_Fields>;
  nodes: Array<View_Chat_Message_With_Last_Message>;
};

/** aggregate fields of "view_chat_message_with_last_message" */
export type View_Chat_Message_With_Last_Message_Aggregate_Fields = {
  __typename?: 'view_chat_message_with_last_message_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<View_Chat_Message_With_Last_Message_Max_Fields>;
  min?: Maybe<View_Chat_Message_With_Last_Message_Min_Fields>;
};


/** aggregate fields of "view_chat_message_with_last_message" */
export type View_Chat_Message_With_Last_Message_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "view_chat_message_with_last_message". All fields are combined with a logical 'AND'. */
export type View_Chat_Message_With_Last_Message_Bool_Exp = {
  _and?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Bool_Exp>>;
  _not?: InputMaybe<View_Chat_Message_With_Last_Message_Bool_Exp>;
  _or?: InputMaybe<Array<View_Chat_Message_With_Last_Message_Bool_Exp>>;
  chatMessage?: InputMaybe<Chat_Message_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastChatMessage?: InputMaybe<Chat_Message_Bool_Exp>;
  last_chat_message_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View_Chat_Message_With_Last_Message_Max_Fields = {
  __typename?: 'view_chat_message_with_last_message_max_fields';
  id?: Maybe<Scalars['uuid']>;
  last_chat_message_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Chat_Message_With_Last_Message_Min_Fields = {
  __typename?: 'view_chat_message_with_last_message_min_fields';
  id?: Maybe<Scalars['uuid']>;
  last_chat_message_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "view_chat_message_with_last_message". */
export type View_Chat_Message_With_Last_Message_Order_By = {
  chatMessage?: InputMaybe<Chat_Message_Order_By>;
  id?: InputMaybe<Order_By>;
  lastChatMessage?: InputMaybe<Chat_Message_Order_By>;
  last_chat_message_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_chat_message_with_last_message" */
export enum View_Chat_Message_With_Last_Message_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastChatMessageId = 'last_chat_message_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "view_chat_message_with_last_message" */
export type View_Chat_Message_With_Last_Message_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View_Chat_Message_With_Last_Message_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View_Chat_Message_With_Last_Message_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  last_chat_message_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "view_participationstates_with_user_info" */
export type View_Participationstates_With_User_Info = {
  __typename?: 'view_participationstates_with_user_info';
  created?: Maybe<Scalars['timestamp']>;
  participationstate?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  reading?: Maybe<Reading>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_participationstates_with_user_info" */
export type View_Participationstates_With_User_Info_Aggregate = {
  __typename?: 'view_participationstates_with_user_info_aggregate';
  aggregate?: Maybe<View_Participationstates_With_User_Info_Aggregate_Fields>;
  nodes: Array<View_Participationstates_With_User_Info>;
};

/** aggregate fields of "view_participationstates_with_user_info" */
export type View_Participationstates_With_User_Info_Aggregate_Fields = {
  __typename?: 'view_participationstates_with_user_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<View_Participationstates_With_User_Info_Max_Fields>;
  min?: Maybe<View_Participationstates_With_User_Info_Min_Fields>;
};


/** aggregate fields of "view_participationstates_with_user_info" */
export type View_Participationstates_With_User_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View_Participationstates_With_User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "view_participationstates_with_user_info". All fields are combined with a logical 'AND'. */
export type View_Participationstates_With_User_Info_Bool_Exp = {
  _and?: InputMaybe<Array<View_Participationstates_With_User_Info_Bool_Exp>>;
  _not?: InputMaybe<View_Participationstates_With_User_Info_Bool_Exp>;
  _or?: InputMaybe<Array<View_Participationstates_With_User_Info_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  participationstate?: InputMaybe<String_Comparison_Exp>;
  person_name?: InputMaybe<String_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View_Participationstates_With_User_Info_Max_Fields = {
  __typename?: 'view_participationstates_with_user_info_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  participationstate?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Participationstates_With_User_Info_Min_Fields = {
  __typename?: 'view_participationstates_with_user_info_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  participationstate?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "view_participationstates_with_user_info". */
export type View_Participationstates_With_User_Info_Order_By = {
  created?: InputMaybe<Order_By>;
  participationstate?: InputMaybe<Order_By>;
  person_name?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_participationstates_with_user_info" */
export enum View_Participationstates_With_User_Info_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Participationstate = 'participationstate',
  /** column name */
  PersonName = 'person_name',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "view_participationstates_with_user_info" */
export type View_Participationstates_With_User_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View_Participationstates_With_User_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View_Participationstates_With_User_Info_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  participationstate?: InputMaybe<Scalars['String']>;
  person_name?: InputMaybe<Scalars['String']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "view_reading_room_book" */
export type View_Reading_Room_Book = {
  __typename?: 'view_reading_room_book';
  person_name?: Maybe<Scalars['String']>;
  person_pronouns?: Maybe<Scalars['String']>;
  /** An object relationship */
  reading?: Maybe<Reading>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_reading_room_book" */
export type View_Reading_Room_Book_Aggregate = {
  __typename?: 'view_reading_room_book_aggregate';
  aggregate?: Maybe<View_Reading_Room_Book_Aggregate_Fields>;
  nodes: Array<View_Reading_Room_Book>;
};

/** aggregate fields of "view_reading_room_book" */
export type View_Reading_Room_Book_Aggregate_Fields = {
  __typename?: 'view_reading_room_book_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<View_Reading_Room_Book_Max_Fields>;
  min?: Maybe<View_Reading_Room_Book_Min_Fields>;
};


/** aggregate fields of "view_reading_room_book" */
export type View_Reading_Room_Book_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View_Reading_Room_Book_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "view_reading_room_book". All fields are combined with a logical 'AND'. */
export type View_Reading_Room_Book_Bool_Exp = {
  _and?: InputMaybe<Array<View_Reading_Room_Book_Bool_Exp>>;
  _not?: InputMaybe<View_Reading_Room_Book_Bool_Exp>;
  _or?: InputMaybe<Array<View_Reading_Room_Book_Bool_Exp>>;
  person_name?: InputMaybe<String_Comparison_Exp>;
  person_pronouns?: InputMaybe<String_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View_Reading_Room_Book_Max_Fields = {
  __typename?: 'view_reading_room_book_max_fields';
  person_name?: Maybe<Scalars['String']>;
  person_pronouns?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Reading_Room_Book_Min_Fields = {
  __typename?: 'view_reading_room_book_min_fields';
  person_name?: Maybe<Scalars['String']>;
  person_pronouns?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "view_reading_room_book". */
export type View_Reading_Room_Book_Order_By = {
  person_name?: InputMaybe<Order_By>;
  person_pronouns?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_reading_room_book" */
export enum View_Reading_Room_Book_Select_Column {
  /** column name */
  PersonName = 'person_name',
  /** column name */
  PersonPronouns = 'person_pronouns',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "view_reading_room_book" */
export type View_Reading_Room_Book_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View_Reading_Room_Book_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View_Reading_Room_Book_Stream_Cursor_Value_Input = {
  person_name?: InputMaybe<Scalars['String']>;
  person_pronouns?: InputMaybe<Scalars['String']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "view_reading_room_participants" */
export type View_Reading_Room_Participants = {
  __typename?: 'view_reading_room_participants';
  created?: Maybe<Scalars['timestamp']>;
  participationstate?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  person_pronouns?: Maybe<Scalars['String']>;
  /** An object relationship */
  reading?: Maybe<Reading>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_reading_room_participants" */
export type View_Reading_Room_Participants_Aggregate = {
  __typename?: 'view_reading_room_participants_aggregate';
  aggregate?: Maybe<View_Reading_Room_Participants_Aggregate_Fields>;
  nodes: Array<View_Reading_Room_Participants>;
};

/** aggregate fields of "view_reading_room_participants" */
export type View_Reading_Room_Participants_Aggregate_Fields = {
  __typename?: 'view_reading_room_participants_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<View_Reading_Room_Participants_Max_Fields>;
  min?: Maybe<View_Reading_Room_Participants_Min_Fields>;
};


/** aggregate fields of "view_reading_room_participants" */
export type View_Reading_Room_Participants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View_Reading_Room_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "view_reading_room_participants". All fields are combined with a logical 'AND'. */
export type View_Reading_Room_Participants_Bool_Exp = {
  _and?: InputMaybe<Array<View_Reading_Room_Participants_Bool_Exp>>;
  _not?: InputMaybe<View_Reading_Room_Participants_Bool_Exp>;
  _or?: InputMaybe<Array<View_Reading_Room_Participants_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  participationstate?: InputMaybe<String_Comparison_Exp>;
  person_name?: InputMaybe<String_Comparison_Exp>;
  person_pronouns?: InputMaybe<String_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View_Reading_Room_Participants_Max_Fields = {
  __typename?: 'view_reading_room_participants_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  participationstate?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  person_pronouns?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Reading_Room_Participants_Min_Fields = {
  __typename?: 'view_reading_room_participants_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  participationstate?: Maybe<Scalars['String']>;
  person_name?: Maybe<Scalars['String']>;
  person_pronouns?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "view_reading_room_participants". */
export type View_Reading_Room_Participants_Order_By = {
  created?: InputMaybe<Order_By>;
  participationstate?: InputMaybe<Order_By>;
  person_name?: InputMaybe<Order_By>;
  person_pronouns?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_reading_room_participants" */
export enum View_Reading_Room_Participants_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Participationstate = 'participationstate',
  /** column name */
  PersonName = 'person_name',
  /** column name */
  PersonPronouns = 'person_pronouns',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "view_reading_room_participants" */
export type View_Reading_Room_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View_Reading_Room_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View_Reading_Room_Participants_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  participationstate?: InputMaybe<Scalars['String']>;
  person_name?: InputMaybe<Scalars['String']>;
  person_pronouns?: InputMaybe<Scalars['String']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "view_readings" */
export type View_Readings = {
  __typename?: 'view_readings';
  created?: Maybe<Scalars['timestamp']>;
  duration_id?: Maybe<Scalars['uuid']>;
  externalid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inviteonly?: Maybe<Scalars['Boolean']>;
  language_id?: Maybe<Scalars['uuid']>;
  maxreaders?: Maybe<Scalars['Int']>;
  meetingurl?: Maybe<Scalars['String']>;
  minreaders?: Maybe<Scalars['Int']>;
  organisingbook_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  reading?: Maybe<Reading>;
  readingstart?: Maybe<Scalars['timestamptz']>;
  topic_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_readings" */
export type View_Readings_Aggregate = {
  __typename?: 'view_readings_aggregate';
  aggregate?: Maybe<View_Readings_Aggregate_Fields>;
  nodes: Array<View_Readings>;
};

/** aggregate fields of "view_readings" */
export type View_Readings_Aggregate_Fields = {
  __typename?: 'view_readings_aggregate_fields';
  avg?: Maybe<View_Readings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<View_Readings_Max_Fields>;
  min?: Maybe<View_Readings_Min_Fields>;
  stddev?: Maybe<View_Readings_Stddev_Fields>;
  stddev_pop?: Maybe<View_Readings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<View_Readings_Stddev_Samp_Fields>;
  sum?: Maybe<View_Readings_Sum_Fields>;
  var_pop?: Maybe<View_Readings_Var_Pop_Fields>;
  var_samp?: Maybe<View_Readings_Var_Samp_Fields>;
  variance?: Maybe<View_Readings_Variance_Fields>;
};


/** aggregate fields of "view_readings" */
export type View_Readings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View_Readings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type View_Readings_Avg_Fields = {
  __typename?: 'view_readings_avg_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "view_readings". All fields are combined with a logical 'AND'. */
export type View_Readings_Bool_Exp = {
  _and?: InputMaybe<Array<View_Readings_Bool_Exp>>;
  _not?: InputMaybe<View_Readings_Bool_Exp>;
  _or?: InputMaybe<Array<View_Readings_Bool_Exp>>;
  created?: InputMaybe<Timestamp_Comparison_Exp>;
  duration_id?: InputMaybe<Uuid_Comparison_Exp>;
  externalid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inviteonly?: InputMaybe<Boolean_Comparison_Exp>;
  language_id?: InputMaybe<Uuid_Comparison_Exp>;
  maxreaders?: InputMaybe<Int_Comparison_Exp>;
  meetingurl?: InputMaybe<String_Comparison_Exp>;
  minreaders?: InputMaybe<Int_Comparison_Exp>;
  organisingbook_id?: InputMaybe<Uuid_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  readingstart?: InputMaybe<Timestamptz_Comparison_Exp>;
  topic_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "view_readings" */
export type View_Readings_Inc_Input = {
  maxreaders?: InputMaybe<Scalars['Int']>;
  minreaders?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "view_readings" */
export type View_Readings_Insert_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  duration_id?: InputMaybe<Scalars['uuid']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteonly?: InputMaybe<Scalars['Boolean']>;
  language_id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  meetingurl?: InputMaybe<Scalars['String']>;
  minreaders?: InputMaybe<Scalars['Int']>;
  organisingbook_id?: InputMaybe<Scalars['uuid']>;
  reading?: InputMaybe<Reading_Obj_Rel_Insert_Input>;
  readingstart?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type View_Readings_Max_Fields = {
  __typename?: 'view_readings_max_fields';
  created?: Maybe<Scalars['timestamp']>;
  duration_id?: Maybe<Scalars['uuid']>;
  externalid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language_id?: Maybe<Scalars['uuid']>;
  maxreaders?: Maybe<Scalars['Int']>;
  meetingurl?: Maybe<Scalars['String']>;
  minreaders?: Maybe<Scalars['Int']>;
  organisingbook_id?: Maybe<Scalars['uuid']>;
  readingstart?: Maybe<Scalars['timestamptz']>;
  topic_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Readings_Min_Fields = {
  __typename?: 'view_readings_min_fields';
  created?: Maybe<Scalars['timestamp']>;
  duration_id?: Maybe<Scalars['uuid']>;
  externalid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language_id?: Maybe<Scalars['uuid']>;
  maxreaders?: Maybe<Scalars['Int']>;
  meetingurl?: Maybe<Scalars['String']>;
  minreaders?: Maybe<Scalars['Int']>;
  organisingbook_id?: Maybe<Scalars['uuid']>;
  readingstart?: Maybe<Scalars['timestamptz']>;
  topic_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "view_readings" */
export type View_Readings_Mutation_Response = {
  __typename?: 'view_readings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<View_Readings>;
};

/** Ordering options when selecting data from "view_readings". */
export type View_Readings_Order_By = {
  created?: InputMaybe<Order_By>;
  duration_id?: InputMaybe<Order_By>;
  externalid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteonly?: InputMaybe<Order_By>;
  language_id?: InputMaybe<Order_By>;
  maxreaders?: InputMaybe<Order_By>;
  meetingurl?: InputMaybe<Order_By>;
  minreaders?: InputMaybe<Order_By>;
  organisingbook_id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  readingstart?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_readings" */
export enum View_Readings_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  DurationId = 'duration_id',
  /** column name */
  Externalid = 'externalid',
  /** column name */
  Id = 'id',
  /** column name */
  Inviteonly = 'inviteonly',
  /** column name */
  LanguageId = 'language_id',
  /** column name */
  Maxreaders = 'maxreaders',
  /** column name */
  Meetingurl = 'meetingurl',
  /** column name */
  Minreaders = 'minreaders',
  /** column name */
  OrganisingbookId = 'organisingbook_id',
  /** column name */
  Readingstart = 'readingstart',
  /** column name */
  TopicId = 'topic_id'
}

/** input type for updating data in table "view_readings" */
export type View_Readings_Set_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  duration_id?: InputMaybe<Scalars['uuid']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteonly?: InputMaybe<Scalars['Boolean']>;
  language_id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  meetingurl?: InputMaybe<Scalars['String']>;
  minreaders?: InputMaybe<Scalars['Int']>;
  organisingbook_id?: InputMaybe<Scalars['uuid']>;
  readingstart?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type View_Readings_Stddev_Fields = {
  __typename?: 'view_readings_stddev_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type View_Readings_Stddev_Pop_Fields = {
  __typename?: 'view_readings_stddev_pop_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type View_Readings_Stddev_Samp_Fields = {
  __typename?: 'view_readings_stddev_samp_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "view_readings" */
export type View_Readings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View_Readings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View_Readings_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamp']>;
  duration_id?: InputMaybe<Scalars['uuid']>;
  externalid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteonly?: InputMaybe<Scalars['Boolean']>;
  language_id?: InputMaybe<Scalars['uuid']>;
  maxreaders?: InputMaybe<Scalars['Int']>;
  meetingurl?: InputMaybe<Scalars['String']>;
  minreaders?: InputMaybe<Scalars['Int']>;
  organisingbook_id?: InputMaybe<Scalars['uuid']>;
  readingstart?: InputMaybe<Scalars['timestamptz']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type View_Readings_Sum_Fields = {
  __typename?: 'view_readings_sum_fields';
  maxreaders?: Maybe<Scalars['Int']>;
  minreaders?: Maybe<Scalars['Int']>;
};

export type View_Readings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<View_Readings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<View_Readings_Set_Input>;
  /** filter the rows which have to be updated */
  where: View_Readings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type View_Readings_Var_Pop_Fields = {
  __typename?: 'view_readings_var_pop_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type View_Readings_Var_Samp_Fields = {
  __typename?: 'view_readings_var_samp_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type View_Readings_Variance_Fields = {
  __typename?: 'view_readings_variance_fields';
  maxreaders?: Maybe<Scalars['Float']>;
  minreaders?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "view_readings_with_book_info" */
export type View_Readings_With_Book_Info = {
  __typename?: 'view_readings_with_book_info';
  person_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  reading?: Maybe<Reading>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_readings_with_book_info" */
export type View_Readings_With_Book_Info_Aggregate = {
  __typename?: 'view_readings_with_book_info_aggregate';
  aggregate?: Maybe<View_Readings_With_Book_Info_Aggregate_Fields>;
  nodes: Array<View_Readings_With_Book_Info>;
};

/** aggregate fields of "view_readings_with_book_info" */
export type View_Readings_With_Book_Info_Aggregate_Fields = {
  __typename?: 'view_readings_with_book_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<View_Readings_With_Book_Info_Max_Fields>;
  min?: Maybe<View_Readings_With_Book_Info_Min_Fields>;
};


/** aggregate fields of "view_readings_with_book_info" */
export type View_Readings_With_Book_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View_Readings_With_Book_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "view_readings_with_book_info". All fields are combined with a logical 'AND'. */
export type View_Readings_With_Book_Info_Bool_Exp = {
  _and?: InputMaybe<Array<View_Readings_With_Book_Info_Bool_Exp>>;
  _not?: InputMaybe<View_Readings_With_Book_Info_Bool_Exp>;
  _or?: InputMaybe<Array<View_Readings_With_Book_Info_Bool_Exp>>;
  person_name?: InputMaybe<String_Comparison_Exp>;
  reading?: InputMaybe<Reading_Bool_Exp>;
  reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View_Readings_With_Book_Info_Max_Fields = {
  __typename?: 'view_readings_with_book_info_max_fields';
  person_name?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Readings_With_Book_Info_Min_Fields = {
  __typename?: 'view_readings_with_book_info_min_fields';
  person_name?: Maybe<Scalars['String']>;
  reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "view_readings_with_book_info". */
export type View_Readings_With_Book_Info_Order_By = {
  person_name?: InputMaybe<Order_By>;
  reading?: InputMaybe<Reading_Order_By>;
  reading_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_readings_with_book_info" */
export enum View_Readings_With_Book_Info_Select_Column {
  /** column name */
  PersonName = 'person_name',
  /** column name */
  ReadingId = 'reading_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "view_readings_with_book_info" */
export type View_Readings_With_Book_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View_Readings_With_Book_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View_Readings_With_Book_Info_Stream_Cursor_Value_Input = {
  person_name?: InputMaybe<Scalars['String']>;
  reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "view_users_with_next_reading" */
export type View_Users_With_Next_Reading = {
  __typename?: 'view_users_with_next_reading';
  /** An object relationship */
  next_reading?: Maybe<Reading>;
  next_reading_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "view_users_with_next_reading" */
export type View_Users_With_Next_Reading_Aggregate = {
  __typename?: 'view_users_with_next_reading_aggregate';
  aggregate?: Maybe<View_Users_With_Next_Reading_Aggregate_Fields>;
  nodes: Array<View_Users_With_Next_Reading>;
};

/** aggregate fields of "view_users_with_next_reading" */
export type View_Users_With_Next_Reading_Aggregate_Fields = {
  __typename?: 'view_users_with_next_reading_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<View_Users_With_Next_Reading_Max_Fields>;
  min?: Maybe<View_Users_With_Next_Reading_Min_Fields>;
};


/** aggregate fields of "view_users_with_next_reading" */
export type View_Users_With_Next_Reading_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<View_Users_With_Next_Reading_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "view_users_with_next_reading". All fields are combined with a logical 'AND'. */
export type View_Users_With_Next_Reading_Bool_Exp = {
  _and?: InputMaybe<Array<View_Users_With_Next_Reading_Bool_Exp>>;
  _not?: InputMaybe<View_Users_With_Next_Reading_Bool_Exp>;
  _or?: InputMaybe<Array<View_Users_With_Next_Reading_Bool_Exp>>;
  next_reading?: InputMaybe<Reading_Bool_Exp>;
  next_reading_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type View_Users_With_Next_Reading_Max_Fields = {
  __typename?: 'view_users_with_next_reading_max_fields';
  next_reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type View_Users_With_Next_Reading_Min_Fields = {
  __typename?: 'view_users_with_next_reading_min_fields';
  next_reading_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "view_users_with_next_reading". */
export type View_Users_With_Next_Reading_Order_By = {
  next_reading?: InputMaybe<Reading_Order_By>;
  next_reading_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "view_users_with_next_reading" */
export enum View_Users_With_Next_Reading_Select_Column {
  /** column name */
  NextReadingId = 'next_reading_id',
  /** column name */
  UserId = 'user_id'
}

/** Streaming cursor of the table "view_users_with_next_reading" */
export type View_Users_With_Next_Reading_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: View_Users_With_Next_Reading_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type View_Users_With_Next_Reading_Stream_Cursor_Value_Input = {
  next_reading_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings = {
  __typename?: 'virtual_book_get_number_of_readings';
  book_id: Scalars['uuid'];
  readings_past_day: Scalars['Int'];
  readings_past_month: Scalars['Int'];
  readings_past_week: Scalars['Int'];
  user_id: Scalars['uuid'];
};

export type Virtual_Book_Get_Number_Of_Readings_Aggregate = {
  __typename?: 'virtual_book_get_number_of_readings_aggregate';
  aggregate?: Maybe<Virtual_Book_Get_Number_Of_Readings_Aggregate_Fields>;
  nodes: Array<Virtual_Book_Get_Number_Of_Readings>;
};

/** aggregate fields of "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings_Aggregate_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_aggregate_fields';
  avg?: Maybe<Virtual_Book_Get_Number_Of_Readings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Virtual_Book_Get_Number_Of_Readings_Max_Fields>;
  min?: Maybe<Virtual_Book_Get_Number_Of_Readings_Min_Fields>;
  stddev?: Maybe<Virtual_Book_Get_Number_Of_Readings_Stddev_Fields>;
  stddev_pop?: Maybe<Virtual_Book_Get_Number_Of_Readings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Virtual_Book_Get_Number_Of_Readings_Stddev_Samp_Fields>;
  sum?: Maybe<Virtual_Book_Get_Number_Of_Readings_Sum_Fields>;
  var_pop?: Maybe<Virtual_Book_Get_Number_Of_Readings_Var_Pop_Fields>;
  var_samp?: Maybe<Virtual_Book_Get_Number_Of_Readings_Var_Samp_Fields>;
  variance?: Maybe<Virtual_Book_Get_Number_Of_Readings_Variance_Fields>;
};


/** aggregate fields of "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Virtual_Book_Get_Number_Of_Readings_Avg_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_avg_fields';
  readings_past_day?: Maybe<Scalars['Float']>;
  readings_past_month?: Maybe<Scalars['Float']>;
  readings_past_week?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "virtual_book_get_number_of_readings". All fields are combined with a logical 'AND'. */
export type Virtual_Book_Get_Number_Of_Readings_Bool_Exp = {
  _and?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>>;
  _not?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>;
  _or?: InputMaybe<Array<Virtual_Book_Get_Number_Of_Readings_Bool_Exp>>;
  book_id?: InputMaybe<Uuid_Comparison_Exp>;
  readings_past_day?: InputMaybe<Int_Comparison_Exp>;
  readings_past_month?: InputMaybe<Int_Comparison_Exp>;
  readings_past_week?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings_Inc_Input = {
  readings_past_day?: InputMaybe<Scalars['Int']>;
  readings_past_month?: InputMaybe<Scalars['Int']>;
  readings_past_week?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings_Insert_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  readings_past_day?: InputMaybe<Scalars['Int']>;
  readings_past_month?: InputMaybe<Scalars['Int']>;
  readings_past_week?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Virtual_Book_Get_Number_Of_Readings_Max_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_max_fields';
  book_id?: Maybe<Scalars['uuid']>;
  readings_past_day?: Maybe<Scalars['Int']>;
  readings_past_month?: Maybe<Scalars['Int']>;
  readings_past_week?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Virtual_Book_Get_Number_Of_Readings_Min_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_min_fields';
  book_id?: Maybe<Scalars['uuid']>;
  readings_past_day?: Maybe<Scalars['Int']>;
  readings_past_month?: Maybe<Scalars['Int']>;
  readings_past_week?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings_Mutation_Response = {
  __typename?: 'virtual_book_get_number_of_readings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Virtual_Book_Get_Number_Of_Readings>;
};

/** Ordering options when selecting data from "virtual_book_get_number_of_readings". */
export type Virtual_Book_Get_Number_Of_Readings_Order_By = {
  book_id?: InputMaybe<Order_By>;
  readings_past_day?: InputMaybe<Order_By>;
  readings_past_month?: InputMaybe<Order_By>;
  readings_past_week?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "virtual_book_get_number_of_readings" */
export enum Virtual_Book_Get_Number_Of_Readings_Select_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  ReadingsPastDay = 'readings_past_day',
  /** column name */
  ReadingsPastMonth = 'readings_past_month',
  /** column name */
  ReadingsPastWeek = 'readings_past_week',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings_Set_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  readings_past_day?: InputMaybe<Scalars['Int']>;
  readings_past_month?: InputMaybe<Scalars['Int']>;
  readings_past_week?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Virtual_Book_Get_Number_Of_Readings_Stddev_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_stddev_fields';
  readings_past_day?: Maybe<Scalars['Float']>;
  readings_past_month?: Maybe<Scalars['Float']>;
  readings_past_week?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Virtual_Book_Get_Number_Of_Readings_Stddev_Pop_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_stddev_pop_fields';
  readings_past_day?: Maybe<Scalars['Float']>;
  readings_past_month?: Maybe<Scalars['Float']>;
  readings_past_week?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Virtual_Book_Get_Number_Of_Readings_Stddev_Samp_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_stddev_samp_fields';
  readings_past_day?: Maybe<Scalars['Float']>;
  readings_past_month?: Maybe<Scalars['Float']>;
  readings_past_week?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "virtual_book_get_number_of_readings" */
export type Virtual_Book_Get_Number_Of_Readings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Virtual_Book_Get_Number_Of_Readings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Virtual_Book_Get_Number_Of_Readings_Stream_Cursor_Value_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  readings_past_day?: InputMaybe<Scalars['Int']>;
  readings_past_month?: InputMaybe<Scalars['Int']>;
  readings_past_week?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Virtual_Book_Get_Number_Of_Readings_Sum_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_sum_fields';
  readings_past_day?: Maybe<Scalars['Int']>;
  readings_past_month?: Maybe<Scalars['Int']>;
  readings_past_week?: Maybe<Scalars['Int']>;
};

export type Virtual_Book_Get_Number_Of_Readings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Virtual_Book_Get_Number_Of_Readings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Virtual_Book_Get_Number_Of_Readings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Virtual_Book_Get_Number_Of_Readings_Var_Pop_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_var_pop_fields';
  readings_past_day?: Maybe<Scalars['Float']>;
  readings_past_month?: Maybe<Scalars['Float']>;
  readings_past_week?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Virtual_Book_Get_Number_Of_Readings_Var_Samp_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_var_samp_fields';
  readings_past_day?: Maybe<Scalars['Float']>;
  readings_past_month?: Maybe<Scalars['Float']>;
  readings_past_week?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Virtual_Book_Get_Number_Of_Readings_Variance_Fields = {
  __typename?: 'virtual_book_get_number_of_readings_variance_fields';
  readings_past_day?: Maybe<Scalars['Float']>;
  readings_past_month?: Maybe<Scalars['Float']>;
  readings_past_week?: Maybe<Scalars['Float']>;
};

export type TestCreateReadingGetDurationQueryVariables = Exact<{ [key: string]: never; }>;


export type TestCreateReadingGetDurationQuery = { __typename?: 'query_root', duration: Array<{ __typename?: 'duration', id: any }>, language: Array<{ __typename?: 'language', id: any }>, readingstate: Array<{ __typename?: 'readingstate', id: any }> };

export type TestCreateReadingInsertUserMutationVariables = Exact<{
  email: Scalars['String'];
  orgName: Scalars['String'];
  roleName: Scalars['String'];
}>;


export type TestCreateReadingInsertUserMutation = { __typename?: 'mutation_root', insert_user_one?: { __typename?: 'user', id: any, org: { __typename?: 'org', id: any, name: string, parent?: any | null }, person: { __typename?: 'person', email: string }, role: { __typename?: 'role', name: string } } | null };

export type TestCreateReadingUpdateReadingSettingMutationVariables = Exact<{
  key: Scalars['String'];
  value: Scalars['String'];
}>;


export type TestCreateReadingUpdateReadingSettingMutation = { __typename?: 'mutation_root', update_reading_setting?: { __typename?: 'reading_setting_mutation_response', affected_rows: number } | null };

export type TestCreateReadingInsertBookMutationVariables = Exact<{
  topicId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type TestCreateReadingInsertBookMutation = { __typename?: 'mutation_root', insert_book_one?: { __typename?: 'book', id: any, book_topics: Array<{ __typename?: 'book_topic', topic: { __typename?: 'topic', id: any } }>, booklanguages: Array<{ __typename?: 'booklanguage', language: { __typename?: 'language', id: any } }> } | null };

export type TestCreateReadingRemoveReadingsMutationVariables = Exact<{ [key: string]: never; }>;


export type TestCreateReadingRemoveReadingsMutation = { __typename?: 'mutation_root', delete_readingevent?: { __typename?: 'readingevent_mutation_response', affected_rows: number } | null, delete_participationevent?: { __typename?: 'participationevent_mutation_response', affected_rows: number } | null, delete_cancel_review?: { __typename?: 'cancel_review_mutation_response', affected_rows: number } | null, delete_review?: { __typename?: 'review_mutation_response', affected_rows: number } | null, delete_reading?: { __typename?: 'reading_mutation_response', affected_rows: number } | null };

export type TestCreateReadingCreateTopicMutationVariables = Exact<{
  topicName: Scalars['String'];
}>;


export type TestCreateReadingCreateTopicMutation = { __typename?: 'mutation_root', insert_topic_one?: { __typename?: 'topic', id: any } | null };

export type PastReadingItemGetReviewsQueryVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type PastReadingItemGetReviewsQuery = { __typename?: 'query_root', review: Array<{ __typename?: 'review', id: any, text?: string | null }> };

export type BookProfileGetReadingsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  readingStartWhere: Timestamptz_Comparison_Exp;
  sortDirection: Order_By;
  userId: Scalars['uuid'];
}>;


export type BookProfileGetReadingsQuery = { __typename?: 'query_root', readings: Array<{ __typename?: 'reading', id: any, maxreaders?: number | null, readingstart: any, seats_available?: any | null, topicName?: string | null, topic: { __typename?: 'topic', id: any, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', user: { __typename?: 'user', person: { __typename?: 'person', name?: string | null } } } }>, reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type AdminPersonalInformationUpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  personName?: InputMaybe<Scalars['String']>;
  orgId: Scalars['uuid'];
  pronouns?: InputMaybe<Scalars['String']>;
  userId: Scalars['uuid'];
}>;


export type AdminPersonalInformationUpdateUserMutation = { __typename?: 'mutation_root', update_person?: { __typename?: 'person_mutation_response', affected_rows: number } | null, update_user_by_pk?: { __typename?: 'user', id: any } | null };

export type LibrarianPersonalInformationUpdateUserMutationVariables = Exact<{
  personName?: InputMaybe<Scalars['String']>;
  userId: Scalars['uuid'];
}>;


export type LibrarianPersonalInformationUpdateUserMutation = { __typename?: 'mutation_root', update_person?: { __typename?: 'person_mutation_response', affected_rows: number } | null };

export type TimezoneInformationGetDataQueryVariables = Exact<{ [key: string]: never; }>;


export type TimezoneInformationGetDataQuery = { __typename?: 'query_root', timezone: Array<{ __typename?: 'timezone', id: any, name: string, offset: any, value: string }> };

export type ReaderProfileGetReadingsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  readingStartWhere: Timestamptz_Comparison_Exp;
  sortDirection: Order_By;
  userId: Scalars['uuid'];
}>;


export type ReaderProfileGetReadingsQuery = { __typename?: 'query_root', readings: Array<{ __typename?: 'reading', id: any, maxreaders?: number | null, readingstart: any, readingEventStatusName?: string | null, seats_available?: any | null, topicName?: string | null, topic: { __typename?: 'topic', id: any, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', user: { __typename?: 'user', person: { __typename?: 'person', name?: string | null } } } }>, reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type ChatButtonGetAdminNumberOfUnreadMessagesQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type ChatButtonGetAdminNumberOfUnreadMessagesQuery = { __typename?: 'query_root', chat_room: Array<{ __typename?: 'chat_room', user: { __typename?: 'user', chat_messages_aggregate: { __typename?: 'chat_message_aggregate', aggregate?: { __typename?: 'chat_message_aggregate_fields', count: number } | null } } }> };

export type ChatButtonGetUserNumberOfUnreadMessagesQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type ChatButtonGetUserNumberOfUnreadMessagesQuery = { __typename?: 'query_root', chat_message_aggregate: { __typename?: 'chat_message_aggregate', aggregate?: { __typename?: 'chat_message_aggregate_fields', count: number } | null } };

export type ChatGetUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type ChatGetUserQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', person: { __typename?: 'person', name?: string | null, pronouns?: string | null }, role: { __typename?: 'role', name: string } } | null };

export type GetChatMessagesSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetChatMessagesSubscription = { __typename?: 'subscription_root', chatMessages: Array<{ __typename?: 'chat_message', id: any, created: any, from_user_id?: any | null, user_id: any, value?: string | null, fromPersonName?: string | null, unread: boolean, fromUser?: { __typename?: 'user', role: { __typename?: 'role', name: string } } | null, chatMessageType: { __typename?: 'chat_message_type', id: any, name: string } }> };

export type ChatRoomGetListSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type ChatRoomGetListSubscription = { __typename?: 'subscription_root', chat_room_by_pk?: { __typename?: 'chat_room', owner_user_id?: any | null, user_id: any, active: boolean } | null };

export type ChatRoomJoinMutationVariables = Exact<{
  messageJoinTypeId: Scalars['uuid'];
  ownerUserId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type ChatRoomJoinMutation = { __typename?: 'mutation_root', insert_chat_room_one?: { __typename?: 'chat_room', user_id: any } | null, insert_chat_message_one?: { __typename?: 'chat_message', id: any } | null };

export type GetNotificationCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationCountQuery = { __typename?: 'query_root', view_chat_message_with_last_message_aggregate: { __typename?: 'view_chat_message_with_last_message_aggregate', aggregate?: { __typename?: 'view_chat_message_with_last_message_aggregate_fields', count: number } | null } };

export type ChatRoomGetMessageTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatRoomGetMessageTypesQuery = { __typename?: 'query_root', chat_message_type: Array<{ __typename?: 'chat_message_type', id: any, name: string }> };

export type NotificationsGetChatMessagesQueryVariables = Exact<{
  where: View_Chat_Message_With_Last_Message_Bool_Exp;
}>;


export type NotificationsGetChatMessagesQuery = { __typename?: 'query_root', view_chat_message_with_last_message: Array<{ __typename?: 'view_chat_message_with_last_message', chatMessage?: { __typename?: 'chat_message', created: any, value?: string | null, chatMessageType: { __typename?: 'chat_message_type', name: string }, chatRoom?: { __typename?: 'chat_room', active: boolean, updated: any, ownerUser?: { __typename?: 'user', id: any, personName?: string | null } | null } | null, user: { __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null, pronouns?: string | null }, role: { __typename?: 'role', name: string } } } | null }> };

export type NotificationsInsertChatMessageMutationVariables = Exact<{
  messageTypeId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type NotificationsInsertChatMessageMutation = { __typename?: 'mutation_root', insert_chat_message_one?: { __typename?: 'chat_message', id: any } | null };

export type NotificationsInsertChatRoomMutationVariables = Exact<{
  active: Scalars['Boolean'];
  ownerUserId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type NotificationsInsertChatRoomMutation = { __typename?: 'mutation_root', insert_chat_room_one?: { __typename?: 'chat_room', user_id: any } | null };

export type GetChatMessageStatusSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
}>;


export type GetChatMessageStatusSubscription = { __typename?: 'subscription_root', chat_room: Array<{ __typename?: 'chat_room', owner_user_id?: any | null, user_id: any, user: { __typename?: 'user', person: { __typename?: 'person', name?: string | null, pronouns?: string | null }, chatMessages: Array<{ __typename?: 'chat_message', created: any, from_user_id?: any | null, unread: boolean, value?: string | null, fromPersonName?: string | null, toPersonName?: string | null, user_id: any, fromUser?: { __typename?: 'user', role: { __typename?: 'role', name: string }, person: { __typename?: 'person', pronouns?: string | null } } | null, chatMessageType: { __typename?: 'chat_message_type', id: any, name: string } }> } }> };

export type InsertEmailActivateReminderMutationVariables = Exact<{
  input: Array<Email_Activate_Reminder_Insert_Input> | Email_Activate_Reminder_Insert_Input;
}>;


export type InsertEmailActivateReminderMutation = { __typename?: 'mutation_root', insert_email_activate_reminder?: { __typename?: 'email_activate_reminder_mutation_response', affected_rows: number } | null };

export type GetActivateEmailUsersQueryVariables = Exact<{
  fromDate: Scalars['timestamp'];
}>;


export type GetActivateEmailUsersQuery = { __typename?: 'query_root', persons: Array<{ __typename?: 'person', created: any, email: string, id: any, emailActivateReminder?: { __typename?: 'email_activate_reminder', sent_first_reminder_at?: any | null, sent_second_reminder_at?: any | null } | null }> };

export type InsertEmailChatMessageUnreadReminderMutationVariables = Exact<{
  input: Array<Email_Chat_Message_Unread_Reminder_Insert_Input> | Email_Chat_Message_Unread_Reminder_Insert_Input;
}>;


export type InsertEmailChatMessageUnreadReminderMutation = { __typename?: 'mutation_root', insert_email_chat_message_unread_reminder?: { __typename?: 'email_chat_message_unread_reminder_mutation_response', affected_rows: number } | null };

export type GetChatReminderMessagesQueryVariables = Exact<{
  minDate: Scalars['timestamptz'];
  userRoles?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetChatReminderMessagesQuery = { __typename?: 'query_root', chat_message: Array<{ __typename?: 'chat_message', id: any, created: any, user_id: any, unread: boolean, emailChatMessageUnreadReminder?: { __typename?: 'email_chat_message_unread_reminder', chat_message_id: any, user_id: any } | null, user: { __typename?: 'user', chatMessages: Array<{ __typename?: 'chat_message', created: any, value?: string | null, fromUser?: { __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null }, role: { __typename?: 'role', name: string } } | null }>, person: { __typename?: 'person', email: string, name?: string | null, timezone?: string | null, language?: { __typename?: 'language', isocode: string } | null }, role: { __typename?: 'role', name: string } } }> };

export type InsertEmailLibraryCardExpirationReminderMutationVariables = Exact<{
  input: Array<Email_Library_Card_Expiration_Reminder_Insert_Input> | Email_Library_Card_Expiration_Reminder_Insert_Input;
}>;


export type InsertEmailLibraryCardExpirationReminderMutation = { __typename?: 'mutation_root', insert_email_library_card_expiration_reminder?: { __typename?: 'email_library_card_expiration_reminder_mutation_response', affected_rows: number } | null };

export type GetReadingPlanExpirationQueryVariables = Exact<{
  validTo: Scalars['date'];
}>;


export type GetReadingPlanExpirationQuery = { __typename?: 'query_root', readingplan: Array<{ __typename?: 'readingplan', id: any, remainingreadings?: any | null, valid_to?: any | null, user?: { __typename?: 'user', person: { __typename?: 'person', email: string } } | null, emailLibraryCardExpirationReminder?: { __typename?: 'email_library_card_expiration_reminder', updated_at: any } | null }> };

export type InsertEmailLibraryCardExpiredReminderMutationVariables = Exact<{
  input: Array<Email_Library_Card_Expired_Reminder_Insert_Input> | Email_Library_Card_Expired_Reminder_Insert_Input;
}>;


export type InsertEmailLibraryCardExpiredReminderMutation = { __typename?: 'mutation_root', insert_email_library_card_expired_reminder?: { __typename?: 'email_library_card_expired_reminder_mutation_response', affected_rows: number } | null };

export type GetReadingPlanExpiredQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReadingPlanExpiredQuery = { __typename?: 'query_root', readingplan: Array<{ __typename?: 'readingplan', id: any, remainingreadings?: any | null, valid_to?: any | null, emailLibraryCardExpiredReminder?: { __typename?: 'email_library_card_expired_reminder', sent_at: any } | null, user?: { __typename?: 'user', participationevents_aggregate: { __typename?: 'participationevent_aggregate', aggregate?: { __typename?: 'participationevent_aggregate_fields', count: number } | null }, org: { __typename?: 'org', reader_review_url?: string | null, reader_review_short_url?: string | null }, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', name: string }, reading: { __typename?: 'reading', readingstart: any, duration: { __typename?: 'duration', minutes: number } } }>, person: { __typename?: 'person', email: string } } | null }> };

export type GetLocalizationQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetLocalizationQuery = { __typename?: 'query_root', language: Array<{ __typename?: 'language', id: any, isocode: string }>, user_by_pk?: { __typename?: 'user', person: { __typename?: 'person', language?: { __typename?: 'language', id: any, isocode: string } | null } } | null };

export type InsertOnboardingEulaAcceptMutationVariables = Exact<{
  eulaId: Scalars['uuid'];
  personId: Scalars['uuid'];
}>;


export type InsertOnboardingEulaAcceptMutation = { __typename?: 'mutation_root', insert_eula_acceptance_one?: { __typename?: 'eula_acceptance', eula_id: any } | null };

export type UpdateOnboardingProfileMutationVariables = Exact<{
  preferredName: Scalars['String'];
  pronouns: Scalars['String'];
  userId: Scalars['uuid'];
}>;


export type UpdateOnboardingProfileMutation = { __typename?: 'mutation_root', update_person?: { __typename?: 'person_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'person', users: Array<{ __typename?: 'user', id: any, role: { __typename?: 'role', name: string } }> }> } | null };

export type UpdateOnboardingReadingPlanAcceptMutationVariables = Exact<{
  readingPlanId: Scalars['uuid'];
}>;


export type UpdateOnboardingReadingPlanAcceptMutation = { __typename?: 'mutation_root', update_readingplan_by_pk?: { __typename?: 'readingplan', activated?: any | null } | null };

export type GetOnboardingProfileQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetOnboardingProfileQuery = { __typename?: 'query_root', eula: Array<{ __typename?: 'eula', id: any }>, user_by_pk?: { __typename?: 'user', person: { __typename?: 'person', id: any, name?: string | null, pronouns?: string | null, eula_acceptances: Array<{ __typename?: 'eula_acceptance', eula_id: any }> }, role: { __typename?: 'role', name: string } } | null };

export type GetOnboardingReadingPlanQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetOnboardingReadingPlanQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', readingplan?: { __typename?: 'readingplan', activated?: any | null, id: any, initialreadings: number, remainingreadings?: any | null, pillarconstraints: Array<{ __typename?: 'pillarconstraint', pillar: { __typename?: 'pillar', id: any, description?: string | null, title: string } }>, readingPlanDuration: { __typename?: 'readingplan_duration', translation: Array<{ __typename?: 'readingplan_duration_translation', name: string }> } } | null } | null };

export type InsertOrganisationMutationVariables = Exact<{
  countryId: Scalars['uuid'];
  name: Scalars['String'];
  parent?: InputMaybe<Scalars['uuid']>;
  reader_review_url: Scalars['String'];
  reader_review_short_url: Scalars['String'];
}>;


export type InsertOrganisationMutation = { __typename?: 'mutation_root', insert_org_one?: { __typename?: 'org', id: any } | null };

export type UpdateOrganisationByIdMutationVariables = Exact<{
  countryId: Scalars['uuid'];
  name: Scalars['String'];
  orgId: Scalars['uuid'];
  parent?: InputMaybe<Scalars['uuid']>;
  reader_review_url: Scalars['String'];
  reader_review_short_url: Scalars['String'];
}>;


export type UpdateOrganisationByIdMutation = { __typename?: 'mutation_root', update_org_by_pk?: { __typename?: 'org', id: any } | null };

export type GetOrganisationByIdQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;


export type GetOrganisationByIdQuery = { __typename?: 'query_root', org_by_pk?: { __typename?: 'org', id: any, name: string, country_id?: any | null, parent?: any | null, reader_review_url?: string | null, reader_review_short_url?: string | null } | null };

export type GetOrganisationListQueryVariables = Exact<{
  nameWhere?: InputMaybe<String_Comparison_Exp>;
  parentWhere?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type GetOrganisationListQuery = { __typename?: 'query_root', org: Array<{ __typename?: 'org', id: any, name: string, created: any, parent?: any | null, users_aggregate: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null } }> };

export type GetOrganisationStatsQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;


export type GetOrganisationStatsQuery = { __typename?: 'query_root', org?: { __typename?: 'org', name: string } | null, user: Array<{ __typename?: 'user', id: any, person: { __typename?: 'person', email: string, emailverified: boolean }, readingplan?: { __typename?: 'readingplan', initialreadings: number, remainingreadings?: any | null } | null, participationevents: Array<{ __typename?: 'participationevent', created: any, participationstate: { __typename?: 'participationstate', name: string }, reading: { __typename?: 'reading', id: any, readingEventStatusName?: string | null, reviews: Array<{ __typename?: 'review', rating?: number | null, text?: string | null, user_id: any }>, topic: { __typename?: 'topic', pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', title: string } }> } } }> }> };

export type GetFeaturedReadingsByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetFeaturedReadingsByUserIdQuery = { __typename?: 'query_root', featuredReadings: Array<{ __typename?: 'view_readings', reading?: { __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', id: any, name: string } }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } } | null }> };

export type GetReadingsByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  after: Scalars['timestamptz'];
  participationEventNameWhere?: InputMaybe<String_Comparison_Exp>;
  topicWhere?: InputMaybe<Topic_Bool_Exp>;
  topicNameWhere?: InputMaybe<String_Comparison_Exp>;
  readingIdWhere?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type GetReadingsByUserIdQuery = { __typename?: 'query_root', readings: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', id: any, name: string } }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }>, reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type GetReadingPlanByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetReadingPlanByUserIdQuery = { __typename?: 'query_root', readingplan: Array<{ __typename?: 'readingplan', activated?: any | null, created: any, id: any, initialreadings: number, remainingreadings?: any | null, updated?: any | null, valid_to?: any | null, updatedBy?: { __typename?: 'user', personName?: string | null } | null, pillarconstraints: Array<{ __typename?: 'pillarconstraint', pillar: { __typename?: 'pillar', id: any, title: string } }>, creator?: { __typename?: 'user', personName?: string | null } | null }> };

export type InsertReadingRoomBookReviewMutationVariables = Exact<{
  readingId: Scalars['uuid'];
  reviewBookOptionId: Scalars['uuid'];
  text: Scalars['String'];
}>;


export type InsertReadingRoomBookReviewMutation = { __typename?: 'mutation_root', insert_review_book_one?: { __typename?: 'review_book', reading_id: any } | null };

export type InsertReadingRoomChatMessageMutationVariables = Exact<{
  reading_id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
}>;


export type InsertReadingRoomChatMessageMutation = { __typename?: 'mutation_root', insert_chat_reading_message_one?: { __typename?: 'chat_reading_message', id: any } | null };

export type GetReadingRoomBookReviewOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReadingRoomBookReviewOptionsQuery = { __typename?: 'query_root', review_book_option: Array<{ __typename?: 'review_book_option', id: any, label: string }> };

export type GetReadingRoomReadingQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReadingRoomReadingQuery = { __typename?: 'query_root', reading?: { __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, cancel_reviews: Array<{ __typename?: 'cancel_review', user_id: any }>, reviews: Array<{ __typename?: 'review', user_id: any, id: any }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } } | null };

export type GetReadingRoomReadingStatusQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetReadingRoomReadingStatusQuery = { __typename?: 'query_root', readingStatus?: { __typename?: 'reading', readingEventStatusName?: string | null } | null };

export type GetReadingRoomBookParticipantsSubscriptionVariables = Exact<{
  reading_id: Scalars['uuid'];
}>;


export type GetReadingRoomBookParticipantsSubscription = { __typename?: 'subscription_root', view_reading_room_book: Array<{ __typename?: 'view_reading_room_book', reading_id?: any | null, user_id?: any | null, person_name?: string | null, person_pronouns?: string | null }> };

export type GetReadingRoomChatMessagesSubscriptionVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type GetReadingRoomChatMessagesSubscription = { __typename?: 'subscription_root', chat_reading_message: Array<{ __typename?: 'chat_reading_message', message: string, created: any, reading_id: any, personName?: string | null, personPronouns?: string | null, id: any, user_id: any }> };

export type GetReadingRoomParticipantsSubscriptionVariables = Exact<{
  reading_id: Scalars['uuid'];
}>;


export type GetReadingRoomParticipantsSubscription = { __typename?: 'subscription_root', view_reading_room_participants: Array<{ __typename?: 'view_reading_room_participants', reading_id?: any | null, user_id?: any | null, person_name?: string | null, person_pronouns?: string | null, participationstate?: string | null, created?: any | null }> };

export type CoreOrgFieldsFragment = { __typename?: 'org', id: any, parent?: any | null, name: string, country?: { __typename?: 'country', id: any, isocode: string, name: string } | null };

export type CoreParticipationEventFieldsFragment = { __typename?: 'participationevent', id: any, created: any, reading: { __typename?: 'reading', id: any, readingstart: any, topic: { __typename?: 'topic', name: string, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', title: string } }> } }, participationstate: { __typename?: 'participationstate', name: string } };

export type CorePersonFieldsFragment = { __typename?: 'person', id: any, externalId?: string | null, name?: string | null, email: string, country_id: any, emailverified: boolean, language?: { __typename?: 'language', isocode: string } | null, users: Array<{ __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null, person_get_has_accepted_latest_eula?: any | null, language?: { __typename?: 'language', isocode: string } | null }, org: { __typename?: 'org', id: any, name: string }, role: { __typename?: 'role', id: any, name: string }, allowedOrgs?: Array<{ __typename?: 'org', id: any, name: string, parent?: any | null }> | null }> };

export type CoreReadingFieldsFragment = { __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } };

export type ActivateReadingPlansForUserMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type ActivateReadingPlansForUserMutation = { __typename?: 'mutation_root', update_readingplan?: { __typename?: 'readingplan_mutation_response', affected_rows: number } | null };

export type CreatePersonMutationVariables = Exact<{
  person: Person_Insert_Input;
}>;


export type CreatePersonMutation = { __typename?: 'mutation_root', insert_person_one?: { __typename?: 'person', id: any, externalId?: string | null, name?: string | null, email: string, country_id: any, emailverified: boolean, language?: { __typename?: 'language', isocode: string } | null, users: Array<{ __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null, person_get_has_accepted_latest_eula?: any | null, language?: { __typename?: 'language', isocode: string } | null }, org: { __typename?: 'org', id: any, name: string }, role: { __typename?: 'role', id: any, name: string }, allowedOrgs?: Array<{ __typename?: 'org', id: any, name: string, parent?: any | null }> | null }> } | null };

export type CreateUserMutationVariables = Exact<{
  user: User_Insert_Input;
}>;


export type CreateUserMutation = { __typename?: 'mutation_root', insert_user_one?: { __typename?: 'user', id: any, org: { __typename?: 'org', name: string }, role: { __typename?: 'role', name: string } } | null };

export type DeleteLiveCallStateMutationVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type DeleteLiveCallStateMutation = { __typename?: 'mutation_root', delete_live_call_state_by_pk?: { __typename?: 'live_call_state', user_id: any } | null };

export type InsertBookMutationVariables = Exact<{
  allowInstantMeetings?: InputMaybe<Scalars['Boolean']>;
  bookTopics?: InputMaybe<Book_Topic_Arr_Rel_Insert_Input>;
  bookLanguages?: InputMaybe<Booklanguage_Arr_Rel_Insert_Input>;
  currentBookStateId: Scalars['uuid'];
  maxReaders?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  userId: Scalars['uuid'];
}>;


export type InsertBookMutation = { __typename?: 'mutation_root', insert_book_one?: { __typename?: 'book', id: any } | null };

export type InsertCancelReviewMutationVariables = Exact<{
  readingId: Scalars['uuid'];
  text?: InputMaybe<Scalars['String']>;
}>;


export type InsertCancelReviewMutation = { __typename?: 'mutation_root', insert_cancel_review_one?: { __typename?: 'cancel_review', reading_id: any } | null };

export type InsertMessageMutationVariables = Exact<{
  userId: Scalars['uuid'];
  value: Scalars['String'];
}>;


export type InsertMessageMutation = { __typename?: 'mutation_root', insert_chat_message_one?: { __typename?: 'chat_message', id: any } | null };

export type InsertParticipationEventMutationVariables = Exact<{
  readingId: Scalars['uuid'];
  participationStateId: Scalars['uuid'];
  comment?: InputMaybe<Scalars['String']>;
  userId: Scalars['uuid'];
}>;


export type InsertParticipationEventMutation = { __typename?: 'mutation_root', insert_participationevent_one?: { __typename?: 'participationevent', id: any, created: any, participationstate: { __typename?: 'participationstate', name: string }, reading: { __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', id: any, name: string } }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } } } | null };

export type InsertReadingMutationVariables = Exact<{
  durationId: Scalars['uuid'];
  languageId: Scalars['uuid'];
  maxReaders: Scalars['Int'];
  minReaders: Scalars['Int'];
  organisingBookId: Scalars['uuid'];
  readingStart: Scalars['timestamptz'];
  topicId: Scalars['uuid'];
}>;


export type InsertReadingMutation = { __typename?: 'mutation_root', insert_reading_one?: { __typename?: 'reading', id: any } | null };

export type InsertReadingEventsMutationVariables = Exact<{
  readingEvents: Array<Readingevent_Insert_Input> | Readingevent_Insert_Input;
}>;


export type InsertReadingEventsMutation = { __typename?: 'mutation_root', insert_readingevent?: { __typename?: 'readingevent_mutation_response', affected_rows: number } | null };

export type InsertReviewMutationVariables = Exact<{
  userId: Scalars['uuid'];
  readingId: Scalars['uuid'];
  rating: Scalars['Int'];
  text: Scalars['String'];
}>;


export type InsertReviewMutation = { __typename?: 'mutation_root', insert_review_one?: { __typename?: 'review', id: any } | null };

export type UpdateBookMutationVariables = Exact<{
  id: Scalars['uuid'];
  allowInstantMeetings?: InputMaybe<Scalars['Boolean']>;
  bookTopics: Array<Book_Topic_Insert_Input> | Book_Topic_Insert_Input;
  bookLanguages: Array<Booklanguage_Insert_Input> | Booklanguage_Insert_Input;
  currentBookStateId?: InputMaybe<Scalars['uuid']>;
  maxReaders?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateBookMutation = { __typename?: 'mutation_root', delete_booklanguage?: { __typename?: 'booklanguage_mutation_response', affected_rows: number } | null, insert_booklanguage?: { __typename?: 'booklanguage_mutation_response', affected_rows: number } | null, delete_book_topic?: { __typename?: 'book_topic_mutation_response', affected_rows: number } | null, insert_book_topic?: { __typename?: 'book_topic_mutation_response', affected_rows: number } | null, book?: { __typename?: 'book', allowinstantmeetings: boolean, currentbookstate_id: any, id: any, maxreaders?: number | null, name?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, topic: { __typename?: 'topic', name: string } }>, booklanguages: Array<{ __typename?: 'booklanguage', language_id: any }>, currentstate: { __typename?: 'bookstate', id: any, name: string }, readings: Array<{ __typename?: 'reading', id: any }> } | null };

export type UpdateChatMessageUnreadMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UpdateChatMessageUnreadMutation = { __typename?: 'mutation_root', update_chat_message?: { __typename?: 'chat_message_mutation_response', affected_rows: number } | null };

export type UpdateOnboardingUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  personId: Scalars['uuid'];
  timezone: Scalars['String'];
}>;


export type UpdateOnboardingUserMutation = { __typename?: 'mutation_root', update_person_by_pk?: { __typename?: 'person', id: any } | null };

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['uuid'];
  emailVerified: Scalars['Boolean'];
  externalId: Scalars['String'];
}>;


export type UpdatePersonMutation = { __typename?: 'mutation_root', person?: { __typename?: 'person', id: any, externalId?: string | null, name?: string | null, email: string, country_id: any, emailverified: boolean, language?: { __typename?: 'language', isocode: string } | null, users: Array<{ __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null, person_get_has_accepted_latest_eula?: any | null, language?: { __typename?: 'language', isocode: string } | null }, org: { __typename?: 'org', id: any, name: string }, role: { __typename?: 'role', id: any, name: string }, allowedOrgs?: Array<{ __typename?: 'org', id: any, name: string, parent?: any | null }> | null }> } | null };

export type UpdatePersonProfileMutationVariables = Exact<{
  id: Scalars['uuid'];
  countryId?: InputMaybe<Scalars['uuid']>;
  defaultLanguageId?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
}>;


export type UpdatePersonProfileMutation = { __typename?: 'mutation_root', person?: { __typename?: 'person', id: any } | null };

export type UpdateProfilePersonMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  pronouns?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProfilePersonMutation = { __typename?: 'mutation_root', update_person_by_pk?: { __typename?: 'person', id: any } | null };

export type UpdateReadingMutationVariables = Exact<{
  id: Scalars['uuid'];
  languageId: Scalars['uuid'];
  maxReaders: Scalars['Int'];
  minReaders: Scalars['Int'];
  organisingBookId: Scalars['uuid'];
  durationId: Scalars['uuid'];
  readingStart: Scalars['timestamptz'];
  topicId: Scalars['uuid'];
}>;


export type UpdateReadingMutation = { __typename?: 'mutation_root', reading?: { __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } } | null };

export type UpdateReadingEventCancelledEmailSendMutationVariables = Exact<{
  id: Scalars['uuid'];
  emailSend: Scalars['Boolean'];
}>;


export type UpdateReadingEventCancelledEmailSendMutation = { __typename?: 'mutation_root', update_readingevent_by_pk?: { __typename?: 'readingevent', email_send: boolean, id: any } | null };

export type UpdateReadingLiveCallStateMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  is_hand_raised: Scalars['Boolean'];
}>;


export type UpdateReadingLiveCallStateMutation = { __typename?: 'mutation_root', update_live_call_state_by_pk?: { __typename?: 'live_call_state', is_hand_raised: boolean, last_updated: any } | null };

export type UpdateTimezoneForUserMutationVariables = Exact<{
  personId: Scalars['uuid'];
  timezone: Scalars['String'];
}>;


export type UpdateTimezoneForUserMutation = { __typename?: 'mutation_root', user?: { __typename?: 'person', id: any } | null };

export type UpdateUserMutationVariables = Exact<{
  countryId: Scalars['uuid'];
  defaultLanguageId: Scalars['uuid'];
  email: Scalars['String'];
  name: Scalars['String'];
  orgId: Scalars['uuid'];
  roleId: Scalars['uuid'];
  id: Scalars['uuid'];
  personId: Scalars['uuid'];
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_person_by_pk?: { __typename?: 'person', id: any } | null, user?: { __typename?: 'user', id: any, role_id: any, created: any, person: { __typename?: 'person', id: any, country_id: any, default_language_id?: any | null, email: string, name?: string | null, person_get_has_accepted_latest_eula?: any | null }, org: { __typename?: 'org', id: any, name: string } } | null };

export type UpsertLiveCallStateMutationVariables = Exact<{
  reading_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  is_hand_raised?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpsertLiveCallStateMutation = { __typename?: 'mutation_root', insert_live_call_state_one?: { __typename?: 'live_call_state', last_updated: any } | null };

export type AdminDeleteBookMutationVariables = Exact<{
  userId: Scalars['uuid'];
  personId: Scalars['uuid'];
}>;


export type AdminDeleteBookMutation = { __typename?: 'mutation_root', delete_email_chat_message_unread_reminder?: { __typename?: 'email_chat_message_unread_reminder_mutation_response', affected_rows: number } | null, delete_chat_message?: { __typename?: 'chat_message_mutation_response', affected_rows: number } | null, delete_cancel_review?: { __typename?: 'cancel_review_mutation_response', affected_rows: number } | null, delete_review?: { __typename?: 'review_mutation_response', affected_rows: number } | null, delete_readingevent?: { __typename?: 'readingevent_mutation_response', affected_rows: number } | null, delete_participationevent?: { __typename?: 'participationevent_mutation_response', affected_rows: number } | null, delete_booklanguage?: { __typename?: 'booklanguage_mutation_response', affected_rows: number } | null, delete_book_topic?: { __typename?: 'book_topic_mutation_response', affected_rows: number } | null, delete_reading?: { __typename?: 'reading_mutation_response', affected_rows: number } | null, delete_book?: { __typename?: 'book_mutation_response', affected_rows: number } | null, delete_user?: { __typename?: 'user_mutation_response', affected_rows: number } | null, delete_eula_acceptance?: { __typename?: 'eula_acceptance_mutation_response', affected_rows: number } | null, delete_email_activate_reminder?: { __typename?: 'email_activate_reminder_mutation_response', affected_rows: number } | null, delete_person?: { __typename?: 'person_mutation_response', affected_rows: number } | null };

export type AdminDeleteDebriefingMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminDeleteDebriefingMutation = { __typename?: 'mutation_root', delete_debriefing_by_pk?: { __typename?: 'debriefing', id: any, link: string, date: any } | null };

export type AdminDeleteReadingPlanMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminDeleteReadingPlanMutation = { __typename?: 'mutation_root', delete_readingplan_by_pk?: { __typename?: 'readingplan', id: any } | null };

export type AdminDeleteTopicPillarMutationVariables = Exact<{
  pillarId: Scalars['uuid'];
  topicId: Scalars['uuid'];
}>;


export type AdminDeleteTopicPillarMutation = { __typename?: 'mutation_root', delete_pillar_topic_by_pk?: { __typename?: 'pillar_topic', pillar_id: any, topic_id: any } | null };

export type AdminDeleteUserMutationVariables = Exact<{
  userId: Scalars['uuid'];
  personId: Scalars['uuid'];
  readingplanId?: InputMaybe<Scalars['uuid']>;
}>;


export type AdminDeleteUserMutation = { __typename?: 'mutation_root', delete_pillarconstraint?: { __typename?: 'pillarconstraint_mutation_response', affected_rows: number } | null, delete_email_chat_message_unread_reminder?: { __typename?: 'email_chat_message_unread_reminder_mutation_response', affected_rows: number } | null, delete_email_library_card_expiration_reminder?: { __typename?: 'email_library_card_expiration_reminder_mutation_response', affected_rows: number } | null, delete_email_library_card_expired_reminder?: { __typename?: 'email_library_card_expired_reminder_mutation_response', affected_rows: number } | null, delete_readingplan?: { __typename?: 'readingplan_mutation_response', affected_rows: number } | null, delete_chat_message?: { __typename?: 'chat_message_mutation_response', affected_rows: number } | null, delete_review?: { __typename?: 'review_mutation_response', affected_rows: number } | null, delete_readingevent?: { __typename?: 'readingevent_mutation_response', affected_rows: number } | null, delete_participationevent?: { __typename?: 'participationevent_mutation_response', affected_rows: number } | null, delete_booklanguage?: { __typename?: 'booklanguage_mutation_response', affected_rows: number } | null, delete_book_topic?: { __typename?: 'book_topic_mutation_response', affected_rows: number } | null, delete_reading?: { __typename?: 'reading_mutation_response', affected_rows: number } | null, delete_book?: { __typename?: 'book_mutation_response', affected_rows: number } | null, delete_user?: { __typename?: 'user_mutation_response', affected_rows: number } | null, delete_eula_acceptance?: { __typename?: 'eula_acceptance_mutation_response', affected_rows: number } | null, delete_email_activate_reminder?: { __typename?: 'email_activate_reminder_mutation_response', affected_rows: number } | null, delete_person?: { __typename?: 'person_mutation_response', affected_rows: number } | null };

export type AdminInsertDebriefingMutationVariables = Exact<{
  date: Scalars['timestamptz'];
  link: Scalars['String'];
}>;


export type AdminInsertDebriefingMutation = { __typename?: 'mutation_root', insert_debriefing_one?: { __typename?: 'debriefing', date: any, id: any, link: string } | null };

export type AdminInsertReadingPlanMutationVariables = Exact<{
  creatorId: Scalars['uuid'];
  initialReadings: Scalars['Int'];
  issuedToEmail: Scalars['String'];
  userId: Scalars['uuid'];
  readingPlanDurationId: Scalars['uuid'];
}>;


export type AdminInsertReadingPlanMutation = { __typename?: 'mutation_root', insert_readingplan_one?: { __typename?: 'readingplan', id: any } | null };

export type AdminInsertTextMutationVariables = Exact<{
  id: Scalars['uuid'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonetic?: InputMaybe<Scalars['String']>;
}>;


export type AdminInsertTextMutation = { __typename?: 'mutation_root', insert_topic_one?: { __typename?: 'topic', name: string } | null };

export type AdminInsertTopicPillarMutationVariables = Exact<{
  pillarId: Scalars['uuid'];
  topicId: Scalars['uuid'];
  isPrimary?: InputMaybe<Scalars['Boolean']>;
}>;


export type AdminInsertTopicPillarMutation = { __typename?: 'mutation_root', insert_pillar_topic_one?: { __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string }, topic: { __typename?: 'topic', id: any, name: string } } | null };

export type AdminInsertUserMutationVariables = Exact<{
  countryId: Scalars['uuid'];
  defaultLanguageId: Scalars['uuid'];
  email: Scalars['String'];
  name: Scalars['String'];
  orgId: Scalars['uuid'];
  roleId: Scalars['uuid'];
}>;


export type AdminInsertUserMutation = { __typename?: 'mutation_root', user?: { __typename?: 'user', id: any, person: { __typename?: 'person', email: string } } | null };

export type AdminUpdateReadingPlanMutationVariables = Exact<{
  id: Scalars['uuid'];
  initialReadings: Scalars['Int'];
  issuedToEmail: Scalars['String'];
  userId: Scalars['uuid'];
  readingPlanDurationId: Scalars['uuid'];
}>;


export type AdminUpdateReadingPlanMutation = { __typename?: 'mutation_root', readingPlan?: { __typename?: 'readingplan', id: any, initialreadings: number, remainingreadings?: any | null, issuedtoemail?: string | null, readingplan_duration_id: any, valid_to?: any | null, created: any, user_id?: any | null, creator_id?: any | null } | null };

export type UpdateTopicDescriptionMutationVariables = Exact<{
  id: Scalars['uuid'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTopicDescriptionMutation = { __typename?: 'mutation_root', update_topic_by_pk?: { __typename?: 'topic', description?: string | null } | null };

export type UpdateTopicNameMutationVariables = Exact<{
  id: Scalars['uuid'];
  newName?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTopicNameMutation = { __typename?: 'mutation_root', update_topic_by_pk?: { __typename?: 'topic', name: string } | null };

export type AdminUpdateTopicPillarMutationVariables = Exact<{
  pillarId: Scalars['uuid'];
  topicId: Scalars['uuid'];
  newPillarId?: InputMaybe<Scalars['uuid']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
}>;


export type AdminUpdateTopicPillarMutation = { __typename?: 'mutation_root', update_pillar_topic_by_pk?: { __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string } } | null };

export type AdminUpdateUserMutationVariables = Exact<{
  countryId: Scalars['uuid'];
  defaultLanguageId: Scalars['uuid'];
  email: Scalars['String'];
  name: Scalars['String'];
  orgId: Scalars['uuid'];
  roleId: Scalars['uuid'];
  id: Scalars['uuid'];
  personId: Scalars['uuid'];
}>;


export type AdminUpdateUserMutation = { __typename?: 'mutation_root', update_person_by_pk?: { __typename?: 'person', id: any } | null, user?: { __typename?: 'user', id: any, role_id: any, created: any, person: { __typename?: 'person', id: any, country_id: any, default_language_id?: any | null, email: string, name?: string | null, person_get_has_accepted_latest_eula?: any | null, emailverified: boolean, timezone?: string | null, language?: { __typename?: 'language', id: any, isocode: string } | null }, org: { __typename?: 'org', id: any, name: string } } | null };

export type AdminUpdateTextMutationVariables = Exact<{
  id: Scalars['uuid'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonetic?: InputMaybe<Scalars['String']>;
}>;


export type AdminUpdateTextMutation = { __typename?: 'mutation_root', update_topic_by_pk?: { __typename?: 'topic', name: string } | null };

export type AdminUpsertTextMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonetic?: InputMaybe<Scalars['String']>;
}>;


export type AdminUpsertTextMutation = { __typename?: 'mutation_root', insert_topic_one?: { __typename?: 'topic', name: string } | null };

export type InsertUsersMutationVariables = Exact<{
  users: Array<User_Insert_Input> | User_Insert_Input;
}>;


export type InsertUsersMutation = { __typename?: 'mutation_root', insert_user?: { __typename?: 'user_mutation_response', affected_rows: number } | null };

export type CheckBookReadingOwnershipQueryVariables = Exact<{
  book_id: Scalars['uuid'];
  reading_id: Scalars['uuid'];
}>;


export type CheckBookReadingOwnershipQuery = { __typename?: 'query_root', reading: { __typename?: 'reading_aggregate', does?: { __typename?: 'reading_aggregate_fields', exist: number } | null } };

export type CountUserParticipationEventsByReadingQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  reading_id: Scalars['uuid'];
}>;


export type CountUserParticipationEventsByReadingQuery = { __typename?: 'query_root', participationevent_aggregate: { __typename?: 'participationevent_aggregate', aggregate?: { __typename?: 'participationevent_aggregate_fields', count: number } | null } };

export type GetAllDebriefingsForUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDebriefingsForUsersQuery = { __typename?: 'query_root', debriefing: Array<{ __typename?: 'debriefing', date: any, id: any, link: string }> };

export type GetBookAggregateQueryVariables = Exact<{
  where?: InputMaybe<Book_Bool_Exp>;
}>;


export type GetBookAggregateQuery = { __typename?: 'query_root', book_aggregate: { __typename?: 'book_aggregate', aggregate?: { __typename?: 'book_aggregate_fields', count: number } | null } };

export type GetBookByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetBookByIdQuery = { __typename?: 'query_root', book?: { __typename?: 'book', allowinstantmeetings: boolean, currentbookstate_id: any, id: any, maxreaders?: number | null, name?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, topic: { __typename?: 'topic', name: string } }>, booklanguages: Array<{ __typename?: 'booklanguage', language_id: any }>, currentstate: { __typename?: 'bookstate', id: any, name: string }, readings: Array<{ __typename?: 'reading', id: any }> } | null };

export type GetBookStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBookStatesQuery = { __typename?: 'query_root', bookstates: Array<{ __typename?: 'bookstate', id: any, name: string }> };

export type GetBookTypesQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetBookTypesQuery = { __typename?: 'query_root', bookstates: Array<{ __typename?: 'bookstate', id: any, name: string }>, languages: Array<{ __typename?: 'language', id: any, name: string }>, topics: Array<{ __typename?: 'topic', id: any, name: string }>, users: Array<{ __typename?: 'user', id: any, created: any, person: { __typename?: 'person', name?: string | null }, org: { __typename?: 'org', name: string }, role: { __typename?: 'role', name: string } }> };

export type GetBooksQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
}>;


export type GetBooksQuery = { __typename?: 'query_root', books: Array<{ __typename?: 'book', id: any, name?: string | null, maxreaders?: number | null, currentstate: { __typename?: 'bookstate', name: string }, readings_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } }> };

export type GetBooksByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetBooksByUserIdQuery = { __typename?: 'query_root', books: Array<{ __typename?: 'book', id: any, name?: string | null, maxreaders?: number | null, book_topics: Array<{ __typename?: 'book_topic', topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }>, currentstate: { __typename?: 'bookstate', name: string }, readings: Array<{ __typename?: 'reading', id: any }>, readings_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } }> };

export type GetDefaultsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultsQuery = { __typename?: 'query_root', orgs: Array<{ __typename?: 'org', id: any, name: string }>, roles: Array<{ __typename?: 'role', id: any, name: string }>, languages: Array<{ __typename?: 'language', id: any, name: string, isocode: string }>, countries: Array<{ __typename?: 'country', id: any, isocode: string, name: string }> };

export type GetEulaAcceptancesByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetEulaAcceptancesByUserIdQuery = { __typename?: 'query_root', eula_acceptances: Array<{ __typename?: 'eula_acceptance', accepted: any, eula: { __typename?: 'eula', created: any, id: any, translationkey: string, version: number } }> };

export type GetEulasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEulasQuery = { __typename?: 'query_root', eula: Array<{ __typename?: 'eula', created: any, id: any, translationkey: string, version: number }> };

export type GetLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLanguagesQuery = { __typename?: 'query_root', languages: Array<{ __typename?: 'language', id: any, name: string }> };

export type GetLatestEulaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestEulaQuery = { __typename?: 'query_root', eula: Array<{ __typename?: 'eula', created: any, id: any, translationkey: string, version: number }> };

export type GetNextReadingsByUserIdQueryVariables = Exact<{
  after: Scalars['timestamptz'];
  userId: Scalars['uuid'];
}>;


export type GetNextReadingsByUserIdQuery = { __typename?: 'query_root', nextReading: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', id: any, name: string } }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }> };

export type GetOrgIdByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetOrgIdByUserIdQuery = { __typename?: 'query_root', org?: { __typename?: 'user', org_id: any } | null };

export type GetParticipationEventsPastQueryVariables = Exact<{
  userId: Scalars['uuid'];
  before: Scalars['timestamptz'];
}>;


export type GetParticipationEventsPastQuery = { __typename?: 'query_root', participationevent: Array<{ __typename?: 'participationevent', id: any, created: any, reading: { __typename?: 'reading', id: any, readingstart: any, topic: { __typename?: 'topic', name: string, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', title: string } }> } }, participationstate: { __typename?: 'participationstate', name: string } }> };

export type GetParticipationEventsUpcomingQueryVariables = Exact<{
  userId: Scalars['uuid'];
  after: Scalars['timestamptz'];
}>;


export type GetParticipationEventsUpcomingQuery = { __typename?: 'query_root', participationevent: Array<{ __typename?: 'participationevent', id: any, created: any, reading: { __typename?: 'reading', id: any, readingstart: any, topic: { __typename?: 'topic', name: string, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', title: string } }> } }, participationstate: { __typename?: 'participationstate', name: string } }> };

export type GetParticipationStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParticipationStatesQuery = { __typename?: 'query_root', participationstate: Array<{ __typename?: 'participationstate', id: any, name: string }> };

export type GetPersonQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetPersonQuery = { __typename?: 'query_root', person: Array<{ __typename?: 'person', id: any, externalId?: string | null, name?: string | null, email: string, country_id: any, emailverified: boolean, language?: { __typename?: 'language', isocode: string } | null, users: Array<{ __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null, person_get_has_accepted_latest_eula?: any | null, language?: { __typename?: 'language', isocode: string } | null }, org: { __typename?: 'org', id: any, name: string }, role: { __typename?: 'role', id: any, name: string }, allowedOrgs?: Array<{ __typename?: 'org', id: any, name: string, parent?: any | null }> | null }> }> };

export type GetPillarByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetPillarByIdQuery = { __typename?: 'query_root', pillar?: { __typename?: 'pillar', id: any, title: string, description?: string | null, topic_description?: string | null, pillar_topics: Array<{ __typename?: 'pillar_topic', topic: { __typename?: 'topic', id: any, name: string, description?: string | null, quote?: string | null, readings: Array<{ __typename?: 'reading', id: any, externalid?: string | null, maxreaders?: number | null, readingstart: any }> } }>, literaryQuote?: { __typename?: 'literary_quote', author: string, text: string } | null } | null };

export type GetPillarConstraintsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetPillarConstraintsQuery = { __typename?: 'query_root', pillarconstraint: Array<{ __typename?: 'pillarconstraint', pillar_id: any }> };

export type GetPillarsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPillarsQuery = { __typename?: 'query_root', pillars: Array<{ __typename?: 'pillar', id: any, title: string, description?: string | null, slug?: string | null, topic_description?: string | null }> };

export type GetReadersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReadersQuery = { __typename?: 'query_root', readers: Array<{ __typename?: 'user', id: any, person_id: any, person: { __typename?: 'person', name?: string | null } }> };

export type GetReadingByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
  userIdWhere?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type GetReadingByIdQuery = { __typename?: 'query_root', reading: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', id: any, name: string } }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }> };

export type GetReadingByIdAndUserIdQueryVariables = Exact<{
  id: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type GetReadingByIdAndUserIdQuery = { __typename?: 'query_root', reading?: { __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', id: any, name: string } }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } } | null };

export type GetReadingPlanForUserQueryVariables = Exact<{
  languageId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type GetReadingPlanForUserQuery = { __typename?: 'query_root', readingPlans: Array<{ __typename?: 'readingplan', id: any, initialreadings: number, remainingreadings?: any | null, valid_to?: any | null, activated?: any | null, readingPlanDuration: { __typename?: 'readingplan_duration', id: any, translation: Array<{ __typename?: 'readingplan_duration_translation', name: string }> }, pillarconstraints: Array<{ __typename?: 'pillarconstraint', pillar: { __typename?: 'pillar', id: any, description?: string | null, title: string } }> }>, user?: { __typename?: 'user', person: { __typename?: 'person', id: any, name?: string | null, pronouns?: string | null, person_get_has_accepted_latest_eula?: any | null, timezone?: string | null, language?: { __typename?: 'language', id: any, isocode: string } | null } } | null };

export type GetReadingStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReadingStatesQuery = { __typename?: 'query_root', readingstate: Array<{ __typename?: 'readingstate', id: any, name: string }> };

export type GetReadingTypesForUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetReadingTypesForUserQuery = { __typename?: 'query_root', books: Array<{ __typename?: 'book', id: any, name?: string | null, maxreaders?: number | null, book_topics: Array<{ __typename?: 'book_topic', topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }>, currentstate: { __typename?: 'bookstate', name: string }, readings_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } }>, languages: Array<{ __typename?: 'language', id: any, name: string }>, durations: Array<{ __typename?: 'duration', id: any, minutes: number }> };

export type GetReadingWithReviewsQueryVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type GetReadingWithReviewsQuery = { __typename?: 'query_root', reading?: { __typename?: 'reading', id: any, topicName?: string | null, topic: { __typename?: 'topic', name: string }, cancel_reviews: Array<{ __typename?: 'cancel_review', user_id: any }>, reviews: Array<{ __typename?: 'review', user_id: any, id: any }> } | null };

export type GetReadingsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  after: Scalars['timestamptz'];
}>;


export type GetReadingsQuery = { __typename?: 'query_root', readings: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }>, reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type GetReadingsBookPastQueryVariables = Exact<{
  userId: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  before?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetReadingsBookPastQuery = { __typename?: 'query_root', books: Array<{ __typename?: 'book', id: any, name?: string | null, created: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, readings: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }> }> };

export type GetReadingsBookUpcomingQueryVariables = Exact<{
  userId: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  after?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetReadingsBookUpcomingQuery = { __typename?: 'query_root', books: Array<{ __typename?: 'book', id: any, name?: string | null, created: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, readings: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }> }> };

export type GetReadingsByPillarIdQueryVariables = Exact<{
  pillarId?: InputMaybe<Scalars['uuid']>;
  after: Scalars['timestamptz'];
}>;


export type GetReadingsByPillarIdQuery = { __typename?: 'query_root', readings: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }>, reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type GetReadingsByTopicSlugQueryVariables = Exact<{
  userId: Scalars['uuid'];
  topicSlug: Scalars['String'];
  after: Scalars['timestamptz'];
}>;


export type GetReadingsByTopicSlugQuery = { __typename?: 'query_root', readings: Array<{ __typename?: 'reading', id: any, readingstart: any, maxreaders?: number | null, minreaders?: number | null, participationEventName?: string | null, readingEventStatusName?: string | null, duration_id: any, topicName?: string | null, seats_available?: any | null, participationevents: Array<{ __typename?: 'participationevent', participationstate: { __typename?: 'participationstate', id: any, name: string } }>, language: { __typename?: 'language', id: any, name: string }, duration: { __typename?: 'duration', minutes: number }, topic: { __typename?: 'topic', id: any, name: string, slug?: string | null, description?: string | null, short_description?: string | null, quote?: string | null, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null }>, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', id: any, name?: string | null, created: any, topicName?: string | null, pronouns?: string | null, user_id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any, book_id: any, topicname?: string | null, topic: { __typename?: 'topic', id: any, name: string } }> } }>, reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type GetReviewsByReadingIdQueryVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type GetReviewsByReadingIdQuery = { __typename?: 'query_root', reviews: Array<{ __typename?: 'review', id: any, text?: string | null, created: any, personName?: string | null }>, reading_by_pk?: { __typename?: 'reading', book: { __typename?: 'book', name?: string | null } } | null };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'query_root', roles: Array<{ __typename?: 'role', id: any, name: string }> };

export type GetTimezonesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimezonesQuery = { __typename?: 'query_root', timezones: Array<{ __typename?: 'timezone', abbr: string, id: any, name: string, offset: any }> };

export type GetTopicBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetTopicBySlugQuery = { __typename?: 'query_root', topic: Array<{ __typename?: 'topic', id: any, name: string, description?: string | null, quote?: string | null, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }> };

export type GetTopicsQueryVariables = Exact<{
  after: Scalars['timestamptz'];
}>;


export type GetTopicsQuery = { __typename?: 'query_root', topics: Array<{ __typename?: 'topic', id: any, name: string }> };

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserByIdQuery = { __typename?: 'query_root', user?: { __typename?: 'user', id: any, role_id: any, created: any, person: { __typename?: 'person', id: any, country_id: any, default_language_id?: any | null, email: string, name?: string | null, emailverified: boolean, person_get_has_accepted_latest_eula?: any | null, pronouns?: string | null, timezone?: string | null, language?: { __typename?: 'language', id: any, isocode: string } | null }, org: { __typename?: 'org', id: any, name: string } } | null };

export type GetUserParticipationEventsByReadingQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  reading_id: Scalars['uuid'];
}>;


export type GetUserParticipationEventsByReadingQuery = { __typename?: 'query_root', participationevent: Array<{ __typename?: 'participationevent', created: any, participationstate: { __typename?: 'participationstate', name: string } }> };

export type GetUserTypesQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type GetUserTypesQuery = { __typename?: 'query_root', books: Array<{ __typename?: 'book', id: any, name?: string | null, maxreaders?: number | null, currentstate: { __typename?: 'bookstate', name: string }, readings: Array<{ __typename?: 'reading', id: any }> }>, countries: Array<{ __typename?: 'country', id: any, name: string }>, currentUser?: { __typename?: 'user', org_id: any } | null, languages: Array<{ __typename?: 'language', id: any, name: string }>, roles: Array<{ __typename?: 'role', id: any, name: string }>, timezones: Array<{ __typename?: 'timezone', id: any, name: string, offset: any }> };

export type GetUserVerificationDataByUserIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserVerificationDataByUserIdQuery = { __typename?: 'query_root', user_by_pk?: { __typename?: 'user', id: any, person: { __typename?: 'person', emailverified: boolean, name?: string | null, person_get_has_accepted_latest_eula?: any | null, timezone?: string | null } } | null };

export type GetAllDebriefingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDebriefingsQuery = { __typename?: 'query_root', debriefing: Array<{ __typename?: 'debriefing', date: any, id: any, link: string }> };

export type GetOrgsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgsQuery = { __typename?: 'query_root', orgs: Array<{ __typename?: 'org', id: any, parent?: any | null, name: string, country_id?: any | null }> };

export type AdminGetReadingPlanByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AdminGetReadingPlanByIdQuery = { __typename?: 'query_root', readingPlan?: { __typename?: 'readingplan', id: any, initialreadings: number, remainingreadings?: any | null, issuedtoemail?: string | null, readingplan_duration_id: any, valid_to?: any | null, created: any, user_id?: any | null, creator_id?: any | null } | null };

export type AdminGetReadingPlanTypesQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;


export type AdminGetReadingPlanTypesQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'user', id: any, person: { __typename?: 'person', email: string, name?: string | null }, org: { __typename?: 'org', name: string } }> };

export type AdminGetReadingPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGetReadingPlansQuery = { __typename?: 'query_root', readingPlans: Array<{ __typename?: 'readingplan', id: any, initialreadings: number, remainingreadings?: any | null, valid_to?: any | null, created: any, user?: { __typename?: 'user', person: { __typename?: 'person', email: string, name?: string | null } } | null }> };

export type AdminGetReadings2024QueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGetReadings2024Query = { __typename?: 'query_root', reading: Array<{ __typename?: 'reading', created: any, readingEventStatusName?: string | null, seats_available?: any | null, topicName?: string | null }> };

export type AdminGetReadingsPerWeekQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGetReadingsPerWeekQuery = { __typename?: 'query_root', get_readings_per_week: Array<{ __typename?: 'readings_per_week', week_start?: any | null, readings_count?: number | null }> };

export type GetAllTopicsQueryVariables = Exact<{
  topicSearch: Scalars['String'];
}>;


export type GetAllTopicsQuery = { __typename?: 'query_root', topic: Array<{ __typename?: 'topic', id: any, name: string, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', title: string } }> }> };

export type GetTopicByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTopicByIdQuery = { __typename?: 'query_root', topic: Array<{ __typename?: 'topic', id: any, name: string, description?: string | null, phonetic?: string | null, quote?: string | null, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }> };

export type AdminNewsletterGeneralStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminNewsletterGeneralStatisticsQuery = { __typename?: 'query_root', readersAmount: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null }, booksAmount: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null }, loggedIn2024BooksAmount: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null }, loggedIn2024ReadersAmount: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null }, acceptedEULA2024ReadersAmount: { __typename?: 'user_aggregate', aggregate?: { __typename?: 'user_aggregate_fields', count: number } | null }, scheduledReadings: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null }, incompleteReadings: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null }, canceledReadings: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null }, totalReadings: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type GetBookParticipantsByReadingIdSubscriptionVariables = Exact<{
  reading_id: Scalars['uuid'];
}>;


export type GetBookParticipantsByReadingIdSubscription = { __typename?: 'subscription_root', view_readings_with_book_info: Array<{ __typename?: 'view_readings_with_book_info', reading_id?: any | null, user_id?: any | null, person_name?: string | null }> };

export type GetReaderParticipantsByReadingIdSubscriptionVariables = Exact<{
  reading_id: Scalars['uuid'];
}>;


export type GetReaderParticipantsByReadingIdSubscription = { __typename?: 'subscription_root', view_participationstates_with_user_info: Array<{ __typename?: 'view_participationstates_with_user_info', reading_id?: any | null, user_id?: any | null, person_name?: string | null, participationstate?: string | null, created?: any | null }> };

export type GetReadingLiveCallStatesSubscriptionVariables = Exact<{
  reading_id: Scalars['uuid'];
}>;


export type GetReadingLiveCallStatesSubscription = { __typename?: 'subscription_root', live_call_state: Array<{ __typename?: 'live_call_state', user_id: any, personName?: string | null, is_hand_raised: boolean, role?: string | null, last_updated: any }> };

export type GetCreateAdminDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreateAdminDataQuery = { __typename?: 'query_root', country: Array<{ __typename?: 'country', id: any }>, language: Array<{ __typename?: 'language', id: any }>, role: Array<{ __typename?: 'role', id: any }> };

export type GetCreateLibrarianDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreateLibrarianDataQuery = { __typename?: 'query_root', country: Array<{ __typename?: 'country', id: any }>, language: Array<{ __typename?: 'language', id: any }>, role: Array<{ __typename?: 'role', id: any }> };

export type GetCreateReaderDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreateReaderDataQuery = { __typename?: 'query_root', country: Array<{ __typename?: 'country', id: any }>, role: Array<{ __typename?: 'role', id: any }>, language: Array<{ __typename?: 'language', id: any }> };

export type ScheduleReadingGetDataQueryVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type ScheduleReadingGetDataQuery = { __typename?: 'query_root', scheduled_email_reminder_by_pk?: { __typename?: 'scheduled_email_reminder', event_id: any } | null, reading_by_pk?: { __typename?: 'reading', readingstart: any, readingevents: Array<{ __typename?: 'readingevent', readingstate: { __typename?: 'readingstate', name: string } }> } | null };

export type ScheduleReadingInsertScheduledEmailMutationVariables = Exact<{
  eventId: Scalars['uuid'];
  readingId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type ScheduleReadingInsertScheduledEmailMutation = { __typename?: 'mutation_root', insert_scheduled_email_reminder_one?: { __typename?: 'scheduled_email_reminder', event_id: any } | null };

export type GetCancelEventDataQueryVariables = Exact<{
  readingEventId: Scalars['uuid'];
}>;


export type GetCancelEventDataQuery = { __typename?: 'query_root', readingevent_by_pk?: { __typename?: 'readingevent', email_send: boolean, canceledBy: { __typename?: 'user', personName?: string | null, id: any }, reading: { __typename?: 'reading', organisingbook_id: any, readingstart: any, topicName?: string | null, topic: { __typename?: 'topic', pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', title: string } }> }, book: { __typename?: 'book', name?: string | null, user: { __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null, email: string, timezone?: string | null, language?: { __typename?: 'language', isocode: string } | null } } }, readers: Array<{ __typename?: 'participationevent', created: any, user_id: any, participationstate: { __typename?: 'participationstate', name: string }, user: { __typename?: 'user', person: { __typename?: 'person', email: string, name?: string | null, timezone?: string | null, language?: { __typename?: 'language', isocode: string } | null } } }> } } | null };

export type GetParticipationEventDetailsQueryVariables = Exact<{
  participationEventId: Scalars['uuid'];
}>;


export type GetParticipationEventDetailsQuery = { __typename?: 'query_root', participationevent_by_pk?: { __typename?: 'participationevent', id: any, comment?: string | null, created: any, reading: { __typename?: 'reading', id: any, topicName?: string | null, readingstart: any, readingEventStatusName?: string | null, organisingbook_id: any, book: { __typename?: 'book', user: { __typename?: 'user', person: { __typename?: 'person', name?: string | null } } } }, user: { __typename?: 'user', person: { __typename?: 'person', email: string, name?: string | null, timezone?: string | null, language?: { __typename?: 'language', isocode: string } | null } } } | null };

export type GetEventScheduledDetailsQueryVariables = Exact<{
  readingEventId: Scalars['uuid'];
}>;


export type GetEventScheduledDetailsQuery = { __typename?: 'query_root', readingevent_by_pk?: { __typename?: 'readingevent', comment?: string | null, user: { __typename?: 'user', scheduledBy?: string | null }, reading: { __typename?: 'reading', id: any, topicName?: string | null, readingstart: any, readingEventStatusName?: string | null, organisingbook_id: any, book: { __typename?: 'book', name?: string | null, user: { __typename?: 'user', person: { __typename?: 'person', email: string, name?: string | null, timezone?: string | null } } }, language: { __typename?: 'language', isocode: string } } } | null };

export type ScheduleReadingDeleteScheduledEmailMutationVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type ScheduleReadingDeleteScheduledEmailMutation = { __typename?: 'mutation_root', delete_scheduled_email_reminder_by_pk?: { __typename?: 'scheduled_email_reminder', event_id: any } | null };

export type AdminEditBookGetDataQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type AdminEditBookGetDataQuery = { __typename?: 'query_root', bookstate: Array<{ __typename?: 'bookstate', id: any }>, country: Array<{ __typename?: 'country', id: any }>, language: Array<{ __typename?: 'language', id: any, name: string }>, org: Array<{ __typename?: 'org', id: any, parent?: any | null, name: string, country_id?: any | null }>, role: Array<{ __typename?: 'role', id: any }>, topic: Array<{ __typename?: 'topic', id: any, name: string }>, user_by_pk?: { __typename?: 'user', org_id: any, person: { __typename?: 'person', email: string, name?: string | null }, role: { __typename?: 'role', name: string }, books: Array<{ __typename?: 'book', name?: string | null, id: any, booklanguages: Array<{ __typename?: 'booklanguage', language: { __typename?: 'language', id: any, name: string } }>, book_topics: Array<{ __typename?: 'book_topic', topic: { __typename?: 'topic', id: any, name: string } }> }> } | null };

export type AdminEditBookInsertMutationVariables = Exact<{
  bookLanguages: Array<Booklanguage_Insert_Input> | Booklanguage_Insert_Input;
  bookName: Scalars['String'];
  bookTopics: Array<Book_Topic_Insert_Input> | Book_Topic_Insert_Input;
  currentBookStateId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type AdminEditBookInsertMutation = { __typename?: 'mutation_root', insert_book_one?: { __typename?: 'book', id: any } | null };

export type AdminEditBookUpdateMutationVariables = Exact<{
  bookId: Scalars['uuid'];
  bookName: Scalars['String'];
  bookTopics: Array<Book_Topic_Insert_Input> | Book_Topic_Insert_Input;
  languages: Array<Booklanguage_Insert_Input> | Booklanguage_Insert_Input;
}>;


export type AdminEditBookUpdateMutation = { __typename?: 'mutation_root', update_book_by_pk?: { __typename?: 'book', id: any } | null, delete_booklanguage?: { __typename?: 'booklanguage_mutation_response', affected_rows: number } | null, insert_booklanguage?: { __typename?: 'booklanguage_mutation_response', affected_rows: number } | null, delete_book_topic?: { __typename?: 'book_topic_mutation_response', affected_rows: number } | null, insert_book_topic?: { __typename?: 'book_topic_mutation_response', affected_rows: number } | null };

export type AdminCreateBookGetDataQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminCreateBookGetDataQuery = { __typename?: 'query_root', bookstate: Array<{ __typename?: 'bookstate', id: any }>, country: Array<{ __typename?: 'country', id: any }>, default_language: Array<{ __typename?: 'language', id: any }>, language: Array<{ __typename?: 'language', id: any, name: string }>, org: Array<{ __typename?: 'org', id: any, parent?: any | null, name: string, country_id?: any | null }>, role: Array<{ __typename?: 'role', id: any }>, topic: Array<{ __typename?: 'topic', id: any, name: string }> };

export type AdminCreateBookInsertMutationVariables = Exact<{
  bookLanguages: Array<Booklanguage_Insert_Input> | Booklanguage_Insert_Input;
  bookTopics: Array<Book_Topic_Insert_Input> | Book_Topic_Insert_Input;
  currentBookStateId: Scalars['uuid'];
  countryId: Scalars['uuid'];
  email: Scalars['String'];
  personName?: InputMaybe<Scalars['String']>;
  bookName: Scalars['String'];
  orgId: Scalars['uuid'];
  roleId: Scalars['uuid'];
  defaultLanguageId: Scalars['uuid'];
}>;


export type AdminCreateBookInsertMutation = { __typename?: 'mutation_root', insert_user_one?: { __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null } } | null };

export type AdminCreateOrgGetOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminCreateOrgGetOptionsQuery = { __typename?: 'query_root', country: Array<{ __typename?: 'country', id: any, name: string }> };

export type AdminReadersReadingPlanDurationsQueryVariables = Exact<{
  languageId: Scalars['uuid'];
}>;


export type AdminReadersReadingPlanDurationsQuery = { __typename?: 'query_root', readingplan_duration: Array<{ __typename?: 'readingplan_duration', id: any, translation: Array<{ __typename?: 'readingplan_duration_translation', name: string }> }> };

export type AdminEditReaderGetDataQueryVariables = Exact<{
  languageId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type AdminEditReaderGetDataQuery = { __typename?: 'query_root', org: Array<{ __typename?: 'org', id: any, parent?: any | null, name: string, country_id?: any | null }>, readingplan_duration: Array<{ __typename?: 'readingplan_duration', id: any, translation: Array<{ __typename?: 'readingplan_duration_translation', name: string }> }>, readingplan: Array<{ __typename?: 'readingplan', id: any, activated?: any | null, creator_id?: any | null, initialreadings: number, valid_to?: any | null, readingplan_duration_id: any, user_id?: any | null, pillarconstraints: Array<{ __typename?: 'pillarconstraint', pillarofprejudice_id: any, pillar: { __typename?: 'pillar', title: string } }> }>, pillars: Array<{ __typename?: 'pillar', id: any, title: string, description?: string | null, slug?: string | null }> };

export type AdminEditReaderInsertReadingPlanMutationVariables = Exact<{
  creatorUserId: Scalars['uuid'];
  initialReadings: Scalars['Int'];
  pillarConstraints: Array<Pillarconstraint_Insert_Input> | Pillarconstraint_Insert_Input;
  readingPlanDurationId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type AdminEditReaderInsertReadingPlanMutation = { __typename?: 'mutation_root', insert_readingplan_one?: { __typename?: 'readingplan', id: any } | null };

export type AdminEditReaderUpdateReadingPlanMutationVariables = Exact<{
  activated?: InputMaybe<Scalars['timestamptz']>;
  initialReadings: Scalars['Int'];
  pillarConstraints: Array<Pillarconstraint_Insert_Input> | Pillarconstraint_Insert_Input;
  readingPlanDurationId: Scalars['uuid'];
  readingPlanId: Scalars['uuid'];
}>;


export type AdminEditReaderUpdateReadingPlanMutation = { __typename?: 'mutation_root', update_readingplan_by_pk?: { __typename?: 'readingplan', id: any } | null, delete_pillarconstraint?: { __typename?: 'pillarconstraint_mutation_response', affected_rows: number } | null, insert_pillarconstraint?: { __typename?: 'pillarconstraint_mutation_response', affected_rows: number } | null };

export type AdminReadingGetDataQueryVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type AdminReadingGetDataQuery = { __typename?: 'query_root', reading_by_pk?: { __typename?: 'reading', readingEventStatusName?: string | null, readingstart: any, topicName?: string | null, book: { __typename?: 'book', user_id: any, name?: string | null, user: { __typename?: 'user', person: { __typename?: 'person', name?: string | null, pronouns?: string | null } } }, participationevents: Array<{ __typename?: 'participationevent', created: any, user_id: any, participationstate: { __typename?: 'participationstate', name: string }, user: { __typename?: 'user', person: { __typename?: 'person', name?: string | null, pronouns?: string | null } } }>, review_book?: { __typename?: 'review_book', review_book_option_id: any, text?: string | null } | null, cancel_reviews: Array<{ __typename?: 'cancel_review', text?: string | null, user_id: any }>, reviews: Array<{ __typename?: 'review', personName?: string | null, rating?: number | null, text?: string | null, user_id: any }> } | null, review_book_option: Array<{ __typename?: 'review_book_option', id: any, translationKey: string }> };

export type AdminReadingIndexGetReadingsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  readingWhere: Reading_Bool_Exp;
  sortDirection: Order_By;
}>;


export type AdminReadingIndexGetReadingsQuery = { __typename?: 'query_root', reading: Array<{ __typename?: 'reading', id: any, maxreaders?: number | null, readingEventStatusName?: string | null, readingstart: any, seats_available?: any | null, topicName?: string | null, topic: { __typename?: 'topic', id: any, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> }, book: { __typename?: 'book', user: { __typename?: 'user', person: { __typename?: 'person', name?: string | null } } } }>, reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null } };

export type GetAllPillarsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPillarsQuery = { __typename?: 'query_root', pillar: Array<{ __typename?: 'pillar', id: any, title: string }> };

export type AdminUserGetByIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type AdminUserGetByIdQuery = { __typename?: 'query_root', book_aggregate: { __typename?: 'book_aggregate', aggregate?: { __typename?: 'book_aggregate_fields', count: number } | null }, eula: Array<{ __typename?: 'eula', id: any }>, role: Array<{ __typename?: 'role', id: any, name: string }>, org: Array<{ __typename?: 'org', id: any, name: string }>, user: Array<{ __typename?: 'user', org_id: any, person: { __typename?: 'person', email: string, emailverified: boolean, created: any, name?: string | null, timezone?: string | null, pronouns?: string | null, eula_acceptances: Array<{ __typename?: 'eula_acceptance', eula_id: any }> }, readingplan?: { __typename?: 'readingplan', id: any, remainingreadings?: any | null, readingplan_duration_id: any, updated?: any | null, initialreadings: number, valid_to?: any | null, user_id?: any | null, created: any, creator?: { __typename?: 'user', id: any, person: { __typename?: 'person', name?: string | null } } | null, updatedBy?: { __typename?: 'user', person: { __typename?: 'person', name?: string | null } } | null, pillarconstraints: Array<{ __typename?: 'pillarconstraint', pillarofprejudice_id: any, pillar: { __typename?: 'pillar', title: string, pillar_topics: Array<{ __typename?: 'pillar_topic', topic: { __typename?: 'topic', book_topics: Array<{ __typename?: 'book_topic', book_id: any }> } }> } }> } | null, role: { __typename?: 'role', name: string }, books: Array<{ __typename?: 'book', id: any, name?: string | null, book_topics: Array<{ __typename?: 'book_topic', topicname?: string | null, topic: { __typename?: 'topic', name: string, id: any, description?: string | null, created: any, short_description?: string | null, pillar_topics: Array<{ __typename?: 'pillar_topic', isprimary: boolean, pillar: { __typename?: 'pillar', description?: string | null, id: any, title: string } }> } }> }> }> };

export type AdminUserGetUpdateUserRoleMutationVariables = Exact<{
  roleId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type AdminUserGetUpdateUserRoleMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: any } | null };

export type AdminUsersGetUsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  personWhere?: Person_Bool_Exp;
  roleName: Scalars['String'];
}>;


export type AdminUsersGetUsersQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'view_users_with_next_reading', user_id?: any | null, next_reading?: { __typename?: 'reading', id: any, topicName?: string | null, readingstart: any } | null, user?: { __typename?: 'user', id: any, created: any, books: Array<{ __typename?: 'book', name?: string | null }>, person: { __typename?: 'person', id: any, name?: string | null, email: string, pronouns?: string | null }, org: { __typename?: 'org', name: string }, role: { __typename?: 'role', name: string }, readingplan?: { __typename?: 'readingplan', id: any, remainingreadings?: any | null, valid_to?: any | null } | null } | null }>, user_aggregate: { __typename?: 'view_users_with_next_reading_aggregate', aggregate?: { __typename?: 'view_users_with_next_reading_aggregate_fields', count: number } | null } };

export type BookCreateReadingGetDataQueryVariables = Exact<{
  userId: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  topicId: Scalars['uuid'];
}>;


export type BookCreateReadingGetDataQuery = { __typename?: 'query_root', book: Array<{ __typename?: 'book', id: any, book_topics: Array<{ __typename?: 'book_topic', topic_id: any }> }>, book_get_number_of_readings: Array<{ __typename?: 'virtual_book_get_number_of_readings', book_id: any, readings_past_day: number, readings_past_month: number, readings_past_week: number }>, reading: Array<{ __typename?: 'reading', id: any }>, number_of_readings: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null }, number_of_topics: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null }, max_number_of_simultaneous_readings: Array<{ __typename?: 'reading_setting', value: string }>, max_number_of_simultaneous_topics: Array<{ __typename?: 'reading_setting', value: string }> };

export type BookCreateReadingInsertMutationVariables = Exact<{
  bookId: Scalars['uuid'];
  durationId: Scalars['uuid'];
  languageId: Scalars['uuid'];
  maxReaders?: InputMaybe<Scalars['Int']>;
  minReaders?: InputMaybe<Scalars['Int']>;
  readingStart: Scalars['timestamptz'];
  readingStateId: Scalars['uuid'];
  topicId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type BookCreateReadingInsertMutation = { __typename?: 'mutation_root', insert_reading_one?: { __typename?: 'reading', id: any } | null };

export type GetUnverifiedPersonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnverifiedPersonsQuery = { __typename?: 'query_root', person: Array<{ __typename?: 'person', created: any, email: string, emailverified: boolean, users: Array<{ __typename?: 'user', role: { __typename?: 'role', name: string } }> }> };

export type AdminGetYearlyReportQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGetYearlyReportQuery = { __typename?: 'query_root', person: Array<{ __typename?: 'person', created: any, emailverified: boolean, email: string, lastlogin?: any | null, timezone?: string | null, users: Array<{ __typename?: 'user', org: { __typename?: 'org', name: string }, role: { __typename?: 'role', name: string } }> }> };

export type ReadingReminderGetDataQueryVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type ReadingReminderGetDataQuery = { __typename?: 'query_root', reading_by_pk?: { __typename?: 'reading', maxreaders?: number | null, readingEventStatusName?: string | null, readingstart: any, seats_available?: any | null, book: { __typename?: 'book', user: { __typename?: 'user', id: any, person: { __typename?: 'person', email: string, timezone?: string | null, language?: { __typename?: 'language', isocode: string } | null } } }, readers: Array<{ __typename?: 'participationevent', created: any, user_id: any, participationstate: { __typename?: 'participationstate', name: string }, user: { __typename?: 'user', id: any, person: { __typename?: 'person', email: string, name?: string | null, timezone?: string | null, language?: { __typename?: 'language', isocode: string } | null } } }> } | null, readingstate: Array<{ __typename?: 'readingstate', id: any }> };

export type ReadingReminderCancelReadingMutationVariables = Exact<{
  readingId: Scalars['uuid'];
  readingstate_id: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;


export type ReadingReminderCancelReadingMutation = { __typename?: 'mutation_root', insert_readingevent_one?: { __typename?: 'readingevent', id: any } | null };

export type GetParticipationEventStateNameQueryVariables = Exact<{
  participationStateId: Scalars['uuid'];
}>;


export type GetParticipationEventStateNameQuery = { __typename?: 'query_root', participationstate_by_pk?: { __typename?: 'participationstate', name: string } | null };

export type GetEventStateNameQueryVariables = Exact<{
  eventStateId: Scalars['uuid'];
}>;


export type GetEventStateNameQuery = { __typename?: 'query_root', readingstate_by_pk?: { __typename?: 'readingstate', name: string } | null };

export type ApiGetRolesForUserRoleChangedQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiGetRolesForUserRoleChangedQuery = { __typename?: 'query_root', roles: Array<{ __typename?: 'role', id: any, name: string }> };

export type ApiGetPersonForUserRoleChangedQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApiGetPersonForUserRoleChangedQuery = { __typename?: 'query_root', person?: { __typename?: 'person', email: string, name?: string | null, language?: { __typename?: 'language', isocode: string } | null } | null };

export type BookCreateReadingGetDefaultValuesQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type BookCreateReadingGetDefaultValuesQuery = { __typename?: 'query_root', book_topic: Array<{ __typename?: 'book_topic', book_id: any, topic: { __typename?: 'topic', id: any, name: string } }>, duration: Array<{ __typename?: 'duration', id: any }>, language: Array<{ __typename?: 'language', id: any }>, readingstate: Array<{ __typename?: 'readingstate', id: any }> };

export type BookIndexGetNextReadingQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type BookIndexGetNextReadingQuery = { __typename?: 'query_root', reading: Array<{ __typename?: 'reading', id: any, maxreaders?: number | null, readingEventStatusName?: string | null, readingstart: any, seats_available?: any | null, topicName?: string | null }> };

export type BookIndexGetReadingsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  readingWhere: Reading_Bool_Exp;
}>;


export type BookIndexGetReadingsQuery = { __typename?: 'query_root', reading_aggregate: { __typename?: 'reading_aggregate', aggregate?: { __typename?: 'reading_aggregate_fields', count: number } | null }, reading: Array<{ __typename?: 'reading', id: any, maxreaders?: number | null, readingEventStatusName?: string | null, readingstart: any, seats_available?: any | null, topicName?: string | null, topic: { __typename?: 'topic', id: any, pillar_topics: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', id: any, title: string, description?: string | null } }> } }> };

export type BookIndexGetTopicsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;


export type BookIndexGetTopicsQuery = { __typename?: 'query_root', book_topic: Array<{ __typename?: 'book_topic', book_id: any, topic: { __typename?: 'topic', id: any, name: string } }>, pillar_topic: Array<{ __typename?: 'pillar_topic', pillar: { __typename?: 'pillar', title: string }, topic: { __typename?: 'topic', book_topics: Array<{ __typename?: 'book_topic', topicname?: string | null, book_id: any, topic: { __typename?: 'topic', id: any, name: string } }> } }> };

export type GetReviewBookQueryVariables = Exact<{
  readingId: Scalars['uuid'];
}>;


export type GetReviewBookQuery = { __typename?: 'query_root', review_book_by_pk?: { __typename?: 'review_book', review_book_option_id: any } | null };

export type GetBookReviewOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBookReviewOptionsQuery = { __typename?: 'query_root', review_book_option: Array<{ __typename?: 'review_book_option', id: any, translationKey: string }> };

export type InsertReviewBookMutationVariables = Exact<{
  readingId: Scalars['uuid'];
  reviewBookOptionId: Scalars['uuid'];
  text: Scalars['String'];
}>;


export type InsertReviewBookMutation = { __typename?: 'mutation_root', insert_review_book_one?: { __typename?: 'review_book', reading_id: any } | null };

export const CoreOrgFieldsFragmentDoc = gql`
    fragment CoreOrgFields on org {
  id
  parent
  name
  country {
    id
    isocode
    name
  }
}
    `;
export const CoreParticipationEventFieldsFragmentDoc = gql`
    fragment CoreParticipationEventFields on participationevent {
  id
  created
  reading {
    id
    topic {
      name
      pillar_topics {
        pillar {
          title
        }
      }
    }
    readingstart
  }
  participationstate {
    name
  }
}
    `;
export const CorePersonFieldsFragmentDoc = gql`
    fragment CorePersonFields on person {
  id
  externalId
  name
  email
  country_id
  emailverified
  language {
    isocode
  }
  users {
    id
    person {
      name
      language {
        isocode
      }
      person_get_has_accepted_latest_eula
    }
    org {
      id
      name
    }
    role {
      id
      name
    }
    allowedOrgs {
      id
      name
      parent
    }
  }
}
    `;
export const CoreReadingFieldsFragmentDoc = gql`
    fragment CoreReadingFields on reading {
  id
  readingstart
  language {
    id
    name
  }
  maxreaders
  minreaders
  participationEventName
  readingEventStatusName
  duration_id
  duration {
    minutes
  }
  topicName
  topic {
    id
    name
    slug
    description
    short_description
    quote
    book_topics {
      topic_id
      book_id
      topicname
    }
    pillar_topics {
      isprimary
      pillar {
        id
        title
        description
      }
    }
  }
  book {
    id
    name
    created
    topicName
    pronouns
    user_id
    book_topics {
      topic_id
      book_id
      topicname
      topic {
        id
        name
      }
    }
  }
  seats_available
}
    `;
export const TestCreateReadingGetDurationDocument = gql`
    query TestCreateReadingGetDuration {
  duration(limit: 1) {
    id
  }
  language(limit: 1) {
    id
  }
  readingstate(limit: 1) {
    id
  }
}
    `;

/**
 * __useTestCreateReadingGetDurationQuery__
 *
 * To run a query within a React component, call `useTestCreateReadingGetDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestCreateReadingGetDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestCreateReadingGetDurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestCreateReadingGetDurationQuery(baseOptions?: Apollo.QueryHookOptions<TestCreateReadingGetDurationQuery, TestCreateReadingGetDurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestCreateReadingGetDurationQuery, TestCreateReadingGetDurationQueryVariables>(TestCreateReadingGetDurationDocument, options);
      }
export function useTestCreateReadingGetDurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestCreateReadingGetDurationQuery, TestCreateReadingGetDurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestCreateReadingGetDurationQuery, TestCreateReadingGetDurationQueryVariables>(TestCreateReadingGetDurationDocument, options);
        }
export type TestCreateReadingGetDurationQueryHookResult = ReturnType<typeof useTestCreateReadingGetDurationQuery>;
export type TestCreateReadingGetDurationLazyQueryHookResult = ReturnType<typeof useTestCreateReadingGetDurationLazyQuery>;
export type TestCreateReadingGetDurationQueryResult = Apollo.QueryResult<TestCreateReadingGetDurationQuery, TestCreateReadingGetDurationQueryVariables>;
export const TestCreateReadingInsertUserDocument = gql`
    mutation TestCreateReadingInsertUser($email: String!, $orgName: String!, $roleName: String!) {
  insert_user_one(
    object: {org: {data: {name: $orgName}, on_conflict: {constraint: org_ak_name, update_columns: [created]}}, person: {data: {country: {data: {isocode: "USA", name: "United States"}, on_conflict: {update_columns: [created], constraint: country_ak_isocode}}, email: $email}, on_conflict: {constraint: person_ak_1, update_columns: [created]}}, role: {data: {name: $roleName}, on_conflict: {constraint: role_ak_name, update_columns: [description]}}}
  ) {
    id
    org {
      id
      name
      parent
    }
    person {
      email
    }
    role {
      name
    }
  }
}
    `;
export type TestCreateReadingInsertUserMutationFn = Apollo.MutationFunction<TestCreateReadingInsertUserMutation, TestCreateReadingInsertUserMutationVariables>;

/**
 * __useTestCreateReadingInsertUserMutation__
 *
 * To run a mutation, you first call `useTestCreateReadingInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCreateReadingInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCreateReadingInsertUserMutation, { data, loading, error }] = useTestCreateReadingInsertUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      orgName: // value for 'orgName'
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useTestCreateReadingInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<TestCreateReadingInsertUserMutation, TestCreateReadingInsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCreateReadingInsertUserMutation, TestCreateReadingInsertUserMutationVariables>(TestCreateReadingInsertUserDocument, options);
      }
export type TestCreateReadingInsertUserMutationHookResult = ReturnType<typeof useTestCreateReadingInsertUserMutation>;
export type TestCreateReadingInsertUserMutationResult = Apollo.MutationResult<TestCreateReadingInsertUserMutation>;
export type TestCreateReadingInsertUserMutationOptions = Apollo.BaseMutationOptions<TestCreateReadingInsertUserMutation, TestCreateReadingInsertUserMutationVariables>;
export const TestCreateReadingUpdateReadingSettingDocument = gql`
    mutation TestCreateReadingUpdateReadingSetting($key: String!, $value: String!) {
  update_reading_setting(_set: {value: $value}, where: {key: {_eq: $key}}) {
    affected_rows
  }
}
    `;
export type TestCreateReadingUpdateReadingSettingMutationFn = Apollo.MutationFunction<TestCreateReadingUpdateReadingSettingMutation, TestCreateReadingUpdateReadingSettingMutationVariables>;

/**
 * __useTestCreateReadingUpdateReadingSettingMutation__
 *
 * To run a mutation, you first call `useTestCreateReadingUpdateReadingSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCreateReadingUpdateReadingSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCreateReadingUpdateReadingSettingMutation, { data, loading, error }] = useTestCreateReadingUpdateReadingSettingMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useTestCreateReadingUpdateReadingSettingMutation(baseOptions?: Apollo.MutationHookOptions<TestCreateReadingUpdateReadingSettingMutation, TestCreateReadingUpdateReadingSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCreateReadingUpdateReadingSettingMutation, TestCreateReadingUpdateReadingSettingMutationVariables>(TestCreateReadingUpdateReadingSettingDocument, options);
      }
export type TestCreateReadingUpdateReadingSettingMutationHookResult = ReturnType<typeof useTestCreateReadingUpdateReadingSettingMutation>;
export type TestCreateReadingUpdateReadingSettingMutationResult = Apollo.MutationResult<TestCreateReadingUpdateReadingSettingMutation>;
export type TestCreateReadingUpdateReadingSettingMutationOptions = Apollo.BaseMutationOptions<TestCreateReadingUpdateReadingSettingMutation, TestCreateReadingUpdateReadingSettingMutationVariables>;
export const TestCreateReadingInsertBookDocument = gql`
    mutation TestCreateReadingInsertBook($topicId: uuid!, $userId: uuid!) {
  insert_book_one(
    object: {booklanguages: {data: {language: {data: {isocode: "TEST", name: "TEST"}, on_conflict: {constraint: language_ak_isocode, update_columns: [created]}}}}, book_topics: {data: {topic_id: $topicId}}, currentstate: {data: {name: "TEST"}, on_conflict: {constraint: bookstate_ak_name, update_columns: [created]}}, user_id: $userId}
  ) {
    id
    book_topics {
      topic {
        id
      }
    }
    booklanguages {
      language {
        id
      }
    }
  }
}
    `;
export type TestCreateReadingInsertBookMutationFn = Apollo.MutationFunction<TestCreateReadingInsertBookMutation, TestCreateReadingInsertBookMutationVariables>;

/**
 * __useTestCreateReadingInsertBookMutation__
 *
 * To run a mutation, you first call `useTestCreateReadingInsertBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCreateReadingInsertBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCreateReadingInsertBookMutation, { data, loading, error }] = useTestCreateReadingInsertBookMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTestCreateReadingInsertBookMutation(baseOptions?: Apollo.MutationHookOptions<TestCreateReadingInsertBookMutation, TestCreateReadingInsertBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCreateReadingInsertBookMutation, TestCreateReadingInsertBookMutationVariables>(TestCreateReadingInsertBookDocument, options);
      }
export type TestCreateReadingInsertBookMutationHookResult = ReturnType<typeof useTestCreateReadingInsertBookMutation>;
export type TestCreateReadingInsertBookMutationResult = Apollo.MutationResult<TestCreateReadingInsertBookMutation>;
export type TestCreateReadingInsertBookMutationOptions = Apollo.BaseMutationOptions<TestCreateReadingInsertBookMutation, TestCreateReadingInsertBookMutationVariables>;
export const TestCreateReadingRemoveReadingsDocument = gql`
    mutation TestCreateReadingRemoveReadings {
  delete_readingevent(where: {}) {
    affected_rows
  }
  delete_participationevent(where: {}) {
    affected_rows
  }
  delete_cancel_review(where: {}) {
    affected_rows
  }
  delete_review(where: {}) {
    affected_rows
  }
  delete_reading(where: {}) {
    affected_rows
  }
}
    `;
export type TestCreateReadingRemoveReadingsMutationFn = Apollo.MutationFunction<TestCreateReadingRemoveReadingsMutation, TestCreateReadingRemoveReadingsMutationVariables>;

/**
 * __useTestCreateReadingRemoveReadingsMutation__
 *
 * To run a mutation, you first call `useTestCreateReadingRemoveReadingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCreateReadingRemoveReadingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCreateReadingRemoveReadingsMutation, { data, loading, error }] = useTestCreateReadingRemoveReadingsMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestCreateReadingRemoveReadingsMutation(baseOptions?: Apollo.MutationHookOptions<TestCreateReadingRemoveReadingsMutation, TestCreateReadingRemoveReadingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCreateReadingRemoveReadingsMutation, TestCreateReadingRemoveReadingsMutationVariables>(TestCreateReadingRemoveReadingsDocument, options);
      }
export type TestCreateReadingRemoveReadingsMutationHookResult = ReturnType<typeof useTestCreateReadingRemoveReadingsMutation>;
export type TestCreateReadingRemoveReadingsMutationResult = Apollo.MutationResult<TestCreateReadingRemoveReadingsMutation>;
export type TestCreateReadingRemoveReadingsMutationOptions = Apollo.BaseMutationOptions<TestCreateReadingRemoveReadingsMutation, TestCreateReadingRemoveReadingsMutationVariables>;
export const TestCreateReadingCreateTopicDocument = gql`
    mutation TestCreateReadingCreateTopic($topicName: String!) {
  insert_topic_one(object: {name: $topicName}) {
    id
  }
}
    `;
export type TestCreateReadingCreateTopicMutationFn = Apollo.MutationFunction<TestCreateReadingCreateTopicMutation, TestCreateReadingCreateTopicMutationVariables>;

/**
 * __useTestCreateReadingCreateTopicMutation__
 *
 * To run a mutation, you first call `useTestCreateReadingCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCreateReadingCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCreateReadingCreateTopicMutation, { data, loading, error }] = useTestCreateReadingCreateTopicMutation({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useTestCreateReadingCreateTopicMutation(baseOptions?: Apollo.MutationHookOptions<TestCreateReadingCreateTopicMutation, TestCreateReadingCreateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCreateReadingCreateTopicMutation, TestCreateReadingCreateTopicMutationVariables>(TestCreateReadingCreateTopicDocument, options);
      }
export type TestCreateReadingCreateTopicMutationHookResult = ReturnType<typeof useTestCreateReadingCreateTopicMutation>;
export type TestCreateReadingCreateTopicMutationResult = Apollo.MutationResult<TestCreateReadingCreateTopicMutation>;
export type TestCreateReadingCreateTopicMutationOptions = Apollo.BaseMutationOptions<TestCreateReadingCreateTopicMutation, TestCreateReadingCreateTopicMutationVariables>;
export const PastReadingItemGetReviewsDocument = gql`
    query PastReadingItemGetReviews($readingId: uuid!) {
  review(where: {reading: {id: {_eq: $readingId}}}) {
    id
    text
  }
}
    `;

/**
 * __usePastReadingItemGetReviewsQuery__
 *
 * To run a query within a React component, call `usePastReadingItemGetReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastReadingItemGetReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastReadingItemGetReviewsQuery({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function usePastReadingItemGetReviewsQuery(baseOptions: Apollo.QueryHookOptions<PastReadingItemGetReviewsQuery, PastReadingItemGetReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PastReadingItemGetReviewsQuery, PastReadingItemGetReviewsQueryVariables>(PastReadingItemGetReviewsDocument, options);
      }
export function usePastReadingItemGetReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PastReadingItemGetReviewsQuery, PastReadingItemGetReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PastReadingItemGetReviewsQuery, PastReadingItemGetReviewsQueryVariables>(PastReadingItemGetReviewsDocument, options);
        }
export type PastReadingItemGetReviewsQueryHookResult = ReturnType<typeof usePastReadingItemGetReviewsQuery>;
export type PastReadingItemGetReviewsLazyQueryHookResult = ReturnType<typeof usePastReadingItemGetReviewsLazyQuery>;
export type PastReadingItemGetReviewsQueryResult = Apollo.QueryResult<PastReadingItemGetReviewsQuery, PastReadingItemGetReviewsQueryVariables>;
export const BookProfileGetReadingsDocument = gql`
    query BookProfileGetReadings($limit: Int!, $offset: Int!, $readingStartWhere: timestamptz_comparison_exp!, $sortDirection: order_by!, $userId: uuid!) {
  readings: reading(
    limit: $limit
    offset: $offset
    order_by: {readingstart: $sortDirection}
    where: {book: {user_id: {_eq: $userId}}, readingstart: $readingStartWhere, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
  ) {
    id
    maxreaders
    readingstart
    seats_available
    topicName
    topic {
      id
      pillar_topics {
        pillar {
          id
          title
          description
        }
      }
    }
    book {
      user {
        person {
          name
        }
      }
    }
  }
  reading_aggregate(
    where: {book: {user_id: {_eq: $userId}}, readingstart: $readingStartWhere, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useBookProfileGetReadingsQuery__
 *
 * To run a query within a React component, call `useBookProfileGetReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookProfileGetReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookProfileGetReadingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      readingStartWhere: // value for 'readingStartWhere'
 *      sortDirection: // value for 'sortDirection'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBookProfileGetReadingsQuery(baseOptions: Apollo.QueryHookOptions<BookProfileGetReadingsQuery, BookProfileGetReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookProfileGetReadingsQuery, BookProfileGetReadingsQueryVariables>(BookProfileGetReadingsDocument, options);
      }
export function useBookProfileGetReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookProfileGetReadingsQuery, BookProfileGetReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookProfileGetReadingsQuery, BookProfileGetReadingsQueryVariables>(BookProfileGetReadingsDocument, options);
        }
export type BookProfileGetReadingsQueryHookResult = ReturnType<typeof useBookProfileGetReadingsQuery>;
export type BookProfileGetReadingsLazyQueryHookResult = ReturnType<typeof useBookProfileGetReadingsLazyQuery>;
export type BookProfileGetReadingsQueryResult = Apollo.QueryResult<BookProfileGetReadingsQuery, BookProfileGetReadingsQueryVariables>;
export const AdminPersonalInformationUpdateUserDocument = gql`
    mutation AdminPersonalInformationUpdateUser($email: String!, $personName: String, $orgId: uuid!, $pronouns: String, $userId: uuid!) {
  update_person(
    _set: {email: $email, name: $personName, pronouns: $pronouns}
    where: {users: {id: {_eq: $userId}}}
  ) {
    affected_rows
  }
  update_user_by_pk(_set: {org_id: $orgId}, pk_columns: {id: $userId}) {
    id
  }
}
    `;
export type AdminPersonalInformationUpdateUserMutationFn = Apollo.MutationFunction<AdminPersonalInformationUpdateUserMutation, AdminPersonalInformationUpdateUserMutationVariables>;

/**
 * __useAdminPersonalInformationUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminPersonalInformationUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPersonalInformationUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPersonalInformationUpdateUserMutation, { data, loading, error }] = useAdminPersonalInformationUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      personName: // value for 'personName'
 *      orgId: // value for 'orgId'
 *      pronouns: // value for 'pronouns'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminPersonalInformationUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminPersonalInformationUpdateUserMutation, AdminPersonalInformationUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPersonalInformationUpdateUserMutation, AdminPersonalInformationUpdateUserMutationVariables>(AdminPersonalInformationUpdateUserDocument, options);
      }
export type AdminPersonalInformationUpdateUserMutationHookResult = ReturnType<typeof useAdminPersonalInformationUpdateUserMutation>;
export type AdminPersonalInformationUpdateUserMutationResult = Apollo.MutationResult<AdminPersonalInformationUpdateUserMutation>;
export type AdminPersonalInformationUpdateUserMutationOptions = Apollo.BaseMutationOptions<AdminPersonalInformationUpdateUserMutation, AdminPersonalInformationUpdateUserMutationVariables>;
export const LibrarianPersonalInformationUpdateUserDocument = gql`
    mutation LibrarianPersonalInformationUpdateUser($personName: String, $userId: uuid!) {
  update_person(_set: {name: $personName}, where: {users: {id: {_eq: $userId}}}) {
    affected_rows
  }
}
    `;
export type LibrarianPersonalInformationUpdateUserMutationFn = Apollo.MutationFunction<LibrarianPersonalInformationUpdateUserMutation, LibrarianPersonalInformationUpdateUserMutationVariables>;

/**
 * __useLibrarianPersonalInformationUpdateUserMutation__
 *
 * To run a mutation, you first call `useLibrarianPersonalInformationUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLibrarianPersonalInformationUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [librarianPersonalInformationUpdateUserMutation, { data, loading, error }] = useLibrarianPersonalInformationUpdateUserMutation({
 *   variables: {
 *      personName: // value for 'personName'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLibrarianPersonalInformationUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<LibrarianPersonalInformationUpdateUserMutation, LibrarianPersonalInformationUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LibrarianPersonalInformationUpdateUserMutation, LibrarianPersonalInformationUpdateUserMutationVariables>(LibrarianPersonalInformationUpdateUserDocument, options);
      }
export type LibrarianPersonalInformationUpdateUserMutationHookResult = ReturnType<typeof useLibrarianPersonalInformationUpdateUserMutation>;
export type LibrarianPersonalInformationUpdateUserMutationResult = Apollo.MutationResult<LibrarianPersonalInformationUpdateUserMutation>;
export type LibrarianPersonalInformationUpdateUserMutationOptions = Apollo.BaseMutationOptions<LibrarianPersonalInformationUpdateUserMutation, LibrarianPersonalInformationUpdateUserMutationVariables>;
export const TimezoneInformationGetDataDocument = gql`
    query TimezoneInformationGetData {
  timezone {
    id
    name
    offset
    value
  }
}
    `;

/**
 * __useTimezoneInformationGetDataQuery__
 *
 * To run a query within a React component, call `useTimezoneInformationGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimezoneInformationGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimezoneInformationGetDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimezoneInformationGetDataQuery(baseOptions?: Apollo.QueryHookOptions<TimezoneInformationGetDataQuery, TimezoneInformationGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimezoneInformationGetDataQuery, TimezoneInformationGetDataQueryVariables>(TimezoneInformationGetDataDocument, options);
      }
export function useTimezoneInformationGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimezoneInformationGetDataQuery, TimezoneInformationGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimezoneInformationGetDataQuery, TimezoneInformationGetDataQueryVariables>(TimezoneInformationGetDataDocument, options);
        }
export type TimezoneInformationGetDataQueryHookResult = ReturnType<typeof useTimezoneInformationGetDataQuery>;
export type TimezoneInformationGetDataLazyQueryHookResult = ReturnType<typeof useTimezoneInformationGetDataLazyQuery>;
export type TimezoneInformationGetDataQueryResult = Apollo.QueryResult<TimezoneInformationGetDataQuery, TimezoneInformationGetDataQueryVariables>;
export const ReaderProfileGetReadingsDocument = gql`
    query ReaderProfileGetReadings($limit: Int!, $offset: Int!, $readingStartWhere: timestamptz_comparison_exp!, $sortDirection: order_by!, $userId: uuid!) {
  readings: readings_by_participationstate(
    args: {particiationstate_name: "signup", reader_user_id: $userId}
    limit: $limit
    offset: $offset
    order_by: {readingstart: $sortDirection}
    where: {readingstart: $readingStartWhere, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
  ) {
    id
    maxreaders
    readingstart
    readingEventStatusName
    seats_available
    topicName
    topic {
      id
      pillar_topics {
        pillar {
          id
          title
          description
        }
      }
    }
    book {
      user {
        person {
          name
        }
      }
    }
  }
  reading_aggregate: readings_by_participationstate_aggregate(
    args: {particiationstate_name: "signup", reader_user_id: $userId}
    where: {readingstart: $readingStartWhere, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useReaderProfileGetReadingsQuery__
 *
 * To run a query within a React component, call `useReaderProfileGetReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReaderProfileGetReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReaderProfileGetReadingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      readingStartWhere: // value for 'readingStartWhere'
 *      sortDirection: // value for 'sortDirection'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReaderProfileGetReadingsQuery(baseOptions: Apollo.QueryHookOptions<ReaderProfileGetReadingsQuery, ReaderProfileGetReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReaderProfileGetReadingsQuery, ReaderProfileGetReadingsQueryVariables>(ReaderProfileGetReadingsDocument, options);
      }
export function useReaderProfileGetReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReaderProfileGetReadingsQuery, ReaderProfileGetReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReaderProfileGetReadingsQuery, ReaderProfileGetReadingsQueryVariables>(ReaderProfileGetReadingsDocument, options);
        }
export type ReaderProfileGetReadingsQueryHookResult = ReturnType<typeof useReaderProfileGetReadingsQuery>;
export type ReaderProfileGetReadingsLazyQueryHookResult = ReturnType<typeof useReaderProfileGetReadingsLazyQuery>;
export type ReaderProfileGetReadingsQueryResult = Apollo.QueryResult<ReaderProfileGetReadingsQuery, ReaderProfileGetReadingsQueryVariables>;
export const ChatButtonGetAdminNumberOfUnreadMessagesDocument = gql`
    query ChatButtonGetAdminNumberOfUnreadMessages($userId: uuid!) {
  chat_room(where: {active: {_eq: true}, owner_user_id: {_eq: $userId}}) {
    user {
      chat_messages_aggregate(
        where: {fromUser: {id: {_neq: $userId}}, chatMessageType: {name: {_in: ["message", "joined"]}}, unread: {_eq: true}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useChatButtonGetAdminNumberOfUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useChatButtonGetAdminNumberOfUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatButtonGetAdminNumberOfUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatButtonGetAdminNumberOfUnreadMessagesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChatButtonGetAdminNumberOfUnreadMessagesQuery(baseOptions: Apollo.QueryHookOptions<ChatButtonGetAdminNumberOfUnreadMessagesQuery, ChatButtonGetAdminNumberOfUnreadMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatButtonGetAdminNumberOfUnreadMessagesQuery, ChatButtonGetAdminNumberOfUnreadMessagesQueryVariables>(ChatButtonGetAdminNumberOfUnreadMessagesDocument, options);
      }
export function useChatButtonGetAdminNumberOfUnreadMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatButtonGetAdminNumberOfUnreadMessagesQuery, ChatButtonGetAdminNumberOfUnreadMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatButtonGetAdminNumberOfUnreadMessagesQuery, ChatButtonGetAdminNumberOfUnreadMessagesQueryVariables>(ChatButtonGetAdminNumberOfUnreadMessagesDocument, options);
        }
export type ChatButtonGetAdminNumberOfUnreadMessagesQueryHookResult = ReturnType<typeof useChatButtonGetAdminNumberOfUnreadMessagesQuery>;
export type ChatButtonGetAdminNumberOfUnreadMessagesLazyQueryHookResult = ReturnType<typeof useChatButtonGetAdminNumberOfUnreadMessagesLazyQuery>;
export type ChatButtonGetAdminNumberOfUnreadMessagesQueryResult = Apollo.QueryResult<ChatButtonGetAdminNumberOfUnreadMessagesQuery, ChatButtonGetAdminNumberOfUnreadMessagesQueryVariables>;
export const ChatButtonGetUserNumberOfUnreadMessagesDocument = gql`
    query ChatButtonGetUserNumberOfUnreadMessages($userId: uuid!) {
  chat_message_aggregate(
    where: {from_user_id: {_neq: $userId}, user_id: {_eq: $userId}, chatMessageType: {name: {_in: ["message", "joined"]}}, unread: {_eq: true}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useChatButtonGetUserNumberOfUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useChatButtonGetUserNumberOfUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatButtonGetUserNumberOfUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatButtonGetUserNumberOfUnreadMessagesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChatButtonGetUserNumberOfUnreadMessagesQuery(baseOptions: Apollo.QueryHookOptions<ChatButtonGetUserNumberOfUnreadMessagesQuery, ChatButtonGetUserNumberOfUnreadMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatButtonGetUserNumberOfUnreadMessagesQuery, ChatButtonGetUserNumberOfUnreadMessagesQueryVariables>(ChatButtonGetUserNumberOfUnreadMessagesDocument, options);
      }
export function useChatButtonGetUserNumberOfUnreadMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatButtonGetUserNumberOfUnreadMessagesQuery, ChatButtonGetUserNumberOfUnreadMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatButtonGetUserNumberOfUnreadMessagesQuery, ChatButtonGetUserNumberOfUnreadMessagesQueryVariables>(ChatButtonGetUserNumberOfUnreadMessagesDocument, options);
        }
export type ChatButtonGetUserNumberOfUnreadMessagesQueryHookResult = ReturnType<typeof useChatButtonGetUserNumberOfUnreadMessagesQuery>;
export type ChatButtonGetUserNumberOfUnreadMessagesLazyQueryHookResult = ReturnType<typeof useChatButtonGetUserNumberOfUnreadMessagesLazyQuery>;
export type ChatButtonGetUserNumberOfUnreadMessagesQueryResult = Apollo.QueryResult<ChatButtonGetUserNumberOfUnreadMessagesQuery, ChatButtonGetUserNumberOfUnreadMessagesQueryVariables>;
export const ChatGetUserDocument = gql`
    query ChatGetUser($userId: uuid!) {
  user_by_pk(id: $userId) {
    person {
      name
      pronouns
    }
    role {
      name
    }
  }
}
    `;

/**
 * __useChatGetUserQuery__
 *
 * To run a query within a React component, call `useChatGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChatGetUserQuery(baseOptions: Apollo.QueryHookOptions<ChatGetUserQuery, ChatGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatGetUserQuery, ChatGetUserQueryVariables>(ChatGetUserDocument, options);
      }
export function useChatGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatGetUserQuery, ChatGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatGetUserQuery, ChatGetUserQueryVariables>(ChatGetUserDocument, options);
        }
export type ChatGetUserQueryHookResult = ReturnType<typeof useChatGetUserQuery>;
export type ChatGetUserLazyQueryHookResult = ReturnType<typeof useChatGetUserLazyQuery>;
export type ChatGetUserQueryResult = Apollo.QueryResult<ChatGetUserQuery, ChatGetUserQueryVariables>;
export const GetChatMessagesDocument = gql`
    subscription GetChatMessages($userId: uuid!) {
  chatMessages: chat_message(
    order_by: {created: asc}
    where: {chatMessageType: {name: {_in: ["message", "joined", "resolved"]}}, user_id: {_eq: $userId}}
  ) {
    id
    created
    from_user_id
    fromUser {
      role {
        name
      }
    }
    user_id
    value
    fromPersonName
    unread
    chatMessageType {
      id
      name
    }
  }
}
    `;

/**
 * __useGetChatMessagesSubscription__
 *
 * To run a query within a React component, call `useGetChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatMessagesSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetChatMessagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetChatMessagesSubscription, GetChatMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetChatMessagesSubscription, GetChatMessagesSubscriptionVariables>(GetChatMessagesDocument, options);
      }
export type GetChatMessagesSubscriptionHookResult = ReturnType<typeof useGetChatMessagesSubscription>;
export type GetChatMessagesSubscriptionResult = Apollo.SubscriptionResult<GetChatMessagesSubscription>;
export const ChatRoomGetListDocument = gql`
    subscription ChatRoomGetList($userId: uuid!) {
  chat_room_by_pk(user_id: $userId) {
    owner_user_id
    user_id
    active
  }
}
    `;

/**
 * __useChatRoomGetListSubscription__
 *
 * To run a query within a React component, call `useChatRoomGetListSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatRoomGetListSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRoomGetListSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChatRoomGetListSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatRoomGetListSubscription, ChatRoomGetListSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatRoomGetListSubscription, ChatRoomGetListSubscriptionVariables>(ChatRoomGetListDocument, options);
      }
export type ChatRoomGetListSubscriptionHookResult = ReturnType<typeof useChatRoomGetListSubscription>;
export type ChatRoomGetListSubscriptionResult = Apollo.SubscriptionResult<ChatRoomGetListSubscription>;
export const ChatRoomJoinDocument = gql`
    mutation ChatRoomJoin($messageJoinTypeId: uuid!, $ownerUserId: uuid!, $userId: uuid!) {
  insert_chat_room_one(
    object: {active: true, owner_user_id: $ownerUserId, user_id: $userId}
    on_conflict: {constraint: chat_room_pkey, update_columns: [active, owner_user_id]}
  ) {
    user_id
  }
  insert_chat_message_one(
    object: {chat_message_type_id: $messageJoinTypeId, user_id: $userId}
  ) {
    id
  }
}
    `;
export type ChatRoomJoinMutationFn = Apollo.MutationFunction<ChatRoomJoinMutation, ChatRoomJoinMutationVariables>;

/**
 * __useChatRoomJoinMutation__
 *
 * To run a mutation, you first call `useChatRoomJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatRoomJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatRoomJoinMutation, { data, loading, error }] = useChatRoomJoinMutation({
 *   variables: {
 *      messageJoinTypeId: // value for 'messageJoinTypeId'
 *      ownerUserId: // value for 'ownerUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChatRoomJoinMutation(baseOptions?: Apollo.MutationHookOptions<ChatRoomJoinMutation, ChatRoomJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatRoomJoinMutation, ChatRoomJoinMutationVariables>(ChatRoomJoinDocument, options);
      }
export type ChatRoomJoinMutationHookResult = ReturnType<typeof useChatRoomJoinMutation>;
export type ChatRoomJoinMutationResult = Apollo.MutationResult<ChatRoomJoinMutation>;
export type ChatRoomJoinMutationOptions = Apollo.BaseMutationOptions<ChatRoomJoinMutation, ChatRoomJoinMutationVariables>;
export const GetNotificationCountDocument = gql`
    query GetNotificationCount {
  view_chat_message_with_last_message_aggregate(
    distinct_on: [user_id]
    order_by: [{user_id: asc}, {chatMessage: {created: desc}}]
    where: {chatMessage: {chatMessageType: {name: {_in: ["message", "cancel_all_readings", "did_not_join_reading", "kick", "joined", "resolved"]}}}, lastChatMessage: {chatMessageType: {name: {_neq: "resolved"}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetNotificationCountQuery__
 *
 * To run a query within a React component, call `useGetNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationCountQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationCountQuery, GetNotificationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationCountQuery, GetNotificationCountQueryVariables>(GetNotificationCountDocument, options);
      }
export function useGetNotificationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationCountQuery, GetNotificationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationCountQuery, GetNotificationCountQueryVariables>(GetNotificationCountDocument, options);
        }
export type GetNotificationCountQueryHookResult = ReturnType<typeof useGetNotificationCountQuery>;
export type GetNotificationCountLazyQueryHookResult = ReturnType<typeof useGetNotificationCountLazyQuery>;
export type GetNotificationCountQueryResult = Apollo.QueryResult<GetNotificationCountQuery, GetNotificationCountQueryVariables>;
export const ChatRoomGetMessageTypesDocument = gql`
    query ChatRoomGetMessageTypes {
  chat_message_type {
    id
    name
  }
}
    `;

/**
 * __useChatRoomGetMessageTypesQuery__
 *
 * To run a query within a React component, call `useChatRoomGetMessageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatRoomGetMessageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatRoomGetMessageTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatRoomGetMessageTypesQuery(baseOptions?: Apollo.QueryHookOptions<ChatRoomGetMessageTypesQuery, ChatRoomGetMessageTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatRoomGetMessageTypesQuery, ChatRoomGetMessageTypesQueryVariables>(ChatRoomGetMessageTypesDocument, options);
      }
export function useChatRoomGetMessageTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatRoomGetMessageTypesQuery, ChatRoomGetMessageTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatRoomGetMessageTypesQuery, ChatRoomGetMessageTypesQueryVariables>(ChatRoomGetMessageTypesDocument, options);
        }
export type ChatRoomGetMessageTypesQueryHookResult = ReturnType<typeof useChatRoomGetMessageTypesQuery>;
export type ChatRoomGetMessageTypesLazyQueryHookResult = ReturnType<typeof useChatRoomGetMessageTypesLazyQuery>;
export type ChatRoomGetMessageTypesQueryResult = Apollo.QueryResult<ChatRoomGetMessageTypesQuery, ChatRoomGetMessageTypesQueryVariables>;
export const NotificationsGetChatMessagesDocument = gql`
    query NotificationsGetChatMessages($where: view_chat_message_with_last_message_bool_exp!) {
  view_chat_message_with_last_message(
    distinct_on: [user_id]
    order_by: [{user_id: asc}, {chatMessage: {created: desc}}]
    where: $where
  ) {
    chatMessage {
      created
      value
      chatMessageType {
        name
      }
      chatRoom {
        active
        updated
        ownerUser {
          id
          personName
        }
      }
      user {
        id
        person {
          name
          pronouns
        }
        role {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useNotificationsGetChatMessagesQuery__
 *
 * To run a query within a React component, call `useNotificationsGetChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsGetChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsGetChatMessagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNotificationsGetChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<NotificationsGetChatMessagesQuery, NotificationsGetChatMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsGetChatMessagesQuery, NotificationsGetChatMessagesQueryVariables>(NotificationsGetChatMessagesDocument, options);
      }
export function useNotificationsGetChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsGetChatMessagesQuery, NotificationsGetChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsGetChatMessagesQuery, NotificationsGetChatMessagesQueryVariables>(NotificationsGetChatMessagesDocument, options);
        }
export type NotificationsGetChatMessagesQueryHookResult = ReturnType<typeof useNotificationsGetChatMessagesQuery>;
export type NotificationsGetChatMessagesLazyQueryHookResult = ReturnType<typeof useNotificationsGetChatMessagesLazyQuery>;
export type NotificationsGetChatMessagesQueryResult = Apollo.QueryResult<NotificationsGetChatMessagesQuery, NotificationsGetChatMessagesQueryVariables>;
export const NotificationsInsertChatMessageDocument = gql`
    mutation NotificationsInsertChatMessage($messageTypeId: uuid!, $userId: uuid!) {
  insert_chat_message_one(
    object: {chat_message_type_id: $messageTypeId, user_id: $userId}
  ) {
    id
  }
}
    `;
export type NotificationsInsertChatMessageMutationFn = Apollo.MutationFunction<NotificationsInsertChatMessageMutation, NotificationsInsertChatMessageMutationVariables>;

/**
 * __useNotificationsInsertChatMessageMutation__
 *
 * To run a mutation, you first call `useNotificationsInsertChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsInsertChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsInsertChatMessageMutation, { data, loading, error }] = useNotificationsInsertChatMessageMutation({
 *   variables: {
 *      messageTypeId: // value for 'messageTypeId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNotificationsInsertChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<NotificationsInsertChatMessageMutation, NotificationsInsertChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationsInsertChatMessageMutation, NotificationsInsertChatMessageMutationVariables>(NotificationsInsertChatMessageDocument, options);
      }
export type NotificationsInsertChatMessageMutationHookResult = ReturnType<typeof useNotificationsInsertChatMessageMutation>;
export type NotificationsInsertChatMessageMutationResult = Apollo.MutationResult<NotificationsInsertChatMessageMutation>;
export type NotificationsInsertChatMessageMutationOptions = Apollo.BaseMutationOptions<NotificationsInsertChatMessageMutation, NotificationsInsertChatMessageMutationVariables>;
export const NotificationsInsertChatRoomDocument = gql`
    mutation NotificationsInsertChatRoom($active: Boolean!, $ownerUserId: uuid!, $userId: uuid!) {
  insert_chat_room_one(
    object: {active: $active, owner_user_id: $ownerUserId, user_id: $userId}
    on_conflict: {constraint: chat_room_pkey, update_columns: [active, owner_user_id]}
  ) {
    user_id
  }
}
    `;
export type NotificationsInsertChatRoomMutationFn = Apollo.MutationFunction<NotificationsInsertChatRoomMutation, NotificationsInsertChatRoomMutationVariables>;

/**
 * __useNotificationsInsertChatRoomMutation__
 *
 * To run a mutation, you first call `useNotificationsInsertChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsInsertChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsInsertChatRoomMutation, { data, loading, error }] = useNotificationsInsertChatRoomMutation({
 *   variables: {
 *      active: // value for 'active'
 *      ownerUserId: // value for 'ownerUserId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNotificationsInsertChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<NotificationsInsertChatRoomMutation, NotificationsInsertChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationsInsertChatRoomMutation, NotificationsInsertChatRoomMutationVariables>(NotificationsInsertChatRoomDocument, options);
      }
export type NotificationsInsertChatRoomMutationHookResult = ReturnType<typeof useNotificationsInsertChatRoomMutation>;
export type NotificationsInsertChatRoomMutationResult = Apollo.MutationResult<NotificationsInsertChatRoomMutation>;
export type NotificationsInsertChatRoomMutationOptions = Apollo.BaseMutationOptions<NotificationsInsertChatRoomMutation, NotificationsInsertChatRoomMutationVariables>;
export const GetChatMessageStatusDocument = gql`
    subscription GetChatMessageStatus($userId: uuid) {
  chat_room(where: {active: {_eq: true}, owner_user_id: {_eq: $userId}}) {
    owner_user_id
    user_id
    user {
      person {
        name
        pronouns
      }
    }
    user {
      chatMessages(
        limit: 1
        order_by: [{created: desc}]
        where: {chatMessageType: {name: {_in: ["message", "joined"]}}}
      ) {
        created
        from_user_id
        unread
        value
        fromPersonName
        toPersonName
        user_id
        fromUser {
          role {
            name
          }
          person {
            pronouns
          }
        }
        chatMessageType {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetChatMessageStatusSubscription__
 *
 * To run a query within a React component, call `useGetChatMessageStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetChatMessageStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatMessageStatusSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetChatMessageStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetChatMessageStatusSubscription, GetChatMessageStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetChatMessageStatusSubscription, GetChatMessageStatusSubscriptionVariables>(GetChatMessageStatusDocument, options);
      }
export type GetChatMessageStatusSubscriptionHookResult = ReturnType<typeof useGetChatMessageStatusSubscription>;
export type GetChatMessageStatusSubscriptionResult = Apollo.SubscriptionResult<GetChatMessageStatusSubscription>;
export const InsertEmailActivateReminderDocument = gql`
    mutation InsertEmailActivateReminder($input: [email_activate_reminder_insert_input!]!) {
  insert_email_activate_reminder(
    objects: $input
    on_conflict: {constraint: email_activate_reminder_pkey, update_columns: [sent_first_reminder_at, sent_second_reminder_at]}
  ) {
    affected_rows
  }
}
    `;
export type InsertEmailActivateReminderMutationFn = Apollo.MutationFunction<InsertEmailActivateReminderMutation, InsertEmailActivateReminderMutationVariables>;

/**
 * __useInsertEmailActivateReminderMutation__
 *
 * To run a mutation, you first call `useInsertEmailActivateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailActivateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailActivateReminderMutation, { data, loading, error }] = useInsertEmailActivateReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertEmailActivateReminderMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmailActivateReminderMutation, InsertEmailActivateReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmailActivateReminderMutation, InsertEmailActivateReminderMutationVariables>(InsertEmailActivateReminderDocument, options);
      }
export type InsertEmailActivateReminderMutationHookResult = ReturnType<typeof useInsertEmailActivateReminderMutation>;
export type InsertEmailActivateReminderMutationResult = Apollo.MutationResult<InsertEmailActivateReminderMutation>;
export type InsertEmailActivateReminderMutationOptions = Apollo.BaseMutationOptions<InsertEmailActivateReminderMutation, InsertEmailActivateReminderMutationVariables>;
export const GetActivateEmailUsersDocument = gql`
    query GetActivateEmailUsers($fromDate: timestamp!) {
  persons: person(
    where: {_and: [{created: {_gte: "2022-11-17 00:00"}}, {created: {_gte: $fromDate}}], emailverified: {_eq: false}, users: {role: {name: {_eq: "reader"}}}}
  ) {
    created
    email
    id
    emailActivateReminder {
      sent_first_reminder_at
      sent_second_reminder_at
    }
  }
}
    `;

/**
 * __useGetActivateEmailUsersQuery__
 *
 * To run a query within a React component, call `useGetActivateEmailUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivateEmailUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivateEmailUsersQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetActivateEmailUsersQuery(baseOptions: Apollo.QueryHookOptions<GetActivateEmailUsersQuery, GetActivateEmailUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivateEmailUsersQuery, GetActivateEmailUsersQueryVariables>(GetActivateEmailUsersDocument, options);
      }
export function useGetActivateEmailUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivateEmailUsersQuery, GetActivateEmailUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivateEmailUsersQuery, GetActivateEmailUsersQueryVariables>(GetActivateEmailUsersDocument, options);
        }
export type GetActivateEmailUsersQueryHookResult = ReturnType<typeof useGetActivateEmailUsersQuery>;
export type GetActivateEmailUsersLazyQueryHookResult = ReturnType<typeof useGetActivateEmailUsersLazyQuery>;
export type GetActivateEmailUsersQueryResult = Apollo.QueryResult<GetActivateEmailUsersQuery, GetActivateEmailUsersQueryVariables>;
export const InsertEmailChatMessageUnreadReminderDocument = gql`
    mutation InsertEmailChatMessageUnreadReminder($input: [email_chat_message_unread_reminder_insert_input!]!) {
  insert_email_chat_message_unread_reminder(
    objects: $input
    on_conflict: {constraint: email_chat_message_unread_reminder_pkey, update_columns: [chat_message_id]}
  ) {
    affected_rows
  }
}
    `;
export type InsertEmailChatMessageUnreadReminderMutationFn = Apollo.MutationFunction<InsertEmailChatMessageUnreadReminderMutation, InsertEmailChatMessageUnreadReminderMutationVariables>;

/**
 * __useInsertEmailChatMessageUnreadReminderMutation__
 *
 * To run a mutation, you first call `useInsertEmailChatMessageUnreadReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailChatMessageUnreadReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailChatMessageUnreadReminderMutation, { data, loading, error }] = useInsertEmailChatMessageUnreadReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertEmailChatMessageUnreadReminderMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmailChatMessageUnreadReminderMutation, InsertEmailChatMessageUnreadReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmailChatMessageUnreadReminderMutation, InsertEmailChatMessageUnreadReminderMutationVariables>(InsertEmailChatMessageUnreadReminderDocument, options);
      }
export type InsertEmailChatMessageUnreadReminderMutationHookResult = ReturnType<typeof useInsertEmailChatMessageUnreadReminderMutation>;
export type InsertEmailChatMessageUnreadReminderMutationResult = Apollo.MutationResult<InsertEmailChatMessageUnreadReminderMutation>;
export type InsertEmailChatMessageUnreadReminderMutationOptions = Apollo.BaseMutationOptions<InsertEmailChatMessageUnreadReminderMutation, InsertEmailChatMessageUnreadReminderMutationVariables>;
export const GetChatReminderMessagesDocument = gql`
    query GetChatReminderMessages($minDate: timestamptz!, $userRoles: [String!]) {
  chat_message(
    distinct_on: user_id
    order_by: [{user_id: asc}, {created: desc}]
    where: {chatMessageType: {name: {_eq: "message"}}, created: {_gte: "2023-01-30"}, user: {role: {name: {_in: $userRoles}}}}
  ) {
    id
    created
    emailChatMessageUnreadReminder {
      chat_message_id
      user_id
    }
    user_id
    user {
      chatMessages(
        limit: 10
        order_by: {created: desc}
        where: {created: {_gte: $minDate}, chatMessageType: {name: {_eq: "message"}}}
      ) {
        created
        fromUser {
          id
          person {
            name
          }
          role {
            name
          }
        }
        value
      }
      person {
        email
        language {
          isocode
        }
        name
        timezone
      }
      role {
        name
      }
    }
    unread
  }
}
    `;

/**
 * __useGetChatReminderMessagesQuery__
 *
 * To run a query within a React component, call `useGetChatReminderMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatReminderMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatReminderMessagesQuery({
 *   variables: {
 *      minDate: // value for 'minDate'
 *      userRoles: // value for 'userRoles'
 *   },
 * });
 */
export function useGetChatReminderMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetChatReminderMessagesQuery, GetChatReminderMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatReminderMessagesQuery, GetChatReminderMessagesQueryVariables>(GetChatReminderMessagesDocument, options);
      }
export function useGetChatReminderMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatReminderMessagesQuery, GetChatReminderMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatReminderMessagesQuery, GetChatReminderMessagesQueryVariables>(GetChatReminderMessagesDocument, options);
        }
export type GetChatReminderMessagesQueryHookResult = ReturnType<typeof useGetChatReminderMessagesQuery>;
export type GetChatReminderMessagesLazyQueryHookResult = ReturnType<typeof useGetChatReminderMessagesLazyQuery>;
export type GetChatReminderMessagesQueryResult = Apollo.QueryResult<GetChatReminderMessagesQuery, GetChatReminderMessagesQueryVariables>;
export const InsertEmailLibraryCardExpirationReminderDocument = gql`
    mutation InsertEmailLibraryCardExpirationReminder($input: [email_library_card_expiration_reminder_insert_input!]!) {
  insert_email_library_card_expiration_reminder(
    objects: $input
    on_conflict: {constraint: email_library_card_expiration_reminder_pkey, update_columns: [updated_at]}
  ) {
    affected_rows
  }
}
    `;
export type InsertEmailLibraryCardExpirationReminderMutationFn = Apollo.MutationFunction<InsertEmailLibraryCardExpirationReminderMutation, InsertEmailLibraryCardExpirationReminderMutationVariables>;

/**
 * __useInsertEmailLibraryCardExpirationReminderMutation__
 *
 * To run a mutation, you first call `useInsertEmailLibraryCardExpirationReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailLibraryCardExpirationReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailLibraryCardExpirationReminderMutation, { data, loading, error }] = useInsertEmailLibraryCardExpirationReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertEmailLibraryCardExpirationReminderMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmailLibraryCardExpirationReminderMutation, InsertEmailLibraryCardExpirationReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmailLibraryCardExpirationReminderMutation, InsertEmailLibraryCardExpirationReminderMutationVariables>(InsertEmailLibraryCardExpirationReminderDocument, options);
      }
export type InsertEmailLibraryCardExpirationReminderMutationHookResult = ReturnType<typeof useInsertEmailLibraryCardExpirationReminderMutation>;
export type InsertEmailLibraryCardExpirationReminderMutationResult = Apollo.MutationResult<InsertEmailLibraryCardExpirationReminderMutation>;
export type InsertEmailLibraryCardExpirationReminderMutationOptions = Apollo.BaseMutationOptions<InsertEmailLibraryCardExpirationReminderMutation, InsertEmailLibraryCardExpirationReminderMutationVariables>;
export const GetReadingPlanExpirationDocument = gql`
    query GetReadingPlanExpiration($validTo: date!) {
  readingplan(where: {remainingreadings: {_gt: 0}, valid_to: {_eq: $validTo}}) {
    user {
      person {
        email
      }
    }
    id
    emailLibraryCardExpirationReminder {
      updated_at
    }
    remainingreadings
    valid_to
  }
}
    `;

/**
 * __useGetReadingPlanExpirationQuery__
 *
 * To run a query within a React component, call `useGetReadingPlanExpirationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingPlanExpirationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingPlanExpirationQuery({
 *   variables: {
 *      validTo: // value for 'validTo'
 *   },
 * });
 */
export function useGetReadingPlanExpirationQuery(baseOptions: Apollo.QueryHookOptions<GetReadingPlanExpirationQuery, GetReadingPlanExpirationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingPlanExpirationQuery, GetReadingPlanExpirationQueryVariables>(GetReadingPlanExpirationDocument, options);
      }
export function useGetReadingPlanExpirationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingPlanExpirationQuery, GetReadingPlanExpirationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingPlanExpirationQuery, GetReadingPlanExpirationQueryVariables>(GetReadingPlanExpirationDocument, options);
        }
export type GetReadingPlanExpirationQueryHookResult = ReturnType<typeof useGetReadingPlanExpirationQuery>;
export type GetReadingPlanExpirationLazyQueryHookResult = ReturnType<typeof useGetReadingPlanExpirationLazyQuery>;
export type GetReadingPlanExpirationQueryResult = Apollo.QueryResult<GetReadingPlanExpirationQuery, GetReadingPlanExpirationQueryVariables>;
export const InsertEmailLibraryCardExpiredReminderDocument = gql`
    mutation InsertEmailLibraryCardExpiredReminder($input: [email_library_card_expired_reminder_insert_input!]!) {
  insert_email_library_card_expired_reminder(objects: $input) {
    affected_rows
  }
}
    `;
export type InsertEmailLibraryCardExpiredReminderMutationFn = Apollo.MutationFunction<InsertEmailLibraryCardExpiredReminderMutation, InsertEmailLibraryCardExpiredReminderMutationVariables>;

/**
 * __useInsertEmailLibraryCardExpiredReminderMutation__
 *
 * To run a mutation, you first call `useInsertEmailLibraryCardExpiredReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailLibraryCardExpiredReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailLibraryCardExpiredReminderMutation, { data, loading, error }] = useInsertEmailLibraryCardExpiredReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertEmailLibraryCardExpiredReminderMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmailLibraryCardExpiredReminderMutation, InsertEmailLibraryCardExpiredReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmailLibraryCardExpiredReminderMutation, InsertEmailLibraryCardExpiredReminderMutationVariables>(InsertEmailLibraryCardExpiredReminderDocument, options);
      }
export type InsertEmailLibraryCardExpiredReminderMutationHookResult = ReturnType<typeof useInsertEmailLibraryCardExpiredReminderMutation>;
export type InsertEmailLibraryCardExpiredReminderMutationResult = Apollo.MutationResult<InsertEmailLibraryCardExpiredReminderMutation>;
export type InsertEmailLibraryCardExpiredReminderMutationOptions = Apollo.BaseMutationOptions<InsertEmailLibraryCardExpiredReminderMutation, InsertEmailLibraryCardExpiredReminderMutationVariables>;
export const GetReadingPlanExpiredDocument = gql`
    query GetReadingPlanExpired {
  readingplan(
    where: {user: {person: {emailverified: {_eq: true}}}, _or: [{_and: [{remainingreadings: {_eq: 0}}, {valid_to: {_gte: "2023-3-30"}}]}, {_and: [{valid_to: {_lt: "now()"}}, {valid_to: {_gte: "2023-3-30"}}]}]}
  ) {
    emailLibraryCardExpiredReminder {
      sent_at
    }
    user {
      participationevents_aggregate(distinct_on: user_id) {
        aggregate {
          count
        }
      }
      org {
        reader_review_url
        reader_review_short_url
      }
      participationevents(
        distinct_on: reading_id
        order_by: {reading_id: asc, reading: {readingstart: desc}}
        limit: 1
      ) {
        participationstate {
          name
        }
        reading {
          duration {
            minutes
          }
          readingstart
        }
      }
      person {
        email
      }
    }
    id
    remainingreadings
    valid_to
  }
}
    `;

/**
 * __useGetReadingPlanExpiredQuery__
 *
 * To run a query within a React component, call `useGetReadingPlanExpiredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingPlanExpiredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingPlanExpiredQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReadingPlanExpiredQuery(baseOptions?: Apollo.QueryHookOptions<GetReadingPlanExpiredQuery, GetReadingPlanExpiredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingPlanExpiredQuery, GetReadingPlanExpiredQueryVariables>(GetReadingPlanExpiredDocument, options);
      }
export function useGetReadingPlanExpiredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingPlanExpiredQuery, GetReadingPlanExpiredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingPlanExpiredQuery, GetReadingPlanExpiredQueryVariables>(GetReadingPlanExpiredDocument, options);
        }
export type GetReadingPlanExpiredQueryHookResult = ReturnType<typeof useGetReadingPlanExpiredQuery>;
export type GetReadingPlanExpiredLazyQueryHookResult = ReturnType<typeof useGetReadingPlanExpiredLazyQuery>;
export type GetReadingPlanExpiredQueryResult = Apollo.QueryResult<GetReadingPlanExpiredQuery, GetReadingPlanExpiredQueryVariables>;
export const GetLocalizationDocument = gql`
    query GetLocalization($userId: uuid!) {
  language(limit: 1, where: {isdefault: {_eq: true}}) {
    id
    isocode
  }
  user_by_pk(id: $userId) {
    person {
      language {
        id
        isocode
      }
    }
  }
}
    `;

/**
 * __useGetLocalizationQuery__
 *
 * To run a query within a React component, call `useGetLocalizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalizationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLocalizationQuery(baseOptions: Apollo.QueryHookOptions<GetLocalizationQuery, GetLocalizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocalizationQuery, GetLocalizationQueryVariables>(GetLocalizationDocument, options);
      }
export function useGetLocalizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocalizationQuery, GetLocalizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocalizationQuery, GetLocalizationQueryVariables>(GetLocalizationDocument, options);
        }
export type GetLocalizationQueryHookResult = ReturnType<typeof useGetLocalizationQuery>;
export type GetLocalizationLazyQueryHookResult = ReturnType<typeof useGetLocalizationLazyQuery>;
export type GetLocalizationQueryResult = Apollo.QueryResult<GetLocalizationQuery, GetLocalizationQueryVariables>;
export const InsertOnboardingEulaAcceptDocument = gql`
    mutation InsertOnboardingEulaAccept($eulaId: uuid!, $personId: uuid!) {
  insert_eula_acceptance_one(
    object: {eula_id: $eulaId, person_id: $personId}
    on_conflict: {constraint: eula_acceptance_pk, update_columns: accepted}
  ) {
    eula_id
  }
}
    `;
export type InsertOnboardingEulaAcceptMutationFn = Apollo.MutationFunction<InsertOnboardingEulaAcceptMutation, InsertOnboardingEulaAcceptMutationVariables>;

/**
 * __useInsertOnboardingEulaAcceptMutation__
 *
 * To run a mutation, you first call `useInsertOnboardingEulaAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOnboardingEulaAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOnboardingEulaAcceptMutation, { data, loading, error }] = useInsertOnboardingEulaAcceptMutation({
 *   variables: {
 *      eulaId: // value for 'eulaId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useInsertOnboardingEulaAcceptMutation(baseOptions?: Apollo.MutationHookOptions<InsertOnboardingEulaAcceptMutation, InsertOnboardingEulaAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOnboardingEulaAcceptMutation, InsertOnboardingEulaAcceptMutationVariables>(InsertOnboardingEulaAcceptDocument, options);
      }
export type InsertOnboardingEulaAcceptMutationHookResult = ReturnType<typeof useInsertOnboardingEulaAcceptMutation>;
export type InsertOnboardingEulaAcceptMutationResult = Apollo.MutationResult<InsertOnboardingEulaAcceptMutation>;
export type InsertOnboardingEulaAcceptMutationOptions = Apollo.BaseMutationOptions<InsertOnboardingEulaAcceptMutation, InsertOnboardingEulaAcceptMutationVariables>;
export const UpdateOnboardingProfileDocument = gql`
    mutation UpdateOnboardingProfile($preferredName: String!, $pronouns: String!, $userId: uuid!) {
  update_person(
    _set: {name: $preferredName, pronouns: $pronouns}
    where: {users: {id: {_eq: $userId}}}
  ) {
    affected_rows
    returning {
      users {
        id
        role {
          name
        }
      }
    }
  }
}
    `;
export type UpdateOnboardingProfileMutationFn = Apollo.MutationFunction<UpdateOnboardingProfileMutation, UpdateOnboardingProfileMutationVariables>;

/**
 * __useUpdateOnboardingProfileMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingProfileMutation, { data, loading, error }] = useUpdateOnboardingProfileMutation({
 *   variables: {
 *      preferredName: // value for 'preferredName'
 *      pronouns: // value for 'pronouns'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateOnboardingProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingProfileMutation, UpdateOnboardingProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingProfileMutation, UpdateOnboardingProfileMutationVariables>(UpdateOnboardingProfileDocument, options);
      }
export type UpdateOnboardingProfileMutationHookResult = ReturnType<typeof useUpdateOnboardingProfileMutation>;
export type UpdateOnboardingProfileMutationResult = Apollo.MutationResult<UpdateOnboardingProfileMutation>;
export type UpdateOnboardingProfileMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingProfileMutation, UpdateOnboardingProfileMutationVariables>;
export const UpdateOnboardingReadingPlanAcceptDocument = gql`
    mutation UpdateOnboardingReadingPlanAccept($readingPlanId: uuid!) {
  update_readingplan_by_pk(
    _set: {activated: "now()"}
    pk_columns: {id: $readingPlanId}
  ) {
    activated
  }
}
    `;
export type UpdateOnboardingReadingPlanAcceptMutationFn = Apollo.MutationFunction<UpdateOnboardingReadingPlanAcceptMutation, UpdateOnboardingReadingPlanAcceptMutationVariables>;

/**
 * __useUpdateOnboardingReadingPlanAcceptMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingReadingPlanAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingReadingPlanAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingReadingPlanAcceptMutation, { data, loading, error }] = useUpdateOnboardingReadingPlanAcceptMutation({
 *   variables: {
 *      readingPlanId: // value for 'readingPlanId'
 *   },
 * });
 */
export function useUpdateOnboardingReadingPlanAcceptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingReadingPlanAcceptMutation, UpdateOnboardingReadingPlanAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingReadingPlanAcceptMutation, UpdateOnboardingReadingPlanAcceptMutationVariables>(UpdateOnboardingReadingPlanAcceptDocument, options);
      }
export type UpdateOnboardingReadingPlanAcceptMutationHookResult = ReturnType<typeof useUpdateOnboardingReadingPlanAcceptMutation>;
export type UpdateOnboardingReadingPlanAcceptMutationResult = Apollo.MutationResult<UpdateOnboardingReadingPlanAcceptMutation>;
export type UpdateOnboardingReadingPlanAcceptMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingReadingPlanAcceptMutation, UpdateOnboardingReadingPlanAcceptMutationVariables>;
export const GetOnboardingProfileDocument = gql`
    query GetOnboardingProfile($userId: uuid!) {
  eula(limit: 1, order_by: {version: desc}) {
    id
  }
  user_by_pk(id: $userId) {
    person {
      id
      eula_acceptances {
        eula_id
      }
      name
      pronouns
    }
    role {
      name
    }
  }
}
    `;

/**
 * __useGetOnboardingProfileQuery__
 *
 * To run a query within a React component, call `useGetOnboardingProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetOnboardingProfileQuery(baseOptions: Apollo.QueryHookOptions<GetOnboardingProfileQuery, GetOnboardingProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingProfileQuery, GetOnboardingProfileQueryVariables>(GetOnboardingProfileDocument, options);
      }
export function useGetOnboardingProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingProfileQuery, GetOnboardingProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingProfileQuery, GetOnboardingProfileQueryVariables>(GetOnboardingProfileDocument, options);
        }
export type GetOnboardingProfileQueryHookResult = ReturnType<typeof useGetOnboardingProfileQuery>;
export type GetOnboardingProfileLazyQueryHookResult = ReturnType<typeof useGetOnboardingProfileLazyQuery>;
export type GetOnboardingProfileQueryResult = Apollo.QueryResult<GetOnboardingProfileQuery, GetOnboardingProfileQueryVariables>;
export const GetOnboardingReadingPlanDocument = gql`
    query GetOnboardingReadingPlan($userId: uuid!) {
  user_by_pk(id: $userId) {
    readingplan {
      activated
      id
      initialreadings
      remainingreadings
      pillarconstraints {
        pillar {
          id
          description
          title
        }
      }
      readingPlanDuration {
        translation {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetOnboardingReadingPlanQuery__
 *
 * To run a query within a React component, call `useGetOnboardingReadingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingReadingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingReadingPlanQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetOnboardingReadingPlanQuery(baseOptions: Apollo.QueryHookOptions<GetOnboardingReadingPlanQuery, GetOnboardingReadingPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingReadingPlanQuery, GetOnboardingReadingPlanQueryVariables>(GetOnboardingReadingPlanDocument, options);
      }
export function useGetOnboardingReadingPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingReadingPlanQuery, GetOnboardingReadingPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingReadingPlanQuery, GetOnboardingReadingPlanQueryVariables>(GetOnboardingReadingPlanDocument, options);
        }
export type GetOnboardingReadingPlanQueryHookResult = ReturnType<typeof useGetOnboardingReadingPlanQuery>;
export type GetOnboardingReadingPlanLazyQueryHookResult = ReturnType<typeof useGetOnboardingReadingPlanLazyQuery>;
export type GetOnboardingReadingPlanQueryResult = Apollo.QueryResult<GetOnboardingReadingPlanQuery, GetOnboardingReadingPlanQueryVariables>;
export const InsertOrganisationDocument = gql`
    mutation InsertOrganisation($countryId: uuid!, $name: String!, $parent: uuid, $reader_review_url: String!, $reader_review_short_url: String!) {
  insert_org_one(
    object: {country_id: $countryId, name: $name, parent: $parent, reader_review_url: $reader_review_url, reader_review_short_url: $reader_review_short_url}
  ) {
    id
  }
}
    `;
export type InsertOrganisationMutationFn = Apollo.MutationFunction<InsertOrganisationMutation, InsertOrganisationMutationVariables>;

/**
 * __useInsertOrganisationMutation__
 *
 * To run a mutation, you first call `useInsertOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrganisationMutation, { data, loading, error }] = useInsertOrganisationMutation({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      name: // value for 'name'
 *      parent: // value for 'parent'
 *      reader_review_url: // value for 'reader_review_url'
 *      reader_review_short_url: // value for 'reader_review_short_url'
 *   },
 * });
 */
export function useInsertOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<InsertOrganisationMutation, InsertOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertOrganisationMutation, InsertOrganisationMutationVariables>(InsertOrganisationDocument, options);
      }
export type InsertOrganisationMutationHookResult = ReturnType<typeof useInsertOrganisationMutation>;
export type InsertOrganisationMutationResult = Apollo.MutationResult<InsertOrganisationMutation>;
export type InsertOrganisationMutationOptions = Apollo.BaseMutationOptions<InsertOrganisationMutation, InsertOrganisationMutationVariables>;
export const UpdateOrganisationByIdDocument = gql`
    mutation UpdateOrganisationById($countryId: uuid!, $name: String!, $orgId: uuid!, $parent: uuid, $reader_review_url: String!, $reader_review_short_url: String!) {
  update_org_by_pk(
    _set: {country_id: $countryId, name: $name, parent: $parent, reader_review_url: $reader_review_url, reader_review_short_url: $reader_review_short_url}
    pk_columns: {id: $orgId}
  ) {
    id
  }
}
    `;
export type UpdateOrganisationByIdMutationFn = Apollo.MutationFunction<UpdateOrganisationByIdMutation, UpdateOrganisationByIdMutationVariables>;

/**
 * __useUpdateOrganisationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationByIdMutation, { data, loading, error }] = useUpdateOrganisationByIdMutation({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      name: // value for 'name'
 *      orgId: // value for 'orgId'
 *      parent: // value for 'parent'
 *      reader_review_url: // value for 'reader_review_url'
 *      reader_review_short_url: // value for 'reader_review_short_url'
 *   },
 * });
 */
export function useUpdateOrganisationByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationByIdMutation, UpdateOrganisationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationByIdMutation, UpdateOrganisationByIdMutationVariables>(UpdateOrganisationByIdDocument, options);
      }
export type UpdateOrganisationByIdMutationHookResult = ReturnType<typeof useUpdateOrganisationByIdMutation>;
export type UpdateOrganisationByIdMutationResult = Apollo.MutationResult<UpdateOrganisationByIdMutation>;
export type UpdateOrganisationByIdMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationByIdMutation, UpdateOrganisationByIdMutationVariables>;
export const GetOrganisationByIdDocument = gql`
    query GetOrganisationById($orgId: uuid!) {
  org_by_pk(id: $orgId) {
    id
    name
    country_id
    parent
    reader_review_url
    reader_review_short_url
  }
}
    `;

/**
 * __useGetOrganisationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganisationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationByIdQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrganisationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>(GetOrganisationByIdDocument, options);
      }
export function useGetOrganisationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>(GetOrganisationByIdDocument, options);
        }
export type GetOrganisationByIdQueryHookResult = ReturnType<typeof useGetOrganisationByIdQuery>;
export type GetOrganisationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganisationByIdLazyQuery>;
export type GetOrganisationByIdQueryResult = Apollo.QueryResult<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>;
export const GetOrganisationListDocument = gql`
    query GetOrganisationList($nameWhere: String_comparison_exp, $parentWhere: uuid_comparison_exp) {
  org(order_by: {name: asc}, where: {name: $nameWhere, parent: $parentWhere}) {
    id
    name
    created
    parent
    users_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationListQuery__
 *
 * To run a query within a React component, call `useGetOrganisationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationListQuery({
 *   variables: {
 *      nameWhere: // value for 'nameWhere'
 *      parentWhere: // value for 'parentWhere'
 *   },
 * });
 */
export function useGetOrganisationListQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganisationListQuery, GetOrganisationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationListQuery, GetOrganisationListQueryVariables>(GetOrganisationListDocument, options);
      }
export function useGetOrganisationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationListQuery, GetOrganisationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationListQuery, GetOrganisationListQueryVariables>(GetOrganisationListDocument, options);
        }
export type GetOrganisationListQueryHookResult = ReturnType<typeof useGetOrganisationListQuery>;
export type GetOrganisationListLazyQueryHookResult = ReturnType<typeof useGetOrganisationListLazyQuery>;
export type GetOrganisationListQueryResult = Apollo.QueryResult<GetOrganisationListQuery, GetOrganisationListQueryVariables>;
export const GetOrganisationStatsDocument = gql`
    query GetOrganisationStats($orgId: uuid!) {
  org: org_by_pk(id: $orgId) {
    name
  }
  user(where: {org_id: {_eq: $orgId}}) {
    id
    person {
      email
      emailverified
    }
    readingplan {
      initialreadings
      remainingreadings
    }
    participationevents(order_by: {created: desc}) {
      created
      participationstate {
        name
      }
      reading {
        id
        readingEventStatusName
        reviews {
          rating
          text
          user_id
        }
        topic {
          pillar_topics {
            isprimary
            pillar {
              title
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationStatsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationStatsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrganisationStatsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationStatsQuery, GetOrganisationStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationStatsQuery, GetOrganisationStatsQueryVariables>(GetOrganisationStatsDocument, options);
      }
export function useGetOrganisationStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationStatsQuery, GetOrganisationStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationStatsQuery, GetOrganisationStatsQueryVariables>(GetOrganisationStatsDocument, options);
        }
export type GetOrganisationStatsQueryHookResult = ReturnType<typeof useGetOrganisationStatsQuery>;
export type GetOrganisationStatsLazyQueryHookResult = ReturnType<typeof useGetOrganisationStatsLazyQuery>;
export type GetOrganisationStatsQueryResult = Apollo.QueryResult<GetOrganisationStatsQuery, GetOrganisationStatsQueryVariables>;
export const GetFeaturedReadingsByUserIdDocument = gql`
    query GetFeaturedReadingsByUserId($userId: uuid!) {
  featuredReadings: view_readings(
    limit: 10
    where: {reading: {participationEventName: {_nsimilar: "(signup|join)"}}}
  ) {
    reading {
      ...CoreReadingFields
      participationevents(
        order_by: [{created: desc}]
        where: {participationstate: {name: {_in: ["cancel", "signup"]}}, user_id: {_eq: $userId}}
        limit: 1
      ) {
        participationstate {
          id
          name
        }
      }
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetFeaturedReadingsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetFeaturedReadingsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedReadingsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedReadingsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetFeaturedReadingsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetFeaturedReadingsByUserIdQuery, GetFeaturedReadingsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedReadingsByUserIdQuery, GetFeaturedReadingsByUserIdQueryVariables>(GetFeaturedReadingsByUserIdDocument, options);
      }
export function useGetFeaturedReadingsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedReadingsByUserIdQuery, GetFeaturedReadingsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedReadingsByUserIdQuery, GetFeaturedReadingsByUserIdQueryVariables>(GetFeaturedReadingsByUserIdDocument, options);
        }
export type GetFeaturedReadingsByUserIdQueryHookResult = ReturnType<typeof useGetFeaturedReadingsByUserIdQuery>;
export type GetFeaturedReadingsByUserIdLazyQueryHookResult = ReturnType<typeof useGetFeaturedReadingsByUserIdLazyQuery>;
export type GetFeaturedReadingsByUserIdQueryResult = Apollo.QueryResult<GetFeaturedReadingsByUserIdQuery, GetFeaturedReadingsByUserIdQueryVariables>;
export const GetReadingsByUserIdDocument = gql`
    query GetReadingsByUserId($userId: uuid!, $limit: Int!, $offset: Int!, $after: timestamptz!, $participationEventNameWhere: String_comparison_exp = {_like: "%"}, $topicWhere: topic_bool_exp = {}, $topicNameWhere: String_comparison_exp = {_like: "%"}, $readingIdWhere: uuid_comparison_exp = {}) {
  readings: reading(
    limit: $limit
    offset: $offset
    order_by: {readingstart: asc, seats_available: desc}
    where: {readingstart: {_gt: $after}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}, topic: $topicWhere, participationEventName: $participationEventNameWhere, topicName: $topicNameWhere, id: $readingIdWhere}
  ) {
    ...CoreReadingFields
    participationevents(
      order_by: [{created: desc}]
      where: {participationstate: {name: {_in: ["cancel", "signup"]}}, user_id: {_eq: $userId}}
      limit: 1
    ) {
      participationstate {
        id
        name
      }
    }
  }
  reading_aggregate(
    where: {readingstart: {_gt: $after}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}, topic: $topicWhere, participationEventName: $participationEventNameWhere, topicName: $topicNameWhere, id: $readingIdWhere}
  ) {
    aggregate {
      count
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetReadingsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *      participationEventNameWhere: // value for 'participationEventNameWhere'
 *      topicWhere: // value for 'topicWhere'
 *      topicNameWhere: // value for 'topicNameWhere'
 *      readingIdWhere: // value for 'readingIdWhere'
 *   },
 * });
 */
export function useGetReadingsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetReadingsByUserIdQuery, GetReadingsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingsByUserIdQuery, GetReadingsByUserIdQueryVariables>(GetReadingsByUserIdDocument, options);
      }
export function useGetReadingsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingsByUserIdQuery, GetReadingsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingsByUserIdQuery, GetReadingsByUserIdQueryVariables>(GetReadingsByUserIdDocument, options);
        }
export type GetReadingsByUserIdQueryHookResult = ReturnType<typeof useGetReadingsByUserIdQuery>;
export type GetReadingsByUserIdLazyQueryHookResult = ReturnType<typeof useGetReadingsByUserIdLazyQuery>;
export type GetReadingsByUserIdQueryResult = Apollo.QueryResult<GetReadingsByUserIdQuery, GetReadingsByUserIdQueryVariables>;
export const GetReadingPlanByUserIdDocument = gql`
    query GetReadingPlanByUserId($userId: uuid!) {
  readingplan(where: {user_id: {_eq: $userId}}) {
    activated
    created
    id
    initialreadings
    remainingreadings
    updated
    updatedBy {
      personName
    }
    valid_to
    pillarconstraints {
      pillar {
        id
        title
      }
    }
    creator {
      personName
    }
  }
}
    `;

/**
 * __useGetReadingPlanByUserIdQuery__
 *
 * To run a query within a React component, call `useGetReadingPlanByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingPlanByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingPlanByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReadingPlanByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetReadingPlanByUserIdQuery, GetReadingPlanByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingPlanByUserIdQuery, GetReadingPlanByUserIdQueryVariables>(GetReadingPlanByUserIdDocument, options);
      }
export function useGetReadingPlanByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingPlanByUserIdQuery, GetReadingPlanByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingPlanByUserIdQuery, GetReadingPlanByUserIdQueryVariables>(GetReadingPlanByUserIdDocument, options);
        }
export type GetReadingPlanByUserIdQueryHookResult = ReturnType<typeof useGetReadingPlanByUserIdQuery>;
export type GetReadingPlanByUserIdLazyQueryHookResult = ReturnType<typeof useGetReadingPlanByUserIdLazyQuery>;
export type GetReadingPlanByUserIdQueryResult = Apollo.QueryResult<GetReadingPlanByUserIdQuery, GetReadingPlanByUserIdQueryVariables>;
export const InsertReadingRoomBookReviewDocument = gql`
    mutation InsertReadingRoomBookReview($readingId: uuid!, $reviewBookOptionId: uuid!, $text: String!) {
  insert_review_book_one(
    object: {reading_id: $readingId, review_book_option_id: $reviewBookOptionId, text: $text}
    on_conflict: {constraint: review_book_pkey, update_columns: [review_book_option_id, text]}
  ) {
    reading_id
  }
}
    `;
export type InsertReadingRoomBookReviewMutationFn = Apollo.MutationFunction<InsertReadingRoomBookReviewMutation, InsertReadingRoomBookReviewMutationVariables>;

/**
 * __useInsertReadingRoomBookReviewMutation__
 *
 * To run a mutation, you first call `useInsertReadingRoomBookReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReadingRoomBookReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReadingRoomBookReviewMutation, { data, loading, error }] = useInsertReadingRoomBookReviewMutation({
 *   variables: {
 *      readingId: // value for 'readingId'
 *      reviewBookOptionId: // value for 'reviewBookOptionId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInsertReadingRoomBookReviewMutation(baseOptions?: Apollo.MutationHookOptions<InsertReadingRoomBookReviewMutation, InsertReadingRoomBookReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReadingRoomBookReviewMutation, InsertReadingRoomBookReviewMutationVariables>(InsertReadingRoomBookReviewDocument, options);
      }
export type InsertReadingRoomBookReviewMutationHookResult = ReturnType<typeof useInsertReadingRoomBookReviewMutation>;
export type InsertReadingRoomBookReviewMutationResult = Apollo.MutationResult<InsertReadingRoomBookReviewMutation>;
export type InsertReadingRoomBookReviewMutationOptions = Apollo.BaseMutationOptions<InsertReadingRoomBookReviewMutation, InsertReadingRoomBookReviewMutationVariables>;
export const InsertReadingRoomChatMessageDocument = gql`
    mutation InsertReadingRoomChatMessage($reading_id: uuid, $message: String) {
  insert_chat_reading_message_one(
    object: {reading_id: $reading_id, message: $message}
  ) {
    id
  }
}
    `;
export type InsertReadingRoomChatMessageMutationFn = Apollo.MutationFunction<InsertReadingRoomChatMessageMutation, InsertReadingRoomChatMessageMutationVariables>;

/**
 * __useInsertReadingRoomChatMessageMutation__
 *
 * To run a mutation, you first call `useInsertReadingRoomChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReadingRoomChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReadingRoomChatMessageMutation, { data, loading, error }] = useInsertReadingRoomChatMessageMutation({
 *   variables: {
 *      reading_id: // value for 'reading_id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useInsertReadingRoomChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<InsertReadingRoomChatMessageMutation, InsertReadingRoomChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReadingRoomChatMessageMutation, InsertReadingRoomChatMessageMutationVariables>(InsertReadingRoomChatMessageDocument, options);
      }
export type InsertReadingRoomChatMessageMutationHookResult = ReturnType<typeof useInsertReadingRoomChatMessageMutation>;
export type InsertReadingRoomChatMessageMutationResult = Apollo.MutationResult<InsertReadingRoomChatMessageMutation>;
export type InsertReadingRoomChatMessageMutationOptions = Apollo.BaseMutationOptions<InsertReadingRoomChatMessageMutation, InsertReadingRoomChatMessageMutationVariables>;
export const GetReadingRoomBookReviewOptionsDocument = gql`
    query GetReadingRoomBookReviewOptions {
  review_book_option(order_by: {order: asc}) {
    id
    label: translationKey
  }
}
    `;

/**
 * __useGetReadingRoomBookReviewOptionsQuery__
 *
 * To run a query within a React component, call `useGetReadingRoomBookReviewOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingRoomBookReviewOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingRoomBookReviewOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReadingRoomBookReviewOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetReadingRoomBookReviewOptionsQuery, GetReadingRoomBookReviewOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingRoomBookReviewOptionsQuery, GetReadingRoomBookReviewOptionsQueryVariables>(GetReadingRoomBookReviewOptionsDocument, options);
      }
export function useGetReadingRoomBookReviewOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingRoomBookReviewOptionsQuery, GetReadingRoomBookReviewOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingRoomBookReviewOptionsQuery, GetReadingRoomBookReviewOptionsQueryVariables>(GetReadingRoomBookReviewOptionsDocument, options);
        }
export type GetReadingRoomBookReviewOptionsQueryHookResult = ReturnType<typeof useGetReadingRoomBookReviewOptionsQuery>;
export type GetReadingRoomBookReviewOptionsLazyQueryHookResult = ReturnType<typeof useGetReadingRoomBookReviewOptionsLazyQuery>;
export type GetReadingRoomBookReviewOptionsQueryResult = Apollo.QueryResult<GetReadingRoomBookReviewOptionsQuery, GetReadingRoomBookReviewOptionsQueryVariables>;
export const GetReadingRoomReadingDocument = gql`
    query GetReadingRoomReading($id: uuid!) {
  reading: reading_by_pk(id: $id) {
    ...CoreReadingFields
    cancel_reviews {
      user_id
    }
    reviews {
      user_id
      id
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingRoomReadingQuery__
 *
 * To run a query within a React component, call `useGetReadingRoomReadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingRoomReadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingRoomReadingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReadingRoomReadingQuery(baseOptions: Apollo.QueryHookOptions<GetReadingRoomReadingQuery, GetReadingRoomReadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingRoomReadingQuery, GetReadingRoomReadingQueryVariables>(GetReadingRoomReadingDocument, options);
      }
export function useGetReadingRoomReadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingRoomReadingQuery, GetReadingRoomReadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingRoomReadingQuery, GetReadingRoomReadingQueryVariables>(GetReadingRoomReadingDocument, options);
        }
export type GetReadingRoomReadingQueryHookResult = ReturnType<typeof useGetReadingRoomReadingQuery>;
export type GetReadingRoomReadingLazyQueryHookResult = ReturnType<typeof useGetReadingRoomReadingLazyQuery>;
export type GetReadingRoomReadingQueryResult = Apollo.QueryResult<GetReadingRoomReadingQuery, GetReadingRoomReadingQueryVariables>;
export const GetReadingRoomReadingStatusDocument = gql`
    query GetReadingRoomReadingStatus($id: uuid!) {
  readingStatus: reading_by_pk(id: $id) {
    readingEventStatusName
  }
}
    `;

/**
 * __useGetReadingRoomReadingStatusQuery__
 *
 * To run a query within a React component, call `useGetReadingRoomReadingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingRoomReadingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingRoomReadingStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReadingRoomReadingStatusQuery(baseOptions: Apollo.QueryHookOptions<GetReadingRoomReadingStatusQuery, GetReadingRoomReadingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingRoomReadingStatusQuery, GetReadingRoomReadingStatusQueryVariables>(GetReadingRoomReadingStatusDocument, options);
      }
export function useGetReadingRoomReadingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingRoomReadingStatusQuery, GetReadingRoomReadingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingRoomReadingStatusQuery, GetReadingRoomReadingStatusQueryVariables>(GetReadingRoomReadingStatusDocument, options);
        }
export type GetReadingRoomReadingStatusQueryHookResult = ReturnType<typeof useGetReadingRoomReadingStatusQuery>;
export type GetReadingRoomReadingStatusLazyQueryHookResult = ReturnType<typeof useGetReadingRoomReadingStatusLazyQuery>;
export type GetReadingRoomReadingStatusQueryResult = Apollo.QueryResult<GetReadingRoomReadingStatusQuery, GetReadingRoomReadingStatusQueryVariables>;
export const GetReadingRoomBookParticipantsDocument = gql`
    subscription GetReadingRoomBookParticipants($reading_id: uuid!) {
  view_reading_room_book(where: {reading_id: {_eq: $reading_id}}) {
    reading_id
    user_id
    person_name
    person_pronouns
  }
}
    `;

/**
 * __useGetReadingRoomBookParticipantsSubscription__
 *
 * To run a query within a React component, call `useGetReadingRoomBookParticipantsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingRoomBookParticipantsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingRoomBookParticipantsSubscription({
 *   variables: {
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useGetReadingRoomBookParticipantsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetReadingRoomBookParticipantsSubscription, GetReadingRoomBookParticipantsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetReadingRoomBookParticipantsSubscription, GetReadingRoomBookParticipantsSubscriptionVariables>(GetReadingRoomBookParticipantsDocument, options);
      }
export type GetReadingRoomBookParticipantsSubscriptionHookResult = ReturnType<typeof useGetReadingRoomBookParticipantsSubscription>;
export type GetReadingRoomBookParticipantsSubscriptionResult = Apollo.SubscriptionResult<GetReadingRoomBookParticipantsSubscription>;
export const GetReadingRoomChatMessagesDocument = gql`
    subscription GetReadingRoomChatMessages($readingId: uuid!) {
  chat_reading_message(
    where: {reading_id: {_eq: $readingId}}
    order_by: {created: asc}
  ) {
    message
    created
    reading_id
    personName
    personPronouns
    id
    user_id
  }
}
    `;

/**
 * __useGetReadingRoomChatMessagesSubscription__
 *
 * To run a query within a React component, call `useGetReadingRoomChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingRoomChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingRoomChatMessagesSubscription({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useGetReadingRoomChatMessagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetReadingRoomChatMessagesSubscription, GetReadingRoomChatMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetReadingRoomChatMessagesSubscription, GetReadingRoomChatMessagesSubscriptionVariables>(GetReadingRoomChatMessagesDocument, options);
      }
export type GetReadingRoomChatMessagesSubscriptionHookResult = ReturnType<typeof useGetReadingRoomChatMessagesSubscription>;
export type GetReadingRoomChatMessagesSubscriptionResult = Apollo.SubscriptionResult<GetReadingRoomChatMessagesSubscription>;
export const GetReadingRoomParticipantsDocument = gql`
    subscription GetReadingRoomParticipants($reading_id: uuid!) {
  view_reading_room_participants(where: {reading_id: {_eq: $reading_id}}) {
    reading_id
    user_id
    person_name
    person_pronouns
    participationstate
    created
  }
}
    `;

/**
 * __useGetReadingRoomParticipantsSubscription__
 *
 * To run a query within a React component, call `useGetReadingRoomParticipantsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingRoomParticipantsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingRoomParticipantsSubscription({
 *   variables: {
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useGetReadingRoomParticipantsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetReadingRoomParticipantsSubscription, GetReadingRoomParticipantsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetReadingRoomParticipantsSubscription, GetReadingRoomParticipantsSubscriptionVariables>(GetReadingRoomParticipantsDocument, options);
      }
export type GetReadingRoomParticipantsSubscriptionHookResult = ReturnType<typeof useGetReadingRoomParticipantsSubscription>;
export type GetReadingRoomParticipantsSubscriptionResult = Apollo.SubscriptionResult<GetReadingRoomParticipantsSubscription>;
export const ActivateReadingPlansForUserDocument = gql`
    mutation ActivateReadingPlansForUser($userId: uuid!) {
  update_readingplan(
    where: {user_id: {_eq: $userId}, activated: {_is_null: true}}
    _set: {activated: "now()"}
  ) {
    affected_rows
  }
}
    `;
export type ActivateReadingPlansForUserMutationFn = Apollo.MutationFunction<ActivateReadingPlansForUserMutation, ActivateReadingPlansForUserMutationVariables>;

/**
 * __useActivateReadingPlansForUserMutation__
 *
 * To run a mutation, you first call `useActivateReadingPlansForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateReadingPlansForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateReadingPlansForUserMutation, { data, loading, error }] = useActivateReadingPlansForUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useActivateReadingPlansForUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateReadingPlansForUserMutation, ActivateReadingPlansForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateReadingPlansForUserMutation, ActivateReadingPlansForUserMutationVariables>(ActivateReadingPlansForUserDocument, options);
      }
export type ActivateReadingPlansForUserMutationHookResult = ReturnType<typeof useActivateReadingPlansForUserMutation>;
export type ActivateReadingPlansForUserMutationResult = Apollo.MutationResult<ActivateReadingPlansForUserMutation>;
export type ActivateReadingPlansForUserMutationOptions = Apollo.BaseMutationOptions<ActivateReadingPlansForUserMutation, ActivateReadingPlansForUserMutationVariables>;
export const CreatePersonDocument = gql`
    mutation CreatePerson($person: person_insert_input!) {
  insert_person_one(object: $person) {
    ...CorePersonFields
  }
}
    ${CorePersonFieldsFragmentDoc}`;
export type CreatePersonMutationFn = Apollo.MutationFunction<CreatePersonMutation, CreatePersonMutationVariables>;

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      person: // value for 'person'
 *   },
 * });
 */
export function useCreatePersonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePersonMutation, CreatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePersonMutation, CreatePersonMutationVariables>(CreatePersonDocument, options);
      }
export type CreatePersonMutationHookResult = ReturnType<typeof useCreatePersonMutation>;
export type CreatePersonMutationResult = Apollo.MutationResult<CreatePersonMutation>;
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<CreatePersonMutation, CreatePersonMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: user_insert_input!) {
  insert_user_one(object: $user) {
    id
    org {
      name
    }
    role {
      name
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteLiveCallStateDocument = gql`
    mutation DeleteLiveCallState($user_id: uuid!) {
  delete_live_call_state_by_pk(user_id: $user_id) {
    user_id
  }
}
    `;
export type DeleteLiveCallStateMutationFn = Apollo.MutationFunction<DeleteLiveCallStateMutation, DeleteLiveCallStateMutationVariables>;

/**
 * __useDeleteLiveCallStateMutation__
 *
 * To run a mutation, you first call `useDeleteLiveCallStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLiveCallStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLiveCallStateMutation, { data, loading, error }] = useDeleteLiveCallStateMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteLiveCallStateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLiveCallStateMutation, DeleteLiveCallStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLiveCallStateMutation, DeleteLiveCallStateMutationVariables>(DeleteLiveCallStateDocument, options);
      }
export type DeleteLiveCallStateMutationHookResult = ReturnType<typeof useDeleteLiveCallStateMutation>;
export type DeleteLiveCallStateMutationResult = Apollo.MutationResult<DeleteLiveCallStateMutation>;
export type DeleteLiveCallStateMutationOptions = Apollo.BaseMutationOptions<DeleteLiveCallStateMutation, DeleteLiveCallStateMutationVariables>;
export const InsertBookDocument = gql`
    mutation insertBook($allowInstantMeetings: Boolean, $bookTopics: book_topic_arr_rel_insert_input, $bookLanguages: booklanguage_arr_rel_insert_input, $currentBookStateId: uuid!, $maxReaders: Int, $name: String, $userId: uuid!) {
  insert_book_one(
    object: {allowinstantmeetings: $allowInstantMeetings, book_topics: $bookTopics, booklanguages: $bookLanguages, currentbookstate_id: $currentBookStateId, maxreaders: $maxReaders, name: $name, user_id: $userId}
  ) {
    id
  }
}
    `;
export type InsertBookMutationFn = Apollo.MutationFunction<InsertBookMutation, InsertBookMutationVariables>;

/**
 * __useInsertBookMutation__
 *
 * To run a mutation, you first call `useInsertBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBookMutation, { data, loading, error }] = useInsertBookMutation({
 *   variables: {
 *      allowInstantMeetings: // value for 'allowInstantMeetings'
 *      bookTopics: // value for 'bookTopics'
 *      bookLanguages: // value for 'bookLanguages'
 *      currentBookStateId: // value for 'currentBookStateId'
 *      maxReaders: // value for 'maxReaders'
 *      name: // value for 'name'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInsertBookMutation(baseOptions?: Apollo.MutationHookOptions<InsertBookMutation, InsertBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBookMutation, InsertBookMutationVariables>(InsertBookDocument, options);
      }
export type InsertBookMutationHookResult = ReturnType<typeof useInsertBookMutation>;
export type InsertBookMutationResult = Apollo.MutationResult<InsertBookMutation>;
export type InsertBookMutationOptions = Apollo.BaseMutationOptions<InsertBookMutation, InsertBookMutationVariables>;
export const InsertCancelReviewDocument = gql`
    mutation InsertCancelReview($readingId: uuid!, $text: String) {
  insert_cancel_review_one(object: {reading_id: $readingId, text: $text}) {
    reading_id
  }
}
    `;
export type InsertCancelReviewMutationFn = Apollo.MutationFunction<InsertCancelReviewMutation, InsertCancelReviewMutationVariables>;

/**
 * __useInsertCancelReviewMutation__
 *
 * To run a mutation, you first call `useInsertCancelReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCancelReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCancelReviewMutation, { data, loading, error }] = useInsertCancelReviewMutation({
 *   variables: {
 *      readingId: // value for 'readingId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInsertCancelReviewMutation(baseOptions?: Apollo.MutationHookOptions<InsertCancelReviewMutation, InsertCancelReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCancelReviewMutation, InsertCancelReviewMutationVariables>(InsertCancelReviewDocument, options);
      }
export type InsertCancelReviewMutationHookResult = ReturnType<typeof useInsertCancelReviewMutation>;
export type InsertCancelReviewMutationResult = Apollo.MutationResult<InsertCancelReviewMutation>;
export type InsertCancelReviewMutationOptions = Apollo.BaseMutationOptions<InsertCancelReviewMutation, InsertCancelReviewMutationVariables>;
export const InsertMessageDocument = gql`
    mutation InsertMessage($userId: uuid!, $value: String!) {
  insert_chat_message_one(
    object: {user_id: $userId, value: $value, chat_message_type_id: "38596163-10aa-4067-b5b0-116df03069dd"}
  ) {
    id
  }
}
    `;
export type InsertMessageMutationFn = Apollo.MutationFunction<InsertMessageMutation, InsertMessageMutationVariables>;

/**
 * __useInsertMessageMutation__
 *
 * To run a mutation, you first call `useInsertMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMessageMutation, { data, loading, error }] = useInsertMessageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useInsertMessageMutation(baseOptions?: Apollo.MutationHookOptions<InsertMessageMutation, InsertMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMessageMutation, InsertMessageMutationVariables>(InsertMessageDocument, options);
      }
export type InsertMessageMutationHookResult = ReturnType<typeof useInsertMessageMutation>;
export type InsertMessageMutationResult = Apollo.MutationResult<InsertMessageMutation>;
export type InsertMessageMutationOptions = Apollo.BaseMutationOptions<InsertMessageMutation, InsertMessageMutationVariables>;
export const InsertParticipationEventDocument = gql`
    mutation InsertParticipationEvent($readingId: uuid!, $participationStateId: uuid!, $comment: String = null, $userId: uuid!) {
  insert_participationevent_one(
    object: {reading_id: $readingId, participationstate_id: $participationStateId, comment: $comment, user_id: $userId}
  ) {
    id
    created
    participationstate {
      name
    }
    reading {
      ...CoreReadingFields
      participationevents(
        order_by: [{created: desc}]
        where: {participationstate: {name: {_in: ["cancel", "signup"]}}, user_id: {_eq: $userId}}
        limit: 1
      ) {
        participationstate {
          id
          name
        }
      }
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;
export type InsertParticipationEventMutationFn = Apollo.MutationFunction<InsertParticipationEventMutation, InsertParticipationEventMutationVariables>;

/**
 * __useInsertParticipationEventMutation__
 *
 * To run a mutation, you first call `useInsertParticipationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertParticipationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertParticipationEventMutation, { data, loading, error }] = useInsertParticipationEventMutation({
 *   variables: {
 *      readingId: // value for 'readingId'
 *      participationStateId: // value for 'participationStateId'
 *      comment: // value for 'comment'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInsertParticipationEventMutation(baseOptions?: Apollo.MutationHookOptions<InsertParticipationEventMutation, InsertParticipationEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertParticipationEventMutation, InsertParticipationEventMutationVariables>(InsertParticipationEventDocument, options);
      }
export type InsertParticipationEventMutationHookResult = ReturnType<typeof useInsertParticipationEventMutation>;
export type InsertParticipationEventMutationResult = Apollo.MutationResult<InsertParticipationEventMutation>;
export type InsertParticipationEventMutationOptions = Apollo.BaseMutationOptions<InsertParticipationEventMutation, InsertParticipationEventMutationVariables>;
export const InsertReadingDocument = gql`
    mutation insertReading($durationId: uuid!, $languageId: uuid!, $maxReaders: Int!, $minReaders: Int!, $organisingBookId: uuid!, $readingStart: timestamptz!, $topicId: uuid!) {
  insert_reading_one(
    object: {duration_id: $durationId, language_id: $languageId, maxreaders: $maxReaders, minreaders: $minReaders, organisingbook_id: $organisingBookId, readingstart: $readingStart, topic_id: $topicId}
  ) {
    id
  }
}
    `;
export type InsertReadingMutationFn = Apollo.MutationFunction<InsertReadingMutation, InsertReadingMutationVariables>;

/**
 * __useInsertReadingMutation__
 *
 * To run a mutation, you first call `useInsertReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReadingMutation, { data, loading, error }] = useInsertReadingMutation({
 *   variables: {
 *      durationId: // value for 'durationId'
 *      languageId: // value for 'languageId'
 *      maxReaders: // value for 'maxReaders'
 *      minReaders: // value for 'minReaders'
 *      organisingBookId: // value for 'organisingBookId'
 *      readingStart: // value for 'readingStart'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useInsertReadingMutation(baseOptions?: Apollo.MutationHookOptions<InsertReadingMutation, InsertReadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReadingMutation, InsertReadingMutationVariables>(InsertReadingDocument, options);
      }
export type InsertReadingMutationHookResult = ReturnType<typeof useInsertReadingMutation>;
export type InsertReadingMutationResult = Apollo.MutationResult<InsertReadingMutation>;
export type InsertReadingMutationOptions = Apollo.BaseMutationOptions<InsertReadingMutation, InsertReadingMutationVariables>;
export const InsertReadingEventsDocument = gql`
    mutation InsertReadingEvents($readingEvents: [readingevent_insert_input!]!) {
  insert_readingevent(objects: $readingEvents) {
    affected_rows
  }
}
    `;
export type InsertReadingEventsMutationFn = Apollo.MutationFunction<InsertReadingEventsMutation, InsertReadingEventsMutationVariables>;

/**
 * __useInsertReadingEventsMutation__
 *
 * To run a mutation, you first call `useInsertReadingEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReadingEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReadingEventsMutation, { data, loading, error }] = useInsertReadingEventsMutation({
 *   variables: {
 *      readingEvents: // value for 'readingEvents'
 *   },
 * });
 */
export function useInsertReadingEventsMutation(baseOptions?: Apollo.MutationHookOptions<InsertReadingEventsMutation, InsertReadingEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReadingEventsMutation, InsertReadingEventsMutationVariables>(InsertReadingEventsDocument, options);
      }
export type InsertReadingEventsMutationHookResult = ReturnType<typeof useInsertReadingEventsMutation>;
export type InsertReadingEventsMutationResult = Apollo.MutationResult<InsertReadingEventsMutation>;
export type InsertReadingEventsMutationOptions = Apollo.BaseMutationOptions<InsertReadingEventsMutation, InsertReadingEventsMutationVariables>;
export const InsertReviewDocument = gql`
    mutation InsertReview($userId: uuid!, $readingId: uuid!, $rating: Int!, $text: String!) {
  insert_review_one(
    object: {user_id: $userId, reading_id: $readingId, rating: $rating, text: $text}
  ) {
    id
  }
}
    `;
export type InsertReviewMutationFn = Apollo.MutationFunction<InsertReviewMutation, InsertReviewMutationVariables>;

/**
 * __useInsertReviewMutation__
 *
 * To run a mutation, you first call `useInsertReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReviewMutation, { data, loading, error }] = useInsertReviewMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      readingId: // value for 'readingId'
 *      rating: // value for 'rating'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInsertReviewMutation(baseOptions?: Apollo.MutationHookOptions<InsertReviewMutation, InsertReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReviewMutation, InsertReviewMutationVariables>(InsertReviewDocument, options);
      }
export type InsertReviewMutationHookResult = ReturnType<typeof useInsertReviewMutation>;
export type InsertReviewMutationResult = Apollo.MutationResult<InsertReviewMutation>;
export type InsertReviewMutationOptions = Apollo.BaseMutationOptions<InsertReviewMutation, InsertReviewMutationVariables>;
export const UpdateBookDocument = gql`
    mutation UpdateBook($id: uuid!, $allowInstantMeetings: Boolean, $bookTopics: [book_topic_insert_input!]!, $bookLanguages: [booklanguage_insert_input!]!, $currentBookStateId: uuid, $maxReaders: Int, $name: String, $userId: uuid) {
  delete_booklanguage(where: {book_id: {_eq: $id}}) {
    affected_rows
  }
  insert_booklanguage(objects: $bookLanguages) {
    affected_rows
  }
  delete_book_topic(where: {book_id: {_eq: $id}}) {
    affected_rows
  }
  insert_book_topic(objects: $bookTopics) {
    affected_rows
  }
  book: update_book_by_pk(
    pk_columns: {id: $id}
    _set: {allowinstantmeetings: $allowInstantMeetings, currentbookstate_id: $currentBookStateId, maxreaders: $maxReaders, name: $name, user_id: $userId}
  ) {
    allowinstantmeetings
    book_topics {
      topic_id
      topic {
        name
      }
    }
    booklanguages {
      language_id
    }
    currentbookstate_id
    id
    maxreaders
    name
    user_id
    currentstate {
      id
      name
    }
    readings {
      id
    }
  }
}
    `;
export type UpdateBookMutationFn = Apollo.MutationFunction<UpdateBookMutation, UpdateBookMutationVariables>;

/**
 * __useUpdateBookMutation__
 *
 * To run a mutation, you first call `useUpdateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookMutation, { data, loading, error }] = useUpdateBookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      allowInstantMeetings: // value for 'allowInstantMeetings'
 *      bookTopics: // value for 'bookTopics'
 *      bookLanguages: // value for 'bookLanguages'
 *      currentBookStateId: // value for 'currentBookStateId'
 *      maxReaders: // value for 'maxReaders'
 *      name: // value for 'name'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateBookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookMutation, UpdateBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookMutation, UpdateBookMutationVariables>(UpdateBookDocument, options);
      }
export type UpdateBookMutationHookResult = ReturnType<typeof useUpdateBookMutation>;
export type UpdateBookMutationResult = Apollo.MutationResult<UpdateBookMutation>;
export type UpdateBookMutationOptions = Apollo.BaseMutationOptions<UpdateBookMutation, UpdateBookMutationVariables>;
export const UpdateChatMessageUnreadDocument = gql`
    mutation UpdateChatMessageUnread($ids: [uuid!]!) {
  update_chat_message(_set: {unread: false}, where: {id: {_in: $ids}}) {
    affected_rows
  }
}
    `;
export type UpdateChatMessageUnreadMutationFn = Apollo.MutationFunction<UpdateChatMessageUnreadMutation, UpdateChatMessageUnreadMutationVariables>;

/**
 * __useUpdateChatMessageUnreadMutation__
 *
 * To run a mutation, you first call `useUpdateChatMessageUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatMessageUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatMessageUnreadMutation, { data, loading, error }] = useUpdateChatMessageUnreadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateChatMessageUnreadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChatMessageUnreadMutation, UpdateChatMessageUnreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChatMessageUnreadMutation, UpdateChatMessageUnreadMutationVariables>(UpdateChatMessageUnreadDocument, options);
      }
export type UpdateChatMessageUnreadMutationHookResult = ReturnType<typeof useUpdateChatMessageUnreadMutation>;
export type UpdateChatMessageUnreadMutationResult = Apollo.MutationResult<UpdateChatMessageUnreadMutation>;
export type UpdateChatMessageUnreadMutationOptions = Apollo.BaseMutationOptions<UpdateChatMessageUnreadMutation, UpdateChatMessageUnreadMutationVariables>;
export const UpdateOnboardingUserDocument = gql`
    mutation UpdateOnboardingUser($id: uuid!, $name: String!, $personId: uuid!, $timezone: String!) {
  update_person_by_pk(
    pk_columns: {id: $personId}
    _set: {name: $name, timezone: $timezone}
  ) {
    id
  }
}
    `;
export type UpdateOnboardingUserMutationFn = Apollo.MutationFunction<UpdateOnboardingUserMutation, UpdateOnboardingUserMutationVariables>;

/**
 * __useUpdateOnboardingUserMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingUserMutation, { data, loading, error }] = useUpdateOnboardingUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      personId: // value for 'personId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useUpdateOnboardingUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingUserMutation, UpdateOnboardingUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingUserMutation, UpdateOnboardingUserMutationVariables>(UpdateOnboardingUserDocument, options);
      }
export type UpdateOnboardingUserMutationHookResult = ReturnType<typeof useUpdateOnboardingUserMutation>;
export type UpdateOnboardingUserMutationResult = Apollo.MutationResult<UpdateOnboardingUserMutation>;
export type UpdateOnboardingUserMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingUserMutation, UpdateOnboardingUserMutationVariables>;
export const UpdatePersonDocument = gql`
    mutation UpdatePerson($id: uuid!, $emailVerified: Boolean!, $externalId: String!) {
  person: update_person_by_pk(
    pk_columns: {id: $id}
    _set: {emailverified: $emailVerified, externalId: $externalId, lastlogin: "now()"}
  ) {
    ...CorePersonFields
  }
}
    ${CorePersonFieldsFragmentDoc}`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailVerified: // value for 'emailVerified'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUpdatePersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, options);
      }
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<UpdatePersonMutation, UpdatePersonMutationVariables>;
export const UpdatePersonProfileDocument = gql`
    mutation UpdatePersonProfile($id: uuid!, $countryId: uuid, $defaultLanguageId: uuid, $name: String!) {
  person: update_person_by_pk(
    pk_columns: {id: $id}
    _set: {country_id: $countryId, default_language_id: $defaultLanguageId, name: $name}
  ) {
    id
  }
}
    `;
export type UpdatePersonProfileMutationFn = Apollo.MutationFunction<UpdatePersonProfileMutation, UpdatePersonProfileMutationVariables>;

/**
 * __useUpdatePersonProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonProfileMutation, { data, loading, error }] = useUpdatePersonProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      countryId: // value for 'countryId'
 *      defaultLanguageId: // value for 'defaultLanguageId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePersonProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonProfileMutation, UpdatePersonProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonProfileMutation, UpdatePersonProfileMutationVariables>(UpdatePersonProfileDocument, options);
      }
export type UpdatePersonProfileMutationHookResult = ReturnType<typeof useUpdatePersonProfileMutation>;
export type UpdatePersonProfileMutationResult = Apollo.MutationResult<UpdatePersonProfileMutation>;
export type UpdatePersonProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePersonProfileMutation, UpdatePersonProfileMutationVariables>;
export const UpdateProfilePersonDocument = gql`
    mutation UpdateProfilePerson($id: uuid!, $name: String!, $pronouns: String) {
  update_person_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, pronouns: $pronouns}
  ) {
    id
  }
}
    `;
export type UpdateProfilePersonMutationFn = Apollo.MutationFunction<UpdateProfilePersonMutation, UpdateProfilePersonMutationVariables>;

/**
 * __useUpdateProfilePersonMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePersonMutation, { data, loading, error }] = useUpdateProfilePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      pronouns: // value for 'pronouns'
 *   },
 * });
 */
export function useUpdateProfilePersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePersonMutation, UpdateProfilePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePersonMutation, UpdateProfilePersonMutationVariables>(UpdateProfilePersonDocument, options);
      }
export type UpdateProfilePersonMutationHookResult = ReturnType<typeof useUpdateProfilePersonMutation>;
export type UpdateProfilePersonMutationResult = Apollo.MutationResult<UpdateProfilePersonMutation>;
export type UpdateProfilePersonMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePersonMutation, UpdateProfilePersonMutationVariables>;
export const UpdateReadingDocument = gql`
    mutation UpdateReading($id: uuid!, $languageId: uuid!, $maxReaders: Int!, $minReaders: Int!, $organisingBookId: uuid!, $durationId: uuid!, $readingStart: timestamptz!, $topicId: uuid!) {
  reading: update_reading_by_pk(
    pk_columns: {id: $id}
    _set: {language_id: $languageId, maxreaders: $maxReaders, minreaders: $minReaders, organisingbook_id: $organisingBookId, readingstart: $readingStart, duration_id: $durationId, topic_id: $topicId}
  ) {
    ...CoreReadingFields
  }
}
    ${CoreReadingFieldsFragmentDoc}`;
export type UpdateReadingMutationFn = Apollo.MutationFunction<UpdateReadingMutation, UpdateReadingMutationVariables>;

/**
 * __useUpdateReadingMutation__
 *
 * To run a mutation, you first call `useUpdateReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadingMutation, { data, loading, error }] = useUpdateReadingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageId: // value for 'languageId'
 *      maxReaders: // value for 'maxReaders'
 *      minReaders: // value for 'minReaders'
 *      organisingBookId: // value for 'organisingBookId'
 *      durationId: // value for 'durationId'
 *      readingStart: // value for 'readingStart'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useUpdateReadingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReadingMutation, UpdateReadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReadingMutation, UpdateReadingMutationVariables>(UpdateReadingDocument, options);
      }
export type UpdateReadingMutationHookResult = ReturnType<typeof useUpdateReadingMutation>;
export type UpdateReadingMutationResult = Apollo.MutationResult<UpdateReadingMutation>;
export type UpdateReadingMutationOptions = Apollo.BaseMutationOptions<UpdateReadingMutation, UpdateReadingMutationVariables>;
export const UpdateReadingEventCancelledEmailSendDocument = gql`
    mutation UpdateReadingEventCancelledEmailSend($id: uuid!, $emailSend: Boolean!) {
  update_readingevent_by_pk(pk_columns: {id: $id}, _set: {email_send: $emailSend}) {
    email_send
    id
  }
}
    `;
export type UpdateReadingEventCancelledEmailSendMutationFn = Apollo.MutationFunction<UpdateReadingEventCancelledEmailSendMutation, UpdateReadingEventCancelledEmailSendMutationVariables>;

/**
 * __useUpdateReadingEventCancelledEmailSendMutation__
 *
 * To run a mutation, you first call `useUpdateReadingEventCancelledEmailSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadingEventCancelledEmailSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadingEventCancelledEmailSendMutation, { data, loading, error }] = useUpdateReadingEventCancelledEmailSendMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailSend: // value for 'emailSend'
 *   },
 * });
 */
export function useUpdateReadingEventCancelledEmailSendMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReadingEventCancelledEmailSendMutation, UpdateReadingEventCancelledEmailSendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReadingEventCancelledEmailSendMutation, UpdateReadingEventCancelledEmailSendMutationVariables>(UpdateReadingEventCancelledEmailSendDocument, options);
      }
export type UpdateReadingEventCancelledEmailSendMutationHookResult = ReturnType<typeof useUpdateReadingEventCancelledEmailSendMutation>;
export type UpdateReadingEventCancelledEmailSendMutationResult = Apollo.MutationResult<UpdateReadingEventCancelledEmailSendMutation>;
export type UpdateReadingEventCancelledEmailSendMutationOptions = Apollo.BaseMutationOptions<UpdateReadingEventCancelledEmailSendMutation, UpdateReadingEventCancelledEmailSendMutationVariables>;
export const UpdateReadingLiveCallStateDocument = gql`
    mutation UpdateReadingLiveCallState($user_id: uuid!, $is_hand_raised: Boolean!) {
  update_live_call_state_by_pk(
    pk_columns: {user_id: $user_id}
    _set: {is_hand_raised: $is_hand_raised}
  ) {
    is_hand_raised
    last_updated
  }
}
    `;
export type UpdateReadingLiveCallStateMutationFn = Apollo.MutationFunction<UpdateReadingLiveCallStateMutation, UpdateReadingLiveCallStateMutationVariables>;

/**
 * __useUpdateReadingLiveCallStateMutation__
 *
 * To run a mutation, you first call `useUpdateReadingLiveCallStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadingLiveCallStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadingLiveCallStateMutation, { data, loading, error }] = useUpdateReadingLiveCallStateMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      is_hand_raised: // value for 'is_hand_raised'
 *   },
 * });
 */
export function useUpdateReadingLiveCallStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReadingLiveCallStateMutation, UpdateReadingLiveCallStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReadingLiveCallStateMutation, UpdateReadingLiveCallStateMutationVariables>(UpdateReadingLiveCallStateDocument, options);
      }
export type UpdateReadingLiveCallStateMutationHookResult = ReturnType<typeof useUpdateReadingLiveCallStateMutation>;
export type UpdateReadingLiveCallStateMutationResult = Apollo.MutationResult<UpdateReadingLiveCallStateMutation>;
export type UpdateReadingLiveCallStateMutationOptions = Apollo.BaseMutationOptions<UpdateReadingLiveCallStateMutation, UpdateReadingLiveCallStateMutationVariables>;
export const UpdateTimezoneForUserDocument = gql`
    mutation UpdateTimezoneForUser($personId: uuid!, $timezone: String!) {
  user: update_person_by_pk(
    pk_columns: {id: $personId}
    _set: {timezone: $timezone}
  ) {
    id
  }
}
    `;
export type UpdateTimezoneForUserMutationFn = Apollo.MutationFunction<UpdateTimezoneForUserMutation, UpdateTimezoneForUserMutationVariables>;

/**
 * __useUpdateTimezoneForUserMutation__
 *
 * To run a mutation, you first call `useUpdateTimezoneForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimezoneForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimezoneForUserMutation, { data, loading, error }] = useUpdateTimezoneForUserMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useUpdateTimezoneForUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimezoneForUserMutation, UpdateTimezoneForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimezoneForUserMutation, UpdateTimezoneForUserMutationVariables>(UpdateTimezoneForUserDocument, options);
      }
export type UpdateTimezoneForUserMutationHookResult = ReturnType<typeof useUpdateTimezoneForUserMutation>;
export type UpdateTimezoneForUserMutationResult = Apollo.MutationResult<UpdateTimezoneForUserMutation>;
export type UpdateTimezoneForUserMutationOptions = Apollo.BaseMutationOptions<UpdateTimezoneForUserMutation, UpdateTimezoneForUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($countryId: uuid!, $defaultLanguageId: uuid!, $email: String!, $name: String!, $orgId: uuid!, $roleId: uuid!, $id: uuid!, $personId: uuid!) {
  update_person_by_pk(
    pk_columns: {id: $personId}
    _set: {country_id: $countryId, default_language_id: $defaultLanguageId, email: $email, name: $name}
  ) {
    id
  }
  user: update_user_by_pk(
    pk_columns: {id: $id}
    _set: {org_id: $orgId, role_id: $roleId}
  ) {
    id
    role_id
    created
    person {
      id
      country_id
      default_language_id
      email
      name
      person_get_has_accepted_latest_eula
    }
    org {
      id
      name
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      defaultLanguageId: // value for 'defaultLanguageId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      orgId: // value for 'orgId'
 *      roleId: // value for 'roleId'
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpsertLiveCallStateDocument = gql`
    mutation UpsertLiveCallState($reading_id: uuid!, $user_id: uuid!, $is_hand_raised: Boolean) {
  insert_live_call_state_one(
    object: {reading_id: $reading_id, user_id: $user_id, last_updated: "now()", is_hand_raised: $is_hand_raised}
    on_conflict: {constraint: live_call_state_pkey, update_columns: [last_updated, is_hand_raised]}
  ) {
    last_updated
  }
}
    `;
export type UpsertLiveCallStateMutationFn = Apollo.MutationFunction<UpsertLiveCallStateMutation, UpsertLiveCallStateMutationVariables>;

/**
 * __useUpsertLiveCallStateMutation__
 *
 * To run a mutation, you first call `useUpsertLiveCallStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLiveCallStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLiveCallStateMutation, { data, loading, error }] = useUpsertLiveCallStateMutation({
 *   variables: {
 *      reading_id: // value for 'reading_id'
 *      user_id: // value for 'user_id'
 *      is_hand_raised: // value for 'is_hand_raised'
 *   },
 * });
 */
export function useUpsertLiveCallStateMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLiveCallStateMutation, UpsertLiveCallStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLiveCallStateMutation, UpsertLiveCallStateMutationVariables>(UpsertLiveCallStateDocument, options);
      }
export type UpsertLiveCallStateMutationHookResult = ReturnType<typeof useUpsertLiveCallStateMutation>;
export type UpsertLiveCallStateMutationResult = Apollo.MutationResult<UpsertLiveCallStateMutation>;
export type UpsertLiveCallStateMutationOptions = Apollo.BaseMutationOptions<UpsertLiveCallStateMutation, UpsertLiveCallStateMutationVariables>;
export const AdminDeleteBookDocument = gql`
    mutation AdminDeleteBook($userId: uuid!, $personId: uuid!) {
  delete_email_chat_message_unread_reminder(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_chat_message(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_cancel_review(
    where: {_or: [{user_id: {_eq: $userId}}, {reading: {book: {user_id: {_eq: $userId}}}}]}
  ) {
    affected_rows
  }
  delete_review(
    where: {_or: [{user_id: {_eq: $userId}}, {reading: {book: {user_id: {_eq: $userId}}}}]}
  ) {
    affected_rows
  }
  delete_readingevent(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_participationevent(
    where: {_or: [{user_id: {_eq: $userId}}, {reading: {book: {user_id: {_eq: $userId}}}}]}
  ) {
    affected_rows
  }
  delete_booklanguage(where: {book: {user_id: {_eq: $userId}}}) {
    affected_rows
  }
  delete_book_topic(where: {book: {user_id: {_eq: $userId}}}) {
    affected_rows
  }
  delete_readingevent(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_reading(where: {book: {user_id: {_eq: $userId}}}) {
    affected_rows
  }
  delete_book(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_user(where: {id: {_eq: $userId}}) {
    affected_rows
  }
  delete_eula_acceptance(where: {person_id: {_eq: $personId}}) {
    affected_rows
  }
  delete_email_activate_reminder(
    where: {person_id: {_eq: $personId}, _not: {person_id: {_is_null: true}}}
  ) {
    affected_rows
  }
  delete_person(where: {id: {_eq: $personId}}) {
    affected_rows
  }
}
    `;
export type AdminDeleteBookMutationFn = Apollo.MutationFunction<AdminDeleteBookMutation, AdminDeleteBookMutationVariables>;

/**
 * __useAdminDeleteBookMutation__
 *
 * To run a mutation, you first call `useAdminDeleteBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteBookMutation, { data, loading, error }] = useAdminDeleteBookMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAdminDeleteBookMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteBookMutation, AdminDeleteBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteBookMutation, AdminDeleteBookMutationVariables>(AdminDeleteBookDocument, options);
      }
export type AdminDeleteBookMutationHookResult = ReturnType<typeof useAdminDeleteBookMutation>;
export type AdminDeleteBookMutationResult = Apollo.MutationResult<AdminDeleteBookMutation>;
export type AdminDeleteBookMutationOptions = Apollo.BaseMutationOptions<AdminDeleteBookMutation, AdminDeleteBookMutationVariables>;
export const AdminDeleteDebriefingDocument = gql`
    mutation AdminDeleteDebriefing($id: uuid!) {
  delete_debriefing_by_pk(id: $id) {
    id
    link
    date
  }
}
    `;
export type AdminDeleteDebriefingMutationFn = Apollo.MutationFunction<AdminDeleteDebriefingMutation, AdminDeleteDebriefingMutationVariables>;

/**
 * __useAdminDeleteDebriefingMutation__
 *
 * To run a mutation, you first call `useAdminDeleteDebriefingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteDebriefingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteDebriefingMutation, { data, loading, error }] = useAdminDeleteDebriefingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteDebriefingMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteDebriefingMutation, AdminDeleteDebriefingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteDebriefingMutation, AdminDeleteDebriefingMutationVariables>(AdminDeleteDebriefingDocument, options);
      }
export type AdminDeleteDebriefingMutationHookResult = ReturnType<typeof useAdminDeleteDebriefingMutation>;
export type AdminDeleteDebriefingMutationResult = Apollo.MutationResult<AdminDeleteDebriefingMutation>;
export type AdminDeleteDebriefingMutationOptions = Apollo.BaseMutationOptions<AdminDeleteDebriefingMutation, AdminDeleteDebriefingMutationVariables>;
export const AdminDeleteReadingPlanDocument = gql`
    mutation AdminDeleteReadingPlan($id: uuid!) {
  delete_readingplan_by_pk(id: $id) {
    id
  }
}
    `;
export type AdminDeleteReadingPlanMutationFn = Apollo.MutationFunction<AdminDeleteReadingPlanMutation, AdminDeleteReadingPlanMutationVariables>;

/**
 * __useAdminDeleteReadingPlanMutation__
 *
 * To run a mutation, you first call `useAdminDeleteReadingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteReadingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteReadingPlanMutation, { data, loading, error }] = useAdminDeleteReadingPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteReadingPlanMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteReadingPlanMutation, AdminDeleteReadingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteReadingPlanMutation, AdminDeleteReadingPlanMutationVariables>(AdminDeleteReadingPlanDocument, options);
      }
export type AdminDeleteReadingPlanMutationHookResult = ReturnType<typeof useAdminDeleteReadingPlanMutation>;
export type AdminDeleteReadingPlanMutationResult = Apollo.MutationResult<AdminDeleteReadingPlanMutation>;
export type AdminDeleteReadingPlanMutationOptions = Apollo.BaseMutationOptions<AdminDeleteReadingPlanMutation, AdminDeleteReadingPlanMutationVariables>;
export const AdminDeleteTopicPillarDocument = gql`
    mutation AdminDeleteTopicPillar($pillarId: uuid!, $topicId: uuid!) {
  delete_pillar_topic_by_pk(pillar_id: $pillarId, topic_id: $topicId) {
    pillar_id
    topic_id
  }
}
    `;
export type AdminDeleteTopicPillarMutationFn = Apollo.MutationFunction<AdminDeleteTopicPillarMutation, AdminDeleteTopicPillarMutationVariables>;

/**
 * __useAdminDeleteTopicPillarMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTopicPillarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTopicPillarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTopicPillarMutation, { data, loading, error }] = useAdminDeleteTopicPillarMutation({
 *   variables: {
 *      pillarId: // value for 'pillarId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useAdminDeleteTopicPillarMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteTopicPillarMutation, AdminDeleteTopicPillarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteTopicPillarMutation, AdminDeleteTopicPillarMutationVariables>(AdminDeleteTopicPillarDocument, options);
      }
export type AdminDeleteTopicPillarMutationHookResult = ReturnType<typeof useAdminDeleteTopicPillarMutation>;
export type AdminDeleteTopicPillarMutationResult = Apollo.MutationResult<AdminDeleteTopicPillarMutation>;
export type AdminDeleteTopicPillarMutationOptions = Apollo.BaseMutationOptions<AdminDeleteTopicPillarMutation, AdminDeleteTopicPillarMutationVariables>;
export const AdminDeleteUserDocument = gql`
    mutation AdminDeleteUser($userId: uuid!, $personId: uuid!, $readingplanId: uuid) {
  delete_pillarconstraint(where: {readingplan: {user_id: {_eq: $userId}}}) {
    affected_rows
  }
  delete_email_chat_message_unread_reminder(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_email_library_card_expiration_reminder(
    where: {readingplan_id: {_eq: $readingplanId}}
  ) {
    affected_rows
  }
  delete_email_library_card_expired_reminder(
    where: {readingplan_id: {_eq: $readingplanId}}
  ) {
    affected_rows
  }
  delete_readingplan(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_chat_message(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_review(
    where: {_or: [{user_id: {_eq: $userId}}, {reading: {book: {user_id: {_eq: $userId}}}}]}
  ) {
    affected_rows
  }
  delete_readingevent(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_participationevent(
    where: {_or: [{user_id: {_eq: $userId}}, {reading: {book: {user_id: {_eq: $userId}}}}]}
  ) {
    affected_rows
  }
  delete_booklanguage(where: {book: {user_id: {_eq: $userId}}}) {
    affected_rows
  }
  delete_book_topic(where: {book: {user_id: {_eq: $userId}}}) {
    affected_rows
  }
  delete_readingevent(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_reading(where: {book: {user_id: {_eq: $userId}}}) {
    affected_rows
  }
  delete_book(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
  delete_user(where: {id: {_eq: $userId}}) {
    affected_rows
  }
  delete_eula_acceptance(where: {person_id: {_eq: $personId}}) {
    affected_rows
  }
  delete_email_activate_reminder(
    where: {person_id: {_eq: $personId}, _not: {person_id: {_is_null: true}}}
  ) {
    affected_rows
  }
  delete_person(where: {id: {_eq: $personId}}) {
    affected_rows
  }
}
    `;
export type AdminDeleteUserMutationFn = Apollo.MutationFunction<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;

/**
 * __useAdminDeleteUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserMutation, { data, loading, error }] = useAdminDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      personId: // value for 'personId'
 *      readingplanId: // value for 'readingplanId'
 *   },
 * });
 */
export function useAdminDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument, options);
      }
export type AdminDeleteUserMutationHookResult = ReturnType<typeof useAdminDeleteUserMutation>;
export type AdminDeleteUserMutationResult = Apollo.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = Apollo.BaseMutationOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export const AdminInsertDebriefingDocument = gql`
    mutation AdminInsertDebriefing($date: timestamptz!, $link: String!) {
  insert_debriefing_one(object: {date: $date, link: $link}) {
    date
    id
    link
  }
}
    `;
export type AdminInsertDebriefingMutationFn = Apollo.MutationFunction<AdminInsertDebriefingMutation, AdminInsertDebriefingMutationVariables>;

/**
 * __useAdminInsertDebriefingMutation__
 *
 * To run a mutation, you first call `useAdminInsertDebriefingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertDebriefingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertDebriefingMutation, { data, loading, error }] = useAdminInsertDebriefingMutation({
 *   variables: {
 *      date: // value for 'date'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAdminInsertDebriefingMutation(baseOptions?: Apollo.MutationHookOptions<AdminInsertDebriefingMutation, AdminInsertDebriefingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminInsertDebriefingMutation, AdminInsertDebriefingMutationVariables>(AdminInsertDebriefingDocument, options);
      }
export type AdminInsertDebriefingMutationHookResult = ReturnType<typeof useAdminInsertDebriefingMutation>;
export type AdminInsertDebriefingMutationResult = Apollo.MutationResult<AdminInsertDebriefingMutation>;
export type AdminInsertDebriefingMutationOptions = Apollo.BaseMutationOptions<AdminInsertDebriefingMutation, AdminInsertDebriefingMutationVariables>;
export const AdminInsertReadingPlanDocument = gql`
    mutation AdminInsertReadingPlan($creatorId: uuid!, $initialReadings: Int!, $issuedToEmail: String!, $userId: uuid!, $readingPlanDurationId: uuid!) {
  insert_readingplan_one(
    object: {creator_id: $creatorId, initialreadings: $initialReadings, issuedtoemail: $issuedToEmail, user_id: $userId, readingplan_duration_id: $readingPlanDurationId}
  ) {
    id
  }
}
    `;
export type AdminInsertReadingPlanMutationFn = Apollo.MutationFunction<AdminInsertReadingPlanMutation, AdminInsertReadingPlanMutationVariables>;

/**
 * __useAdminInsertReadingPlanMutation__
 *
 * To run a mutation, you first call `useAdminInsertReadingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertReadingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertReadingPlanMutation, { data, loading, error }] = useAdminInsertReadingPlanMutation({
 *   variables: {
 *      creatorId: // value for 'creatorId'
 *      initialReadings: // value for 'initialReadings'
 *      issuedToEmail: // value for 'issuedToEmail'
 *      userId: // value for 'userId'
 *      readingPlanDurationId: // value for 'readingPlanDurationId'
 *   },
 * });
 */
export function useAdminInsertReadingPlanMutation(baseOptions?: Apollo.MutationHookOptions<AdminInsertReadingPlanMutation, AdminInsertReadingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminInsertReadingPlanMutation, AdminInsertReadingPlanMutationVariables>(AdminInsertReadingPlanDocument, options);
      }
export type AdminInsertReadingPlanMutationHookResult = ReturnType<typeof useAdminInsertReadingPlanMutation>;
export type AdminInsertReadingPlanMutationResult = Apollo.MutationResult<AdminInsertReadingPlanMutation>;
export type AdminInsertReadingPlanMutationOptions = Apollo.BaseMutationOptions<AdminInsertReadingPlanMutation, AdminInsertReadingPlanMutationVariables>;
export const AdminInsertTextDocument = gql`
    mutation AdminInsertText($id: uuid!, $description: String, $name: String, $phonetic: String) {
  insert_topic_one(
    object: {id: $id, phonetic: $phonetic, name: $name, description: $description}
  ) {
    name
  }
}
    `;
export type AdminInsertTextMutationFn = Apollo.MutationFunction<AdminInsertTextMutation, AdminInsertTextMutationVariables>;

/**
 * __useAdminInsertTextMutation__
 *
 * To run a mutation, you first call `useAdminInsertTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertTextMutation, { data, loading, error }] = useAdminInsertTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      phonetic: // value for 'phonetic'
 *   },
 * });
 */
export function useAdminInsertTextMutation(baseOptions?: Apollo.MutationHookOptions<AdminInsertTextMutation, AdminInsertTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminInsertTextMutation, AdminInsertTextMutationVariables>(AdminInsertTextDocument, options);
      }
export type AdminInsertTextMutationHookResult = ReturnType<typeof useAdminInsertTextMutation>;
export type AdminInsertTextMutationResult = Apollo.MutationResult<AdminInsertTextMutation>;
export type AdminInsertTextMutationOptions = Apollo.BaseMutationOptions<AdminInsertTextMutation, AdminInsertTextMutationVariables>;
export const AdminInsertTopicPillarDocument = gql`
    mutation AdminInsertTopicPillar($pillarId: uuid!, $topicId: uuid!, $isPrimary: Boolean) {
  insert_pillar_topic_one(
    object: {isprimary: $isPrimary, pillar_id: $pillarId, topic_id: $topicId}
    on_conflict: {constraint: pillar_topic_pk, update_columns: pillar_id, where: {pillar_id: {_eq: $pillarId}, topic_id: {_eq: $topicId}}}
  ) {
    pillar {
      id
      title
    }
    topic {
      id
      name
    }
    isprimary
  }
}
    `;
export type AdminInsertTopicPillarMutationFn = Apollo.MutationFunction<AdminInsertTopicPillarMutation, AdminInsertTopicPillarMutationVariables>;

/**
 * __useAdminInsertTopicPillarMutation__
 *
 * To run a mutation, you first call `useAdminInsertTopicPillarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertTopicPillarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertTopicPillarMutation, { data, loading, error }] = useAdminInsertTopicPillarMutation({
 *   variables: {
 *      pillarId: // value for 'pillarId'
 *      topicId: // value for 'topicId'
 *      isPrimary: // value for 'isPrimary'
 *   },
 * });
 */
export function useAdminInsertTopicPillarMutation(baseOptions?: Apollo.MutationHookOptions<AdminInsertTopicPillarMutation, AdminInsertTopicPillarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminInsertTopicPillarMutation, AdminInsertTopicPillarMutationVariables>(AdminInsertTopicPillarDocument, options);
      }
export type AdminInsertTopicPillarMutationHookResult = ReturnType<typeof useAdminInsertTopicPillarMutation>;
export type AdminInsertTopicPillarMutationResult = Apollo.MutationResult<AdminInsertTopicPillarMutation>;
export type AdminInsertTopicPillarMutationOptions = Apollo.BaseMutationOptions<AdminInsertTopicPillarMutation, AdminInsertTopicPillarMutationVariables>;
export const AdminInsertUserDocument = gql`
    mutation AdminInsertUser($countryId: uuid!, $defaultLanguageId: uuid!, $email: String!, $name: String!, $orgId: uuid!, $roleId: uuid!) {
  user: insert_user_one(
    object: {org_id: $orgId, role_id: $roleId, person: {data: {country_id: $countryId, default_language_id: $defaultLanguageId, email: $email, name: $name}}}
  ) {
    id
    person {
      email
    }
  }
}
    `;
export type AdminInsertUserMutationFn = Apollo.MutationFunction<AdminInsertUserMutation, AdminInsertUserMutationVariables>;

/**
 * __useAdminInsertUserMutation__
 *
 * To run a mutation, you first call `useAdminInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertUserMutation, { data, loading, error }] = useAdminInsertUserMutation({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      defaultLanguageId: // value for 'defaultLanguageId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      orgId: // value for 'orgId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useAdminInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminInsertUserMutation, AdminInsertUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminInsertUserMutation, AdminInsertUserMutationVariables>(AdminInsertUserDocument, options);
      }
export type AdminInsertUserMutationHookResult = ReturnType<typeof useAdminInsertUserMutation>;
export type AdminInsertUserMutationResult = Apollo.MutationResult<AdminInsertUserMutation>;
export type AdminInsertUserMutationOptions = Apollo.BaseMutationOptions<AdminInsertUserMutation, AdminInsertUserMutationVariables>;
export const AdminUpdateReadingPlanDocument = gql`
    mutation AdminUpdateReadingPlan($id: uuid!, $initialReadings: Int!, $issuedToEmail: String!, $userId: uuid!, $readingPlanDurationId: uuid!) {
  readingPlan: update_readingplan_by_pk(
    pk_columns: {id: $id}
    _set: {initialreadings: $initialReadings, issuedtoemail: $issuedToEmail, user_id: $userId, readingplan_duration_id: $readingPlanDurationId}
  ) {
    id
    initialreadings
    remainingreadings
    issuedtoemail
    readingplan_duration_id
    valid_to
    created
    user_id
    creator_id
  }
}
    `;
export type AdminUpdateReadingPlanMutationFn = Apollo.MutationFunction<AdminUpdateReadingPlanMutation, AdminUpdateReadingPlanMutationVariables>;

/**
 * __useAdminUpdateReadingPlanMutation__
 *
 * To run a mutation, you first call `useAdminUpdateReadingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateReadingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateReadingPlanMutation, { data, loading, error }] = useAdminUpdateReadingPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      initialReadings: // value for 'initialReadings'
 *      issuedToEmail: // value for 'issuedToEmail'
 *      userId: // value for 'userId'
 *      readingPlanDurationId: // value for 'readingPlanDurationId'
 *   },
 * });
 */
export function useAdminUpdateReadingPlanMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateReadingPlanMutation, AdminUpdateReadingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateReadingPlanMutation, AdminUpdateReadingPlanMutationVariables>(AdminUpdateReadingPlanDocument, options);
      }
export type AdminUpdateReadingPlanMutationHookResult = ReturnType<typeof useAdminUpdateReadingPlanMutation>;
export type AdminUpdateReadingPlanMutationResult = Apollo.MutationResult<AdminUpdateReadingPlanMutation>;
export type AdminUpdateReadingPlanMutationOptions = Apollo.BaseMutationOptions<AdminUpdateReadingPlanMutation, AdminUpdateReadingPlanMutationVariables>;
export const UpdateTopicDescriptionDocument = gql`
    mutation UpdateTopicDescription($id: uuid!, $description: String) {
  update_topic_by_pk(pk_columns: {id: $id}, _set: {description: $description}) {
    description
  }
}
    `;
export type UpdateTopicDescriptionMutationFn = Apollo.MutationFunction<UpdateTopicDescriptionMutation, UpdateTopicDescriptionMutationVariables>;

/**
 * __useUpdateTopicDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTopicDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicDescriptionMutation, { data, loading, error }] = useUpdateTopicDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTopicDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicDescriptionMutation, UpdateTopicDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicDescriptionMutation, UpdateTopicDescriptionMutationVariables>(UpdateTopicDescriptionDocument, options);
      }
export type UpdateTopicDescriptionMutationHookResult = ReturnType<typeof useUpdateTopicDescriptionMutation>;
export type UpdateTopicDescriptionMutationResult = Apollo.MutationResult<UpdateTopicDescriptionMutation>;
export type UpdateTopicDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateTopicDescriptionMutation, UpdateTopicDescriptionMutationVariables>;
export const UpdateTopicNameDocument = gql`
    mutation UpdateTopicName($id: uuid!, $newName: String) {
  update_topic_by_pk(pk_columns: {id: $id}, _set: {name: $newName}) {
    name
  }
}
    `;
export type UpdateTopicNameMutationFn = Apollo.MutationFunction<UpdateTopicNameMutation, UpdateTopicNameMutationVariables>;

/**
 * __useUpdateTopicNameMutation__
 *
 * To run a mutation, you first call `useUpdateTopicNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicNameMutation, { data, loading, error }] = useUpdateTopicNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useUpdateTopicNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicNameMutation, UpdateTopicNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicNameMutation, UpdateTopicNameMutationVariables>(UpdateTopicNameDocument, options);
      }
export type UpdateTopicNameMutationHookResult = ReturnType<typeof useUpdateTopicNameMutation>;
export type UpdateTopicNameMutationResult = Apollo.MutationResult<UpdateTopicNameMutation>;
export type UpdateTopicNameMutationOptions = Apollo.BaseMutationOptions<UpdateTopicNameMutation, UpdateTopicNameMutationVariables>;
export const AdminUpdateTopicPillarDocument = gql`
    mutation AdminUpdateTopicPillar($pillarId: uuid!, $topicId: uuid!, $newPillarId: uuid, $isPrimary: Boolean) {
  update_pillar_topic_by_pk(
    pk_columns: {pillar_id: $pillarId, topic_id: $topicId}
    _set: {pillar_id: $newPillarId, topic_id: $topicId, isprimary: $isPrimary}
  ) {
    pillar {
      id
      title
    }
    isprimary
  }
}
    `;
export type AdminUpdateTopicPillarMutationFn = Apollo.MutationFunction<AdminUpdateTopicPillarMutation, AdminUpdateTopicPillarMutationVariables>;

/**
 * __useAdminUpdateTopicPillarMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTopicPillarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTopicPillarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTopicPillarMutation, { data, loading, error }] = useAdminUpdateTopicPillarMutation({
 *   variables: {
 *      pillarId: // value for 'pillarId'
 *      topicId: // value for 'topicId'
 *      newPillarId: // value for 'newPillarId'
 *      isPrimary: // value for 'isPrimary'
 *   },
 * });
 */
export function useAdminUpdateTopicPillarMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateTopicPillarMutation, AdminUpdateTopicPillarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateTopicPillarMutation, AdminUpdateTopicPillarMutationVariables>(AdminUpdateTopicPillarDocument, options);
      }
export type AdminUpdateTopicPillarMutationHookResult = ReturnType<typeof useAdminUpdateTopicPillarMutation>;
export type AdminUpdateTopicPillarMutationResult = Apollo.MutationResult<AdminUpdateTopicPillarMutation>;
export type AdminUpdateTopicPillarMutationOptions = Apollo.BaseMutationOptions<AdminUpdateTopicPillarMutation, AdminUpdateTopicPillarMutationVariables>;
export const AdminUpdateUserDocument = gql`
    mutation AdminUpdateUser($countryId: uuid!, $defaultLanguageId: uuid!, $email: String!, $name: String!, $orgId: uuid!, $roleId: uuid!, $id: uuid!, $personId: uuid!) {
  update_person_by_pk(
    pk_columns: {id: $personId}
    _set: {country_id: $countryId, default_language_id: $defaultLanguageId, email: $email, name: $name}
  ) {
    id
  }
  user: update_user_by_pk(
    pk_columns: {id: $id}
    _set: {org_id: $orgId, role_id: $roleId}
  ) {
    id
    role_id
    created
    person {
      id
      country_id
      default_language_id
      email
      name
      language {
        id
        isocode
      }
      person_get_has_accepted_latest_eula
      emailverified
      timezone
    }
    org {
      id
      name
    }
  }
}
    `;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      defaultLanguageId: // value for 'defaultLanguageId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      orgId: // value for 'orgId'
 *      roleId: // value for 'roleId'
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(AdminUpdateUserDocument, options);
      }
export type AdminUpdateUserMutationHookResult = ReturnType<typeof useAdminUpdateUserMutation>;
export type AdminUpdateUserMutationResult = Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>;
export const AdminUpdateTextDocument = gql`
    mutation AdminUpdateText($id: uuid!, $description: String, $name: String, $phonetic: String) {
  update_topic_by_pk(
    pk_columns: {id: $id}
    _set: {phonetic: $phonetic, name: $name, description: $description}
  ) {
    name
  }
}
    `;
export type AdminUpdateTextMutationFn = Apollo.MutationFunction<AdminUpdateTextMutation, AdminUpdateTextMutationVariables>;

/**
 * __useAdminUpdateTextMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTextMutation, { data, loading, error }] = useAdminUpdateTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      phonetic: // value for 'phonetic'
 *   },
 * });
 */
export function useAdminUpdateTextMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateTextMutation, AdminUpdateTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateTextMutation, AdminUpdateTextMutationVariables>(AdminUpdateTextDocument, options);
      }
export type AdminUpdateTextMutationHookResult = ReturnType<typeof useAdminUpdateTextMutation>;
export type AdminUpdateTextMutationResult = Apollo.MutationResult<AdminUpdateTextMutation>;
export type AdminUpdateTextMutationOptions = Apollo.BaseMutationOptions<AdminUpdateTextMutation, AdminUpdateTextMutationVariables>;
export const AdminUpsertTextDocument = gql`
    mutation AdminUpsertText($id: uuid, $description: String, $name: String, $phonetic: String) {
  insert_topic_one(
    object: {id: $id, phonetic: $phonetic, name: $name, description: $description}
    on_conflict: {constraint: topic_ak_name, update_columns: [name, description, phonetic]}
  ) {
    name
  }
}
    `;
export type AdminUpsertTextMutationFn = Apollo.MutationFunction<AdminUpsertTextMutation, AdminUpsertTextMutationVariables>;

/**
 * __useAdminUpsertTextMutation__
 *
 * To run a mutation, you first call `useAdminUpsertTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpsertTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpsertTextMutation, { data, loading, error }] = useAdminUpsertTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      phonetic: // value for 'phonetic'
 *   },
 * });
 */
export function useAdminUpsertTextMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpsertTextMutation, AdminUpsertTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpsertTextMutation, AdminUpsertTextMutationVariables>(AdminUpsertTextDocument, options);
      }
export type AdminUpsertTextMutationHookResult = ReturnType<typeof useAdminUpsertTextMutation>;
export type AdminUpsertTextMutationResult = Apollo.MutationResult<AdminUpsertTextMutation>;
export type AdminUpsertTextMutationOptions = Apollo.BaseMutationOptions<AdminUpsertTextMutation, AdminUpsertTextMutationVariables>;
export const InsertUsersDocument = gql`
    mutation InsertUsers($users: [user_insert_input!]!) {
  insert_user(objects: $users) {
    affected_rows
  }
}
    `;
export type InsertUsersMutationFn = Apollo.MutationFunction<InsertUsersMutation, InsertUsersMutationVariables>;

/**
 * __useInsertUsersMutation__
 *
 * To run a mutation, you first call `useInsertUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUsersMutation, { data, loading, error }] = useInsertUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useInsertUsersMutation(baseOptions?: Apollo.MutationHookOptions<InsertUsersMutation, InsertUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUsersMutation, InsertUsersMutationVariables>(InsertUsersDocument, options);
      }
export type InsertUsersMutationHookResult = ReturnType<typeof useInsertUsersMutation>;
export type InsertUsersMutationResult = Apollo.MutationResult<InsertUsersMutation>;
export type InsertUsersMutationOptions = Apollo.BaseMutationOptions<InsertUsersMutation, InsertUsersMutationVariables>;
export const CheckBookReadingOwnershipDocument = gql`
    query CheckBookReadingOwnership($book_id: uuid!, $reading_id: uuid!) {
  reading: reading_aggregate(
    where: {book: {user_id: {_eq: $book_id}}, id: {_eq: $reading_id}}
  ) {
    does: aggregate {
      exist: count(distinct: true)
    }
  }
}
    `;

/**
 * __useCheckBookReadingOwnershipQuery__
 *
 * To run a query within a React component, call `useCheckBookReadingOwnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBookReadingOwnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBookReadingOwnershipQuery({
 *   variables: {
 *      book_id: // value for 'book_id'
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useCheckBookReadingOwnershipQuery(baseOptions: Apollo.QueryHookOptions<CheckBookReadingOwnershipQuery, CheckBookReadingOwnershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckBookReadingOwnershipQuery, CheckBookReadingOwnershipQueryVariables>(CheckBookReadingOwnershipDocument, options);
      }
export function useCheckBookReadingOwnershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckBookReadingOwnershipQuery, CheckBookReadingOwnershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckBookReadingOwnershipQuery, CheckBookReadingOwnershipQueryVariables>(CheckBookReadingOwnershipDocument, options);
        }
export type CheckBookReadingOwnershipQueryHookResult = ReturnType<typeof useCheckBookReadingOwnershipQuery>;
export type CheckBookReadingOwnershipLazyQueryHookResult = ReturnType<typeof useCheckBookReadingOwnershipLazyQuery>;
export type CheckBookReadingOwnershipQueryResult = Apollo.QueryResult<CheckBookReadingOwnershipQuery, CheckBookReadingOwnershipQueryVariables>;
export const CountUserParticipationEventsByReadingDocument = gql`
    query CountUserParticipationEventsByReading($user_id: uuid!, $reading_id: uuid!) {
  participationevent_aggregate(
    where: {user_id: {_eq: $user_id}, reading_id: {_eq: $reading_id}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountUserParticipationEventsByReadingQuery__
 *
 * To run a query within a React component, call `useCountUserParticipationEventsByReadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUserParticipationEventsByReadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUserParticipationEventsByReadingQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useCountUserParticipationEventsByReadingQuery(baseOptions: Apollo.QueryHookOptions<CountUserParticipationEventsByReadingQuery, CountUserParticipationEventsByReadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountUserParticipationEventsByReadingQuery, CountUserParticipationEventsByReadingQueryVariables>(CountUserParticipationEventsByReadingDocument, options);
      }
export function useCountUserParticipationEventsByReadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountUserParticipationEventsByReadingQuery, CountUserParticipationEventsByReadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountUserParticipationEventsByReadingQuery, CountUserParticipationEventsByReadingQueryVariables>(CountUserParticipationEventsByReadingDocument, options);
        }
export type CountUserParticipationEventsByReadingQueryHookResult = ReturnType<typeof useCountUserParticipationEventsByReadingQuery>;
export type CountUserParticipationEventsByReadingLazyQueryHookResult = ReturnType<typeof useCountUserParticipationEventsByReadingLazyQuery>;
export type CountUserParticipationEventsByReadingQueryResult = Apollo.QueryResult<CountUserParticipationEventsByReadingQuery, CountUserParticipationEventsByReadingQueryVariables>;
export const GetAllDebriefingsForUsersDocument = gql`
    query GetAllDebriefingsForUsers {
  debriefing(order_by: {date: asc}, where: {date: {_gte: "now()"}}) {
    date
    id
    link
  }
}
    `;

/**
 * __useGetAllDebriefingsForUsersQuery__
 *
 * To run a query within a React component, call `useGetAllDebriefingsForUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDebriefingsForUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDebriefingsForUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDebriefingsForUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDebriefingsForUsersQuery, GetAllDebriefingsForUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDebriefingsForUsersQuery, GetAllDebriefingsForUsersQueryVariables>(GetAllDebriefingsForUsersDocument, options);
      }
export function useGetAllDebriefingsForUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDebriefingsForUsersQuery, GetAllDebriefingsForUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDebriefingsForUsersQuery, GetAllDebriefingsForUsersQueryVariables>(GetAllDebriefingsForUsersDocument, options);
        }
export type GetAllDebriefingsForUsersQueryHookResult = ReturnType<typeof useGetAllDebriefingsForUsersQuery>;
export type GetAllDebriefingsForUsersLazyQueryHookResult = ReturnType<typeof useGetAllDebriefingsForUsersLazyQuery>;
export type GetAllDebriefingsForUsersQueryResult = Apollo.QueryResult<GetAllDebriefingsForUsersQuery, GetAllDebriefingsForUsersQueryVariables>;
export const GetBookAggregateDocument = gql`
    query GetBookAggregate($where: book_bool_exp) {
  book_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetBookAggregateQuery__
 *
 * To run a query within a React component, call `useGetBookAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetBookAggregateQuery, GetBookAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookAggregateQuery, GetBookAggregateQueryVariables>(GetBookAggregateDocument, options);
      }
export function useGetBookAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookAggregateQuery, GetBookAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookAggregateQuery, GetBookAggregateQueryVariables>(GetBookAggregateDocument, options);
        }
export type GetBookAggregateQueryHookResult = ReturnType<typeof useGetBookAggregateQuery>;
export type GetBookAggregateLazyQueryHookResult = ReturnType<typeof useGetBookAggregateLazyQuery>;
export type GetBookAggregateQueryResult = Apollo.QueryResult<GetBookAggregateQuery, GetBookAggregateQueryVariables>;
export const GetBookByIdDocument = gql`
    query GetBookById($id: uuid!) {
  book: book_by_pk(id: $id) {
    allowinstantmeetings
    book_topics {
      topic_id
      topic {
        name
      }
    }
    booklanguages {
      language_id
    }
    currentbookstate_id
    id
    maxreaders
    name
    user_id
    currentstate {
      id
      name
    }
    readings {
      id
    }
  }
}
    `;

/**
 * __useGetBookByIdQuery__
 *
 * To run a query within a React component, call `useGetBookByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBookByIdQuery, GetBookByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookByIdQuery, GetBookByIdQueryVariables>(GetBookByIdDocument, options);
      }
export function useGetBookByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookByIdQuery, GetBookByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookByIdQuery, GetBookByIdQueryVariables>(GetBookByIdDocument, options);
        }
export type GetBookByIdQueryHookResult = ReturnType<typeof useGetBookByIdQuery>;
export type GetBookByIdLazyQueryHookResult = ReturnType<typeof useGetBookByIdLazyQuery>;
export type GetBookByIdQueryResult = Apollo.QueryResult<GetBookByIdQuery, GetBookByIdQueryVariables>;
export const GetBookStatesDocument = gql`
    query GetBookStates {
  bookstates: bookstate {
    id
    name
  }
}
    `;

/**
 * __useGetBookStatesQuery__
 *
 * To run a query within a React component, call `useGetBookStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetBookStatesQuery, GetBookStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookStatesQuery, GetBookStatesQueryVariables>(GetBookStatesDocument, options);
      }
export function useGetBookStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookStatesQuery, GetBookStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookStatesQuery, GetBookStatesQueryVariables>(GetBookStatesDocument, options);
        }
export type GetBookStatesQueryHookResult = ReturnType<typeof useGetBookStatesQuery>;
export type GetBookStatesLazyQueryHookResult = ReturnType<typeof useGetBookStatesLazyQuery>;
export type GetBookStatesQueryResult = Apollo.QueryResult<GetBookStatesQuery, GetBookStatesQueryVariables>;
export const GetBookTypesDocument = gql`
    query GetBookTypes($userId: uuid!) {
  bookstates: bookstate {
    id
    name
  }
  languages: language {
    id
    name
  }
  topics: topic {
    id
    name
  }
  users: user(
    where: {org: {users: {id: {_eq: $userId}}}, role: {name: {_eq: "book"}}}
  ) {
    id
    created
    person {
      name
    }
    org {
      name
    }
    role {
      name
    }
  }
}
    `;

/**
 * __useGetBookTypesQuery__
 *
 * To run a query within a React component, call `useGetBookTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookTypesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBookTypesQuery(baseOptions: Apollo.QueryHookOptions<GetBookTypesQuery, GetBookTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookTypesQuery, GetBookTypesQueryVariables>(GetBookTypesDocument, options);
      }
export function useGetBookTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookTypesQuery, GetBookTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookTypesQuery, GetBookTypesQueryVariables>(GetBookTypesDocument, options);
        }
export type GetBookTypesQueryHookResult = ReturnType<typeof useGetBookTypesQuery>;
export type GetBookTypesLazyQueryHookResult = ReturnType<typeof useGetBookTypesLazyQuery>;
export type GetBookTypesQueryResult = Apollo.QueryResult<GetBookTypesQuery, GetBookTypesQueryVariables>;
export const GetBooksDocument = gql`
    query GetBooks($userId: uuid) {
  books: book(
    where: {user: {org: {users: {id: {_eq: $userId}}}}}
    order_by: {created: desc}
  ) {
    id
    name
    maxreaders
    currentstate {
      name
    }
    readings_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetBooksQuery__
 *
 * To run a query within a React component, call `useGetBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBooksQuery(baseOptions?: Apollo.QueryHookOptions<GetBooksQuery, GetBooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBooksQuery, GetBooksQueryVariables>(GetBooksDocument, options);
      }
export function useGetBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBooksQuery, GetBooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBooksQuery, GetBooksQueryVariables>(GetBooksDocument, options);
        }
export type GetBooksQueryHookResult = ReturnType<typeof useGetBooksQuery>;
export type GetBooksLazyQueryHookResult = ReturnType<typeof useGetBooksLazyQuery>;
export type GetBooksQueryResult = Apollo.QueryResult<GetBooksQuery, GetBooksQueryVariables>;
export const GetBooksByUserIdDocument = gql`
    query GetBooksByUserId($userId: uuid!) {
  books: book(where: {user_id: {_eq: $userId}}) {
    id
    name
    maxreaders
    book_topics {
      topicname
      topic {
        id
        name
      }
    }
    currentstate {
      name
    }
    readings {
      id
    }
    readings_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetBooksByUserIdQuery__
 *
 * To run a query within a React component, call `useGetBooksByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBooksByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetBooksByUserIdQuery, GetBooksByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBooksByUserIdQuery, GetBooksByUserIdQueryVariables>(GetBooksByUserIdDocument, options);
      }
export function useGetBooksByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBooksByUserIdQuery, GetBooksByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBooksByUserIdQuery, GetBooksByUserIdQueryVariables>(GetBooksByUserIdDocument, options);
        }
export type GetBooksByUserIdQueryHookResult = ReturnType<typeof useGetBooksByUserIdQuery>;
export type GetBooksByUserIdLazyQueryHookResult = ReturnType<typeof useGetBooksByUserIdLazyQuery>;
export type GetBooksByUserIdQueryResult = Apollo.QueryResult<GetBooksByUserIdQuery, GetBooksByUserIdQueryVariables>;
export const GetDefaultsDocument = gql`
    query GetDefaults {
  orgs: org(where: {parent: {_is_null: true}}) {
    id
    name
  }
  roles: role(where: {isdefault: {_eq: true}}) {
    id
    name
  }
  languages: language(where: {isdefault: {_eq: true}}) {
    id
    name
    isocode
  }
  countries: country(where: {isdefault: {_eq: true}}) {
    id
    isocode
    name
  }
}
    `;

/**
 * __useGetDefaultsQuery__
 *
 * To run a query within a React component, call `useGetDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultsQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultsQuery, GetDefaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultsQuery, GetDefaultsQueryVariables>(GetDefaultsDocument, options);
      }
export function useGetDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultsQuery, GetDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultsQuery, GetDefaultsQueryVariables>(GetDefaultsDocument, options);
        }
export type GetDefaultsQueryHookResult = ReturnType<typeof useGetDefaultsQuery>;
export type GetDefaultsLazyQueryHookResult = ReturnType<typeof useGetDefaultsLazyQuery>;
export type GetDefaultsQueryResult = Apollo.QueryResult<GetDefaultsQuery, GetDefaultsQueryVariables>;
export const GetEulaAcceptancesByUserIdDocument = gql`
    query GetEulaAcceptancesByUserId($userId: uuid!) {
  eula_acceptances: eula_acceptance(
    where: {person: {users: {id: {_eq: $userId}}}}
  ) {
    accepted
    eula {
      created
      id
      translationkey
      version
    }
  }
}
    `;

/**
 * __useGetEulaAcceptancesByUserIdQuery__
 *
 * To run a query within a React component, call `useGetEulaAcceptancesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEulaAcceptancesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEulaAcceptancesByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEulaAcceptancesByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetEulaAcceptancesByUserIdQuery, GetEulaAcceptancesByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEulaAcceptancesByUserIdQuery, GetEulaAcceptancesByUserIdQueryVariables>(GetEulaAcceptancesByUserIdDocument, options);
      }
export function useGetEulaAcceptancesByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEulaAcceptancesByUserIdQuery, GetEulaAcceptancesByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEulaAcceptancesByUserIdQuery, GetEulaAcceptancesByUserIdQueryVariables>(GetEulaAcceptancesByUserIdDocument, options);
        }
export type GetEulaAcceptancesByUserIdQueryHookResult = ReturnType<typeof useGetEulaAcceptancesByUserIdQuery>;
export type GetEulaAcceptancesByUserIdLazyQueryHookResult = ReturnType<typeof useGetEulaAcceptancesByUserIdLazyQuery>;
export type GetEulaAcceptancesByUserIdQueryResult = Apollo.QueryResult<GetEulaAcceptancesByUserIdQuery, GetEulaAcceptancesByUserIdQueryVariables>;
export const GetEulasDocument = gql`
    query GetEulas {
  eula {
    created
    id
    translationkey
    version
  }
}
    `;

/**
 * __useGetEulasQuery__
 *
 * To run a query within a React component, call `useGetEulasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEulasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEulasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEulasQuery(baseOptions?: Apollo.QueryHookOptions<GetEulasQuery, GetEulasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEulasQuery, GetEulasQueryVariables>(GetEulasDocument, options);
      }
export function useGetEulasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEulasQuery, GetEulasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEulasQuery, GetEulasQueryVariables>(GetEulasDocument, options);
        }
export type GetEulasQueryHookResult = ReturnType<typeof useGetEulasQuery>;
export type GetEulasLazyQueryHookResult = ReturnType<typeof useGetEulasLazyQuery>;
export type GetEulasQueryResult = Apollo.QueryResult<GetEulasQuery, GetEulasQueryVariables>;
export const GetLanguagesDocument = gql`
    query GetLanguages {
  languages: language {
    id
    name
  }
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
      }
export function useGetLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetLatestEulaDocument = gql`
    query GetLatestEula {
  eula(limit: 1, order_by: {version: desc}) {
    created
    id
    translationkey
    version
  }
}
    `;

/**
 * __useGetLatestEulaQuery__
 *
 * To run a query within a React component, call `useGetLatestEulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestEulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestEulaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestEulaQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestEulaQuery, GetLatestEulaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestEulaQuery, GetLatestEulaQueryVariables>(GetLatestEulaDocument, options);
      }
export function useGetLatestEulaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestEulaQuery, GetLatestEulaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestEulaQuery, GetLatestEulaQueryVariables>(GetLatestEulaDocument, options);
        }
export type GetLatestEulaQueryHookResult = ReturnType<typeof useGetLatestEulaQuery>;
export type GetLatestEulaLazyQueryHookResult = ReturnType<typeof useGetLatestEulaLazyQuery>;
export type GetLatestEulaQueryResult = Apollo.QueryResult<GetLatestEulaQuery, GetLatestEulaQueryVariables>;
export const GetNextReadingsByUserIdDocument = gql`
    query GetNextReadingsByUserId($after: timestamptz!, $userId: uuid!) {
  nextReading: reading(
    order_by: {readingstart: asc}
    where: {participationEventName: {_eq: "signup"}, readingEventStatusName: {_eq: "Scheduled"}, readingstart: {_gt: $after}}
  ) {
    ...CoreReadingFields
    participationevents(
      order_by: [{created: desc}]
      where: {participationstate: {name: {_in: ["cancel", "signup"]}}, user_id: {_eq: $userId}}
    ) {
      participationstate {
        id
        name
      }
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetNextReadingsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetNextReadingsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextReadingsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextReadingsByUserIdQuery({
 *   variables: {
 *      after: // value for 'after'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNextReadingsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetNextReadingsByUserIdQuery, GetNextReadingsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextReadingsByUserIdQuery, GetNextReadingsByUserIdQueryVariables>(GetNextReadingsByUserIdDocument, options);
      }
export function useGetNextReadingsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextReadingsByUserIdQuery, GetNextReadingsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextReadingsByUserIdQuery, GetNextReadingsByUserIdQueryVariables>(GetNextReadingsByUserIdDocument, options);
        }
export type GetNextReadingsByUserIdQueryHookResult = ReturnType<typeof useGetNextReadingsByUserIdQuery>;
export type GetNextReadingsByUserIdLazyQueryHookResult = ReturnType<typeof useGetNextReadingsByUserIdLazyQuery>;
export type GetNextReadingsByUserIdQueryResult = Apollo.QueryResult<GetNextReadingsByUserIdQuery, GetNextReadingsByUserIdQueryVariables>;
export const GetOrgIdByUserIdDocument = gql`
    query GetOrgIdByUserId($userId: uuid!) {
  org: user_by_pk(id: $userId) {
    org_id
  }
}
    `;

/**
 * __useGetOrgIdByUserIdQuery__
 *
 * To run a query within a React component, call `useGetOrgIdByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgIdByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgIdByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetOrgIdByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrgIdByUserIdQuery, GetOrgIdByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgIdByUserIdQuery, GetOrgIdByUserIdQueryVariables>(GetOrgIdByUserIdDocument, options);
      }
export function useGetOrgIdByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgIdByUserIdQuery, GetOrgIdByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgIdByUserIdQuery, GetOrgIdByUserIdQueryVariables>(GetOrgIdByUserIdDocument, options);
        }
export type GetOrgIdByUserIdQueryHookResult = ReturnType<typeof useGetOrgIdByUserIdQuery>;
export type GetOrgIdByUserIdLazyQueryHookResult = ReturnType<typeof useGetOrgIdByUserIdLazyQuery>;
export type GetOrgIdByUserIdQueryResult = Apollo.QueryResult<GetOrgIdByUserIdQuery, GetOrgIdByUserIdQueryVariables>;
export const GetParticipationEventsPastDocument = gql`
    query GetParticipationEventsPast($userId: uuid!, $before: timestamptz!) {
  participationevent(
    distinct_on: [reading_id]
    order_by: [{reading_id: desc}, {created: desc}]
    where: {user_id: {_eq: $userId}, reading: {readingstart: {_lt: $before}}}
  ) {
    ...CoreParticipationEventFields
  }
}
    ${CoreParticipationEventFieldsFragmentDoc}`;

/**
 * __useGetParticipationEventsPastQuery__
 *
 * To run a query within a React component, call `useGetParticipationEventsPastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationEventsPastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationEventsPastQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetParticipationEventsPastQuery(baseOptions: Apollo.QueryHookOptions<GetParticipationEventsPastQuery, GetParticipationEventsPastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipationEventsPastQuery, GetParticipationEventsPastQueryVariables>(GetParticipationEventsPastDocument, options);
      }
export function useGetParticipationEventsPastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipationEventsPastQuery, GetParticipationEventsPastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipationEventsPastQuery, GetParticipationEventsPastQueryVariables>(GetParticipationEventsPastDocument, options);
        }
export type GetParticipationEventsPastQueryHookResult = ReturnType<typeof useGetParticipationEventsPastQuery>;
export type GetParticipationEventsPastLazyQueryHookResult = ReturnType<typeof useGetParticipationEventsPastLazyQuery>;
export type GetParticipationEventsPastQueryResult = Apollo.QueryResult<GetParticipationEventsPastQuery, GetParticipationEventsPastQueryVariables>;
export const GetParticipationEventsUpcomingDocument = gql`
    query GetParticipationEventsUpcoming($userId: uuid!, $after: timestamptz!) {
  participationevent(
    distinct_on: [reading_id]
    order_by: [{reading_id: desc}, {created: desc}]
    where: {user_id: {_eq: $userId}, reading: {readingstart: {_gt: $after}}}
  ) {
    ...CoreParticipationEventFields
  }
}
    ${CoreParticipationEventFieldsFragmentDoc}`;

/**
 * __useGetParticipationEventsUpcomingQuery__
 *
 * To run a query within a React component, call `useGetParticipationEventsUpcomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationEventsUpcomingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationEventsUpcomingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetParticipationEventsUpcomingQuery(baseOptions: Apollo.QueryHookOptions<GetParticipationEventsUpcomingQuery, GetParticipationEventsUpcomingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipationEventsUpcomingQuery, GetParticipationEventsUpcomingQueryVariables>(GetParticipationEventsUpcomingDocument, options);
      }
export function useGetParticipationEventsUpcomingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipationEventsUpcomingQuery, GetParticipationEventsUpcomingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipationEventsUpcomingQuery, GetParticipationEventsUpcomingQueryVariables>(GetParticipationEventsUpcomingDocument, options);
        }
export type GetParticipationEventsUpcomingQueryHookResult = ReturnType<typeof useGetParticipationEventsUpcomingQuery>;
export type GetParticipationEventsUpcomingLazyQueryHookResult = ReturnType<typeof useGetParticipationEventsUpcomingLazyQuery>;
export type GetParticipationEventsUpcomingQueryResult = Apollo.QueryResult<GetParticipationEventsUpcomingQuery, GetParticipationEventsUpcomingQueryVariables>;
export const GetParticipationStatesDocument = gql`
    query GetParticipationStates {
  participationstate {
    id
    name
  }
}
    `;

/**
 * __useGetParticipationStatesQuery__
 *
 * To run a query within a React component, call `useGetParticipationStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParticipationStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetParticipationStatesQuery, GetParticipationStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipationStatesQuery, GetParticipationStatesQueryVariables>(GetParticipationStatesDocument, options);
      }
export function useGetParticipationStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipationStatesQuery, GetParticipationStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipationStatesQuery, GetParticipationStatesQueryVariables>(GetParticipationStatesDocument, options);
        }
export type GetParticipationStatesQueryHookResult = ReturnType<typeof useGetParticipationStatesQuery>;
export type GetParticipationStatesLazyQueryHookResult = ReturnType<typeof useGetParticipationStatesLazyQuery>;
export type GetParticipationStatesQueryResult = Apollo.QueryResult<GetParticipationStatesQuery, GetParticipationStatesQueryVariables>;
export const GetPersonDocument = gql`
    query GetPerson($email: String!) {
  person(where: {email: {_eq: $email}}) {
    ...CorePersonFields
  }
}
    ${CorePersonFieldsFragmentDoc}`;

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
      }
export function useGetPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
        }
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>;
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>;
export type GetPersonQueryResult = Apollo.QueryResult<GetPersonQuery, GetPersonQueryVariables>;
export const GetPillarByIdDocument = gql`
    query GetPillarById($id: uuid!) {
  pillar: pillar_by_pk(id: $id) {
    id
    title
    description
    topic_description
    pillar_topics {
      topic {
        id
        name
        description
        quote
        readings(order_by: {readingstart: asc}) {
          id
          externalid
          maxreaders
          readingstart
        }
      }
    }
    literaryQuote {
      author
      text
    }
  }
}
    `;

/**
 * __useGetPillarByIdQuery__
 *
 * To run a query within a React component, call `useGetPillarByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPillarByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPillarByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPillarByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPillarByIdQuery, GetPillarByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPillarByIdQuery, GetPillarByIdQueryVariables>(GetPillarByIdDocument, options);
      }
export function useGetPillarByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPillarByIdQuery, GetPillarByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPillarByIdQuery, GetPillarByIdQueryVariables>(GetPillarByIdDocument, options);
        }
export type GetPillarByIdQueryHookResult = ReturnType<typeof useGetPillarByIdQuery>;
export type GetPillarByIdLazyQueryHookResult = ReturnType<typeof useGetPillarByIdLazyQuery>;
export type GetPillarByIdQueryResult = Apollo.QueryResult<GetPillarByIdQuery, GetPillarByIdQueryVariables>;
export const GetPillarConstraintsDocument = gql`
    query GetPillarConstraints($userId: uuid!) {
  pillarconstraint(where: {readingplan: {user_id: {_eq: $userId}}}) {
    pillar_id: pillarofprejudice_id
  }
}
    `;

/**
 * __useGetPillarConstraintsQuery__
 *
 * To run a query within a React component, call `useGetPillarConstraintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPillarConstraintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPillarConstraintsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPillarConstraintsQuery(baseOptions: Apollo.QueryHookOptions<GetPillarConstraintsQuery, GetPillarConstraintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPillarConstraintsQuery, GetPillarConstraintsQueryVariables>(GetPillarConstraintsDocument, options);
      }
export function useGetPillarConstraintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPillarConstraintsQuery, GetPillarConstraintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPillarConstraintsQuery, GetPillarConstraintsQueryVariables>(GetPillarConstraintsDocument, options);
        }
export type GetPillarConstraintsQueryHookResult = ReturnType<typeof useGetPillarConstraintsQuery>;
export type GetPillarConstraintsLazyQueryHookResult = ReturnType<typeof useGetPillarConstraintsLazyQuery>;
export type GetPillarConstraintsQueryResult = Apollo.QueryResult<GetPillarConstraintsQuery, GetPillarConstraintsQueryVariables>;
export const GetPillarsDocument = gql`
    query GetPillars {
  pillars: pillar(order_by: {title: asc}) {
    id
    title
    description
    slug
    topic_description
  }
}
    `;

/**
 * __useGetPillarsQuery__
 *
 * To run a query within a React component, call `useGetPillarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPillarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPillarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPillarsQuery(baseOptions?: Apollo.QueryHookOptions<GetPillarsQuery, GetPillarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPillarsQuery, GetPillarsQueryVariables>(GetPillarsDocument, options);
      }
export function useGetPillarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPillarsQuery, GetPillarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPillarsQuery, GetPillarsQueryVariables>(GetPillarsDocument, options);
        }
export type GetPillarsQueryHookResult = ReturnType<typeof useGetPillarsQuery>;
export type GetPillarsLazyQueryHookResult = ReturnType<typeof useGetPillarsLazyQuery>;
export type GetPillarsQueryResult = Apollo.QueryResult<GetPillarsQuery, GetPillarsQueryVariables>;
export const GetReadersDocument = gql`
    query GetReaders {
  readers: user(where: {role: {name: {_eq: "reader"}}}) {
    id
    person_id
    person {
      name
    }
  }
}
    `;

/**
 * __useGetReadersQuery__
 *
 * To run a query within a React component, call `useGetReadersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReadersQuery(baseOptions?: Apollo.QueryHookOptions<GetReadersQuery, GetReadersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadersQuery, GetReadersQueryVariables>(GetReadersDocument, options);
      }
export function useGetReadersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadersQuery, GetReadersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadersQuery, GetReadersQueryVariables>(GetReadersDocument, options);
        }
export type GetReadersQueryHookResult = ReturnType<typeof useGetReadersQuery>;
export type GetReadersLazyQueryHookResult = ReturnType<typeof useGetReadersLazyQuery>;
export type GetReadersQueryResult = Apollo.QueryResult<GetReadersQuery, GetReadersQueryVariables>;
export const GetReadingByIdDocument = gql`
    query GetReadingById($id: uuid!, $userIdWhere: uuid_comparison_exp = {}) {
  reading: reading(where: {id: {_eq: $id}}) {
    ...CoreReadingFields
    participationevents(
      order_by: [{created: desc}]
      where: {participationstate: {name: {_in: ["cancel", "signup"]}}, user_id: $userIdWhere}
      limit: 1
    ) {
      participationstate {
        id
        name
      }
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingByIdQuery__
 *
 * To run a query within a React component, call `useGetReadingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userIdWhere: // value for 'userIdWhere'
 *   },
 * });
 */
export function useGetReadingByIdQuery(baseOptions: Apollo.QueryHookOptions<GetReadingByIdQuery, GetReadingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingByIdQuery, GetReadingByIdQueryVariables>(GetReadingByIdDocument, options);
      }
export function useGetReadingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingByIdQuery, GetReadingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingByIdQuery, GetReadingByIdQueryVariables>(GetReadingByIdDocument, options);
        }
export type GetReadingByIdQueryHookResult = ReturnType<typeof useGetReadingByIdQuery>;
export type GetReadingByIdLazyQueryHookResult = ReturnType<typeof useGetReadingByIdLazyQuery>;
export type GetReadingByIdQueryResult = Apollo.QueryResult<GetReadingByIdQuery, GetReadingByIdQueryVariables>;
export const GetReadingByIdAndUserIdDocument = gql`
    query GetReadingByIdAndUserId($id: uuid!, $userId: uuid!) {
  reading: reading_by_pk(id: $id) {
    ...CoreReadingFields
    participationevents(
      order_by: [{created: desc}]
      where: {participationstate: {name: {_in: ["cancel", "signup"]}}, user_id: {_eq: $userId}}
      limit: 1
    ) {
      participationstate {
        id
        name
      }
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingByIdAndUserIdQuery__
 *
 * To run a query within a React component, call `useGetReadingByIdAndUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingByIdAndUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingByIdAndUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReadingByIdAndUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetReadingByIdAndUserIdQuery, GetReadingByIdAndUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingByIdAndUserIdQuery, GetReadingByIdAndUserIdQueryVariables>(GetReadingByIdAndUserIdDocument, options);
      }
export function useGetReadingByIdAndUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingByIdAndUserIdQuery, GetReadingByIdAndUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingByIdAndUserIdQuery, GetReadingByIdAndUserIdQueryVariables>(GetReadingByIdAndUserIdDocument, options);
        }
export type GetReadingByIdAndUserIdQueryHookResult = ReturnType<typeof useGetReadingByIdAndUserIdQuery>;
export type GetReadingByIdAndUserIdLazyQueryHookResult = ReturnType<typeof useGetReadingByIdAndUserIdLazyQuery>;
export type GetReadingByIdAndUserIdQueryResult = Apollo.QueryResult<GetReadingByIdAndUserIdQuery, GetReadingByIdAndUserIdQueryVariables>;
export const GetReadingPlanForUserDocument = gql`
    query GetReadingPlanForUser($languageId: uuid!, $userId: uuid!) {
  readingPlans: readingplan(where: {user_id: {_eq: $userId}}) {
    id
    initialreadings
    remainingreadings
    valid_to
    readingPlanDuration {
      id
      translation(where: {language_id: {_eq: $languageId}}) {
        name
      }
    }
    activated
    pillarconstraints {
      pillar {
        id
        description
        title
      }
    }
  }
  user: user_by_pk(id: $userId) {
    person {
      id
      name
      pronouns
      language {
        id
        isocode
      }
      person_get_has_accepted_latest_eula
      timezone
    }
  }
}
    `;

/**
 * __useGetReadingPlanForUserQuery__
 *
 * To run a query within a React component, call `useGetReadingPlanForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingPlanForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingPlanForUserQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReadingPlanForUserQuery(baseOptions: Apollo.QueryHookOptions<GetReadingPlanForUserQuery, GetReadingPlanForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingPlanForUserQuery, GetReadingPlanForUserQueryVariables>(GetReadingPlanForUserDocument, options);
      }
export function useGetReadingPlanForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingPlanForUserQuery, GetReadingPlanForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingPlanForUserQuery, GetReadingPlanForUserQueryVariables>(GetReadingPlanForUserDocument, options);
        }
export type GetReadingPlanForUserQueryHookResult = ReturnType<typeof useGetReadingPlanForUserQuery>;
export type GetReadingPlanForUserLazyQueryHookResult = ReturnType<typeof useGetReadingPlanForUserLazyQuery>;
export type GetReadingPlanForUserQueryResult = Apollo.QueryResult<GetReadingPlanForUserQuery, GetReadingPlanForUserQueryVariables>;
export const GetReadingStatesDocument = gql`
    query GetReadingStates {
  readingstate {
    id
    name
  }
}
    `;

/**
 * __useGetReadingStatesQuery__
 *
 * To run a query within a React component, call `useGetReadingStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReadingStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetReadingStatesQuery, GetReadingStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingStatesQuery, GetReadingStatesQueryVariables>(GetReadingStatesDocument, options);
      }
export function useGetReadingStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingStatesQuery, GetReadingStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingStatesQuery, GetReadingStatesQueryVariables>(GetReadingStatesDocument, options);
        }
export type GetReadingStatesQueryHookResult = ReturnType<typeof useGetReadingStatesQuery>;
export type GetReadingStatesLazyQueryHookResult = ReturnType<typeof useGetReadingStatesLazyQuery>;
export type GetReadingStatesQueryResult = Apollo.QueryResult<GetReadingStatesQuery, GetReadingStatesQueryVariables>;
export const GetReadingTypesForUserDocument = gql`
    query GetReadingTypesForUser($userId: uuid!) {
  books: book(where: {user: {id: {_eq: $userId}}}) {
    id
    name
    maxreaders
    book_topics {
      topicname
      topic {
        id
        name
      }
    }
    currentstate {
      name
    }
    readings_aggregate {
      aggregate {
        count
      }
    }
  }
  languages: language {
    id
    name
  }
  durations: duration {
    id
    minutes
  }
}
    `;

/**
 * __useGetReadingTypesForUserQuery__
 *
 * To run a query within a React component, call `useGetReadingTypesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingTypesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingTypesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReadingTypesForUserQuery(baseOptions: Apollo.QueryHookOptions<GetReadingTypesForUserQuery, GetReadingTypesForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingTypesForUserQuery, GetReadingTypesForUserQueryVariables>(GetReadingTypesForUserDocument, options);
      }
export function useGetReadingTypesForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingTypesForUserQuery, GetReadingTypesForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingTypesForUserQuery, GetReadingTypesForUserQueryVariables>(GetReadingTypesForUserDocument, options);
        }
export type GetReadingTypesForUserQueryHookResult = ReturnType<typeof useGetReadingTypesForUserQuery>;
export type GetReadingTypesForUserLazyQueryHookResult = ReturnType<typeof useGetReadingTypesForUserLazyQuery>;
export type GetReadingTypesForUserQueryResult = Apollo.QueryResult<GetReadingTypesForUserQuery, GetReadingTypesForUserQueryVariables>;
export const GetReadingWithReviewsDocument = gql`
    query GetReadingWithReviews($readingId: uuid!) {
  reading: reading_by_pk(id: $readingId) {
    id
    topicName
    topic {
      name
    }
    cancel_reviews {
      user_id
    }
    reviews {
      user_id
      id
    }
  }
}
    `;

/**
 * __useGetReadingWithReviewsQuery__
 *
 * To run a query within a React component, call `useGetReadingWithReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingWithReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingWithReviewsQuery({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useGetReadingWithReviewsQuery(baseOptions: Apollo.QueryHookOptions<GetReadingWithReviewsQuery, GetReadingWithReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingWithReviewsQuery, GetReadingWithReviewsQueryVariables>(GetReadingWithReviewsDocument, options);
      }
export function useGetReadingWithReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingWithReviewsQuery, GetReadingWithReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingWithReviewsQuery, GetReadingWithReviewsQueryVariables>(GetReadingWithReviewsDocument, options);
        }
export type GetReadingWithReviewsQueryHookResult = ReturnType<typeof useGetReadingWithReviewsQuery>;
export type GetReadingWithReviewsLazyQueryHookResult = ReturnType<typeof useGetReadingWithReviewsLazyQuery>;
export type GetReadingWithReviewsQueryResult = Apollo.QueryResult<GetReadingWithReviewsQuery, GetReadingWithReviewsQueryVariables>;
export const GetReadingsDocument = gql`
    query GetReadings($limit: Int!, $offset: Int!, $after: timestamptz!) {
  readings: reading(
    limit: $limit
    offset: $offset
    order_by: {readingstart: asc}
    where: {readingstart: {_gt: $after}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
  ) {
    ...CoreReadingFields
  }
  reading_aggregate(
    where: {readingstart: {_gt: $after}}
    order_by: {readingstart: asc}
  ) {
    aggregate {
      count
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingsQuery__
 *
 * To run a query within a React component, call `useGetReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetReadingsQuery(baseOptions: Apollo.QueryHookOptions<GetReadingsQuery, GetReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingsQuery, GetReadingsQueryVariables>(GetReadingsDocument, options);
      }
export function useGetReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingsQuery, GetReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingsQuery, GetReadingsQueryVariables>(GetReadingsDocument, options);
        }
export type GetReadingsQueryHookResult = ReturnType<typeof useGetReadingsQuery>;
export type GetReadingsLazyQueryHookResult = ReturnType<typeof useGetReadingsLazyQuery>;
export type GetReadingsQueryResult = Apollo.QueryResult<GetReadingsQuery, GetReadingsQueryVariables>;
export const GetReadingsBookPastDocument = gql`
    query GetReadingsBookPast($userId: uuid!, $limit: Int!, $offset: Int!, $before: timestamptz = "now()") {
  books: book(where: {user_id: {_eq: $userId}}) {
    id
    name
    created
    book_topics {
      topic_id
      book_id
      topicname
    }
    readings(
      limit: $limit
      offset: $offset
      order_by: {readingstart: asc}
      where: {readingstart: {_lte: $before}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
    ) {
      ...CoreReadingFields
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingsBookPastQuery__
 *
 * To run a query within a React component, call `useGetReadingsBookPastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingsBookPastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingsBookPastQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetReadingsBookPastQuery(baseOptions: Apollo.QueryHookOptions<GetReadingsBookPastQuery, GetReadingsBookPastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingsBookPastQuery, GetReadingsBookPastQueryVariables>(GetReadingsBookPastDocument, options);
      }
export function useGetReadingsBookPastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingsBookPastQuery, GetReadingsBookPastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingsBookPastQuery, GetReadingsBookPastQueryVariables>(GetReadingsBookPastDocument, options);
        }
export type GetReadingsBookPastQueryHookResult = ReturnType<typeof useGetReadingsBookPastQuery>;
export type GetReadingsBookPastLazyQueryHookResult = ReturnType<typeof useGetReadingsBookPastLazyQuery>;
export type GetReadingsBookPastQueryResult = Apollo.QueryResult<GetReadingsBookPastQuery, GetReadingsBookPastQueryVariables>;
export const GetReadingsBookUpcomingDocument = gql`
    query GetReadingsBookUpcoming($userId: uuid!, $limit: Int!, $offset: Int!, $after: timestamptz = "now()") {
  books: book(where: {user_id: {_eq: $userId}}) {
    id
    name
    created
    book_topics {
      topic_id
      book_id
      topicname
    }
    readings(
      limit: $limit
      offset: $offset
      order_by: {readingstart: asc}
      where: {readingstart: {_gte: $after}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
    ) {
      ...CoreReadingFields
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingsBookUpcomingQuery__
 *
 * To run a query within a React component, call `useGetReadingsBookUpcomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingsBookUpcomingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingsBookUpcomingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetReadingsBookUpcomingQuery(baseOptions: Apollo.QueryHookOptions<GetReadingsBookUpcomingQuery, GetReadingsBookUpcomingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingsBookUpcomingQuery, GetReadingsBookUpcomingQueryVariables>(GetReadingsBookUpcomingDocument, options);
      }
export function useGetReadingsBookUpcomingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingsBookUpcomingQuery, GetReadingsBookUpcomingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingsBookUpcomingQuery, GetReadingsBookUpcomingQueryVariables>(GetReadingsBookUpcomingDocument, options);
        }
export type GetReadingsBookUpcomingQueryHookResult = ReturnType<typeof useGetReadingsBookUpcomingQuery>;
export type GetReadingsBookUpcomingLazyQueryHookResult = ReturnType<typeof useGetReadingsBookUpcomingLazyQuery>;
export type GetReadingsBookUpcomingQueryResult = Apollo.QueryResult<GetReadingsBookUpcomingQuery, GetReadingsBookUpcomingQueryVariables>;
export const GetReadingsByPillarIdDocument = gql`
    query GetReadingsByPillarId($pillarId: uuid, $after: timestamptz!) {
  readings: reading(
    order_by: {readingstart: asc}
    where: {readingstart: {_gt: $after}, topic: {pillar_topics: {pillar_id: {_eq: $pillarId}}}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
  ) {
    ...CoreReadingFields
  }
  reading_aggregate(
    where: {readingstart: {_gt: $after}}
    order_by: {readingstart: asc}
  ) {
    aggregate {
      count
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingsByPillarIdQuery__
 *
 * To run a query within a React component, call `useGetReadingsByPillarIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingsByPillarIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingsByPillarIdQuery({
 *   variables: {
 *      pillarId: // value for 'pillarId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetReadingsByPillarIdQuery(baseOptions: Apollo.QueryHookOptions<GetReadingsByPillarIdQuery, GetReadingsByPillarIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingsByPillarIdQuery, GetReadingsByPillarIdQueryVariables>(GetReadingsByPillarIdDocument, options);
      }
export function useGetReadingsByPillarIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingsByPillarIdQuery, GetReadingsByPillarIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingsByPillarIdQuery, GetReadingsByPillarIdQueryVariables>(GetReadingsByPillarIdDocument, options);
        }
export type GetReadingsByPillarIdQueryHookResult = ReturnType<typeof useGetReadingsByPillarIdQuery>;
export type GetReadingsByPillarIdLazyQueryHookResult = ReturnType<typeof useGetReadingsByPillarIdLazyQuery>;
export type GetReadingsByPillarIdQueryResult = Apollo.QueryResult<GetReadingsByPillarIdQuery, GetReadingsByPillarIdQueryVariables>;
export const GetReadingsByTopicSlugDocument = gql`
    query GetReadingsByTopicSlug($userId: uuid!, $topicSlug: String!, $after: timestamptz!) {
  readings: reading(
    order_by: {readingstart: asc}
    where: {readingstart: {_gt: $after}, topic: {slug: {_eq: $topicSlug}}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}}
  ) {
    ...CoreReadingFields
    participationevents(
      order_by: [{created: desc}]
      where: {participationstate: {name: {_in: ["cancel", "signup"]}}, user_id: {_eq: $userId}}
      limit: 1
    ) {
      participationstate {
        id
        name
      }
    }
  }
  reading_aggregate(
    where: {readingstart: {_gt: $after}, topic: {slug: {_eq: $topicSlug}}}
    order_by: {readingstart: asc}
  ) {
    aggregate {
      count
    }
  }
}
    ${CoreReadingFieldsFragmentDoc}`;

/**
 * __useGetReadingsByTopicSlugQuery__
 *
 * To run a query within a React component, call `useGetReadingsByTopicSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingsByTopicSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingsByTopicSlugQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      topicSlug: // value for 'topicSlug'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetReadingsByTopicSlugQuery(baseOptions: Apollo.QueryHookOptions<GetReadingsByTopicSlugQuery, GetReadingsByTopicSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReadingsByTopicSlugQuery, GetReadingsByTopicSlugQueryVariables>(GetReadingsByTopicSlugDocument, options);
      }
export function useGetReadingsByTopicSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReadingsByTopicSlugQuery, GetReadingsByTopicSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReadingsByTopicSlugQuery, GetReadingsByTopicSlugQueryVariables>(GetReadingsByTopicSlugDocument, options);
        }
export type GetReadingsByTopicSlugQueryHookResult = ReturnType<typeof useGetReadingsByTopicSlugQuery>;
export type GetReadingsByTopicSlugLazyQueryHookResult = ReturnType<typeof useGetReadingsByTopicSlugLazyQuery>;
export type GetReadingsByTopicSlugQueryResult = Apollo.QueryResult<GetReadingsByTopicSlugQuery, GetReadingsByTopicSlugQueryVariables>;
export const GetReviewsByReadingIdDocument = gql`
    query GetReviewsByReadingId($readingId: uuid!) {
  reviews: review(
    order_by: {created: desc}
    where: {reading_id: {_eq: $readingId}}
  ) {
    id
    text
    created
    personName
  }
  reading_by_pk(id: $readingId) {
    book {
      name
    }
  }
}
    `;

/**
 * __useGetReviewsByReadingIdQuery__
 *
 * To run a query within a React component, call `useGetReviewsByReadingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewsByReadingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewsByReadingIdQuery({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useGetReviewsByReadingIdQuery(baseOptions: Apollo.QueryHookOptions<GetReviewsByReadingIdQuery, GetReviewsByReadingIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReviewsByReadingIdQuery, GetReviewsByReadingIdQueryVariables>(GetReviewsByReadingIdDocument, options);
      }
export function useGetReviewsByReadingIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReviewsByReadingIdQuery, GetReviewsByReadingIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReviewsByReadingIdQuery, GetReviewsByReadingIdQueryVariables>(GetReviewsByReadingIdDocument, options);
        }
export type GetReviewsByReadingIdQueryHookResult = ReturnType<typeof useGetReviewsByReadingIdQuery>;
export type GetReviewsByReadingIdLazyQueryHookResult = ReturnType<typeof useGetReviewsByReadingIdLazyQuery>;
export type GetReviewsByReadingIdQueryResult = Apollo.QueryResult<GetReviewsByReadingIdQuery, GetReviewsByReadingIdQueryVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  roles: role {
    id
    name
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetTimezonesDocument = gql`
    query GetTimezones {
  timezones: timezone(order_by: {offset: asc}) {
    abbr
    id
    name
    offset
  }
}
    `;

/**
 * __useGetTimezonesQuery__
 *
 * To run a query within a React component, call `useGetTimezonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimezonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimezonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimezonesQuery(baseOptions?: Apollo.QueryHookOptions<GetTimezonesQuery, GetTimezonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimezonesQuery, GetTimezonesQueryVariables>(GetTimezonesDocument, options);
      }
export function useGetTimezonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimezonesQuery, GetTimezonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimezonesQuery, GetTimezonesQueryVariables>(GetTimezonesDocument, options);
        }
export type GetTimezonesQueryHookResult = ReturnType<typeof useGetTimezonesQuery>;
export type GetTimezonesLazyQueryHookResult = ReturnType<typeof useGetTimezonesLazyQuery>;
export type GetTimezonesQueryResult = Apollo.QueryResult<GetTimezonesQuery, GetTimezonesQueryVariables>;
export const GetTopicBySlugDocument = gql`
    query GetTopicBySlug($slug: String!) {
  topic: topic(where: {slug: {_eq: $slug}}) {
    id
    name
    description
    quote
    pillar_topics {
      isprimary
      pillar {
        id
        title
        description
      }
    }
  }
}
    `;

/**
 * __useGetTopicBySlugQuery__
 *
 * To run a query within a React component, call `useGetTopicBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetTopicBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetTopicBySlugQuery, GetTopicBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicBySlugQuery, GetTopicBySlugQueryVariables>(GetTopicBySlugDocument, options);
      }
export function useGetTopicBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicBySlugQuery, GetTopicBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicBySlugQuery, GetTopicBySlugQueryVariables>(GetTopicBySlugDocument, options);
        }
export type GetTopicBySlugQueryHookResult = ReturnType<typeof useGetTopicBySlugQuery>;
export type GetTopicBySlugLazyQueryHookResult = ReturnType<typeof useGetTopicBySlugLazyQuery>;
export type GetTopicBySlugQueryResult = Apollo.QueryResult<GetTopicBySlugQuery, GetTopicBySlugQueryVariables>;
export const GetTopicsDocument = gql`
    query GetTopics($after: timestamptz!) {
  topics: topic(
    order_by: {name: asc}
    where: {readings: {readingstart: {_gt: $after}}}
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTopicsQuery(baseOptions: Apollo.QueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
      }
export function useGetTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
        }
export type GetTopicsQueryHookResult = ReturnType<typeof useGetTopicsQuery>;
export type GetTopicsLazyQueryHookResult = ReturnType<typeof useGetTopicsLazyQuery>;
export type GetTopicsQueryResult = Apollo.QueryResult<GetTopicsQuery, GetTopicsQueryVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($id: uuid!) {
  user: user_by_pk(id: $id) {
    id
    role_id
    created
    person {
      id
      country_id
      default_language_id
      email
      name
      language {
        id
        isocode
      }
      emailverified
      person_get_has_accepted_latest_eula
      pronouns
      timezone
    }
    org {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUserParticipationEventsByReadingDocument = gql`
    query GetUserParticipationEventsByReading($user_id: uuid!, $reading_id: uuid!) {
  participationevent(
    where: {user_id: {_eq: $user_id}, reading_id: {_eq: $reading_id}}
  ) {
    participationstate {
      name
    }
    created
  }
}
    `;

/**
 * __useGetUserParticipationEventsByReadingQuery__
 *
 * To run a query within a React component, call `useGetUserParticipationEventsByReadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserParticipationEventsByReadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserParticipationEventsByReadingQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useGetUserParticipationEventsByReadingQuery(baseOptions: Apollo.QueryHookOptions<GetUserParticipationEventsByReadingQuery, GetUserParticipationEventsByReadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserParticipationEventsByReadingQuery, GetUserParticipationEventsByReadingQueryVariables>(GetUserParticipationEventsByReadingDocument, options);
      }
export function useGetUserParticipationEventsByReadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserParticipationEventsByReadingQuery, GetUserParticipationEventsByReadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserParticipationEventsByReadingQuery, GetUserParticipationEventsByReadingQueryVariables>(GetUserParticipationEventsByReadingDocument, options);
        }
export type GetUserParticipationEventsByReadingQueryHookResult = ReturnType<typeof useGetUserParticipationEventsByReadingQuery>;
export type GetUserParticipationEventsByReadingLazyQueryHookResult = ReturnType<typeof useGetUserParticipationEventsByReadingLazyQuery>;
export type GetUserParticipationEventsByReadingQueryResult = Apollo.QueryResult<GetUserParticipationEventsByReadingQuery, GetUserParticipationEventsByReadingQueryVariables>;
export const GetUserTypesDocument = gql`
    query GetUserTypes($userId: uuid!) {
  books: book(where: {user_id: {_eq: $userId}}) {
    id
    name
    maxreaders
    currentstate {
      name
    }
    readings {
      id
    }
  }
  countries: country {
    id
    name
  }
  currentUser: user_by_pk(id: $userId) {
    org_id
  }
  languages: language {
    id
    name
  }
  roles: role {
    id
    name
  }
  timezones: timezone(order_by: {offset: asc}) {
    id
    name
    offset
  }
}
    `;

/**
 * __useGetUserTypesQuery__
 *
 * To run a query within a React component, call `useGetUserTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTypesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserTypesQuery(baseOptions: Apollo.QueryHookOptions<GetUserTypesQuery, GetUserTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTypesQuery, GetUserTypesQueryVariables>(GetUserTypesDocument, options);
      }
export function useGetUserTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTypesQuery, GetUserTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTypesQuery, GetUserTypesQueryVariables>(GetUserTypesDocument, options);
        }
export type GetUserTypesQueryHookResult = ReturnType<typeof useGetUserTypesQuery>;
export type GetUserTypesLazyQueryHookResult = ReturnType<typeof useGetUserTypesLazyQuery>;
export type GetUserTypesQueryResult = Apollo.QueryResult<GetUserTypesQuery, GetUserTypesQueryVariables>;
export const GetUserVerificationDataByUserIdDocument = gql`
    query GetUserVerificationDataByUserId($id: uuid!) {
  user_by_pk(id: $id) {
    id
    person {
      emailverified
      name
      person_get_has_accepted_latest_eula
      timezone
    }
  }
}
    `;

/**
 * __useGetUserVerificationDataByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserVerificationDataByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVerificationDataByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVerificationDataByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserVerificationDataByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserVerificationDataByUserIdQuery, GetUserVerificationDataByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserVerificationDataByUserIdQuery, GetUserVerificationDataByUserIdQueryVariables>(GetUserVerificationDataByUserIdDocument, options);
      }
export function useGetUserVerificationDataByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserVerificationDataByUserIdQuery, GetUserVerificationDataByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserVerificationDataByUserIdQuery, GetUserVerificationDataByUserIdQueryVariables>(GetUserVerificationDataByUserIdDocument, options);
        }
export type GetUserVerificationDataByUserIdQueryHookResult = ReturnType<typeof useGetUserVerificationDataByUserIdQuery>;
export type GetUserVerificationDataByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserVerificationDataByUserIdLazyQuery>;
export type GetUserVerificationDataByUserIdQueryResult = Apollo.QueryResult<GetUserVerificationDataByUserIdQuery, GetUserVerificationDataByUserIdQueryVariables>;
export const GetAllDebriefingsDocument = gql`
    query GetAllDebriefings {
  debriefing(order_by: {date: asc}) {
    date
    id
    link
  }
}
    `;

/**
 * __useGetAllDebriefingsQuery__
 *
 * To run a query within a React component, call `useGetAllDebriefingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDebriefingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDebriefingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDebriefingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDebriefingsQuery, GetAllDebriefingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDebriefingsQuery, GetAllDebriefingsQueryVariables>(GetAllDebriefingsDocument, options);
      }
export function useGetAllDebriefingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDebriefingsQuery, GetAllDebriefingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDebriefingsQuery, GetAllDebriefingsQueryVariables>(GetAllDebriefingsDocument, options);
        }
export type GetAllDebriefingsQueryHookResult = ReturnType<typeof useGetAllDebriefingsQuery>;
export type GetAllDebriefingsLazyQueryHookResult = ReturnType<typeof useGetAllDebriefingsLazyQuery>;
export type GetAllDebriefingsQueryResult = Apollo.QueryResult<GetAllDebriefingsQuery, GetAllDebriefingsQueryVariables>;
export const GetOrgsDocument = gql`
    query GetOrgs {
  orgs: org {
    id
    parent
    name
    country_id
  }
}
    `;

/**
 * __useGetOrgsQuery__
 *
 * To run a query within a React component, call `useGetOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgsQuery, GetOrgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgsQuery, GetOrgsQueryVariables>(GetOrgsDocument, options);
      }
export function useGetOrgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgsQuery, GetOrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgsQuery, GetOrgsQueryVariables>(GetOrgsDocument, options);
        }
export type GetOrgsQueryHookResult = ReturnType<typeof useGetOrgsQuery>;
export type GetOrgsLazyQueryHookResult = ReturnType<typeof useGetOrgsLazyQuery>;
export type GetOrgsQueryResult = Apollo.QueryResult<GetOrgsQuery, GetOrgsQueryVariables>;
export const AdminGetReadingPlanByIdDocument = gql`
    query AdminGetReadingPlanById($id: uuid!) {
  readingPlan: readingplan_by_pk(id: $id) {
    id
    initialreadings
    remainingreadings
    issuedtoemail
    readingplan_duration_id
    valid_to
    created
    user_id
    creator_id
  }
}
    `;

/**
 * __useAdminGetReadingPlanByIdQuery__
 *
 * To run a query within a React component, call `useAdminGetReadingPlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetReadingPlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetReadingPlanByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetReadingPlanByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminGetReadingPlanByIdQuery, AdminGetReadingPlanByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetReadingPlanByIdQuery, AdminGetReadingPlanByIdQueryVariables>(AdminGetReadingPlanByIdDocument, options);
      }
export function useAdminGetReadingPlanByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetReadingPlanByIdQuery, AdminGetReadingPlanByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetReadingPlanByIdQuery, AdminGetReadingPlanByIdQueryVariables>(AdminGetReadingPlanByIdDocument, options);
        }
export type AdminGetReadingPlanByIdQueryHookResult = ReturnType<typeof useAdminGetReadingPlanByIdQuery>;
export type AdminGetReadingPlanByIdLazyQueryHookResult = ReturnType<typeof useAdminGetReadingPlanByIdLazyQuery>;
export type AdminGetReadingPlanByIdQueryResult = Apollo.QueryResult<AdminGetReadingPlanByIdQuery, AdminGetReadingPlanByIdQueryVariables>;
export const AdminGetReadingPlanTypesDocument = gql`
    query AdminGetReadingPlanTypes($orgId: uuid!) {
  users: user(order_by: {person: {name: asc}}, where: {org_id: {_eq: $orgId}}) {
    id
    person {
      email
      name
    }
    org {
      name
    }
  }
}
    `;

/**
 * __useAdminGetReadingPlanTypesQuery__
 *
 * To run a query within a React component, call `useAdminGetReadingPlanTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetReadingPlanTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetReadingPlanTypesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useAdminGetReadingPlanTypesQuery(baseOptions: Apollo.QueryHookOptions<AdminGetReadingPlanTypesQuery, AdminGetReadingPlanTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetReadingPlanTypesQuery, AdminGetReadingPlanTypesQueryVariables>(AdminGetReadingPlanTypesDocument, options);
      }
export function useAdminGetReadingPlanTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetReadingPlanTypesQuery, AdminGetReadingPlanTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetReadingPlanTypesQuery, AdminGetReadingPlanTypesQueryVariables>(AdminGetReadingPlanTypesDocument, options);
        }
export type AdminGetReadingPlanTypesQueryHookResult = ReturnType<typeof useAdminGetReadingPlanTypesQuery>;
export type AdminGetReadingPlanTypesLazyQueryHookResult = ReturnType<typeof useAdminGetReadingPlanTypesLazyQuery>;
export type AdminGetReadingPlanTypesQueryResult = Apollo.QueryResult<AdminGetReadingPlanTypesQuery, AdminGetReadingPlanTypesQueryVariables>;
export const AdminGetReadingPlansDocument = gql`
    query AdminGetReadingPlans {
  readingPlans: readingplan(order_by: {created: desc}) {
    id
    initialreadings
    remainingreadings
    user {
      person {
        email
        name
      }
    }
    valid_to
    created
  }
}
    `;

/**
 * __useAdminGetReadingPlansQuery__
 *
 * To run a query within a React component, call `useAdminGetReadingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetReadingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetReadingPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetReadingPlansQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetReadingPlansQuery, AdminGetReadingPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetReadingPlansQuery, AdminGetReadingPlansQueryVariables>(AdminGetReadingPlansDocument, options);
      }
export function useAdminGetReadingPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetReadingPlansQuery, AdminGetReadingPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetReadingPlansQuery, AdminGetReadingPlansQueryVariables>(AdminGetReadingPlansDocument, options);
        }
export type AdminGetReadingPlansQueryHookResult = ReturnType<typeof useAdminGetReadingPlansQuery>;
export type AdminGetReadingPlansLazyQueryHookResult = ReturnType<typeof useAdminGetReadingPlansLazyQuery>;
export type AdminGetReadingPlansQueryResult = Apollo.QueryResult<AdminGetReadingPlansQuery, AdminGetReadingPlansQueryVariables>;
export const AdminGetReadings2024Document = gql`
    query AdminGetReadings2024 {
  reading(where: {created: {_gte: "01.01.24"}}, order_by: {topicName: asc}) {
    created
    readingEventStatusName
    seats_available
    topicName
  }
}
    `;

/**
 * __useAdminGetReadings2024Query__
 *
 * To run a query within a React component, call `useAdminGetReadings2024Query` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetReadings2024Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetReadings2024Query({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetReadings2024Query(baseOptions?: Apollo.QueryHookOptions<AdminGetReadings2024Query, AdminGetReadings2024QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetReadings2024Query, AdminGetReadings2024QueryVariables>(AdminGetReadings2024Document, options);
      }
export function useAdminGetReadings2024LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetReadings2024Query, AdminGetReadings2024QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetReadings2024Query, AdminGetReadings2024QueryVariables>(AdminGetReadings2024Document, options);
        }
export type AdminGetReadings2024QueryHookResult = ReturnType<typeof useAdminGetReadings2024Query>;
export type AdminGetReadings2024LazyQueryHookResult = ReturnType<typeof useAdminGetReadings2024LazyQuery>;
export type AdminGetReadings2024QueryResult = Apollo.QueryResult<AdminGetReadings2024Query, AdminGetReadings2024QueryVariables>;
export const AdminGetReadingsPerWeekDocument = gql`
    query AdminGetReadingsPerWeek {
  get_readings_per_week {
    week_start
    readings_count
  }
}
    `;

/**
 * __useAdminGetReadingsPerWeekQuery__
 *
 * To run a query within a React component, call `useAdminGetReadingsPerWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetReadingsPerWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetReadingsPerWeekQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetReadingsPerWeekQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetReadingsPerWeekQuery, AdminGetReadingsPerWeekQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetReadingsPerWeekQuery, AdminGetReadingsPerWeekQueryVariables>(AdminGetReadingsPerWeekDocument, options);
      }
export function useAdminGetReadingsPerWeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetReadingsPerWeekQuery, AdminGetReadingsPerWeekQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetReadingsPerWeekQuery, AdminGetReadingsPerWeekQueryVariables>(AdminGetReadingsPerWeekDocument, options);
        }
export type AdminGetReadingsPerWeekQueryHookResult = ReturnType<typeof useAdminGetReadingsPerWeekQuery>;
export type AdminGetReadingsPerWeekLazyQueryHookResult = ReturnType<typeof useAdminGetReadingsPerWeekLazyQuery>;
export type AdminGetReadingsPerWeekQueryResult = Apollo.QueryResult<AdminGetReadingsPerWeekQuery, AdminGetReadingsPerWeekQueryVariables>;
export const GetAllTopicsDocument = gql`
    query GetAllTopics($topicSearch: String!) {
  topic(
    order_by: {name: asc}
    where: {name: {_ilike: $topicSearch}, _and: {pillar_topics: {}}}
  ) {
    id
    name
    pillar_topics {
      pillar {
        title
      }
    }
  }
}
    `;

/**
 * __useGetAllTopicsQuery__
 *
 * To run a query within a React component, call `useGetAllTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTopicsQuery({
 *   variables: {
 *      topicSearch: // value for 'topicSearch'
 *   },
 * });
 */
export function useGetAllTopicsQuery(baseOptions: Apollo.QueryHookOptions<GetAllTopicsQuery, GetAllTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTopicsQuery, GetAllTopicsQueryVariables>(GetAllTopicsDocument, options);
      }
export function useGetAllTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTopicsQuery, GetAllTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTopicsQuery, GetAllTopicsQueryVariables>(GetAllTopicsDocument, options);
        }
export type GetAllTopicsQueryHookResult = ReturnType<typeof useGetAllTopicsQuery>;
export type GetAllTopicsLazyQueryHookResult = ReturnType<typeof useGetAllTopicsLazyQuery>;
export type GetAllTopicsQueryResult = Apollo.QueryResult<GetAllTopicsQuery, GetAllTopicsQueryVariables>;
export const GetTopicByIdDocument = gql`
    query GetTopicById($id: uuid!) {
  topic: topic(where: {id: {_eq: $id}}) {
    id
    name
    description
    phonetic
    quote
    pillar_topics {
      isprimary
      pillar {
        id
        title
        description
      }
    }
  }
}
    `;

/**
 * __useGetTopicByIdQuery__
 *
 * To run a query within a React component, call `useGetTopicByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTopicByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTopicByIdQuery, GetTopicByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicByIdQuery, GetTopicByIdQueryVariables>(GetTopicByIdDocument, options);
      }
export function useGetTopicByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicByIdQuery, GetTopicByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicByIdQuery, GetTopicByIdQueryVariables>(GetTopicByIdDocument, options);
        }
export type GetTopicByIdQueryHookResult = ReturnType<typeof useGetTopicByIdQuery>;
export type GetTopicByIdLazyQueryHookResult = ReturnType<typeof useGetTopicByIdLazyQuery>;
export type GetTopicByIdQueryResult = Apollo.QueryResult<GetTopicByIdQuery, GetTopicByIdQueryVariables>;
export const AdminNewsletterGeneralStatisticsDocument = gql`
    query AdminNewsletterGeneralStatistics {
  readersAmount: user_aggregate(where: {role: {name: {_eq: "reader"}}}) {
    aggregate {
      count
    }
  }
  booksAmount: user_aggregate(where: {role: {name: {_eq: "book"}}}) {
    aggregate {
      count
    }
  }
  loggedIn2024BooksAmount: user_aggregate(
    where: {role: {name: {_eq: "book"}}, person: {lastlogin: {_gte: "01.01.24"}}}
  ) {
    aggregate {
      count
    }
  }
  loggedIn2024ReadersAmount: user_aggregate(
    where: {role: {name: {_eq: "reader"}}, person: {lastlogin: {_gt: "01.01.24"}}}
  ) {
    aggregate {
      count
    }
  }
  acceptedEULA2024ReadersAmount: user_aggregate(
    where: {role: {name: {_eq: "reader"}}, person: {lastlogin: {_gt: "01.01.24"}, eula_acceptances: {accepted: {_is_null: false}}}}
  ) {
    aggregate {
      count
    }
  }
  scheduledReadings: reading_aggregate(
    where: {created: {_gte: "01.01.24"}, readingEventStatusName: {_eq: "Scheduled"}}
  ) {
    aggregate {
      count
    }
  }
  incompleteReadings: reading_aggregate(
    where: {created: {_gte: "01.01.24"}, readingEventStatusName: {_eq: "Incomplete"}}
  ) {
    aggregate {
      count
    }
  }
  canceledReadings: reading_aggregate(
    where: {created: {_gte: "01.01.24"}, readingEventStatusName: {_eq: "Canceled"}}
  ) {
    aggregate {
      count
    }
  }
  totalReadings: reading_aggregate(where: {created: {_gte: "01.01.24"}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAdminNewsletterGeneralStatisticsQuery__
 *
 * To run a query within a React component, call `useAdminNewsletterGeneralStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminNewsletterGeneralStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminNewsletterGeneralStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminNewsletterGeneralStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<AdminNewsletterGeneralStatisticsQuery, AdminNewsletterGeneralStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminNewsletterGeneralStatisticsQuery, AdminNewsletterGeneralStatisticsQueryVariables>(AdminNewsletterGeneralStatisticsDocument, options);
      }
export function useAdminNewsletterGeneralStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminNewsletterGeneralStatisticsQuery, AdminNewsletterGeneralStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminNewsletterGeneralStatisticsQuery, AdminNewsletterGeneralStatisticsQueryVariables>(AdminNewsletterGeneralStatisticsDocument, options);
        }
export type AdminNewsletterGeneralStatisticsQueryHookResult = ReturnType<typeof useAdminNewsletterGeneralStatisticsQuery>;
export type AdminNewsletterGeneralStatisticsLazyQueryHookResult = ReturnType<typeof useAdminNewsletterGeneralStatisticsLazyQuery>;
export type AdminNewsletterGeneralStatisticsQueryResult = Apollo.QueryResult<AdminNewsletterGeneralStatisticsQuery, AdminNewsletterGeneralStatisticsQueryVariables>;
export const GetBookParticipantsByReadingIdDocument = gql`
    subscription GetBookParticipantsByReadingId($reading_id: uuid!) {
  view_readings_with_book_info(where: {reading_id: {_eq: $reading_id}}) {
    reading_id
    user_id
    person_name
  }
}
    `;

/**
 * __useGetBookParticipantsByReadingIdSubscription__
 *
 * To run a query within a React component, call `useGetBookParticipantsByReadingIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetBookParticipantsByReadingIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookParticipantsByReadingIdSubscription({
 *   variables: {
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useGetBookParticipantsByReadingIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetBookParticipantsByReadingIdSubscription, GetBookParticipantsByReadingIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetBookParticipantsByReadingIdSubscription, GetBookParticipantsByReadingIdSubscriptionVariables>(GetBookParticipantsByReadingIdDocument, options);
      }
export type GetBookParticipantsByReadingIdSubscriptionHookResult = ReturnType<typeof useGetBookParticipantsByReadingIdSubscription>;
export type GetBookParticipantsByReadingIdSubscriptionResult = Apollo.SubscriptionResult<GetBookParticipantsByReadingIdSubscription>;
export const GetReaderParticipantsByReadingIdDocument = gql`
    subscription GetReaderParticipantsByReadingId($reading_id: uuid!) {
  view_participationstates_with_user_info(where: {reading_id: {_eq: $reading_id}}) {
    reading_id
    user_id
    person_name
    participationstate
    created
  }
}
    `;

/**
 * __useGetReaderParticipantsByReadingIdSubscription__
 *
 * To run a query within a React component, call `useGetReaderParticipantsByReadingIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetReaderParticipantsByReadingIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReaderParticipantsByReadingIdSubscription({
 *   variables: {
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useGetReaderParticipantsByReadingIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetReaderParticipantsByReadingIdSubscription, GetReaderParticipantsByReadingIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetReaderParticipantsByReadingIdSubscription, GetReaderParticipantsByReadingIdSubscriptionVariables>(GetReaderParticipantsByReadingIdDocument, options);
      }
export type GetReaderParticipantsByReadingIdSubscriptionHookResult = ReturnType<typeof useGetReaderParticipantsByReadingIdSubscription>;
export type GetReaderParticipantsByReadingIdSubscriptionResult = Apollo.SubscriptionResult<GetReaderParticipantsByReadingIdSubscription>;
export const GetReadingLiveCallStatesDocument = gql`
    subscription GetReadingLiveCallStates($reading_id: uuid!) {
  live_call_state(where: {reading_id: {_eq: $reading_id}}) {
    user_id
    personName
    is_hand_raised
    role
    last_updated
  }
}
    `;

/**
 * __useGetReadingLiveCallStatesSubscription__
 *
 * To run a query within a React component, call `useGetReadingLiveCallStatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetReadingLiveCallStatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReadingLiveCallStatesSubscription({
 *   variables: {
 *      reading_id: // value for 'reading_id'
 *   },
 * });
 */
export function useGetReadingLiveCallStatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetReadingLiveCallStatesSubscription, GetReadingLiveCallStatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetReadingLiveCallStatesSubscription, GetReadingLiveCallStatesSubscriptionVariables>(GetReadingLiveCallStatesDocument, options);
      }
export type GetReadingLiveCallStatesSubscriptionHookResult = ReturnType<typeof useGetReadingLiveCallStatesSubscription>;
export type GetReadingLiveCallStatesSubscriptionResult = Apollo.SubscriptionResult<GetReadingLiveCallStatesSubscription>;
export const GetCreateAdminDataDocument = gql`
    query GetCreateAdminData {
  country(where: {isdefault: {_eq: true}}) {
    id
  }
  language(where: {isdefault: {_eq: true}}) {
    id
  }
  role(where: {name: {_eq: "hlo-admin"}}) {
    id
  }
}
    `;

/**
 * __useGetCreateAdminDataQuery__
 *
 * To run a query within a React component, call `useGetCreateAdminDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateAdminDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateAdminDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreateAdminDataQuery(baseOptions?: Apollo.QueryHookOptions<GetCreateAdminDataQuery, GetCreateAdminDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreateAdminDataQuery, GetCreateAdminDataQueryVariables>(GetCreateAdminDataDocument, options);
      }
export function useGetCreateAdminDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreateAdminDataQuery, GetCreateAdminDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreateAdminDataQuery, GetCreateAdminDataQueryVariables>(GetCreateAdminDataDocument, options);
        }
export type GetCreateAdminDataQueryHookResult = ReturnType<typeof useGetCreateAdminDataQuery>;
export type GetCreateAdminDataLazyQueryHookResult = ReturnType<typeof useGetCreateAdminDataLazyQuery>;
export type GetCreateAdminDataQueryResult = Apollo.QueryResult<GetCreateAdminDataQuery, GetCreateAdminDataQueryVariables>;
export const GetCreateLibrarianDataDocument = gql`
    query GetCreateLibrarianData {
  country(where: {isdefault: {_eq: true}}) {
    id
  }
  language(where: {isdefault: {_eq: true}}) {
    id
  }
  role(where: {name: {_eq: "librarian"}}) {
    id
  }
}
    `;

/**
 * __useGetCreateLibrarianDataQuery__
 *
 * To run a query within a React component, call `useGetCreateLibrarianDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateLibrarianDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateLibrarianDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreateLibrarianDataQuery(baseOptions?: Apollo.QueryHookOptions<GetCreateLibrarianDataQuery, GetCreateLibrarianDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreateLibrarianDataQuery, GetCreateLibrarianDataQueryVariables>(GetCreateLibrarianDataDocument, options);
      }
export function useGetCreateLibrarianDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreateLibrarianDataQuery, GetCreateLibrarianDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreateLibrarianDataQuery, GetCreateLibrarianDataQueryVariables>(GetCreateLibrarianDataDocument, options);
        }
export type GetCreateLibrarianDataQueryHookResult = ReturnType<typeof useGetCreateLibrarianDataQuery>;
export type GetCreateLibrarianDataLazyQueryHookResult = ReturnType<typeof useGetCreateLibrarianDataLazyQuery>;
export type GetCreateLibrarianDataQueryResult = Apollo.QueryResult<GetCreateLibrarianDataQuery, GetCreateLibrarianDataQueryVariables>;
export const GetCreateReaderDataDocument = gql`
    query GetCreateReaderData {
  country(where: {isdefault: {_eq: true}}) {
    id
  }
  role(where: {name: {_eq: "reader"}}) {
    id
  }
  language(where: {isdefault: {_eq: true}}) {
    id
  }
}
    `;

/**
 * __useGetCreateReaderDataQuery__
 *
 * To run a query within a React component, call `useGetCreateReaderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateReaderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateReaderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreateReaderDataQuery(baseOptions?: Apollo.QueryHookOptions<GetCreateReaderDataQuery, GetCreateReaderDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreateReaderDataQuery, GetCreateReaderDataQueryVariables>(GetCreateReaderDataDocument, options);
      }
export function useGetCreateReaderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreateReaderDataQuery, GetCreateReaderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreateReaderDataQuery, GetCreateReaderDataQueryVariables>(GetCreateReaderDataDocument, options);
        }
export type GetCreateReaderDataQueryHookResult = ReturnType<typeof useGetCreateReaderDataQuery>;
export type GetCreateReaderDataLazyQueryHookResult = ReturnType<typeof useGetCreateReaderDataLazyQuery>;
export type GetCreateReaderDataQueryResult = Apollo.QueryResult<GetCreateReaderDataQuery, GetCreateReaderDataQueryVariables>;
export const ScheduleReadingGetDataDocument = gql`
    query ScheduleReadingGetData($readingId: uuid!) {
  scheduled_email_reminder_by_pk(reading_id: $readingId) {
    event_id
  }
  reading_by_pk(id: $readingId) {
    readingstart
    readingevents {
      readingstate {
        name
      }
    }
  }
}
    `;

/**
 * __useScheduleReadingGetDataQuery__
 *
 * To run a query within a React component, call `useScheduleReadingGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleReadingGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleReadingGetDataQuery({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useScheduleReadingGetDataQuery(baseOptions: Apollo.QueryHookOptions<ScheduleReadingGetDataQuery, ScheduleReadingGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleReadingGetDataQuery, ScheduleReadingGetDataQueryVariables>(ScheduleReadingGetDataDocument, options);
      }
export function useScheduleReadingGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleReadingGetDataQuery, ScheduleReadingGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleReadingGetDataQuery, ScheduleReadingGetDataQueryVariables>(ScheduleReadingGetDataDocument, options);
        }
export type ScheduleReadingGetDataQueryHookResult = ReturnType<typeof useScheduleReadingGetDataQuery>;
export type ScheduleReadingGetDataLazyQueryHookResult = ReturnType<typeof useScheduleReadingGetDataLazyQuery>;
export type ScheduleReadingGetDataQueryResult = Apollo.QueryResult<ScheduleReadingGetDataQuery, ScheduleReadingGetDataQueryVariables>;
export const ScheduleReadingInsertScheduledEmailDocument = gql`
    mutation ScheduleReadingInsertScheduledEmail($eventId: uuid!, $readingId: uuid!, $userId: uuid!) {
  insert_scheduled_email_reminder_one(
    object: {event_id: $eventId, reading_id: $readingId, user_id: $userId}
  ) {
    event_id
  }
}
    `;
export type ScheduleReadingInsertScheduledEmailMutationFn = Apollo.MutationFunction<ScheduleReadingInsertScheduledEmailMutation, ScheduleReadingInsertScheduledEmailMutationVariables>;

/**
 * __useScheduleReadingInsertScheduledEmailMutation__
 *
 * To run a mutation, you first call `useScheduleReadingInsertScheduledEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleReadingInsertScheduledEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleReadingInsertScheduledEmailMutation, { data, loading, error }] = useScheduleReadingInsertScheduledEmailMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      readingId: // value for 'readingId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useScheduleReadingInsertScheduledEmailMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleReadingInsertScheduledEmailMutation, ScheduleReadingInsertScheduledEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleReadingInsertScheduledEmailMutation, ScheduleReadingInsertScheduledEmailMutationVariables>(ScheduleReadingInsertScheduledEmailDocument, options);
      }
export type ScheduleReadingInsertScheduledEmailMutationHookResult = ReturnType<typeof useScheduleReadingInsertScheduledEmailMutation>;
export type ScheduleReadingInsertScheduledEmailMutationResult = Apollo.MutationResult<ScheduleReadingInsertScheduledEmailMutation>;
export type ScheduleReadingInsertScheduledEmailMutationOptions = Apollo.BaseMutationOptions<ScheduleReadingInsertScheduledEmailMutation, ScheduleReadingInsertScheduledEmailMutationVariables>;
export const GetCancelEventDataDocument = gql`
    query GetCancelEventData($readingEventId: uuid!) {
  readingevent_by_pk(id: $readingEventId) {
    canceledBy: user {
      personName
      id
    }
    email_send
    reading {
      organisingbook_id
      readingstart
      topicName
      topic {
        pillar_topics {
          isprimary
          pillar {
            title
          }
        }
      }
      book {
        name
        user {
          id
          person {
            name
            email
            language {
              isocode
            }
            timezone
          }
        }
      }
      readers: participationevents(
        distinct_on: [user_id]
        order_by: [{user_id: asc}, {created: desc}]
      ) {
        participationstate {
          name
        }
        created
        user_id
        user {
          person {
            email
            language {
              isocode
            }
            name
            timezone
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCancelEventDataQuery__
 *
 * To run a query within a React component, call `useGetCancelEventDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCancelEventDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancelEventDataQuery({
 *   variables: {
 *      readingEventId: // value for 'readingEventId'
 *   },
 * });
 */
export function useGetCancelEventDataQuery(baseOptions: Apollo.QueryHookOptions<GetCancelEventDataQuery, GetCancelEventDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCancelEventDataQuery, GetCancelEventDataQueryVariables>(GetCancelEventDataDocument, options);
      }
export function useGetCancelEventDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCancelEventDataQuery, GetCancelEventDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCancelEventDataQuery, GetCancelEventDataQueryVariables>(GetCancelEventDataDocument, options);
        }
export type GetCancelEventDataQueryHookResult = ReturnType<typeof useGetCancelEventDataQuery>;
export type GetCancelEventDataLazyQueryHookResult = ReturnType<typeof useGetCancelEventDataLazyQuery>;
export type GetCancelEventDataQueryResult = Apollo.QueryResult<GetCancelEventDataQuery, GetCancelEventDataQueryVariables>;
export const GetParticipationEventDetailsDocument = gql`
    query GetParticipationEventDetails($participationEventId: uuid!) {
  participationevent_by_pk(id: $participationEventId) {
    id
    comment
    created
    reading {
      id
      topicName
      readingstart
      readingEventStatusName
      organisingbook_id
      book {
        user {
          person {
            name
          }
        }
      }
    }
    user {
      person {
        email
        name
        language {
          isocode
        }
        timezone
      }
    }
  }
}
    `;

/**
 * __useGetParticipationEventDetailsQuery__
 *
 * To run a query within a React component, call `useGetParticipationEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationEventDetailsQuery({
 *   variables: {
 *      participationEventId: // value for 'participationEventId'
 *   },
 * });
 */
export function useGetParticipationEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetParticipationEventDetailsQuery, GetParticipationEventDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipationEventDetailsQuery, GetParticipationEventDetailsQueryVariables>(GetParticipationEventDetailsDocument, options);
      }
export function useGetParticipationEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipationEventDetailsQuery, GetParticipationEventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipationEventDetailsQuery, GetParticipationEventDetailsQueryVariables>(GetParticipationEventDetailsDocument, options);
        }
export type GetParticipationEventDetailsQueryHookResult = ReturnType<typeof useGetParticipationEventDetailsQuery>;
export type GetParticipationEventDetailsLazyQueryHookResult = ReturnType<typeof useGetParticipationEventDetailsLazyQuery>;
export type GetParticipationEventDetailsQueryResult = Apollo.QueryResult<GetParticipationEventDetailsQuery, GetParticipationEventDetailsQueryVariables>;
export const GetEventScheduledDetailsDocument = gql`
    query GetEventScheduledDetails($readingEventId: uuid!) {
  readingevent_by_pk(id: $readingEventId) {
    comment
    user {
      scheduledBy: personName
    }
    reading {
      id
      topicName
      readingstart
      readingEventStatusName
      organisingbook_id
      book {
        name
        user {
          person {
            email
            name
            timezone
          }
        }
      }
      language {
        isocode
      }
    }
  }
}
    `;

/**
 * __useGetEventScheduledDetailsQuery__
 *
 * To run a query within a React component, call `useGetEventScheduledDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventScheduledDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventScheduledDetailsQuery({
 *   variables: {
 *      readingEventId: // value for 'readingEventId'
 *   },
 * });
 */
export function useGetEventScheduledDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEventScheduledDetailsQuery, GetEventScheduledDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventScheduledDetailsQuery, GetEventScheduledDetailsQueryVariables>(GetEventScheduledDetailsDocument, options);
      }
export function useGetEventScheduledDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventScheduledDetailsQuery, GetEventScheduledDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventScheduledDetailsQuery, GetEventScheduledDetailsQueryVariables>(GetEventScheduledDetailsDocument, options);
        }
export type GetEventScheduledDetailsQueryHookResult = ReturnType<typeof useGetEventScheduledDetailsQuery>;
export type GetEventScheduledDetailsLazyQueryHookResult = ReturnType<typeof useGetEventScheduledDetailsLazyQuery>;
export type GetEventScheduledDetailsQueryResult = Apollo.QueryResult<GetEventScheduledDetailsQuery, GetEventScheduledDetailsQueryVariables>;
export const ScheduleReadingDeleteScheduledEmailDocument = gql`
    mutation ScheduleReadingDeleteScheduledEmail($readingId: uuid!) {
  delete_scheduled_email_reminder_by_pk(reading_id: $readingId) {
    event_id
  }
}
    `;
export type ScheduleReadingDeleteScheduledEmailMutationFn = Apollo.MutationFunction<ScheduleReadingDeleteScheduledEmailMutation, ScheduleReadingDeleteScheduledEmailMutationVariables>;

/**
 * __useScheduleReadingDeleteScheduledEmailMutation__
 *
 * To run a mutation, you first call `useScheduleReadingDeleteScheduledEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleReadingDeleteScheduledEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleReadingDeleteScheduledEmailMutation, { data, loading, error }] = useScheduleReadingDeleteScheduledEmailMutation({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useScheduleReadingDeleteScheduledEmailMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleReadingDeleteScheduledEmailMutation, ScheduleReadingDeleteScheduledEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleReadingDeleteScheduledEmailMutation, ScheduleReadingDeleteScheduledEmailMutationVariables>(ScheduleReadingDeleteScheduledEmailDocument, options);
      }
export type ScheduleReadingDeleteScheduledEmailMutationHookResult = ReturnType<typeof useScheduleReadingDeleteScheduledEmailMutation>;
export type ScheduleReadingDeleteScheduledEmailMutationResult = Apollo.MutationResult<ScheduleReadingDeleteScheduledEmailMutation>;
export type ScheduleReadingDeleteScheduledEmailMutationOptions = Apollo.BaseMutationOptions<ScheduleReadingDeleteScheduledEmailMutation, ScheduleReadingDeleteScheduledEmailMutationVariables>;
export const AdminEditBookGetDataDocument = gql`
    query AdminEditBookGetData($userId: uuid!) {
  bookstate(where: {name: {_eq: "Approved"}}) {
    id
  }
  country(where: {isocode: {_eq: "USA"}}) {
    id
  }
  language(order_by: {name: asc}) {
    id
    name
  }
  org {
    id
    parent
    name
    country_id
  }
  role(where: {name: {_eq: "book"}}) {
    id
  }
  topic(order_by: {name: asc}) {
    id
    name
  }
  user_by_pk(id: $userId) {
    org_id
    person {
      email
      name
    }
    role {
      name
    }
    books {
      name
      id
      booklanguages {
        language {
          id
          name
        }
      }
      book_topics {
        topic {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useAdminEditBookGetDataQuery__
 *
 * To run a query within a React component, call `useAdminEditBookGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminEditBookGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminEditBookGetDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminEditBookGetDataQuery(baseOptions: Apollo.QueryHookOptions<AdminEditBookGetDataQuery, AdminEditBookGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminEditBookGetDataQuery, AdminEditBookGetDataQueryVariables>(AdminEditBookGetDataDocument, options);
      }
export function useAdminEditBookGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminEditBookGetDataQuery, AdminEditBookGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminEditBookGetDataQuery, AdminEditBookGetDataQueryVariables>(AdminEditBookGetDataDocument, options);
        }
export type AdminEditBookGetDataQueryHookResult = ReturnType<typeof useAdminEditBookGetDataQuery>;
export type AdminEditBookGetDataLazyQueryHookResult = ReturnType<typeof useAdminEditBookGetDataLazyQuery>;
export type AdminEditBookGetDataQueryResult = Apollo.QueryResult<AdminEditBookGetDataQuery, AdminEditBookGetDataQueryVariables>;
export const AdminEditBookInsertDocument = gql`
    mutation AdminEditBookInsert($bookLanguages: [booklanguage_insert_input!]!, $bookName: String!, $bookTopics: [book_topic_insert_input!]!, $currentBookStateId: uuid!, $userId: uuid!) {
  insert_book_one(
    object: {book_topics: {data: $bookTopics}, booklanguages: {data: $bookLanguages}, currentbookstate_id: $currentBookStateId, name: $bookName, user_id: $userId}
  ) {
    id
  }
}
    `;
export type AdminEditBookInsertMutationFn = Apollo.MutationFunction<AdminEditBookInsertMutation, AdminEditBookInsertMutationVariables>;

/**
 * __useAdminEditBookInsertMutation__
 *
 * To run a mutation, you first call `useAdminEditBookInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditBookInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditBookInsertMutation, { data, loading, error }] = useAdminEditBookInsertMutation({
 *   variables: {
 *      bookLanguages: // value for 'bookLanguages'
 *      bookName: // value for 'bookName'
 *      bookTopics: // value for 'bookTopics'
 *      currentBookStateId: // value for 'currentBookStateId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminEditBookInsertMutation(baseOptions?: Apollo.MutationHookOptions<AdminEditBookInsertMutation, AdminEditBookInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminEditBookInsertMutation, AdminEditBookInsertMutationVariables>(AdminEditBookInsertDocument, options);
      }
export type AdminEditBookInsertMutationHookResult = ReturnType<typeof useAdminEditBookInsertMutation>;
export type AdminEditBookInsertMutationResult = Apollo.MutationResult<AdminEditBookInsertMutation>;
export type AdminEditBookInsertMutationOptions = Apollo.BaseMutationOptions<AdminEditBookInsertMutation, AdminEditBookInsertMutationVariables>;
export const AdminEditBookUpdateDocument = gql`
    mutation AdminEditBookUpdate($bookId: uuid!, $bookName: String!, $bookTopics: [book_topic_insert_input!]!, $languages: [booklanguage_insert_input!]!) {
  update_book_by_pk(_set: {name: $bookName}, pk_columns: {id: $bookId}) {
    id
  }
  delete_booklanguage(where: {book_id: {_eq: $bookId}}) {
    affected_rows
  }
  insert_booklanguage(objects: $languages) {
    affected_rows
  }
  delete_book_topic(where: {book_id: {_eq: $bookId}}) {
    affected_rows
  }
  insert_book_topic(objects: $bookTopics) {
    affected_rows
  }
}
    `;
export type AdminEditBookUpdateMutationFn = Apollo.MutationFunction<AdminEditBookUpdateMutation, AdminEditBookUpdateMutationVariables>;

/**
 * __useAdminEditBookUpdateMutation__
 *
 * To run a mutation, you first call `useAdminEditBookUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditBookUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditBookUpdateMutation, { data, loading, error }] = useAdminEditBookUpdateMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      bookName: // value for 'bookName'
 *      bookTopics: // value for 'bookTopics'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useAdminEditBookUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AdminEditBookUpdateMutation, AdminEditBookUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminEditBookUpdateMutation, AdminEditBookUpdateMutationVariables>(AdminEditBookUpdateDocument, options);
      }
export type AdminEditBookUpdateMutationHookResult = ReturnType<typeof useAdminEditBookUpdateMutation>;
export type AdminEditBookUpdateMutationResult = Apollo.MutationResult<AdminEditBookUpdateMutation>;
export type AdminEditBookUpdateMutationOptions = Apollo.BaseMutationOptions<AdminEditBookUpdateMutation, AdminEditBookUpdateMutationVariables>;
export const AdminCreateBookGetDataDocument = gql`
    query AdminCreateBookGetData {
  bookstate(where: {name: {_eq: "Approved"}}) {
    id
  }
  country(where: {isdefault: {_eq: true}}) {
    id
  }
  default_language: language(where: {isdefault: {_eq: true}}) {
    id
  }
  language(order_by: {name: asc}) {
    id
    name
  }
  org {
    id
    parent
    name
    country_id
  }
  role(where: {name: {_eq: "book"}}) {
    id
  }
  topic(order_by: {name: asc}) {
    id
    name
  }
}
    `;

/**
 * __useAdminCreateBookGetDataQuery__
 *
 * To run a query within a React component, call `useAdminCreateBookGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateBookGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCreateBookGetDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminCreateBookGetDataQuery(baseOptions?: Apollo.QueryHookOptions<AdminCreateBookGetDataQuery, AdminCreateBookGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCreateBookGetDataQuery, AdminCreateBookGetDataQueryVariables>(AdminCreateBookGetDataDocument, options);
      }
export function useAdminCreateBookGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCreateBookGetDataQuery, AdminCreateBookGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCreateBookGetDataQuery, AdminCreateBookGetDataQueryVariables>(AdminCreateBookGetDataDocument, options);
        }
export type AdminCreateBookGetDataQueryHookResult = ReturnType<typeof useAdminCreateBookGetDataQuery>;
export type AdminCreateBookGetDataLazyQueryHookResult = ReturnType<typeof useAdminCreateBookGetDataLazyQuery>;
export type AdminCreateBookGetDataQueryResult = Apollo.QueryResult<AdminCreateBookGetDataQuery, AdminCreateBookGetDataQueryVariables>;
export const AdminCreateBookInsertDocument = gql`
    mutation AdminCreateBookInsert($bookLanguages: [booklanguage_insert_input!]!, $bookTopics: [book_topic_insert_input!]!, $currentBookStateId: uuid!, $countryId: uuid!, $email: String!, $personName: String, $bookName: String!, $orgId: uuid!, $roleId: uuid!, $defaultLanguageId: uuid!) {
  insert_user_one(
    object: {books: {data: {name: $bookName, booklanguages: {data: $bookLanguages}, book_topics: {data: $bookTopics}, currentbookstate_id: $currentBookStateId}}, org_id: $orgId, person: {data: {country_id: $countryId, default_language_id: $defaultLanguageId, email: $email, name: $personName}}, role_id: $roleId}
  ) {
    id
    person {
      name
    }
  }
}
    `;
export type AdminCreateBookInsertMutationFn = Apollo.MutationFunction<AdminCreateBookInsertMutation, AdminCreateBookInsertMutationVariables>;

/**
 * __useAdminCreateBookInsertMutation__
 *
 * To run a mutation, you first call `useAdminCreateBookInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateBookInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateBookInsertMutation, { data, loading, error }] = useAdminCreateBookInsertMutation({
 *   variables: {
 *      bookLanguages: // value for 'bookLanguages'
 *      bookTopics: // value for 'bookTopics'
 *      currentBookStateId: // value for 'currentBookStateId'
 *      countryId: // value for 'countryId'
 *      email: // value for 'email'
 *      personName: // value for 'personName'
 *      bookName: // value for 'bookName'
 *      orgId: // value for 'orgId'
 *      roleId: // value for 'roleId'
 *      defaultLanguageId: // value for 'defaultLanguageId'
 *   },
 * });
 */
export function useAdminCreateBookInsertMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateBookInsertMutation, AdminCreateBookInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateBookInsertMutation, AdminCreateBookInsertMutationVariables>(AdminCreateBookInsertDocument, options);
      }
export type AdminCreateBookInsertMutationHookResult = ReturnType<typeof useAdminCreateBookInsertMutation>;
export type AdminCreateBookInsertMutationResult = Apollo.MutationResult<AdminCreateBookInsertMutation>;
export type AdminCreateBookInsertMutationOptions = Apollo.BaseMutationOptions<AdminCreateBookInsertMutation, AdminCreateBookInsertMutationVariables>;
export const AdminCreateOrgGetOptionsDocument = gql`
    query AdminCreateOrgGetOptions {
  country {
    id
    name
  }
}
    `;

/**
 * __useAdminCreateOrgGetOptionsQuery__
 *
 * To run a query within a React component, call `useAdminCreateOrgGetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOrgGetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCreateOrgGetOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminCreateOrgGetOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminCreateOrgGetOptionsQuery, AdminCreateOrgGetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCreateOrgGetOptionsQuery, AdminCreateOrgGetOptionsQueryVariables>(AdminCreateOrgGetOptionsDocument, options);
      }
export function useAdminCreateOrgGetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCreateOrgGetOptionsQuery, AdminCreateOrgGetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCreateOrgGetOptionsQuery, AdminCreateOrgGetOptionsQueryVariables>(AdminCreateOrgGetOptionsDocument, options);
        }
export type AdminCreateOrgGetOptionsQueryHookResult = ReturnType<typeof useAdminCreateOrgGetOptionsQuery>;
export type AdminCreateOrgGetOptionsLazyQueryHookResult = ReturnType<typeof useAdminCreateOrgGetOptionsLazyQuery>;
export type AdminCreateOrgGetOptionsQueryResult = Apollo.QueryResult<AdminCreateOrgGetOptionsQuery, AdminCreateOrgGetOptionsQueryVariables>;
export const AdminReadersReadingPlanDurationsDocument = gql`
    query AdminReadersReadingPlanDurations($languageId: uuid!) {
  readingplan_duration(order_by: {months: asc}) {
    id
    translation(where: {language_id: {_eq: $languageId}}) {
      name
    }
  }
}
    `;

/**
 * __useAdminReadersReadingPlanDurationsQuery__
 *
 * To run a query within a React component, call `useAdminReadersReadingPlanDurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReadersReadingPlanDurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReadersReadingPlanDurationsQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useAdminReadersReadingPlanDurationsQuery(baseOptions: Apollo.QueryHookOptions<AdminReadersReadingPlanDurationsQuery, AdminReadersReadingPlanDurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminReadersReadingPlanDurationsQuery, AdminReadersReadingPlanDurationsQueryVariables>(AdminReadersReadingPlanDurationsDocument, options);
      }
export function useAdminReadersReadingPlanDurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminReadersReadingPlanDurationsQuery, AdminReadersReadingPlanDurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminReadersReadingPlanDurationsQuery, AdminReadersReadingPlanDurationsQueryVariables>(AdminReadersReadingPlanDurationsDocument, options);
        }
export type AdminReadersReadingPlanDurationsQueryHookResult = ReturnType<typeof useAdminReadersReadingPlanDurationsQuery>;
export type AdminReadersReadingPlanDurationsLazyQueryHookResult = ReturnType<typeof useAdminReadersReadingPlanDurationsLazyQuery>;
export type AdminReadersReadingPlanDurationsQueryResult = Apollo.QueryResult<AdminReadersReadingPlanDurationsQuery, AdminReadersReadingPlanDurationsQueryVariables>;
export const AdminEditReaderGetDataDocument = gql`
    query AdminEditReaderGetData($languageId: uuid!, $userId: uuid!) {
  org(where: {users: {id: {_eq: $userId}}}) {
    id
    parent
    name
    country_id
  }
  readingplan_duration(order_by: {months: asc}) {
    id
    translation(where: {language_id: {_eq: $languageId}}) {
      name
    }
  }
  readingplan(where: {user_id: {_eq: $userId}}) {
    id
    activated
    creator_id
    initialreadings
    pillarconstraints {
      pillarofprejudice_id
      pillar {
        title
      }
    }
    valid_to
    readingplan_duration_id
    user_id
  }
  pillars: pillar(order_by: {title: asc}) {
    id
    title
    description
    slug
  }
}
    `;

/**
 * __useAdminEditReaderGetDataQuery__
 *
 * To run a query within a React component, call `useAdminEditReaderGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminEditReaderGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminEditReaderGetDataQuery({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminEditReaderGetDataQuery(baseOptions: Apollo.QueryHookOptions<AdminEditReaderGetDataQuery, AdminEditReaderGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminEditReaderGetDataQuery, AdminEditReaderGetDataQueryVariables>(AdminEditReaderGetDataDocument, options);
      }
export function useAdminEditReaderGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminEditReaderGetDataQuery, AdminEditReaderGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminEditReaderGetDataQuery, AdminEditReaderGetDataQueryVariables>(AdminEditReaderGetDataDocument, options);
        }
export type AdminEditReaderGetDataQueryHookResult = ReturnType<typeof useAdminEditReaderGetDataQuery>;
export type AdminEditReaderGetDataLazyQueryHookResult = ReturnType<typeof useAdminEditReaderGetDataLazyQuery>;
export type AdminEditReaderGetDataQueryResult = Apollo.QueryResult<AdminEditReaderGetDataQuery, AdminEditReaderGetDataQueryVariables>;
export const AdminEditReaderInsertReadingPlanDocument = gql`
    mutation AdminEditReaderInsertReadingPlan($creatorUserId: uuid!, $initialReadings: Int!, $pillarConstraints: [pillarconstraint_insert_input!]!, $readingPlanDurationId: uuid!, $userId: uuid!) {
  insert_readingplan_one(
    object: {creator_id: $creatorUserId, initialreadings: $initialReadings, pillarconstraints: {data: $pillarConstraints}, readingplan_duration_id: $readingPlanDurationId, user_id: $userId}
  ) {
    id
  }
}
    `;
export type AdminEditReaderInsertReadingPlanMutationFn = Apollo.MutationFunction<AdminEditReaderInsertReadingPlanMutation, AdminEditReaderInsertReadingPlanMutationVariables>;

/**
 * __useAdminEditReaderInsertReadingPlanMutation__
 *
 * To run a mutation, you first call `useAdminEditReaderInsertReadingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditReaderInsertReadingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditReaderInsertReadingPlanMutation, { data, loading, error }] = useAdminEditReaderInsertReadingPlanMutation({
 *   variables: {
 *      creatorUserId: // value for 'creatorUserId'
 *      initialReadings: // value for 'initialReadings'
 *      pillarConstraints: // value for 'pillarConstraints'
 *      readingPlanDurationId: // value for 'readingPlanDurationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminEditReaderInsertReadingPlanMutation(baseOptions?: Apollo.MutationHookOptions<AdminEditReaderInsertReadingPlanMutation, AdminEditReaderInsertReadingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminEditReaderInsertReadingPlanMutation, AdminEditReaderInsertReadingPlanMutationVariables>(AdminEditReaderInsertReadingPlanDocument, options);
      }
export type AdminEditReaderInsertReadingPlanMutationHookResult = ReturnType<typeof useAdminEditReaderInsertReadingPlanMutation>;
export type AdminEditReaderInsertReadingPlanMutationResult = Apollo.MutationResult<AdminEditReaderInsertReadingPlanMutation>;
export type AdminEditReaderInsertReadingPlanMutationOptions = Apollo.BaseMutationOptions<AdminEditReaderInsertReadingPlanMutation, AdminEditReaderInsertReadingPlanMutationVariables>;
export const AdminEditReaderUpdateReadingPlanDocument = gql`
    mutation AdminEditReaderUpdateReadingPlan($activated: timestamptz, $initialReadings: Int!, $pillarConstraints: [pillarconstraint_insert_input!]!, $readingPlanDurationId: uuid!, $readingPlanId: uuid!) {
  update_readingplan_by_pk(
    pk_columns: {id: $readingPlanId}
    _set: {activated: $activated, initialreadings: $initialReadings, readingplan_duration_id: $readingPlanDurationId}
  ) {
    id
  }
  delete_pillarconstraint(where: {readingplan_id: {_eq: $readingPlanId}}) {
    affected_rows
  }
  insert_pillarconstraint(objects: $pillarConstraints) {
    affected_rows
  }
}
    `;
export type AdminEditReaderUpdateReadingPlanMutationFn = Apollo.MutationFunction<AdminEditReaderUpdateReadingPlanMutation, AdminEditReaderUpdateReadingPlanMutationVariables>;

/**
 * __useAdminEditReaderUpdateReadingPlanMutation__
 *
 * To run a mutation, you first call `useAdminEditReaderUpdateReadingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEditReaderUpdateReadingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEditReaderUpdateReadingPlanMutation, { data, loading, error }] = useAdminEditReaderUpdateReadingPlanMutation({
 *   variables: {
 *      activated: // value for 'activated'
 *      initialReadings: // value for 'initialReadings'
 *      pillarConstraints: // value for 'pillarConstraints'
 *      readingPlanDurationId: // value for 'readingPlanDurationId'
 *      readingPlanId: // value for 'readingPlanId'
 *   },
 * });
 */
export function useAdminEditReaderUpdateReadingPlanMutation(baseOptions?: Apollo.MutationHookOptions<AdminEditReaderUpdateReadingPlanMutation, AdminEditReaderUpdateReadingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminEditReaderUpdateReadingPlanMutation, AdminEditReaderUpdateReadingPlanMutationVariables>(AdminEditReaderUpdateReadingPlanDocument, options);
      }
export type AdminEditReaderUpdateReadingPlanMutationHookResult = ReturnType<typeof useAdminEditReaderUpdateReadingPlanMutation>;
export type AdminEditReaderUpdateReadingPlanMutationResult = Apollo.MutationResult<AdminEditReaderUpdateReadingPlanMutation>;
export type AdminEditReaderUpdateReadingPlanMutationOptions = Apollo.BaseMutationOptions<AdminEditReaderUpdateReadingPlanMutation, AdminEditReaderUpdateReadingPlanMutationVariables>;
export const AdminReadingGetDataDocument = gql`
    query AdminReadingGetData($readingId: uuid!) {
  reading_by_pk(id: $readingId) {
    book {
      user_id
      name
      user {
        person {
          name
          pronouns
        }
      }
    }
    readingEventStatusName
    readingstart
    topicName
    participationevents {
      participationstate {
        name
      }
      created
      user_id
      user {
        person {
          name
          pronouns
        }
      }
    }
    review_book {
      review_book_option_id
      text
    }
    cancel_reviews {
      text
      user_id
    }
    reviews {
      personName
      rating
      text
      user_id
    }
  }
  review_book_option(order_by: {order: asc}) {
    id
    translationKey
  }
}
    `;

/**
 * __useAdminReadingGetDataQuery__
 *
 * To run a query within a React component, call `useAdminReadingGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReadingGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReadingGetDataQuery({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useAdminReadingGetDataQuery(baseOptions: Apollo.QueryHookOptions<AdminReadingGetDataQuery, AdminReadingGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminReadingGetDataQuery, AdminReadingGetDataQueryVariables>(AdminReadingGetDataDocument, options);
      }
export function useAdminReadingGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminReadingGetDataQuery, AdminReadingGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminReadingGetDataQuery, AdminReadingGetDataQueryVariables>(AdminReadingGetDataDocument, options);
        }
export type AdminReadingGetDataQueryHookResult = ReturnType<typeof useAdminReadingGetDataQuery>;
export type AdminReadingGetDataLazyQueryHookResult = ReturnType<typeof useAdminReadingGetDataLazyQuery>;
export type AdminReadingGetDataQueryResult = Apollo.QueryResult<AdminReadingGetDataQuery, AdminReadingGetDataQueryVariables>;
export const AdminReadingIndexGetReadingsDocument = gql`
    query AdminReadingIndexGetReadings($limit: Int!, $offset: Int!, $readingWhere: reading_bool_exp!, $sortDirection: order_by!) {
  reading: reading(
    limit: $limit
    offset: $offset
    order_by: {readingstart: $sortDirection}
    where: $readingWhere
  ) {
    id
    maxreaders
    readingEventStatusName
    readingstart
    seats_available
    topicName
    topic {
      id
      pillar_topics {
        pillar {
          id
          title
          description
        }
      }
    }
    book {
      user {
        person {
          name
        }
      }
    }
  }
  reading_aggregate(where: $readingWhere) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAdminReadingIndexGetReadingsQuery__
 *
 * To run a query within a React component, call `useAdminReadingIndexGetReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReadingIndexGetReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReadingIndexGetReadingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      readingWhere: // value for 'readingWhere'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useAdminReadingIndexGetReadingsQuery(baseOptions: Apollo.QueryHookOptions<AdminReadingIndexGetReadingsQuery, AdminReadingIndexGetReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminReadingIndexGetReadingsQuery, AdminReadingIndexGetReadingsQueryVariables>(AdminReadingIndexGetReadingsDocument, options);
      }
export function useAdminReadingIndexGetReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminReadingIndexGetReadingsQuery, AdminReadingIndexGetReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminReadingIndexGetReadingsQuery, AdminReadingIndexGetReadingsQueryVariables>(AdminReadingIndexGetReadingsDocument, options);
        }
export type AdminReadingIndexGetReadingsQueryHookResult = ReturnType<typeof useAdminReadingIndexGetReadingsQuery>;
export type AdminReadingIndexGetReadingsLazyQueryHookResult = ReturnType<typeof useAdminReadingIndexGetReadingsLazyQuery>;
export type AdminReadingIndexGetReadingsQueryResult = Apollo.QueryResult<AdminReadingIndexGetReadingsQuery, AdminReadingIndexGetReadingsQueryVariables>;
export const GetAllPillarsDocument = gql`
    query GetAllPillars {
  pillar {
    id
    title
  }
}
    `;

/**
 * __useGetAllPillarsQuery__
 *
 * To run a query within a React component, call `useGetAllPillarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPillarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPillarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPillarsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPillarsQuery, GetAllPillarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPillarsQuery, GetAllPillarsQueryVariables>(GetAllPillarsDocument, options);
      }
export function useGetAllPillarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPillarsQuery, GetAllPillarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPillarsQuery, GetAllPillarsQueryVariables>(GetAllPillarsDocument, options);
        }
export type GetAllPillarsQueryHookResult = ReturnType<typeof useGetAllPillarsQuery>;
export type GetAllPillarsLazyQueryHookResult = ReturnType<typeof useGetAllPillarsLazyQuery>;
export type GetAllPillarsQueryResult = Apollo.QueryResult<GetAllPillarsQuery, GetAllPillarsQueryVariables>;
export const AdminUserGetByIdDocument = gql`
    query AdminUserGetById($userId: uuid!) {
  book_aggregate {
    aggregate {
      count
    }
  }
  eula(order_by: {version: desc}, limit: 1) {
    id
  }
  role {
    id
    name
  }
  org {
    id
    name
  }
  user(where: {id: {_eq: $userId}}) {
    org_id
    person {
      eula_acceptances(order_by: {accepted: desc}, limit: 1) {
        eula_id
      }
      email
      emailverified
      created
      name
      timezone
      pronouns
    }
    readingplan {
      id
      creator {
        id
        person {
          name
        }
      }
      remainingreadings
      readingplan_duration_id
      updated
      updatedBy {
        person {
          name
        }
      }
      initialreadings
      pillarconstraints {
        pillarofprejudice_id
        pillar {
          title
          pillar_topics {
            topic {
              book_topics(distinct_on: [book_id]) {
                book_id
              }
            }
          }
        }
      }
      valid_to
      user_id
      created
    }
    role {
      name
    }
    books {
      id
      name
      book_topics {
        topic {
          name
          id
          description
          created
          short_description
          pillar_topics {
            isprimary
            pillar {
              description
              id
              title
            }
          }
        }
        topicname
      }
    }
  }
}
    `;

/**
 * __useAdminUserGetByIdQuery__
 *
 * To run a query within a React component, call `useAdminUserGetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserGetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserGetByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminUserGetByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminUserGetByIdQuery, AdminUserGetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUserGetByIdQuery, AdminUserGetByIdQueryVariables>(AdminUserGetByIdDocument, options);
      }
export function useAdminUserGetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserGetByIdQuery, AdminUserGetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUserGetByIdQuery, AdminUserGetByIdQueryVariables>(AdminUserGetByIdDocument, options);
        }
export type AdminUserGetByIdQueryHookResult = ReturnType<typeof useAdminUserGetByIdQuery>;
export type AdminUserGetByIdLazyQueryHookResult = ReturnType<typeof useAdminUserGetByIdLazyQuery>;
export type AdminUserGetByIdQueryResult = Apollo.QueryResult<AdminUserGetByIdQuery, AdminUserGetByIdQueryVariables>;
export const AdminUserGetUpdateUserRoleDocument = gql`
    mutation AdminUserGetUpdateUserRole($roleId: uuid!, $userId: uuid!) {
  update_user_by_pk(pk_columns: {id: $userId}, _set: {role_id: $roleId}) {
    id
  }
}
    `;
export type AdminUserGetUpdateUserRoleMutationFn = Apollo.MutationFunction<AdminUserGetUpdateUserRoleMutation, AdminUserGetUpdateUserRoleMutationVariables>;

/**
 * __useAdminUserGetUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useAdminUserGetUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUserGetUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUserGetUpdateUserRoleMutation, { data, loading, error }] = useAdminUserGetUpdateUserRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminUserGetUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<AdminUserGetUpdateUserRoleMutation, AdminUserGetUpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUserGetUpdateUserRoleMutation, AdminUserGetUpdateUserRoleMutationVariables>(AdminUserGetUpdateUserRoleDocument, options);
      }
export type AdminUserGetUpdateUserRoleMutationHookResult = ReturnType<typeof useAdminUserGetUpdateUserRoleMutation>;
export type AdminUserGetUpdateUserRoleMutationResult = Apollo.MutationResult<AdminUserGetUpdateUserRoleMutation>;
export type AdminUserGetUpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<AdminUserGetUpdateUserRoleMutation, AdminUserGetUpdateUserRoleMutationVariables>;
export const AdminUsersGetUsersDocument = gql`
    query AdminUsersGetUsers($limit: Int!, $offset: Int!, $personWhere: person_bool_exp! = {}, $roleName: String!) {
  user: view_users_with_next_reading(
    limit: $limit
    offset: $offset
    order_by: {user: {created: desc}}
    where: {user: {role: {name: {_eq: $roleName}}, person: $personWhere}}
  ) {
    user_id
    next_reading {
      id
      topicName
      readingstart
    }
    user {
      id
      created
      books {
        name
      }
      person {
        id
        name
        email
        pronouns
      }
      org {
        name
      }
      role {
        name
      }
      readingplan {
        id
        remainingreadings
        valid_to
      }
    }
  }
  user_aggregate: view_users_with_next_reading_aggregate(
    where: {user: {role: {name: {_eq: $roleName}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useAdminUsersGetUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      personWhere: // value for 'personWhere'
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useAdminUsersGetUsersQuery(baseOptions: Apollo.QueryHookOptions<AdminUsersGetUsersQuery, AdminUsersGetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUsersGetUsersQuery, AdminUsersGetUsersQueryVariables>(AdminUsersGetUsersDocument, options);
      }
export function useAdminUsersGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUsersGetUsersQuery, AdminUsersGetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUsersGetUsersQuery, AdminUsersGetUsersQueryVariables>(AdminUsersGetUsersDocument, options);
        }
export type AdminUsersGetUsersQueryHookResult = ReturnType<typeof useAdminUsersGetUsersQuery>;
export type AdminUsersGetUsersLazyQueryHookResult = ReturnType<typeof useAdminUsersGetUsersLazyQuery>;
export type AdminUsersGetUsersQueryResult = Apollo.QueryResult<AdminUsersGetUsersQuery, AdminUsersGetUsersQueryVariables>;
export const BookCreateReadingGetDataDocument = gql`
    query BookCreateReadingGetData($userId: uuid!, $startDate: timestamptz!, $topicId: uuid!) {
  book(where: {user_id: {_eq: $userId}}) {
    id
    book_topics {
      topic_id
    }
  }
  book_get_number_of_readings(
    args: {start_date: $startDate, book_user_id: $userId}
  ) {
    book_id
    readings_past_day
    readings_past_month
    readings_past_week
  }
  reading(
    where: {book: {user_id: {_eq: $userId}}, readingstart: {_eq: $startDate}}
  ) {
    id
  }
  number_of_readings: reading_aggregate(
    where: {readingEventStatusName: {_eq: "Scheduled"}, readingstart: {_eq: $startDate}}
  ) {
    aggregate {
      count
    }
  }
  number_of_topics: reading_aggregate(
    where: {readingEventStatusName: {_eq: "Scheduled"}, readingstart: {_eq: $startDate}, topic_id: {_eq: $topicId}}
  ) {
    aggregate {
      count
    }
  }
  max_number_of_simultaneous_readings: reading_setting(
    where: {key: {_eq: "number_of_simultaneous_readings"}}
  ) {
    value
  }
  max_number_of_simultaneous_topics: reading_setting(
    where: {key: {_eq: "number_of_simultaneous_topics"}}
  ) {
    value
  }
}
    `;

/**
 * __useBookCreateReadingGetDataQuery__
 *
 * To run a query within a React component, call `useBookCreateReadingGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookCreateReadingGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookCreateReadingGetDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startDate: // value for 'startDate'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useBookCreateReadingGetDataQuery(baseOptions: Apollo.QueryHookOptions<BookCreateReadingGetDataQuery, BookCreateReadingGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookCreateReadingGetDataQuery, BookCreateReadingGetDataQueryVariables>(BookCreateReadingGetDataDocument, options);
      }
export function useBookCreateReadingGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookCreateReadingGetDataQuery, BookCreateReadingGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookCreateReadingGetDataQuery, BookCreateReadingGetDataQueryVariables>(BookCreateReadingGetDataDocument, options);
        }
export type BookCreateReadingGetDataQueryHookResult = ReturnType<typeof useBookCreateReadingGetDataQuery>;
export type BookCreateReadingGetDataLazyQueryHookResult = ReturnType<typeof useBookCreateReadingGetDataLazyQuery>;
export type BookCreateReadingGetDataQueryResult = Apollo.QueryResult<BookCreateReadingGetDataQuery, BookCreateReadingGetDataQueryVariables>;
export const BookCreateReadingInsertDocument = gql`
    mutation BookCreateReadingInsert($bookId: uuid!, $durationId: uuid!, $languageId: uuid!, $maxReaders: Int = 5, $minReaders: Int = 2, $readingStart: timestamptz!, $readingStateId: uuid!, $topicId: uuid!, $userId: uuid!) {
  insert_reading_one(
    object: {minreaders: $minReaders, maxreaders: $maxReaders, duration_id: $durationId, inviteonly: false, language_id: $languageId, organisingbook_id: $bookId, readingevents: {data: {readingstatus_id: $readingStateId, user_id: $userId}}, readingstart: $readingStart, topic_id: $topicId}
  ) {
    id
  }
}
    `;
export type BookCreateReadingInsertMutationFn = Apollo.MutationFunction<BookCreateReadingInsertMutation, BookCreateReadingInsertMutationVariables>;

/**
 * __useBookCreateReadingInsertMutation__
 *
 * To run a mutation, you first call `useBookCreateReadingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookCreateReadingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookCreateReadingInsertMutation, { data, loading, error }] = useBookCreateReadingInsertMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      durationId: // value for 'durationId'
 *      languageId: // value for 'languageId'
 *      maxReaders: // value for 'maxReaders'
 *      minReaders: // value for 'minReaders'
 *      readingStart: // value for 'readingStart'
 *      readingStateId: // value for 'readingStateId'
 *      topicId: // value for 'topicId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBookCreateReadingInsertMutation(baseOptions?: Apollo.MutationHookOptions<BookCreateReadingInsertMutation, BookCreateReadingInsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookCreateReadingInsertMutation, BookCreateReadingInsertMutationVariables>(BookCreateReadingInsertDocument, options);
      }
export type BookCreateReadingInsertMutationHookResult = ReturnType<typeof useBookCreateReadingInsertMutation>;
export type BookCreateReadingInsertMutationResult = Apollo.MutationResult<BookCreateReadingInsertMutation>;
export type BookCreateReadingInsertMutationOptions = Apollo.BaseMutationOptions<BookCreateReadingInsertMutation, BookCreateReadingInsertMutationVariables>;
export const GetUnverifiedPersonsDocument = gql`
    query GetUnverifiedPersons {
  person(
    order_by: {created: asc}
    where: {created: {_gte: "2022-1-1 00:00"}, emailverified: {_eq: false}, users: {role: {name: {_neq: "guest"}}}}
  ) {
    created
    email
    emailverified
    users {
      role {
        name
      }
    }
  }
}
    `;

/**
 * __useGetUnverifiedPersonsQuery__
 *
 * To run a query within a React component, call `useGetUnverifiedPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnverifiedPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnverifiedPersonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnverifiedPersonsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnverifiedPersonsQuery, GetUnverifiedPersonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnverifiedPersonsQuery, GetUnverifiedPersonsQueryVariables>(GetUnverifiedPersonsDocument, options);
      }
export function useGetUnverifiedPersonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnverifiedPersonsQuery, GetUnverifiedPersonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnverifiedPersonsQuery, GetUnverifiedPersonsQueryVariables>(GetUnverifiedPersonsDocument, options);
        }
export type GetUnverifiedPersonsQueryHookResult = ReturnType<typeof useGetUnverifiedPersonsQuery>;
export type GetUnverifiedPersonsLazyQueryHookResult = ReturnType<typeof useGetUnverifiedPersonsLazyQuery>;
export type GetUnverifiedPersonsQueryResult = Apollo.QueryResult<GetUnverifiedPersonsQuery, GetUnverifiedPersonsQueryVariables>;
export const AdminGetYearlyReportDocument = gql`
    query AdminGetYearlyReport {
  person {
    created
    emailverified
    email
    lastlogin
    users {
      org {
        name
      }
      role {
        name
      }
    }
    timezone
  }
}
    `;

/**
 * __useAdminGetYearlyReportQuery__
 *
 * To run a query within a React component, call `useAdminGetYearlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetYearlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetYearlyReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetYearlyReportQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetYearlyReportQuery, AdminGetYearlyReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetYearlyReportQuery, AdminGetYearlyReportQueryVariables>(AdminGetYearlyReportDocument, options);
      }
export function useAdminGetYearlyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetYearlyReportQuery, AdminGetYearlyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetYearlyReportQuery, AdminGetYearlyReportQueryVariables>(AdminGetYearlyReportDocument, options);
        }
export type AdminGetYearlyReportQueryHookResult = ReturnType<typeof useAdminGetYearlyReportQuery>;
export type AdminGetYearlyReportLazyQueryHookResult = ReturnType<typeof useAdminGetYearlyReportLazyQuery>;
export type AdminGetYearlyReportQueryResult = Apollo.QueryResult<AdminGetYearlyReportQuery, AdminGetYearlyReportQueryVariables>;
export const ReadingReminderGetDataDocument = gql`
    query ReadingReminderGetData($readingId: uuid!) {
  reading_by_pk(id: $readingId) {
    book {
      user {
        id
        person {
          email
          language {
            isocode
          }
          timezone
        }
      }
    }
    maxreaders
    readingEventStatusName
    readingstart
    seats_available
    readers: participationevents(
      distinct_on: [user_id]
      order_by: [{user_id: asc}, {created: desc}]
    ) {
      participationstate {
        name
      }
      created
      user_id
      user {
        id
        person {
          email
          language {
            isocode
          }
          name
          timezone
        }
      }
    }
  }
  readingstate(where: {name: {_eq: "Incomplete"}}) {
    id
  }
}
    `;

/**
 * __useReadingReminderGetDataQuery__
 *
 * To run a query within a React component, call `useReadingReminderGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadingReminderGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadingReminderGetDataQuery({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useReadingReminderGetDataQuery(baseOptions: Apollo.QueryHookOptions<ReadingReminderGetDataQuery, ReadingReminderGetDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadingReminderGetDataQuery, ReadingReminderGetDataQueryVariables>(ReadingReminderGetDataDocument, options);
      }
export function useReadingReminderGetDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadingReminderGetDataQuery, ReadingReminderGetDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadingReminderGetDataQuery, ReadingReminderGetDataQueryVariables>(ReadingReminderGetDataDocument, options);
        }
export type ReadingReminderGetDataQueryHookResult = ReturnType<typeof useReadingReminderGetDataQuery>;
export type ReadingReminderGetDataLazyQueryHookResult = ReturnType<typeof useReadingReminderGetDataLazyQuery>;
export type ReadingReminderGetDataQueryResult = Apollo.QueryResult<ReadingReminderGetDataQuery, ReadingReminderGetDataQueryVariables>;
export const ReadingReminderCancelReadingDocument = gql`
    mutation ReadingReminderCancelReading($readingId: uuid!, $readingstate_id: uuid!, $userId: uuid!) {
  insert_readingevent_one(
    object: {reading_id: $readingId, readingstatus_id: $readingstate_id, user_id: $userId}
  ) {
    id
  }
}
    `;
export type ReadingReminderCancelReadingMutationFn = Apollo.MutationFunction<ReadingReminderCancelReadingMutation, ReadingReminderCancelReadingMutationVariables>;

/**
 * __useReadingReminderCancelReadingMutation__
 *
 * To run a mutation, you first call `useReadingReminderCancelReadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadingReminderCancelReadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readingReminderCancelReadingMutation, { data, loading, error }] = useReadingReminderCancelReadingMutation({
 *   variables: {
 *      readingId: // value for 'readingId'
 *      readingstate_id: // value for 'readingstate_id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReadingReminderCancelReadingMutation(baseOptions?: Apollo.MutationHookOptions<ReadingReminderCancelReadingMutation, ReadingReminderCancelReadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadingReminderCancelReadingMutation, ReadingReminderCancelReadingMutationVariables>(ReadingReminderCancelReadingDocument, options);
      }
export type ReadingReminderCancelReadingMutationHookResult = ReturnType<typeof useReadingReminderCancelReadingMutation>;
export type ReadingReminderCancelReadingMutationResult = Apollo.MutationResult<ReadingReminderCancelReadingMutation>;
export type ReadingReminderCancelReadingMutationOptions = Apollo.BaseMutationOptions<ReadingReminderCancelReadingMutation, ReadingReminderCancelReadingMutationVariables>;
export const GetParticipationEventStateNameDocument = gql`
    query GetParticipationEventStateName($participationStateId: uuid!) {
  participationstate_by_pk(id: $participationStateId) {
    name
  }
}
    `;

/**
 * __useGetParticipationEventStateNameQuery__
 *
 * To run a query within a React component, call `useGetParticipationEventStateNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationEventStateNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationEventStateNameQuery({
 *   variables: {
 *      participationStateId: // value for 'participationStateId'
 *   },
 * });
 */
export function useGetParticipationEventStateNameQuery(baseOptions: Apollo.QueryHookOptions<GetParticipationEventStateNameQuery, GetParticipationEventStateNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipationEventStateNameQuery, GetParticipationEventStateNameQueryVariables>(GetParticipationEventStateNameDocument, options);
      }
export function useGetParticipationEventStateNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipationEventStateNameQuery, GetParticipationEventStateNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipationEventStateNameQuery, GetParticipationEventStateNameQueryVariables>(GetParticipationEventStateNameDocument, options);
        }
export type GetParticipationEventStateNameQueryHookResult = ReturnType<typeof useGetParticipationEventStateNameQuery>;
export type GetParticipationEventStateNameLazyQueryHookResult = ReturnType<typeof useGetParticipationEventStateNameLazyQuery>;
export type GetParticipationEventStateNameQueryResult = Apollo.QueryResult<GetParticipationEventStateNameQuery, GetParticipationEventStateNameQueryVariables>;
export const GetEventStateNameDocument = gql`
    query GetEventStateName($eventStateId: uuid!) {
  readingstate_by_pk(id: $eventStateId) {
    name
  }
}
    `;

/**
 * __useGetEventStateNameQuery__
 *
 * To run a query within a React component, call `useGetEventStateNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventStateNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventStateNameQuery({
 *   variables: {
 *      eventStateId: // value for 'eventStateId'
 *   },
 * });
 */
export function useGetEventStateNameQuery(baseOptions: Apollo.QueryHookOptions<GetEventStateNameQuery, GetEventStateNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventStateNameQuery, GetEventStateNameQueryVariables>(GetEventStateNameDocument, options);
      }
export function useGetEventStateNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventStateNameQuery, GetEventStateNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventStateNameQuery, GetEventStateNameQueryVariables>(GetEventStateNameDocument, options);
        }
export type GetEventStateNameQueryHookResult = ReturnType<typeof useGetEventStateNameQuery>;
export type GetEventStateNameLazyQueryHookResult = ReturnType<typeof useGetEventStateNameLazyQuery>;
export type GetEventStateNameQueryResult = Apollo.QueryResult<GetEventStateNameQuery, GetEventStateNameQueryVariables>;
export const ApiGetRolesForUserRoleChangedDocument = gql`
    query ApiGetRolesForUserRoleChanged {
  roles: role {
    id
    name
  }
}
    `;

/**
 * __useApiGetRolesForUserRoleChangedQuery__
 *
 * To run a query within a React component, call `useApiGetRolesForUserRoleChangedQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiGetRolesForUserRoleChangedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiGetRolesForUserRoleChangedQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiGetRolesForUserRoleChangedQuery(baseOptions?: Apollo.QueryHookOptions<ApiGetRolesForUserRoleChangedQuery, ApiGetRolesForUserRoleChangedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiGetRolesForUserRoleChangedQuery, ApiGetRolesForUserRoleChangedQueryVariables>(ApiGetRolesForUserRoleChangedDocument, options);
      }
export function useApiGetRolesForUserRoleChangedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiGetRolesForUserRoleChangedQuery, ApiGetRolesForUserRoleChangedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiGetRolesForUserRoleChangedQuery, ApiGetRolesForUserRoleChangedQueryVariables>(ApiGetRolesForUserRoleChangedDocument, options);
        }
export type ApiGetRolesForUserRoleChangedQueryHookResult = ReturnType<typeof useApiGetRolesForUserRoleChangedQuery>;
export type ApiGetRolesForUserRoleChangedLazyQueryHookResult = ReturnType<typeof useApiGetRolesForUserRoleChangedLazyQuery>;
export type ApiGetRolesForUserRoleChangedQueryResult = Apollo.QueryResult<ApiGetRolesForUserRoleChangedQuery, ApiGetRolesForUserRoleChangedQueryVariables>;
export const ApiGetPersonForUserRoleChangedDocument = gql`
    query ApiGetPersonForUserRoleChanged($id: uuid!) {
  person: person_by_pk(id: $id) {
    email
    name
    language {
      isocode
    }
  }
}
    `;

/**
 * __useApiGetPersonForUserRoleChangedQuery__
 *
 * To run a query within a React component, call `useApiGetPersonForUserRoleChangedQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiGetPersonForUserRoleChangedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiGetPersonForUserRoleChangedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiGetPersonForUserRoleChangedQuery(baseOptions: Apollo.QueryHookOptions<ApiGetPersonForUserRoleChangedQuery, ApiGetPersonForUserRoleChangedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiGetPersonForUserRoleChangedQuery, ApiGetPersonForUserRoleChangedQueryVariables>(ApiGetPersonForUserRoleChangedDocument, options);
      }
export function useApiGetPersonForUserRoleChangedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiGetPersonForUserRoleChangedQuery, ApiGetPersonForUserRoleChangedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiGetPersonForUserRoleChangedQuery, ApiGetPersonForUserRoleChangedQueryVariables>(ApiGetPersonForUserRoleChangedDocument, options);
        }
export type ApiGetPersonForUserRoleChangedQueryHookResult = ReturnType<typeof useApiGetPersonForUserRoleChangedQuery>;
export type ApiGetPersonForUserRoleChangedLazyQueryHookResult = ReturnType<typeof useApiGetPersonForUserRoleChangedLazyQuery>;
export type ApiGetPersonForUserRoleChangedQueryResult = Apollo.QueryResult<ApiGetPersonForUserRoleChangedQuery, ApiGetPersonForUserRoleChangedQueryVariables>;
export const BookCreateReadingGetDefaultValuesDocument = gql`
    query BookCreateReadingGetDefaultValues($userId: uuid!) {
  book_topic(
    order_by: {topic: {name: asc}}
    where: {book: {user_id: {_eq: $userId}}}
  ) {
    book_id
    topic {
      id
      name
    }
  }
  duration(where: {minutes: {_eq: 30}}) {
    id
  }
  language(where: {isocode: {_eq: "en-US"}}) {
    id
  }
  readingstate(where: {name: {_eq: "Scheduled"}}) {
    id
  }
}
    `;

/**
 * __useBookCreateReadingGetDefaultValuesQuery__
 *
 * To run a query within a React component, call `useBookCreateReadingGetDefaultValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookCreateReadingGetDefaultValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookCreateReadingGetDefaultValuesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBookCreateReadingGetDefaultValuesQuery(baseOptions: Apollo.QueryHookOptions<BookCreateReadingGetDefaultValuesQuery, BookCreateReadingGetDefaultValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookCreateReadingGetDefaultValuesQuery, BookCreateReadingGetDefaultValuesQueryVariables>(BookCreateReadingGetDefaultValuesDocument, options);
      }
export function useBookCreateReadingGetDefaultValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookCreateReadingGetDefaultValuesQuery, BookCreateReadingGetDefaultValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookCreateReadingGetDefaultValuesQuery, BookCreateReadingGetDefaultValuesQueryVariables>(BookCreateReadingGetDefaultValuesDocument, options);
        }
export type BookCreateReadingGetDefaultValuesQueryHookResult = ReturnType<typeof useBookCreateReadingGetDefaultValuesQuery>;
export type BookCreateReadingGetDefaultValuesLazyQueryHookResult = ReturnType<typeof useBookCreateReadingGetDefaultValuesLazyQuery>;
export type BookCreateReadingGetDefaultValuesQueryResult = Apollo.QueryResult<BookCreateReadingGetDefaultValuesQuery, BookCreateReadingGetDefaultValuesQueryVariables>;
export const BookIndexGetNextReadingDocument = gql`
    query BookIndexGetNextReading($userId: uuid!) {
  reading(
    limit: 1
    order_by: {readingstart: asc}
    where: {book: {user_id: {_eq: $userId}}, readingEventStatusName: {_nin: ["Canceled", "Incomplete"]}, readingstart: {_gte: "now()"}}
  ) {
    id
    maxreaders
    readingEventStatusName
    readingstart
    seats_available
    topicName
  }
}
    `;

/**
 * __useBookIndexGetNextReadingQuery__
 *
 * To run a query within a React component, call `useBookIndexGetNextReadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookIndexGetNextReadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookIndexGetNextReadingQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBookIndexGetNextReadingQuery(baseOptions: Apollo.QueryHookOptions<BookIndexGetNextReadingQuery, BookIndexGetNextReadingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookIndexGetNextReadingQuery, BookIndexGetNextReadingQueryVariables>(BookIndexGetNextReadingDocument, options);
      }
export function useBookIndexGetNextReadingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookIndexGetNextReadingQuery, BookIndexGetNextReadingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookIndexGetNextReadingQuery, BookIndexGetNextReadingQueryVariables>(BookIndexGetNextReadingDocument, options);
        }
export type BookIndexGetNextReadingQueryHookResult = ReturnType<typeof useBookIndexGetNextReadingQuery>;
export type BookIndexGetNextReadingLazyQueryHookResult = ReturnType<typeof useBookIndexGetNextReadingLazyQuery>;
export type BookIndexGetNextReadingQueryResult = Apollo.QueryResult<BookIndexGetNextReadingQuery, BookIndexGetNextReadingQueryVariables>;
export const BookIndexGetReadingsDocument = gql`
    query BookIndexGetReadings($limit: Int!, $offset: Int!, $readingWhere: reading_bool_exp!) {
  reading_aggregate(where: $readingWhere) {
    aggregate {
      count
    }
  }
  reading(
    limit: $limit
    offset: $offset
    order_by: {readingstart: asc}
    where: $readingWhere
  ) {
    id
    maxreaders
    readingEventStatusName
    readingstart
    seats_available
    topicName
    topic {
      id
      pillar_topics {
        pillar {
          id
          title
          description
        }
      }
    }
  }
}
    `;

/**
 * __useBookIndexGetReadingsQuery__
 *
 * To run a query within a React component, call `useBookIndexGetReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookIndexGetReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookIndexGetReadingsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      readingWhere: // value for 'readingWhere'
 *   },
 * });
 */
export function useBookIndexGetReadingsQuery(baseOptions: Apollo.QueryHookOptions<BookIndexGetReadingsQuery, BookIndexGetReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookIndexGetReadingsQuery, BookIndexGetReadingsQueryVariables>(BookIndexGetReadingsDocument, options);
      }
export function useBookIndexGetReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookIndexGetReadingsQuery, BookIndexGetReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookIndexGetReadingsQuery, BookIndexGetReadingsQueryVariables>(BookIndexGetReadingsDocument, options);
        }
export type BookIndexGetReadingsQueryHookResult = ReturnType<typeof useBookIndexGetReadingsQuery>;
export type BookIndexGetReadingsLazyQueryHookResult = ReturnType<typeof useBookIndexGetReadingsLazyQuery>;
export type BookIndexGetReadingsQueryResult = Apollo.QueryResult<BookIndexGetReadingsQuery, BookIndexGetReadingsQueryVariables>;
export const BookIndexGetTopicsDocument = gql`
    query BookIndexGetTopics($userId: uuid!) {
  book_topic(
    order_by: {topic: {name: asc}}
    where: {book: {user_id: {_eq: $userId}}}
  ) {
    book_id
    topic {
      id
      name
    }
  }
  pillar_topic(
    order_by: {pillar: {title: asc}}
    where: {topic: {book_topics: {book: {user_id: {_eq: $userId}}}}}
  ) {
    pillar {
      title
    }
    topic {
      book_topics {
        topicname
        book_id
        topic {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useBookIndexGetTopicsQuery__
 *
 * To run a query within a React component, call `useBookIndexGetTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookIndexGetTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookIndexGetTopicsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBookIndexGetTopicsQuery(baseOptions: Apollo.QueryHookOptions<BookIndexGetTopicsQuery, BookIndexGetTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookIndexGetTopicsQuery, BookIndexGetTopicsQueryVariables>(BookIndexGetTopicsDocument, options);
      }
export function useBookIndexGetTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookIndexGetTopicsQuery, BookIndexGetTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookIndexGetTopicsQuery, BookIndexGetTopicsQueryVariables>(BookIndexGetTopicsDocument, options);
        }
export type BookIndexGetTopicsQueryHookResult = ReturnType<typeof useBookIndexGetTopicsQuery>;
export type BookIndexGetTopicsLazyQueryHookResult = ReturnType<typeof useBookIndexGetTopicsLazyQuery>;
export type BookIndexGetTopicsQueryResult = Apollo.QueryResult<BookIndexGetTopicsQuery, BookIndexGetTopicsQueryVariables>;
export const GetReviewBookDocument = gql`
    query GetReviewBook($readingId: uuid!) {
  review_book_by_pk(reading_id: $readingId) {
    review_book_option_id
  }
}
    `;

/**
 * __useGetReviewBookQuery__
 *
 * To run a query within a React component, call `useGetReviewBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewBookQuery({
 *   variables: {
 *      readingId: // value for 'readingId'
 *   },
 * });
 */
export function useGetReviewBookQuery(baseOptions: Apollo.QueryHookOptions<GetReviewBookQuery, GetReviewBookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReviewBookQuery, GetReviewBookQueryVariables>(GetReviewBookDocument, options);
      }
export function useGetReviewBookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReviewBookQuery, GetReviewBookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReviewBookQuery, GetReviewBookQueryVariables>(GetReviewBookDocument, options);
        }
export type GetReviewBookQueryHookResult = ReturnType<typeof useGetReviewBookQuery>;
export type GetReviewBookLazyQueryHookResult = ReturnType<typeof useGetReviewBookLazyQuery>;
export type GetReviewBookQueryResult = Apollo.QueryResult<GetReviewBookQuery, GetReviewBookQueryVariables>;
export const GetBookReviewOptionsDocument = gql`
    query GetBookReviewOptions {
  review_book_option(order_by: {order: asc}) {
    id
    translationKey
  }
}
    `;

/**
 * __useGetBookReviewOptionsQuery__
 *
 * To run a query within a React component, call `useGetBookReviewOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookReviewOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookReviewOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookReviewOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetBookReviewOptionsQuery, GetBookReviewOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookReviewOptionsQuery, GetBookReviewOptionsQueryVariables>(GetBookReviewOptionsDocument, options);
      }
export function useGetBookReviewOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookReviewOptionsQuery, GetBookReviewOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookReviewOptionsQuery, GetBookReviewOptionsQueryVariables>(GetBookReviewOptionsDocument, options);
        }
export type GetBookReviewOptionsQueryHookResult = ReturnType<typeof useGetBookReviewOptionsQuery>;
export type GetBookReviewOptionsLazyQueryHookResult = ReturnType<typeof useGetBookReviewOptionsLazyQuery>;
export type GetBookReviewOptionsQueryResult = Apollo.QueryResult<GetBookReviewOptionsQuery, GetBookReviewOptionsQueryVariables>;
export const InsertReviewBookDocument = gql`
    mutation InsertReviewBook($readingId: uuid!, $reviewBookOptionId: uuid!, $text: String!) {
  insert_review_book_one(
    object: {reading_id: $readingId, review_book_option_id: $reviewBookOptionId, text: $text}
    on_conflict: {constraint: review_book_pkey, update_columns: [review_book_option_id, text]}
  ) {
    reading_id
  }
}
    `;
export type InsertReviewBookMutationFn = Apollo.MutationFunction<InsertReviewBookMutation, InsertReviewBookMutationVariables>;

/**
 * __useInsertReviewBookMutation__
 *
 * To run a mutation, you first call `useInsertReviewBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReviewBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReviewBookMutation, { data, loading, error }] = useInsertReviewBookMutation({
 *   variables: {
 *      readingId: // value for 'readingId'
 *      reviewBookOptionId: // value for 'reviewBookOptionId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInsertReviewBookMutation(baseOptions?: Apollo.MutationHookOptions<InsertReviewBookMutation, InsertReviewBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReviewBookMutation, InsertReviewBookMutationVariables>(InsertReviewBookDocument, options);
      }
export type InsertReviewBookMutationHookResult = ReturnType<typeof useInsertReviewBookMutation>;
export type InsertReviewBookMutationResult = Apollo.MutationResult<InsertReviewBookMutation>;
export type InsertReviewBookMutationOptions = Apollo.BaseMutationOptions<InsertReviewBookMutation, InsertReviewBookMutationVariables>;